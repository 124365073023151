export enum ProcedureMapEnum {
	ImplantPlanningGuideTooth = 24,
	ImplantPlanningGuideTissue = 25,
	ImplantPlanningGuideBone = 26,
	ImplantPlanningGuideToothChairside = 27,
	ImplantPlanningGuideTissueChairside = 28,
	ImplantPlanningGuideTissueWithoutSendTo = 60,
	ImplantPlanningGuideBoneChairside = 29,
	ImplantPlanningGuideBoneWithoutSendTo = 61,
	ImplantPlanningGuideToothWithoutSendTo = 59,
	ImplantPlanningGuideToothRealGuide = 108,
	ImplantPlanningGuideTissueRealGuide = 109,
	ImplantPlanningGuideBoneRealGuide = 110,
	ImplantPlanningGuideToothSprintRay = 113,
	ImplantPlanningGuideTissueSprintRay = 114,
	ImplantPlanningGuideBoneSprintRay = 115,
	FixedRestorativeLabResto = 30,
	FixedRestorativeChairsideExocad = 31,
	FixedRestorativeGlidewellIo = 32,
	FixedRestorativeWithoutSendTo = 62,
	FixedRestorativeMidcOnly = 88,
	FixedRestorativeSprintRay = 120,
	ImplantPlanningDefault = 44,
	ImplantPlanningDefaultChairside = 45,
	ImplantPlanningNoGuide = 46,
	ImplantPlanningNoGuideChairside = 47,
	ImplantPlanningNoGuideWithoutSendTo = 64,
	ImplantPlanningGuideToothMidcOnly = 85,
	ImplantPlanningGuideTissueMidcOnly = 86,
	ImplantPlanningGuideBoneMidcOnly = 87,
	ImplantPlanningNoGuideMidcOnly = 90,
	ImplantPlanningDefaultMidcOnly = 91,
	ImplantPlanningNoGuideRealGuide = 111,
	ImplantPlanningNoGuideSprintRay = 116,
	ImplantPlanningDefaultRealGuide = 112,
	ImplantPlanningDefaultSprintRay = 117,
	DentureRemovablePartialDentureFramework = 20,
	DentureRemovablePartialDentureFrameworkWithoutSendTo = 57,
	DentureRemovablePartialDentureFrameworkExocad = 73,
	DentureRemovablePartialDentureFrameworkMidcOnly = 83,
	DentureRemovablePartialDentureFrameworkSprintRay = 123,
	DentureRemovableImmediateDenture = 21,
	DentureRemovableImmediateDentureWithoutSendTo = 58,
	DentureRemovableImmediateDentureExocad = 74,
	DentureRemovableImmediateDentureMidcOnly = 84,
	DentureRemovableImmediateDentureSprintRay = 124,
	DentureRemovableFullDentureImplantBased = 23,
	DentureRemovableFullDentureImplantBasedWithoutSendTo = 71,
	DentureRemovableFullDentureImplantBasedExocad = 76,
	DentureRemovableFullDentureImplantBasedMidcOnly = 96,
	DentureRemovableFullDentureImplantBasedSprintRay = 126,
	DentureRemovableFullDentureTissueBasedSprintRay = 122,
	DentureRemovableFullImmediateDentureSprintRay = 125,
	DentureRemovable = 48,
	DentureRemovableSprintRay = 121,
	ApplianceSprintRay = 127,
	ApplianceBiteSplintSprintRay = 128,
	ApplianceOrthoApplianceSprintRay = 129,
	ApplianceSportsMouthGuardSprintRay = 130,
	ApplianceNightGuardSprintRay = 131,
	ApplianceApneaSleepApplianceSprintRay = 132,
	StudyModeliRecordSprintRay = 118,
	StudyModeliCastSprintRay = 119
}
