import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { SpinnerService } from '@core/services/spinner.service';
import { ShellStore } from '@shared/store/shell/shell-store';

@Injectable({
	providedIn: 'root'
})
export class LoaderInterceptor implements HttpInterceptor {
	showLoader = false;

	constructor(public spinnerService: SpinnerService, private shellQuery: ShellQuery, private shellStore: ShellStore) {
		this.shellQuery.isLoading$.subscribe(isLoading => (this.showLoader = isLoading));
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (this.showLoader) {
			this.spinnerService.start();
		}

		return next.handle(req).pipe(
			tap(response => {
				const key = 'url';

				if (response.hasOwnProperty('url') && new RegExp(['GetRxById', 'GetRxByOrderId'].join('|')).test(response[key])) {
					this.shellStore.update({ isRxPending: false });
				}
			}),
			finalize(() => {
				this.spinnerService.stop();
				this.shellStore.update({ isLoading: false });
			})
		);
	}
}
