<form
  [formGroup]="fromToForm"
  class="bridge-range-selector"
  fxLayout="row"
  fxLayoutAlign="space-between center"
>
  <div>
    <mat-form-field fxFlex class="selector-container" id="rx-bridge-range-from-selector">
      <mat-select
        disableOptionCentering
        [value]="fromControl.value"
        [formControl]="fromControl"
        id="from-list-selector"
        name="from"
        ngDefaultControl
      >
        <mat-option *ngFor="let option of fromOptions" [value]="option" [id]="'from-tooth-' + option.Id">
          {{ option.Name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div
      fxLayout="column"
      fxLayoutAlign="space-around center"
      class="mr-5 ml-5"
    >
      <div class="to-separator" [class.read-only-separator]="isReadOnly">{{ 'ToothEditor.To' | translate }}</div>
    </div>
    <mat-form-field fxFlex class="selector-container" id="rx-bridge-range-to-selector">
      <mat-select
        disableOptionCentering
        [value]="toControl.value"
        [formControl]="toControl"
        id="to-list-selector"
        name="to"
        ngDefaultControl
      >
        <mat-option *ngFor="let option of toOptions" [value]="option" [id]="'to-tooth-' + option.Id">
          {{ option.Name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</form>
