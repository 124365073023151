import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { BaseDestroyableComponent } from '@shared/base-classes/base-destroyable';
import { IdName } from '@shared/models/id-name';
import { combineLatest, merge, Observable } from 'rxjs';
import { switchMap, takeUntil, tap, filter, map, shareReplay, take } from 'rxjs/operators';
import { Procedure } from '@shared/models/procedure';
import { ProcedureFormState } from '@modules/order/models/procedure-form-state';
import { TreatmentStage } from '@shared/models/treatment-stage';
import { PrimaryProcedureOrderForm, ProcedureOrderValues, SecondaryProcedureOrderForm } from '@modules/order/models/order-form';
import { OrderV1Facade } from '@modules/order/order-v1.facade';
import { FavoritesEnum } from '@shared/models/enums/favorites.enum';
import { RoleTypeEnum } from '@shared/models/role-type';

@Component({
	selector: 'rx-procedure-flow-order',
	templateUrl: './procedure-flow-order.component.html',
	styleUrls: ['./procedure-flow-order.component.scss'],
	providers: [OrderV1Facade],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProcedureFlowOrderComponent extends BaseDestroyableComponent implements OnInit {
	isReadOnly$: Observable<boolean> = this.facade.isReadOnly$;
	isiCastToggleDisabled$: Observable<boolean> = combineLatest([
		this.isReadOnly$,
		this.facade.isReturned$,
		this.facade.isOrthoModelIcastEnabled$,
		this.facade.isStudyModelIRecordEnabled$
	]).pipe(
		map(
			([isReadOnly, isReturned, isOrthoModelIcastEnabled, isStudyModelIRecordEnabled]) =>
				!isOrthoModelIcastEnabled || !isStudyModelIRecordEnabled || isReadOnly || isReturned
		),
		shareReplay({ bufferSize: 1, refCount: true })
	);
	isProcedureAndTypeReadOnly$: Observable<boolean> = this.facade.isProcedureAndTypeReadOnly$.pipe(
		shareReplay({ bufferSize: 1, refCount: true })
	);
	formState$: Observable<ProcedureFormState> = this.facade.procedureFormState$;
	procedures$: Observable<Procedure[]> = this.facade.procedures$;
	treatmentStages$: Observable<TreatmentStage[]> = this.facade.procedureTreatmentStages$;
	availableLabs$: Observable<IdName[]> = this.facade.availableLabs$;
	minimalAvailableDueDate$: Observable<Date> = this.facade.minimalAvailableDueDate$;
	types$: Observable<IdName[]> = this.facade.types$;
	iCastOrthoModelOnValue$: Observable<IdName> = this.facade.iCastOrthoModelOnValue$;
	isSendToIDSMessageVisible$: Observable<boolean> = this.facade.isSendToIDSMessageVisible$;
	isCancellable$: Observable<boolean> = this.facade.isCancellable$;

	primeForm = this.formBuilder.group<PrimaryProcedureOrderForm>({
		procedure: null,
		type: null,
		sendTo: null,
		treatmentStage: null
	});

	secondaryForm = this.formBuilder.group<SecondaryProcedureOrderForm>({
		dueDate: undefined,
		currentAlignerId: ''
	});

	favoriteSendTo = FavoritesEnum.SendTo;
	isNotDoctor$: Observable<boolean> = this.facade.userRole$.pipe(
		map(userRole => userRole !== RoleTypeEnum.Doctor),
		shareReplay({ bufferSize: 1, refCount: true })
	);

	get procedureControl() {
		return this.primeForm.controls.procedure;
	}
	get sendToControl() {
		return this.primeForm.controls.sendTo;
	}
	get typeControl() {
		return this.primeForm.controls.type;
	}
	get treatmentStageControl() {
		return this.primeForm.controls.treatmentStage;
	}
	get dueDateControl() {
		return this.secondaryForm.controls.dueDate;
	}
	get currentAlignerIdControl() {
		return this.secondaryForm.controls.currentAlignerId;
	}

	constructor(
		private facade: OrderV1Facade,
		private formBuilder: FormBuilder
	) {
		super();
	}

	ngOnInit(): void {
		this.subscribeToUserChanges();
		this.subscribeToRxLoaded();
		this.subscribeToGetDirectToLab();
		this.subscribeToSetDirectToLab();
		this.subscribeToGetDefaultProcedure();
	}

	clearControl(control: FormControl) {
		control.patchValue(null);
	}

	private subscribeToGetDirectToLab(): void {
		this.facade.getDirectToLab().pipe(takeUntil(this.componentAlive$)).subscribe();
	}

	private subscribeToSetDirectToLab(): void {
		this.facade.setDirectToLab().pipe(takeUntil(this.componentAlive$)).subscribe();
	}

	private subscribeToUserChanges(): void {
		merge(
			this.secondaryForm.valueChanges.pipe(
				tap((formValue: SecondaryProcedureOrderForm) => this.facade.handleProcedureFlowValuesChanges(formValue))
			),
			this.procedureControl.valueChanges.pipe(
				switchMap((procedure: IdName) => this.facade.handleProcedureChanged(procedure.Id)),
				filter(result => !!result),
				tap((result: { returnToProcedure?: IdName; procedureOrderValues?: ProcedureOrderValues }) => {
					if (result.returnToProcedure) {
						this.procedureControl.patchValue(result.returnToProcedure, { emitEvent: false });

						return;
					}

					this.primeForm.patchValue(result.procedureOrderValues, { emitEvent: false });
					this.secondaryForm.patchValue(result.procedureOrderValues, { emitEvent: false });
					this.typeControl.markAsUntouched();
				})
			),
			this.typeControl.valueChanges.pipe(
				switchMap((type: IdName) => this.facade.handleTypeChanged(type ? type.Id : 0)),
				filter(result => result.shouldResetSendTo),
				tap(() => this.sendToControl.patchValue(null, { emitEvent: false }))
			),
			this.sendToControl.valueChanges.pipe(
				switchMap((type: IdName) => this.facade.handleSendToChanged(type)),
				tap(result => this.sendToControl.patchValue(result, { emitEvent: false }))
			),
			this.treatmentStageControl.valueChanges.pipe(
				map((treatmentStag: IdName) => this.facade.handleTreatmentStageChanges(treatmentStag)),
				filter((result: { shouldUpdateCurrentAligner: boolean; currentAligner: string }) => result.shouldUpdateCurrentAligner),
				tap((result: { currentAligner: string }) =>
					this.currentAlignerIdControl.patchValue(result.currentAligner, { emitEvent: false })
				)
			)
		)
			.pipe(takeUntil(this.componentAlive$))
			.subscribe();
	}

	private subscribeToRxLoaded(): void {
		this.facade
			.loadProcedureFlowOrder()
			.pipe(
				tap(procedureOrderValues => {
					this.primeForm.patchValue(procedureOrderValues, { emitEvent: false });
					this.secondaryForm.patchValue(procedureOrderValues, { emitEvent: false });
				}),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}

	private subscribeToGetDefaultProcedure(): void {
		this.facade
			.getDefaultProcedure()
			.pipe(
				take(1),
				tap(procedure => {
					if (procedure) {
						this.primeForm.patchValue({ procedure });
					}
				}),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}
}
