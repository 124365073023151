import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass } from '@angular/common';

@Component({
    selector: 'rx-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgClass, ExtendedModule, TranslateModule]
})
export class AlertComponent {

	@Input() type: 'success' | 'warning' | 'danger' | 'info' | 'primary' | 'secondary';

	@Input() alertKey: string;
}
