import { Injectable } from '@angular/core';
import { ProductTypes } from '@shared/models/enums/enums';
import { LimitedFeatures } from '@shared/models/limited-features';
import { scannerFeatureMapper } from '@shared/models/Mappers/scanner-feature-mapper';
import { ScannerTypes, scannerVersionPrefixByType } from '@shared/models/scanner-types';

@Injectable({ providedIn: 'root' })
export class VersionsService {
	isFeatureAvailableForScanner(feature: LimitedFeatures, clientVersion: string, packageVersion: string): boolean {
		const version = packageVersion || clientVersion;

		const scannerTypes = scannerFeatureMapper[feature][packageVersion ? 'packageVersion' : 'clientVersion'];
		const versionForComparation = this.getVersionForComparation(feature, scannerTypes, version);

		return this.versionIsHigherThanOrEqual(version, versionForComparation);
	}

	isFeatureAvailableForAllScanners(feature: LimitedFeatures, packageVersions: string[]): boolean {
		if (!packageVersions?.length) {
			return false;
		}

		return packageVersions.every(version => this.isFeatureAvailableForScanner(feature, version, version));
	}

	isFeatureAvailableForWeb(feature: LimitedFeatures, packageVersions: string[]): boolean {
		return packageVersions?.some(version => this.isFeatureAvailableForScanner(feature, version, version)) ?? false;
	}

	public findMaxVersions(versions: string[]): Partial<Record<scannerVersionPrefixByType, string>> {
		return versions.reduce((maxVersionPerPrefix, version) => {
			const versionPrefix = typeof version !== 'string' ? null : (version[0] as scannerVersionPrefixByType);

			if (
				versionPrefix &&
				(!maxVersionPerPrefix.hasOwnProperty(versionPrefix) ||
					this.versionIsHigherThanOrEqual(version, maxVersionPerPrefix[versionPrefix]))
			) {
				maxVersionPerPrefix[versionPrefix] = version;
			}

			return maxVersionPerPrefix;
		}, {} as Partial<Record<scannerVersionPrefixByType, string>>);
	}

	private versionIsHigherThanOrEqual(version: string, versionForComparison: string): boolean {
		if (!version || !versionForComparison) {
			return false;
		}

		const version1Parts = version.split('.');
		const version2Parts = versionForComparison.split('.');
		const length = Math.min(version1Parts.length, version2Parts.length);

		for (let index = 0; index < length; index++) {
			const diff = parseInt(version1Parts[index], 10) - parseInt(version2Parts[index], 10);

			if (diff !== 0) {
				return diff >= 0;
			}
		}

		return version.length - versionForComparison.length >= 0;
	}

	private getVersionForComparation(feature: LimitedFeatures, scannerTypes: ScannerTypes, version: string) {
		const versionPrefix = version ? version[0] : ProductTypes.empty;

		const scannerType = Object.keys(scannerVersionPrefixByType).find(key => {
			return scannerVersionPrefixByType[key] === versionPrefix;
		});
		const versionForComparation = scannerTypes[scannerType];

		return versionForComparation ?? scannerFeatureMapper[feature]?.anyVersionFrom;
	}
}
