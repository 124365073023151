<form
  [formGroup]="manufactureForm"
  fxLayout="row"
  fxLayoutAlign="space-between"
  fxLayout.sm="column"
  fxLayout.xs="column"
  [class.read-only]="isReadOnly"
>
    <rx-favorite-selector
            fxFlex="1 1 28%"
            [id]="'scan-body-manufacturer'"
            [label]="'ToothEditor.ScanBodyManufacturer' | translate"
            [isRequired]="shouldValidateForSend$ | async"
			[isCancellable]="(shouldValidateForSend$ | async) === false && isProcedureFlow"
            [formControl]="scanBodyManufacturerControl"
            [isReadOnly]="isReadOnly"
            [options]="scanBodyManufacturerOptions$ | async"
            [favoriteName]="favoriteScanBody"
            name="scanBodyManufacturerControl"
            [enableSimpleMode]="isFavoritesHidden$ | async"
    ></rx-favorite-selector>
    <rx-favorite-selector
            fxFlex="1 1 28%"
            [id]="'implant-manufacturer'"
            [label]="'ToothEditor.ImplantManufacturer' | translate"
            [isReadOnly]="isReadOnly"
            [isRequired]="shouldValidateForSend$ | async"
			[isCancellable]="(shouldValidateForSend$ | async) === false && isProcedureFlow"
            [formControl]="implantManufacturerControl"
            [options]="implantManufacturerOptions"
            [isDisabled]="!implantManufacturerOptions?.length"
            [favoriteName]="favoriteImplant"
            name="implantManufacturerControl"
            [enableSimpleMode]="isFavoritesHidden$ | async"
    ></rx-favorite-selector>
	<rx-select
			fxFlex="1 1 28%"
			[id]="'implant-type-scan-body'"
			[label]="'ToothEditor.ImplantTypeScanBody' | translate"
			[isDisabled]="!implantTypeScanBodyOptions?.length || isReadOnly"
			[isRequired]="shouldValidateForSend$ | async"
			[isCancellable]="(shouldValidateForSend$ | async) === false && isProcedureFlow"
			[formControl]="implantTypeScanBodyControl"
			[options]="implantTypeScanBodyOptions"
			[dropdownPositions]="'right'"
			[panelClass]="'implant-type-scan-body-panel'"
	></rx-select>
</form>
