export enum BiEventType {
	UserAction = 'UserActionType',
	SessionStart = 'SessionStartType',
	StageReport = 'StageReportType'
}

export enum BiAction {
	Clicked = 'click'
}

export enum BiLocationObject {
	RxContainer = 'Rx'
}

export enum BiObjectType {
	Button = 'button',
	ToggleButton = 'toggleButton',
	CheckBox = 'checkBox'
}

export enum BiBannerType {
	BannerRegister = 'banner-Register',
	BannerLearnMore = 'banner-LearnMore'
}

export enum BiObjectName {
	MultiBiteRestoScan = 'multiBiteRestoScan',
	IsDraft = 'attachmentOnHold'
}

export enum BiObject {
	Attachments = 'RXForm_Attachments',
	ScanOptions = 'RXForm_ScanOptions'
}

export enum BiStage {
	Save = 'save'
}
