import { Injectable } from '@angular/core';
import { ToothEditorQuery } from '@modules/tooth-editor/state/tooth-editor-query';
import { ToothEditorStore } from '@modules/tooth-editor/state/tooth-editor-store';
import { Tooth } from '@modules/teeth-diagram/models/tooth';
import { ToothEditorSharingService } from '@modules/tooth-editor/services/tooth-editor-sharing.service';
import { TeethManagerService } from '@modules/tooth-editor/state/teeth-manager-service';

@Injectable({
	providedIn: 'root'
})
export class ToothEditorUpdatingService {
	constructor(
		private toothEditorQuery: ToothEditorQuery,
		private toothEditorStore: ToothEditorStore,
		private toothEditorSharingService: ToothEditorSharingService,
		private teethManagerService: TeethManagerService
	) {}

	updateSelectedTooth(tooth: Tooth): void {
		const teeth = this.teeth;
		const indexOfToothToUpdate = teeth.findIndex(toothToUpdate => tooth.ToothID === toothToUpdate.ToothID);
		const updatedTeeth = [...teeth.slice(0, indexOfToothToUpdate), tooth, ...teeth.slice(indexOfToothToUpdate + 1)];

		this.toothEditorStore.updateToothClickedOn({ toothClickedOn: tooth });
		this.toothEditorStore.updateTeeth({ teeth: updatedTeeth });
	}

	updateSelectedToothAndSharedFieldsInBridge(tooth: Tooth): void {
		const teethAndSharedProps = this.toothEditorSharingService.sharePropsToTeeth(this.teeth, tooth);
		const toothClickedOn = teethAndSharedProps.find(t => t.tooth.ToothID === tooth.ToothID)?.tooth ?? tooth;

		this.teethManagerService.markManyAsShared(teethAndSharedProps);
		this.toothEditorStore.updateToothClickedOn({ toothClickedOn });
		this.toothEditorStore.updateTeeth({ teeth: teethAndSharedProps.map(s => s.tooth) });
	}

	private get teeth(): Tooth[] {
		return this.toothEditorQuery.teeth || [];
	}
}
