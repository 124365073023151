<div
    class="layout-container"
    fxLayout="row"
    fxLayoutAlign="center"
    id="rx-for-lab"
    [ngClass]="(isRxloadedAndReady$ | async) ? 'isVisible' : 'isHidden'"
>
    <div class="lab-container">
        <div class="lab-components-container" fxLayout="column" fxLayoutAlign="start">
            <rx-general-data-v1></rx-general-data-v1>
            <rx-denture-details-info [rx]="rx$ | async" [isResponsive]="true" *ngIf="procedureId$ | async | mapper : isDentureDetailsVisible">
            </rx-denture-details-info>
			<rx-scan-options-lab [rx]="rx$ | async"></rx-scan-options-lab>
            <div *ngIf="isTeethDiagramAreaVisible$ | async">
                <rx-teeth-diagram-v1></rx-teeth-diagram-v1>
                <rx-treatment-information-v1></rx-treatment-information-v1>
            </div>
            <div *ngIf="isAttachmentsSectionVisible$ | async" class="rx-card" id="attachments-section">
                <div class="rx-card-title">
                    <div class="rx-title-row" fxLayout="row" fxLayoutAlign="space-between center">{{
                        'AttachmentsSection.Attachments' | translate }}</div>
                </div>
                <div class="rx-card-body">
                    <rx-attachments
                        [rxId]="attachmentsRxId$ | async"
                        [companyId]="companyId$ | async"
                        [contactId]="contactId$ | async"
                        [userRole]="userRole$ | async"
                        [isReadOnly]="isAttachmentsReadOnly$ | async"
                        [biSessionInfo]="sessionInfo$ | async"
                        [timberUrl]="timberUrl$ | async"
                        (attachmentsListUpdated)="onAttachmentsListUpdated()">
                    </rx-attachments>
                </div>
            </div>
            <ng-content></ng-content>
        </div>
    </div>
</div>
