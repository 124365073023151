import { Bite } from '@modules/teeth-diagram/models/bite-diagram';
import { Bites } from '@modules/teeth-diagram/models/bites.enum';

export const additionalBites: Bite[] = [
	{
		id: Bites.AdditionalCentric,
		img: 'bite',
		textKey: 'AdditionalCentric'
	},
	{
		id: Bites.RightLateralExcursion,
		img: 'rightLateral',
		textKey: 'RightLateral'
	},
	{
		id: Bites.LeftLateralExcursion,
		img: 'leftLateral',
		textKey: 'LeftLateral'
	},
	{
		id: Bites.Protrusive,
		img: 'protrusive',
		textKey: 'Protrusive'
	},
	{
		id: Bites.Retrusive,
		img: 'retrusive',
		textKey: 'Retrusive'
	}
];
