export enum patientAppEventTypes {
	patientAppReady = 100,
	patientAppAddPatient = 101,
	patientAppSelectedPatient = 102,
	patientAppUpdateHostPatient = 103,
	patientAppSelectedPatientConflict = 104,
	patientAppSetPatientValidationsConfig = 105,
	patientAppRedirect = 106,
	patientAppDialog = 107,
	patientAppCreatePatient = 108,
	patientAppSearchPatient = 109,
	patientAppPatientInConflict = 110,
	patientAppError = 111,
	patientAppCancelConflictPopup = 112,
	patientAppConfirmConflictPopup = 113,
	patientAppClearPatient = 114
}
