import { UnitTypesInBridge } from './unit-type-in-bridge.enum';
import { UnitTypes } from './unit-type.enum';

export const unitTypeFolderNames: Record<number, string> = {
	[UnitTypes.Regular]: 'regular',
	[UnitTypes.Clasp]: 'clasp',
	[UnitTypes.Crown]: 'crown',
	[UnitTypes.ImplantAbutment]: 'implant-abutment',
	[UnitTypes.Inlay]: 'inlay',
	[UnitTypes.Onlay]: 'onlay',
	[UnitTypes.Veneer]: 'veneer',
	[UnitTypes.Detachable]: 'detachable',
	[UnitTypes.Missing_edentulousSpace]: 'missing-edentulous-space',
	[UnitTypes.ToBeRemoved]: 'missing-edentulous-space',
	[UnitTypes.Missing_noSpace]: 'missing-no-space',
	[UnitTypes.ScanBody]: 'scan-body',
	[UnitTypes.Crown_ThreeQuarters]: 'crown-three-quarters',
	[UnitTypes.VeneerOrLaminates]: 'veneer',
	[UnitTypes.ImplantBased]: 'scan-body',
	[UnitTypes.Missing]: 'missing-edentulous-space',
	[UnitTypes.ImplantPosition]: 'missing-edentulous-space',
	[UnitTypes.SupportingTooth]: 'supporting-tooth',
	[UnitTypes.EggshellCrown]: 'eggshell-crown'
};

export const unitTypeInBridgeFolderNames: Record<number, string> = {
	[UnitTypesInBridge.Abutment]: 'crown',
	[UnitTypesInBridge.MarylandAbutment]: 'regular',
	[UnitTypesInBridge.Pontic]: 'pontic',
	[UnitTypesInBridge.Skipped]: 'missing-no-space',
	[UnitTypesInBridge.ImplantAbutment]: 'implant-abutment',
	[UnitTypesInBridge.ScanBody]: 'scan-body',
	[UnitTypesInBridge.Maryland]: 'regular',
	[UnitTypesInBridge.Inlay]: 'inlay',
	[UnitTypesInBridge.Onlay]: 'onlay',
	[UnitTypesInBridge.ImplantBased]: 'implant-abutment',
	[UnitTypesInBridge.Missing]: 'missing-edentulous-space',
	[UnitTypesInBridge.EggshellBridge]: 'eggshell-crown'
};
