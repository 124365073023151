import { Component, Output, EventEmitter } from '@angular/core';
import { ToothEditorModalActions } from '@modules/teeth-diagram/models/tooth-editor-actions.enum';

@Component({
	selector: 'rx-tooth-editor-footer',
	styleUrls: ['./tooth-editor-footer.component.scss'],
	templateUrl: 'tooth-editor-footer.component.html'
})
export class ToothEditorFooterComponent {
	@Output() modalClosed = new EventEmitter<{ action: ToothEditorModalActions }>();

	handleDelete() {
		this.modalClosed.emit({ action: ToothEditorModalActions.Delete });
	}
}