export enum MaterialEnum {
	CeramicZirconia = 2009,
	CeramicTranslucentZirconia = 2010,
	CeramicMultilayerZirconia = 2011,
	CeramicLithiumDisilicate = 2012,
	CeramicLithiumSilicate = 2013,
	CeramicGlassCeramic = 2014,
	CeramicFeldspathicCeramic = 2015,
	CeramicHybrid = 2016,
	CeramicLabPreference = 2017,
	MetalHighNoble = 2018,
	MetalSemiPrecious = 2019,
	MetalTitanium = 2020,
	MetalCobaltChrome = 2021,
	ResinAcrylicPMMA = 2022,
	PolymerPEEK = 2023,
	Composite = 2024,
	OtherCheckNote = 2025,
	OtherWax = 2026
}
