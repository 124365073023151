import { ProcedureRule } from '../../models/procedure-unit-type-material-rules-config';
import { ProcedureMapEnum } from '../../models/procedure-map.enum';
import { allTeeth } from '../teeth-sets';
import { UnitTypesInBridge } from '@modules/teeth-diagram/models/unit-type-in-bridge.enum';

export const rulesForBridgeFixedRestorativeGlidewellIo: ProcedureRule = {
	Ids: [ProcedureMapEnum.FixedRestorativeGlidewellIo],
	TeethRules: [
		{
			Ids: allTeeth,
			UnitAndMaterialRules: {
				UnitTypeRules: [
					{
						Id: UnitTypesInBridge.Abutment
					},
					{
						Id: UnitTypesInBridge.Pontic
					},
					{
						Id: UnitTypesInBridge.Missing
					}
				]
			}
		}
	]
};
