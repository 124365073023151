import { MaterialOption } from "@shared/models/material-option";
import { MaterialToothEditorRule } from "@shared/models/material-tooth-editor-rule";

export class ProcedureToothEditorParser {
	constructor(private specMaterialToothEditorRules: MaterialToothEditorRule[]) {}

	getMaterialSpecOption(materialId: number, specId: number): MaterialOption {
		const options = this.specMaterialToothEditorRules.find(({ Id }) => Id === materialId)?.Options;
		return options?.find(option => option.ForSpecificationIds?.includes(specId));
	}
}