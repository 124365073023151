<div class="rectangle-container">
    <div class="rows-flex">
        <div fxLayout="row" fxFlex fxLayout="row grow" class="rx-card-title">
            <div class="general-info">{{ 'PrintRx.GeneralInfo' | translate }}</div>
        </div>
        <div class="flex-body">
            <div fxLayout="column" fxFlex>
                <rx-info-block
                    fxLayout="column"
                    class="info-box"
                    fxFlex
                    [fields]="[
            { name: 'DoctorSection.Doctor', id: 'general-doctor-name', value: doctorName$ | async },
            { name: 'PatientSection.Patient', id: 'general-patient-name', value: patientName$ | async },
            { name: 'Lab.LabName', id: 'general-lab-name', value: sendToLab$ | async }
                ]"
                ></rx-info-block>
            </div>
            <div class="separator"></div>
            <div fxLayout="column" fxFlex>
                <rx-info-block
                    fxLayout="column"
                    class="info-box"
                    fxFlex
                    [fields]="[
            {name: 'GeneralRxData.CaseType', id: 'general-case-type', value: caseType$ | async},
            {name: 'GeneralRxData.ChartNumber', id: 'general-chart-number', value: chartNumber$ | async}
                ]"
                ></rx-info-block>
            </div>
            <div class="separator"></div>
            <div fxLayout="column" fxFlex>
                <rx-info-block
                    fxLayout="column"
                    class="info-box"
                    fxFlex
                    [fields]="[
            {
              name: 'GeneralRxData.ScanDate',
              id: 'general-scan-date',
              value: scanDate$ | async | date: (dateFormat$ | async)
            },
            {
              name: 'GeneralRxData.DueDate',
              id: 'general-due-date',
              value: dueDate$ | async | date: (dateFormat$ | async):'UTC'
            },
            { 
                name: 'GeneralRxData.Status', 
                id: 'general-status',
                value: state$ | async 
            }
                ]"
                ></rx-info-block>
            </div>
            <div class="separator"></div>
            <div fxLayout="column" fxFlex>
                <rx-info-block
                    fxLayout="column"
                    class="info-box"
                    fxFlex
                    [fields]="[
            {
              name: 'GeneralRxData.Signature',
              id: 'general-signature',
              value: signature$ | async,
              isSignature: true
            },
            { name: 'GeneralRxData.License', id: 'general-license', value: license$ | async }
                ]"
                ></rx-info-block>
            </div>
        </div>

        <div fxLayout="row" fxFlex fxLayout="row grow" class="general-info-footer">
            <mat-checkbox
                class="pre-treatment-checkbox"
                [disabled]="true"
                [checked]="preTreatmentScan$ | async"
                name="preTreatmentScan"
            >
                {{ 'GeneralRxData.PreTreatmentScan' | translate }}
            </mat-checkbox>
        </div>
    </div>
</div>
