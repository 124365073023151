import { Injectable } from '@angular/core';
import { Tooth } from '@modules/teeth-diagram/models/tooth';
import { UnitTypes } from '@modules/teeth-diagram/models/unit-type.enum';
import { Doctor } from '@shared/models/doctor';
import { PopupIconNames } from '@shared/models/enums/popup-icon.enum';
import { PopUpActions } from '@shared/models/enums/popup-modal-actions.enum';
import { IdName } from '@shared/models/id-name';
import { PatientModelDto } from '@shared/models/rx-models/interfaces/patient-model-dto';
import { Observable } from 'rxjs';
import { BridgeService } from './bridge.service';
import { PopupService } from './popup.service';
import { RxRulesService } from './rx-rules-helper/rx-rules.service';
import { ProcedureMap } from '@shared/models/procedure-map';
import { CaseTypeFormStateService } from '@modules/order/services/case-type-form-state.service';
import { positiveIntegersOnlyRegExp } from '@shared/models/consts';
import { PatientQuery } from '@modules/patient/state/patient-query';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { OrderQuery } from '@modules/order/state/order-query';
import { ProcedureEnum } from '@core/procedure-helpers/models/procedure.enum';
import { MaterialOption } from '@shared/models/material-option';
import { UnitTypesInBridge } from '@modules/teeth-diagram/models/unit-type-in-bridge.enum';
import { RxModel } from '@shared/models/rx-models/interfaces/rx-model';
import { OrderInformationModel } from '@shared/models/rx-models/interfaces/order-information-model';
import { LoggerService } from '@core/services/logger/logger.service';
import { SpecificationEnum } from '@core/procedure-helpers/models/specification.enum';
import { DentureDetailsQuery } from '@modules/denture-details/state/denture-details.query';

interface ValidationPair {
	isMandatory: (t: Tooth) => boolean;
	isInvalid: (t: Tooth) => boolean;
}

@Injectable({ providedIn: 'root' })
export class ValidateRxService {
	private name = 'ValidateRxService';
	private static get requiredTypeProcedureIds() {
		return [ProcedureEnum.Appliance, ProcedureEnum.ImplantPlanning, ProcedureEnum.Denture_Removable, ProcedureEnum.Invisalign];
	}

	constructor(
		private popupService: PopupService,
		private bridgeService: BridgeService,
		private rxRulesService: RxRulesService,
		private caseTypeFormStateService: CaseTypeFormStateService,
		private patientQuery: PatientQuery,
		private shellQuery: ShellQuery,
		private orderQuery: OrderQuery,
		private logger: LoggerService
	) {}

	static isProcedureFlowOrderValid(procedureMap: ProcedureMap): boolean {
		return (
			!!procedureMap?.Id &&
			(!ValidateRxService.requiredTypeProcedureIds.includes(procedureMap.ProcedureId) || procedureMap.TypeId > 0)
		);
	}

	validateRxForSave({
		patient,
		caseType,
		currentAlignerId,
		procedureMap,
		doctor,
		rx
	}: {
		patient: PatientModelDto;
		caseType: IdName;
		currentAlignerId: string;
		procedureMap: ProcedureMap;
		doctor: Doctor;
		rx: RxModel;
	}): boolean {
		const isCaseTypeValid = this.isCaseTypeValid(caseType);
		const isCurrentAlignerIdValid = this.isCurrentAlignerIdValid(caseType, currentAlignerId);
		const isDoctorValid = this.isDoctorValid(doctor);
		const isProcedureFlowOrderValid = ValidateRxService.isProcedureFlowOrderValid(procedureMap);
		const isPatientValid = this.isPatientValid(patient || rx?.Patient);
		const isRxAttachmentsReady = this.shellQuery.isRxAttachmentsReady;

		const isOrderValid = (isCaseTypeValid && isCurrentAlignerIdValid) || isProcedureFlowOrderValid;

		return isOrderValid && isDoctorValid && isPatientValid && isRxAttachmentsReady;
	}

	isPatientValid(patient: PatientModelDto): boolean {
		const isPatientInConflict = this.patientQuery.isPatientInConflict;

		return !!patient?.FirstName && !!patient?.LastName && !isPatientInConflict;
	}

	isCaseTypeValid(caseType: IdName): boolean {
		return !!caseType?.Id;
	}

	isDoctorValid(doctor: Doctor): boolean {
		return !!doctor?.Licence && !!doctor?.Id;
	}

	openValidationPopup(): Observable<PopUpActions> {
		const popUpInput = {
			titleTranslationKey: 'Popup.Confirmation',
			contentTranslationKey: 'Validation.InvalidRxMessage',
			iconName: PopupIconNames.Question
		};

		return this.popupService.openConfirmationPopUp({ popUpInput });
	}

	isOrderInfoInvalidForSend(orderInfo: OrderInformationModel): boolean {
		const isOrderInfoInvalid =
			!orderInfo ||
			((orderInfo?.LocalIdeCadCamSystemId === null || orderInfo.LocalIdeCadCamSystemId <= 0) &&
				(orderInfo?.NumOfModels === null || orderInfo.NumOfModels <= 0));

		if (isOrderInfoInvalid) {
			this.logger.info('Rx has invalid order information for send.', {
				module: this.name
			});
		}

		return isOrderInfoInvalid;
	}

	isTreatmentInvalidForSend(tooth: Tooth): boolean {
		if (this.shellQuery.isProcedureFlow) {
			return this.isTreatmentInvalidForSendV1(tooth);
		}

		return this.isTreatmentInvalidForSendV0(tooth);
	}

	isTreatmentInvalidForSendV1(tooth: Tooth): boolean {
		const config = tooth.BridgeIndex ? this.getTreatmentValidationInBridgeConfig() : this.getTreatmentValidationConfig();

		for (const { isMandatory, isInvalid } of Object.values(config)) {
			if (isMandatory(tooth) && isInvalid(tooth)) {
				this.logger.info(`Tooth ${tooth.ToothID} is mandatory but invalid`, {
					module: this.name
				});

				return true;
			}
		}

		return false;
	}

	isDentureDetailsInvalidForSend(dentureDetails: DentureDetailsQuery): boolean {
		const isDentureDetailsInfoInvalid = !dentureDetails.shadeSystem || !dentureDetails.teethShade || !dentureDetails.gingival;

		if (isDentureDetailsInfoInvalid) {
			this.logger.info('Rx has invalid order information for send.', {
				module: this.name
			});
		}

		return isDentureDetailsInfoInvalid;
	}

	isDentureDetailsValidForSend(dentureDetails: DentureDetailsQuery): boolean {
		return !this.isDentureDetailsInvalidForSend(dentureDetails);
	}

	isTreatmentInvalidForSendV0(tooth: Tooth): boolean {
		const isBridgeTooth = !tooth.UnitTypeID;
		const unitTypeWithoutValidation = [UnitTypes.Detachable, UnitTypes.Missing_edentulousSpace, UnitTypes.Missing_noSpace].includes(
			tooth.UnitTypeID
		);

		if (
			(isBridgeTooth && this.bridgeService.isPonticMDAbutmentOrMissing(tooth.ToothInBridgeTypeID)) ||
			(!isBridgeTooth && unitTypeWithoutValidation)
		) {
			return false;
		}

		if (!this.orderQuery.isGlidewellOrder && (!tooth.MaterialID || tooth.MaterialID === -1)) {
			this.logger.info(`Invalid material for the tooth ${tooth.ToothID}`, {
				module: this.name
			});

			return true;
		}

		const unitTypeId = isBridgeTooth
			? this.bridgeService.convertFromBridgeToothUnitType({ unitTypeInBridge: tooth.ToothInBridgeTypeID })
			: tooth.UnitTypeID;

		const materialOption = this.orderQuery.isGlidewellOrder
			? this.getUniversalGlidewellMaterial()
			: this.rxRulesService.getMaterialUnitTypeOption({ materialId: tooth.MaterialID, unitTypeId });

		const isInvalidShadeBody = !materialOption && materialOption.BodyShadeMandatory && !tooth.ShadeBody;
		const isInvalidMarginDesign =
			!materialOption && materialOption.MarginDesignMandatory && (!tooth.MarginDesignLingualId || !tooth.MarginDesignBuccalId);
		const isInvalidPrepDesign =
			!materialOption &&
			materialOption.PrepDesignMandatory &&
			(!tooth.PreparationDesignBuccalId || !tooth.PreparationDesignLingualId);
		const isInvalidStumpShade = !materialOption && materialOption.StumpShadeMandatory && !tooth.StumpfShade;
		const isInvalidImplantType = unitTypeId === UnitTypes.ScanBody && !tooth.ImplantTypeID;

		const isInvalid =
			!materialOption ||
			isInvalidShadeBody ||
			isInvalidMarginDesign ||
			isInvalidPrepDesign ||
			isInvalidStumpShade ||
			isInvalidImplantType;

		if (isInvalid) {
			this.logger.info(
				`Invalid treatment for the tooth ${tooth.ToothID}. ` +
					`isInvalidShadeBody: ${isInvalidShadeBody} ` +
					`isInvalidMarginDesign: ${isInvalidMarginDesign} ` +
					`isInvalidPrepDesign: ${isInvalidPrepDesign} ` +
					`isInvalidStumpShade: ${isInvalidStumpShade} ` +
					`isInvalidImplantType: ${isInvalidImplantType}`,
				{
					module: this.name
				}
			);
		}

		return isInvalid;
	}

	private isCrownSectionInvalidV1(tooth: Tooth): boolean {
		if (!tooth.MaterialID || tooth.MaterialID === -1) {
			return true;
		}

		const materialOption = this.rxRulesService.getMaterialSpecOption(tooth.MaterialID, tooth.SpecificationId ?? SpecificationEnum.None);

		return (
			!materialOption ||
			(materialOption.BodyShadeMandatory && !tooth.ShadeBody) ||
			(materialOption.MarginDesignMandatory && (!tooth.MarginDesignLingualId || !tooth.MarginDesignBuccalId)) ||
			(materialOption.PrepDesignMandatory && (!tooth.PreparationDesignBuccalId || !tooth.PreparationDesignLingualId)) ||
			(materialOption.StumpShadeMandatory && !tooth.StumpfShade)
		);
	}

	private getTreatmentValidationConfig(): Record<string, ValidationPair> {
		const isMandatory = (t: Tooth) => ![
			UnitTypes.Detachable,
			UnitTypes.SupportingTooth,
			UnitTypes.Missing,
			UnitTypes.EggshellCrown
		].includes(t.UnitTypeID);
		const isImplantPosition = (t: Tooth) => t.UnitTypeID === UnitTypes.ImplantPosition;

		return {
			forCrown: {
				isMandatory: (t: Tooth) => isMandatory(t) && !isImplantPosition(t) && this.isCrownMandatory(),
				isInvalid: (t: Tooth) => this.isCrownSectionInvalidV1(t)
			},
			forScanBody: {
				isMandatory: (t: Tooth) => isMandatory(t) && UnitTypes.ImplantBased === t.UnitTypeID && !this.orderQuery.isGlidewellOrder,
				isInvalid: (t: Tooth) => !t.ImplantTypeID
			}
		};
	}

	private getTreatmentValidationInBridgeConfig(): Record<string, ValidationPair> {
		const isMandatory = (t: Tooth) => ![UnitTypesInBridge.Missing].includes(t.ToothInBridgeTypeID);

		return {
			forCrown: {
				isMandatory: (t: Tooth) => isMandatory(t) && this.isCrownMandatory(),
				isInvalid: (t: Tooth) => this.isCrownSectionInvalidV1(t)
			},
			forScanBody: {
				isMandatory: (t: Tooth) => isMandatory(t) && t.ToothInBridgeTypeID === UnitTypesInBridge.ImplantBased,
				isInvalid: (t: Tooth) => !t.ImplantTypeID
			}
		};
	}

	private isCrownMandatory(): boolean {
		return !(this.orderQuery.isGlidewellOrderV1 || this.orderQuery.isDentureOrderV1);
	}

	private isCurrentAlignerIdValid(caseType: IdName, currentAlignerId: string): boolean {
		if (!this.caseTypeFormStateService.isCurrentAlignerRequired({ caseType })) {
			return true;
		}

		return !currentAlignerId || (positiveIntegersOnlyRegExp.exec(currentAlignerId) && +currentAlignerId <= 99);
	}

	private getUniversalGlidewellMaterial(): MaterialOption {
		return {
			StumpShadeMandatory: false,
			BodyShadeMandatory: false,
			MarginDesignMandatory: false,
			PrepDesignMandatory: false
		};
	}
}
