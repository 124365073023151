import { defaultDateFormat } from '@shared/models/consts';
import { PatientModelDto } from '@shared/models/rx-models/interfaces/patient-model-dto';
import moment from 'moment';

export class DateOfBirthUtils {
	public static getFormat(dateFormat: string, regulatoryDOBMask: string): string {
		dateFormat = dateFormat ?? defaultDateFormat;
		if (!regulatoryDOBMask) {
			return dateFormat.toUpperCase();
		}

		dateFormat = [/yyyy/i, /mm/i, /dd/i].reduce(
			(resultDateFormat, datePart) => DateOfBirthUtils.cutDateFormat(resultDateFormat, regulatoryDOBMask, datePart),
			dateFormat
		);
		// remove duplicated separator.
		dateFormat = dateFormat.replace(/([^a-z]+)(?=[^a-z])/gi, '');
		// trim separators.
		return dateFormat.replace(/^[^a-z]+|[^a-z]+$/gi, '').toUpperCase();
	}

	public static format(dateOfBirth: string | Date, dateFormat: string, regulatoryDOBMask?: string): string {
		if (!dateOfBirth) {
			return '';
		}

		dateFormat = DateOfBirthUtils.getFormat(dateFormat, regulatoryDOBMask);

		if (typeof dateOfBirth !== 'string') {
			return moment(dateOfBirth).format(dateFormat);
		}

		if (dateOfBirth.includes('T')) {
			dateOfBirth = dateOfBirth.split('T').shift();
		}

		if (regulatoryDOBMask) {
			const formattedDateOfBirth = moment(dateOfBirth, regulatoryDOBMask);
			if (formattedDateOfBirth.isValid()) {
				return formattedDateOfBirth.format(dateFormat);
			}
		}

		if (moment(dateOfBirth, dateFormat, true).isValid()) {
			return dateOfBirth;
		}

		const result = moment(dateOfBirth);
		return result.isValid() ? result.format(dateFormat) : '';
	}

	public static getDate(dateString: string, dateFormat?: string, regulatoryDOBMask?: string): Date {
		if (!dateString) {
			return null;
		}

		dateFormat = !dateFormat && regulatoryDOBMask ? regulatoryDOBMask : DateOfBirthUtils.getFormat(dateFormat, regulatoryDOBMask);
		return this.parseToLocalTimeZone(dateString, dateFormat).toDate();
	}

	public static makePatientDateOfBirthFull(patient: PatientModelDto): PatientModelDto {
		if (!patient?.DateOfBirth) {
			return patient;
		}

		const fullDate = DateOfBirthUtils.format(patient.DateOfBirth, 'YYYY-MM-DD');
		return { ...patient, ...{ DateOfBirth: fullDate } };
	}

	private static cutDateFormat(dateFormat: string, regulatoryDOBMask: string, datePart: RegExp) {
		// eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
		return regulatoryDOBMask.match(datePart) ? dateFormat : dateFormat.replace(datePart, '');
	}

	private static parseToLocalTimeZone(dateString: string, dateFormat: string): moment.Moment {
		const localDateTime = dateString.replace('Z', '');
		let result = moment(localDateTime, dateFormat);

		if (!result.isValid()) {
			result = moment(localDateTime);
		}
		return result;
	}
}
