<div [id]="id" class="incremental-selector" fxFlex fxLayout="row" fxLayoutAlign="start center">
    <div *ngIf="label" fxFlex="1 1 auto">{{label}}</div>
    <div fxFlex="1 1 30%" fxLayout="row" fxLayoutAlign="start center">
        <button [id]="id + '-decrement-button'" mat-button (click)="decrement()">
            -
        </button>
        <div class="value-indicator">{{ formControl?.value }}</div>
        <button [id]="id + '-increment-button'" mat-button (click)="increment()">
            +
        </button>
    </div>
</div>
