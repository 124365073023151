import { Component, OnInit } from '@angular/core';
import { TreatmentInformationFacade } from '@modules/treatment-information/treatment-information.facade';
import { ToothEditorService } from '@shared/services/tooth-editor.service';
import { TreatmentInformationComponentPart } from '../models/treatment-information-component.enum';
import { TOOTH_EDITOR_PROVIDERS } from '@modules/tooth-editor/injection/providers';
import { TreatmentInformationBaseComponent } from '../base/treatment-information-base.component';
import { Tooth } from '@modules/teeth-diagram/models/tooth';
import { ToothEditorFacade } from '@modules/tooth-editor/tooth-editor.facade';
import { UnitTypes } from '@modules/teeth-diagram/models/unit-type.enum';
import { ImplantService } from '@modules/tooth-editor/services/implant.service';

@Component({
	selector: 'rx-treatment-information-v0',
	templateUrl: './treatment-information-v0.component.html',
	styleUrls: ['../base/treatment-information-base.component.scss'],
	providers: [TOOTH_EDITOR_PROVIDERS, TreatmentInformationFacade, ToothEditorFacade]
})
export class TreatmentInformationV0Component extends TreatmentInformationBaseComponent implements OnInit {
	componentPart = {
		treatmentInformation: TreatmentInformationComponentPart.TreatmentInformation,
		bridgeInformation: TreatmentInformationComponentPart.BridgeInformation
	};

	constructor(
		protected facade: TreatmentInformationFacade,
		protected toothEditorService: ToothEditorService,
		private implantService: ImplantService
	) {
		super(facade, toothEditorService);
	}

	getAdditionalInfo = (tooth: Tooth): string => {
		if (tooth.UnitTypeID === UnitTypes.ScanBody) {
			const scanBodyName = this.implantService.getSelectedValuesByImplantId(tooth?.ImplantTypeID)?.implantTypeScanBody?.Name;
			return scanBodyName ? ` - ${scanBodyName}` : null;
		}
		return null;
	};
}
