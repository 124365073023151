import { Component, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { TeethNumberingSystem } from '@modules/teeth-diagram/models/teeth-numbering-system.enum';
import { getToothNumberById } from '@modules/teeth-diagram/models/teeth-numbering';
import { Tooth } from '@modules/teeth-diagram/models/tooth';

@Component({
	selector: 'rx-mini-teeth-diagram',
	templateUrl: './mini-teeth-diagram.component.html',
	styleUrls: ['./mini-teeth-diagram.component.scss']
})
export class MiniTeethDiagramComponent {
	@Input() teethNumberingSystem: TeethNumberingSystem;
	@Input() teeth: Tooth[];
	@Input() toothClickedOn: Tooth;
	@Input() isReadOnly: boolean;
	@Output() toothClicked = new EventEmitter<{ tooth: Tooth; event: any; isReadOnly?: boolean }>();
	enableToothSelectionInReadOnlyBridge = false;
	@Input() isGlidewellOrder: boolean;

	getToothNumberById = getToothNumberById;

	handleToothClick(event: { tooth: Tooth; event: any; isReadOnly?: boolean }) {
		this.toothClicked.emit(event);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (
			changes.teeth?.currentValue !== changes.teeth?.previousValue &&
			changes.isReadOnly?.currentValue !== changes.isReadOnly?.previousValue
		) {
			this.enableToothSelectionInReadOnlyBridge = changes.teeth.currentValue.every(tooth => tooth.BridgeIndex);
		}
	}
}
