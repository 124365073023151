import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { audit, filter, map, mapTo, tap, withLatestFrom } from 'rxjs/operators';
import { OrderState, OrderStore } from '@modules/order/state/order-store';
import { combineQueries } from '@datorama/akita';
import { OrderQuery } from '@modules/order/state/order-query';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { ProcedureConfigurationService } from '@modules/order/services/procedure-configuration.service';
import { ProcedureOrderValues } from '@modules/order/models/order-form';
import { LoggerService } from '@core/services/logger/logger.service';
import { ProcedureMap } from '@shared/models/procedure-map';

@Injectable({
	providedIn: 'root'
})
export class ProcedureLoadRxService {
	constructor(
		private store: OrderStore,
		private query: OrderQuery,
		private shellQuery: ShellQuery,
		private procedureConfigurationService: ProcedureConfigurationService,
		private loggerService: LoggerService
	) {}

	readonly name = 'ProcedureLoadRxService';

	loadProcedureFlowOrder(): Observable<ProcedureOrderValues> {
		return this.shellQuery.rx$.pipe(
			filter(rx => !!rx),
			// wait until order data is loaded for withLatestFrom to emit loaded values
			audit(_ => this.availableProcedureMapIdsSynced),
			withLatestFrom(this.shellQuery.rxConfiguration$, this.shellQuery.allLabs$),
			map(([rx, rxConfiguration, labs]) => {
				const procedureMap = rxConfiguration.RxRules.ProceduresMap.find(x => x.Id === rx.Order.ProcedureMapId);
				const sendTo =
					labs.find(lab => lab.Id === rx.Order.ShipToId) ??
					this.procedureConfigurationService.getSendToIdForNotLabs(procedureMap?.SendToTypes);
				const dueDate = rx.Order.DueDate ? new Date(rx.Order.DueDate) : null;
				const treatmentStage = rxConfiguration.TreatmentStages.find(ts => ts.Id === rx.TreatmentStage) ?? null;
				const state: Partial<OrderState> = {
					availableLabs: this.procedureConfigurationService.getAvailableLabs({
						procedureId: procedureMap?.ProcedureId,
						typeId: procedureMap?.TypeId
					}),
					isMultiBiteSelected: rx.MultiBiteScan,
					treatmentStage,
					dueDate,
					currentAlignerId: rx.AlignerNumber,
					sendTo,
					procedureMap
				};

				this.store.update({ ...state });

				return {
					procedure: rxConfiguration.Procedures.find(x => x.Id === procedureMap.ProcedureId),
					type: rxConfiguration.Types.find(x => x.Id === procedureMap.TypeId ),
					sendTo,
					treatmentStage,
					dueDate,
					currentAlignerId: rx.AlignerNumber
				};
			}),
		);
	}
	// TODO: move it to app.facade where we update all stores first time with rx, config, version and maps
	private get availableProcedureMapIdsSynced(): Observable<true> {
		return combineQueries([this.query.availableProcedureMaps$, this.shellQuery.rx$, this.shellQuery.rxConfiguration$]).pipe(
			filter(([availableProcedureMaps, rx, rxConfiguration]) => !!(rx && Array.isArray(availableProcedureMaps) && rxConfiguration)),
			tap(([availableProcedureMaps, rx, rxConfiguration]) => {
				const id = rx.Order?.ProcedureMapId;
				if (id === null || id === undefined) {
					return;
				}
				if (!availableProcedureMaps.some(({Id}) => Id === id)) {
					const procedureMap = rxConfiguration.RxRules.ProceduresMap.find(({ Id }) => Id === id);

					if (procedureMap) {
						this.store.addAvailableProcedureMap(procedureMap);
					} else {
						this.logProcedureMapsSyncedError(id, rxConfiguration.RxRules.ProceduresMap);
					}
				}
			}),
			filter(([availableProcedureMaps, rx]) => {
				const id = rx.Order?.ProcedureMapId;
				return id === null || id === undefined ? true : availableProcedureMaps.some(({Id}) => Id === id);
			}),
			mapTo(true)
		);
	}

	private logProcedureMapsSyncedError(id: number, procedureMaps: ProcedureMap[]): void {
		this.loggerService.error(
			`ProcedureMapId: ${id} from loaded RX cant be found in rxConfig (ids are ${procedureMaps.map(({Id}) => Id) } )`,
			{module: this.name}
		);
	}
}
