import { Query } from '@datorama/akita';
import { LocalSettingsState, LocalSettingsStore } from '@shared/store/localSettings/local-settings-store';
import { LocalSettings } from '@shared/models/local-settings';
import { Injectable } from '@angular/core';
import { FavoritesSettings } from '@shared/models/favorites-settings';
import { RegisterBannerSetting } from '@shared/models/rx-models/interfaces/rx-model';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LocalSettingsQuery extends Query<LocalSettingsState> {
	get userSettings(): Record<number, LocalSettings> {
		return this.getValue().userSettings;
	}

	get favoritesSettings(): Record<number, FavoritesSettings> {
		return this.getValue().favoritesSettings;
	}
	get registerDesignSuiteSettings(): Observable<Record<number, RegisterBannerSetting>> {
		return this.select(state => state.registerBannerSetting);
	}
	constructor(store: LocalSettingsStore) {
		super(store);
	}
}
