import { NgModule } from '@angular/core';
import { ToothEditorComponent } from './containers/tooth-editor/v1/tooth-editor.component';
import { CrownSectionComponent } from '@modules/tooth-editor/containers/crown-section/v1/crown-section.component';
import { ToothEditorSharedModule } from '@modules/tooth-editor/tooth-editor-shared.module';
import { MiniTeethDiagramComponent } from '@modules/tooth-editor/components/mini-teeth-diagram/v1/mini-teeth-diagram.component';
import { TlkModalWindowModule } from '@itero/ui-toolkit-angular/modal-window';

@NgModule({
	declarations: [
		ToothEditorComponent,
		CrownSectionComponent,
		MiniTeethDiagramComponent
	],
    imports: [ToothEditorSharedModule, TlkModalWindowModule],
	exports: []
})
export class ToothEditorV1Module {}
