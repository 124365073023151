import { NgModule } from '@angular/core';

// Angular Material Components
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
// import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatRippleModule } from '@angular/material/core';
// import { MatSliderModule } from '@angular/material/slider';
// import { MatSidenavModule } from '@angular/material/sidenav';
// import { MatCardModule } from '@angular/material/card';
// import { MatStepperModule } from '@angular/material/stepper';
// import { MatTabsModule } from '@angular/material/tabs';
// import { MatButtonToggleModule } from '@angular/material/button-toggle';
// import { MatChipsModule } from '@angular/material/chips';
// import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
// import { MatProgressBarModule } from '@angular/material/progress-bar';
// import { MatSnackBarModule } from '@angular/material/snack-bar';
// import { MatSortModule } from '@angular/material/sort';
// import { MatPaginatorModule } from '@angular/material/paginator';

@NgModule({
	declarations: [],
	imports: [
		MatButtonModule,
		MatToolbarModule,
		MatFormFieldModule,
		MatInputModule,
		MatSelectModule,
		MatSlideToggleModule,
		MatGridListModule,
		MatIconModule,
		MatCheckboxModule,
		MomentDateModule,
		MatMomentDateModule,
		MatMenuModule,
		MatListModule,
		MatDialogModule,
		ClipboardModule,
		MatSnackBarModule,
		MatTableModule,
		MatTooltipModule,
		MatRippleModule,
		// MatAutocompleteModule,
		MatRadioModule
		// MatSliderModule,
		// MatSidenavModule,
		// MatCardModule,
		// MatStepperModule,
		// MatTabsModule,
		// MatExpansionModule,
		// MatButtonToggleModule,
		// MatChipsModule,
		// MatProgressSpinnerModule,
		// MatProgressBarModule,
		// MatSortModule,
		// MatPaginatorModule
	],
	exports: [
		MatButtonModule,
		MatToolbarModule,
		MatFormFieldModule,
		MatInputModule,
		MatSelectModule,
		MatSlideToggleModule,
		MatGridListModule,
		MatIconModule,
		MatCheckboxModule,
		MatDatepickerModule,
		MatMenuModule,
		MatListModule,
		MatDialogModule,
		MatExpansionModule,
		ClipboardModule,
		MatSnackBarModule,
		MatTableModule,
		MatTooltipModule,
		MatRippleModule,
		MomentDateModule,
		MatMomentDateModule,
		// MatAutocompleteModule,
		MatRadioModule
		// MatSliderModule,
		// MatSidenavModule,
		// MatCardModule,
		// MatStepperModule,
		// MatTabsModule,
		// MatButtonToggleModule,
		// MatChipsModule,
		// MatProgressSpinnerModule,
		// MatProgressBarModule,
		// MatSortModule,
		// MatPaginatorModule
	]
})
export class MaterialModule {}
