<div class="rx-card read-only read-only-container" id="patient-app-read-only">
    <div class="rx-card-title">
        <div class="rx-title-row" fxLayout="row" fxLayoutAlign="space-between center">
            <div>{{ 'PatientSection.Patient' | translate }}:</div>
            <div *ngIf="isButtonsVisible" class="clickable">
                <button
                    *ngIf="isEditButtonVisible"
                    mat-raised-button
                    [disabled]="!patient"
                    (click)="editPatient()"
                    class="buttons-margin edit-button action-icon"
                    [ngClass]="{ 'buttons-margin edit-button mat-primary action-icon': true, 'edit-button-disabled': !patient }"
                    id="patient-read-only-edit-button"
                    mat-icon-button
                >
                    <mat-icon class="edit-icon" [svgIcon]="patient ? 'pencil_dark' : 'pencil_icon_disabled'"></mat-icon>
                </button>
                <button
                    *ngIf="isClearButtonVisible"
                    mat-raised-button
                    [disabled]="!patient"
                    (click)="clearPatient()"
                    class="clear-button mat-primary clear-button-content buttons-margin action-icon"
                    [ngClass]="{ 'clear-button-disabled': !patient }"
                    id="patient-read-only-clear-button"
                    mat-icon-button
                >
                    {{ 'PatientSection.Clear' | translate }}
                </button>
            </div>
        </div>
    </div>
    <div class="rx-card-body read-only">
        <form [formGroup]="formGroup">
            <div fxLayout="row" fxLayoutAlign="space-between">
                <mat-form-field fxFlex="0 1 calc(50% - 15px)" class="read-only">
                    <mat-label>{{ 'PatientSection.FirstName' | translate }}:</mat-label>
                    <input matInput id="patient-read-only-first-name" [formControl]="firstNameControl"  />
                </mat-form-field>

                <mat-form-field fxFlex="0 1 calc(50% - 15px)" class="read-only">
                    <mat-label>{{ 'PatientSection.LastName' | translate }}:</mat-label>
                    <input matInput id="patient-read-only-last-name" [formControl]="lastNameControl"  />
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between">
                <mat-form-field fxFlex="0 1 calc(50% - 15px)" class="read-only">
                    <mat-label>{{ 'PatientSection.DateOfBirth' | translate }}:</mat-label>
                    <input
                        matInput
                        id="patient-read-only-date-of-birth"
                        [formControl]="dateOfBirthControl"                    />
                </mat-form-field>
                <div class="gender-container">
                    <div class="group-container">
                        <mat-label class="label-gender">{{ 'PatientSection.Gender' | translate }}: </mat-label>
                        <mat-radio-group fxLayout="row" fxFlex fxLayoutAlign="flex-start" [disabled]="true" class="read-only">
                            <mat-radio-button [checked]="genderOption === 0" value="0">{{
                                'PatientSection.Male' | translate
                            }}</mat-radio-button>
                            <mat-radio-button [checked]="genderOption === 1" value="1">{{
                                'PatientSection.Female' | translate
                            }}</mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div class="underline"></div>
                </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between">
                <mat-form-field fxFlex="0 1 calc(50% - 15px)" class="read-only">
                    <mat-label>{{ 'PatientSection.ChartNumber' | translate }}:</mat-label>
                    <input matInput [formControl]="chartNumberControl" id="patient-read-only-chart-number" />
                </mat-form-field>
            </div>
        </form>
    </div>
</div>
