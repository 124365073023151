import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { Doctor } from '@shared/models/doctor';

export interface DoctorState {
	doctor: Doctor,
	isLicenseEditable: boolean
}

export const createInitialState = (): DoctorState => ({
	doctor: {
		Id: null,
		Name: null,
		Licence: null
	},
	isLicenseEditable: true
});

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'doctor' })
export class DoctorStore extends Store<DoctorState> {
	constructor() {
		super(createInitialState());
	}
}
