import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface TracesState {
	configurationTraceId: string;
	featureTogglesTraceId: string;
	userSettingsTraceId: string;
	availableCaseTypesTraceId: string;
	availableProcedureMapIdsTraceId: string;
	savingTraceId: string;
	getRxTraceId: string;
}

export const getInitialState = (): TracesState => {
	return {
		configurationTraceId: undefined,
		featureTogglesTraceId: undefined,
		userSettingsTraceId: undefined,
		availableCaseTypesTraceId: undefined,
		availableProcedureMapIdsTraceId: undefined,
		savingTraceId: undefined,
		getRxTraceId: undefined
	};
};

@Injectable({
	providedIn: 'root'
})
@StoreConfig({ name: 'TracesStore' })
export class TracesStore extends Store<TracesState> {
	constructor() {
		super(getInitialState());
	}
}
