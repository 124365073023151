<div class="mould-element">
    <img
        [src]="mould | mapper : getMouldImage.bind(this)"
        class="mould-element__image"
        [class.mould-element__image--selected]="isSelected"
        [alt]="mould?.Name"/>
    <div class="mould-element__name" *ngIf="showName">
        {{mould?.Name}}
    </div>
</div>
