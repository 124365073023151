import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FlexModule } from '@angular/flex-layout/flex';

@Component({
	selector: 'rx-doctor-name',
	templateUrl: './doctor-name.component.html',
	styleUrls: ['./doctor-name.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [FlexModule, TranslateModule]
})
export class DoctorNameComponent {
	@Input() doctorName: string;
}
