import { UnitTypes } from '@modules/teeth-diagram/models/unit-type.enum';
import { CaseTypeRule, TeethRule, TeethUnitType, UnitTypeRule } from '@shared/models/rx-rules-json-interface';

export class CaseTypeRulesParser {
	constructor(private caseTypeRules: CaseTypeRule[]) {}

	getUnitTypeIdsByCaseTypeIdAndToothId(caseTypeId: number, toothId: number, currentUnitTypeOnTooth: UnitTypes): number[] {
		const teethRules = this.getTeethRulesByCaseTypeIdAndToothId({ caseTypeId, toothId });
		const additionalUnitTypeIds: number[] = this.getAdditionalUnitTypeIds({ teethRules, toothId, currentUnitTypeOnTooth });
		const getUnitTypeIdsFromTeethRules = (accumulator: number[], teethRule: TeethRule) => {
			if (teethRule.Ids.includes(toothId)) {
				(teethRule.UnitAndMaterialRules.UnitTypeRules as UnitTypeRule[]).forEach((unitTypeRule: UnitTypeRule) => {
					accumulator.push(...unitTypeRule.Ids);
				});
			}
			return accumulator;
		};
		const unitTypeIds: number[] = teethRules?.reduce(getUnitTypeIdsFromTeethRules, additionalUnitTypeIds);
		return [...new Set(unitTypeIds)];
	}

	getMaterialIds(caseTypeId: number, toothId: number, currentUnitTypeOnTooth: UnitTypes): number[] {
		const teethRules = this.getTeethRulesByCaseTypeIdAndToothId({ caseTypeId, toothId });
		const getUnitTypeIdsFromTeethRules = (accumulator: number[], teethRule: TeethRule) => {
			if (teethRule.Ids.includes(toothId)) {
				(teethRule.UnitAndMaterialRules.UnitTypeRules as UnitTypeRule[]).forEach((unitTypeRule: UnitTypeRule) => {
					if (unitTypeRule.Ids.includes(currentUnitTypeOnTooth)) {
						accumulator.push(...unitTypeRule.MaterialRules.Ids);
					}
				});
			}
			return accumulator;
		};
		const materialIds: number[] = teethRules?.reduce(getUnitTypeIdsFromTeethRules, []);
		return [...new Set(materialIds)];
	}

	getTeethUnitTypeRule(caseTypeId: number): TeethUnitType[] {
		const caseTypeRule = this.getCaseTypeRuleByCaseTypeId({ id: caseTypeId });
		if (!caseTypeRule) {
			return null;
		}

		const getUnitTypeRules = (accum: TeethUnitType[], curr: TeethRule) => {
			const unitTypeIds = [];
			(curr.UnitAndMaterialRules.UnitTypeRules as UnitTypeRule[]).forEach((unitTypeRule: UnitTypeRule) => {
				unitTypeIds.push(...unitTypeRule.Ids);
			});
			curr.UnitAndMaterialRules?.UnitTypesAdditionalRules?.UnitTypesAddedOutput.forEach((id: number) => {
				unitTypeIds.push(id);
			});
			accum.push({
				TeethIds: curr.Ids,
				UnitTypeIds: unitTypeIds
			});
			return accum;
		};
		return caseTypeRule.TeethRules.reduce(getUnitTypeRules, []);
	}

	private getCaseTypeRuleByCaseTypeId({ id }: { id: number }): CaseTypeRule {
		const index = this.caseTypeRules.findIndex((caseTypeRule: CaseTypeRule) => caseTypeRule.Ids.includes(id));
		return index < 0 ? null : this.caseTypeRules[index];
	}

	private getTeethRulesByCaseTypeIdAndToothId({ caseTypeId, toothId }: { caseTypeId: number; toothId: number }): TeethRule[] {
		const caseTypeRule = this.getCaseTypeRuleByCaseTypeId({ id: caseTypeId });
		return caseTypeRule?.TeethRules.filter((teethRule: TeethRule) => teethRule.Ids.includes(toothId));
	}

	private getAdditionalUnitTypeIds({
		teethRules,
		toothId,
		currentUnitTypeOnTooth
	}: {
		teethRules: TeethRule[];
		toothId: number;
		currentUnitTypeOnTooth: number;
	}): number[] {
		const getAdditionalUnitTypeIdsFromTeethRules = (accumulator: number[], teethRule: TeethRule) => {
			if (teethRule.Ids.includes(toothId) && teethRule.UnitAndMaterialRules.UnitTypesAdditionalRules) {
				const additionalRules = teethRule.UnitAndMaterialRules.UnitTypesAdditionalRules;
				if (additionalRules.UnitTypesInput.includes(currentUnitTypeOnTooth)) {
					accumulator.push(...additionalRules.UnitTypesAddedOutput);
				}
			}
			return accumulator;
		};
		return teethRules?.reduce(getAdditionalUnitTypeIdsFromTeethRules, []);
	}
}
