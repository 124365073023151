export const objectKeysToLowerCase = (origObj): any => {
	return Object.keys(origObj).reduce((newObj, key) => {
		const val = origObj[key];
		const isObject =
			!!val && typeof val === 'object' && typeof val.getMonth !== 'function';
						const newVal = isObject ? objectKeysToLowerCase(val) : val;
		newObj[key.toLowerCase()] = newVal;
		return newObj;
	}, {});
};
