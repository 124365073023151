/* eslint-disable no-underscore-dangle, @typescript-eslint/naming-convention, @typescript-eslint/unbound-method */
import {
	ChangeDetectionStrategy,
	Component,
	ComponentRef,
	EventEmitter,
	inject,
	Inject,
	Input,
	NgZone,
	OnChanges,
	OnDestroy,
	Optional,
	Output,
	SimpleChanges,
	ViewContainerRef,
	ViewEncapsulation
} from '@angular/core';
import { DateFilterFn, DatepickerDropdownPositionX, DatepickerDropdownPositionY, ExtractDateTypeFromSelection, MAT_DATEPICKER_SCROLL_STRATEGY, MAT_SINGLE_DATE_SELECTION_MODEL_PROVIDER, matDatepickerAnimations, MatDatepickerContent, MatDatepickerControl, MatDatepickerPanel, MatDateSelectionModel, MatDatepickerModule } from '@angular/material/datepicker';
import { merge, Subject, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { FlexibleConnectedPositionStrategy, Overlay, OverlayConfig, OverlayRef, ScrollStrategy } from '@angular/cdk/overlay';
import { Directionality } from '@angular/cdk/bidi';
import { DateAdapter, ThemePalette } from '@angular/material/core';
import { BooleanInput, coerceBooleanProperty, coerceStringArray } from '@angular/cdk/coercion';
import { createMissingDateImplError } from '@shared/components/rx-datepicker/datepicker-errors';
import { DOCUMENT, NgClass } from '@angular/common';
import { _getFocusedElementPierceShadowDom } from '@angular/cdk/platform';
import {
	DOWN_ARROW,
	ESCAPE,
	hasModifierKey,
	LEFT_ARROW,
	ModifierKey,
	PAGE_DOWN,
	PAGE_UP,
	RIGHT_ARROW,
	UP_ARROW
} from '@angular/cdk/keycodes';
import { ComponentPortal, ComponentType, TemplatePortal, PortalModule } from '@angular/cdk/portal';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { A11yModule } from '@angular/cdk/a11y';

let datepickerUid = 0;

/** Variable replaced at build time that indicates whether the app is in development mode. */
declare const ngDevMode: object | null;

@Component({
    selector: 'rx-datepicker-content',
    templateUrl: 'rx-datepicker-content.html',
    styleUrls: ['rx-datepicker-content.scss'],
    host: {
        class: 'mat-datepicker-content',
        '[@transformPanel]': '_animationState',
        '(@transformPanel.start)': '_handleAnimationEvent($event)',
        '(@transformPanel.done)': '_handleAnimationEvent($event)',
        '[class.mat-datepicker-content-touch]': 'datepicker.touchUi'
    },
    animations: [matDatepickerAnimations.transformPanel, matDatepickerAnimations.fadeInCalendar],
    exportAs: 'rxDatepickerContent',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    inputs: ['color'],
    standalone: true,
    imports: [A11yModule, MatDatepickerModule, NgClass, PortalModule, MatLegacyButtonModule]
})
export class RxDatepickerContent<S, D = ExtractDateTypeFromSelection<S>> extends MatDatepickerContent<S, D> {
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	datepicker: RxDatepicker<any, S, D>;
	contentPortal: TemplatePortal;
}

/** Component responsible for managing the datepicker popup/dialog. */
@Component({
    selector: 'rx-datepicker',
    template: '',
    exportAs: 'rxDatepicker',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    providers: [MAT_SINGLE_DATE_SELECTION_MODEL_PROVIDER, { provide: RxDatepicker, useExisting: RxDatepicker }],
    standalone: true
})
export class RxDatepicker<D> implements MatDatepickerPanel<MatDatepickerControl<D>, D | null, D>, OnDestroy, OnChanges {
	/** Emits when the datepicker has been opened. */
	@Output('opened') readonly openedStream = new EventEmitter<void>();
	/** Emits when the datepicker has been closed. */
	@Output('closed') readonly closedStream = new EventEmitter<void>();
	/** An input indicating the type of the custom header component for the calendar, if set. */
	@Input() calendarHeaderComponent: ComponentType<any>;
	/** The view that the calendar should start in. */
	@Input() startView: 'month' | 'year' | 'multi-year' = 'month';
	/** The content to project between calendar and actions */
	contentPortal: TemplatePortal;
	/** Preferred position of the datepicker in the X axis. */
	@Input()
	xPosition: DatepickerDropdownPositionX = 'start';
	/** Preferred position of the datepicker in the Y axis. */
	@Input()
	yPosition: DatepickerDropdownPositionY = 'below';
	/** The input element this datepicker is associated with. */
	datepickerInput: MatDatepickerControl<D>;
	/** Emits when the datepicker's state changes. */
	readonly stateChanges = new Subject<void>();
	/** The id for the datepicker calendar. */
	id = `mat-datepicker-${datepickerUid++}`;
	private _scrollStrategy: () => ScrollStrategy;
	private _inputStateChanges = Subscription.EMPTY;
	private _document = inject(DOCUMENT);
	/** A reference to the overlay into which we've rendered the calendar. */
	private _overlayRef: OverlayRef | null;
	/** Reference to the component instance rendered in the overlay. */
	private _componentRef: ComponentRef<RxDatepickerContent<D, D>> | null;
	/** The element that was focused before the datepicker was opened. */
	private _focusedElementBeforeOpen: HTMLElement | null = null;
	/** Unique class that will be added to the backdrop so that the test harnesses can look it up. */
	private _backdropHarnessClass = `${this.id}-backdrop`;

	constructor(
		private _overlay: Overlay,
		private _ngZone: NgZone,
		private _viewContainerRef: ViewContainerRef,
		@Inject(MAT_DATEPICKER_SCROLL_STRATEGY) scrollStrategy: any,
		@Optional() private _dateAdapter: DateAdapter<D>,
		@Optional() private _dir: Directionality,
		private _model: MatDateSelectionModel<D, D>
	) {
		if (!this._dateAdapter && (typeof ngDevMode === 'undefined' || ngDevMode)) {
			throw createMissingDateImplError('DateAdapter');
		}

		this._scrollStrategy = scrollStrategy;
	}

	private _startAt: D | null;

	@Input()
	get panelClass(): string | string[] {
		return this._panelClass;
	}
	set panelClass(value: string | string[]) {
		this._panelClass = coerceStringArray(value);
	}
	private _panelClass: string[];

	/** The date to open the calendar to initially. */
	@Input()
	get startAt(): D | null {
		// If an explicit startAt is set we start there, otherwise we start at whatever the currently
		// selected value is.
		return this._startAt || (this.datepickerInput ? this.datepickerInput.getStartValue() : null);
	}

	set startAt(value: D | null) {
		this._startAt = this._dateAdapter.getValidDateOrNull(this._dateAdapter.deserialize(value));
	}

	_color: ThemePalette;

	/** Color palette to use on the datepicker's calendar. */
	@Input()
	get color(): ThemePalette {
		return this._color || (this.datepickerInput ? this.datepickerInput.getThemePalette() : undefined);
	}

	set color(value: ThemePalette) {
		this._color = value;
	}

	private _disabled: boolean;

	/** Whether the datepicker pop-up should be disabled. */
	@Input()
	get disabled(): boolean {
		return this._disabled === undefined && this.datepickerInput ? this.datepickerInput.disabled : !!this._disabled;
	}

	set disabled(value: BooleanInput) {
		const newValue = coerceBooleanProperty(value);

		if (newValue !== this._disabled) {
			this._disabled = newValue;
			this.stateChanges.next(undefined);
		}
	}

	private _opened = false;

	/** Whether the calendar is open. */
	@Input()
	get opened(): boolean {
		return this._opened;
	}

	set opened(value: BooleanInput) {
		if (coerceBooleanProperty(value)) {
			this.open();
		} else {
			this.close();
		}
	}

	private _restoreFocus = true;

	/**
	 * Whether to restore focus to the previously-focused element when the calendar is closed.
	 * Note that automatic focus restoration is an accessibility feature and it is recommended that
	 * you provide your own equivalent, if you decide to turn it off.
	 */
	@Input()
	get restoreFocus(): boolean {
		return this._restoreFocus;
	}

	set restoreFocus(value: BooleanInput) {
		this._restoreFocus = coerceBooleanProperty(value);
	}

	private _touchUi = false;

	/**
	 * Whether the calendar UI is in touch mode. In touch mode the calendar opens in a dialog rather
	 * than a dropdown and elements have more padding to allow for bigger touch targets.
	 */
	@Input()
	get touchUi(): boolean {
		return this._touchUi;
	}

	set touchUi(value: BooleanInput) {
		this._touchUi = coerceBooleanProperty(value);
	}

	/** The minimum selectable date. */
	_getMinDate(): D | null {
		return this.datepickerInput && this.datepickerInput.min;
	}

	/** The maximum selectable date. */
	_getMaxDate(): D | null {
		return this.datepickerInput && this.datepickerInput.max;
	}

	_getDateFilter(): DateFilterFn<D> {
		return this.datepickerInput && this.datepickerInput.dateFilter;
	}

	/** Selects the given date */
	select(date: D): void {
		this._model.add(date);
	}

	ngOnChanges(changes: SimpleChanges) {
		const positionChange = changes.xPosition || changes.yPosition;

		if (positionChange && !positionChange.firstChange && this._overlayRef) {
			const positionStrategy = this._overlayRef.getConfig().positionStrategy;

			if (positionStrategy instanceof FlexibleConnectedPositionStrategy) {
				this._setConnectedPositions(positionStrategy);

				if (this.opened) {
					this._overlayRef.updatePosition();
				}
			}
		}

		this.stateChanges.next(undefined);
	}

	ngOnDestroy() {
		this._destroyOverlay();
		this.close();
		this._inputStateChanges.unsubscribe();
		this.stateChanges.complete();
	}

	open(): void {
		// Skip reopening if there's an in-progress animation to avoid overlapping
		// sequences which can cause "changed after checked" errors. See #25837.
		if (this._opened || this.disabled || this._componentRef?.instance._isAnimating) {
			return;
		}

		if (!this.datepickerInput && (typeof ngDevMode === 'undefined' || ngDevMode)) {
			throw Error('Attempted to open an MatDatepicker with no associated input.');
		}

		this._focusedElementBeforeOpen = _getFocusedElementPierceShadowDom();
		this._openOverlay();
		this._opened = true;
		this.openedStream.emit();
	}

	close(): void {
		// Skip reopening if there's an in-progress animation to avoid overlapping
		// sequences which can cause "changed after checked" errors. See #25837.
		if (!this._opened || this._componentRef?.instance._isAnimating) {
			return;
		}

		const canRestoreFocus =
			this._restoreFocus && this._focusedElementBeforeOpen && typeof this._focusedElementBeforeOpen.focus === 'function';

		const completeClose = () => {
			// The `_opened` could've been reset already if
			// we got two events in quick succession.
			if (this._opened) {
				this._opened = false;
				this.closedStream.emit();
			}
		};

		if (this._componentRef) {
			const { instance, location } = this._componentRef;

			instance._startExitAnimation();
			instance._animationDone.pipe(take(1)).subscribe(() => {
				const activeElement = this._document.activeElement;

				// Since we restore focus after the exit animation, we have to check that
				// the user didn't move focus themselves inside the `close` handler.
				if (
					canRestoreFocus &&
					// eslint-disable-next-line @typescript-eslint/no-unsafe-call
					(!activeElement || activeElement === this._document.activeElement || location.nativeElement.contains(activeElement))
				) {
					this._focusedElementBeforeOpen.focus();
				}

				this._focusedElementBeforeOpen = null;
				this._destroyOverlay();
			});
		}

		if (canRestoreFocus) {
			// Because IE moves focus asynchronously, we can't count on it being restored before we've
			// marked the datepicker as closed. If the event fires out of sequence and the element that
			// we're refocusing opens the datepicker on focus, the user could be stuck with not being
			// able to close the calendar at all. We work around it by making the logic, that marks
			// the datepicker as closed, async as well.
			setTimeout(completeClose);
		} else {
			completeClose();
		}
	}

	registerInput(input: MatDatepickerControl<D>): MatDateSelectionModel<D, D> {
		if (this.datepickerInput && (typeof ngDevMode === 'undefined' || ngDevMode)) {
			throw Error('A MatDatepicker can only be associated with a single input.');
		}
		this._inputStateChanges.unsubscribe();
		this.datepickerInput = input;
		this._inputStateChanges = input.stateChanges.subscribe(() => this.stateChanges.next(undefined));

		return this._model;
	}

	/** Forwards relevant values from the datepicker to the datepicker content inside the overlay. */
	protected _forwardContentValues(instance: RxDatepickerContent<D, D>) {
		instance.datepicker = this;
		instance.color = this.color;
		instance._dialogLabelId = this.datepickerInput.getOverlayLabelId();
		instance._assignActions(null, false);
		instance.contentPortal = this.contentPortal;
	}

	private _openOverlay(): void {
		this._destroyOverlay();

		const isDialog = this.touchUi;
		const portal = new ComponentPortal<RxDatepickerContent<D, D>>(RxDatepickerContent, this._viewContainerRef);
		const overlayRef = (this._overlayRef = this._overlay.create(
			new OverlayConfig({
				positionStrategy: isDialog ? this._getDialogStrategy() : this._getDropdownStrategy(),
				hasBackdrop: true,
				backdropClass: [isDialog ? 'cdk-overlay-dark-backdrop' : 'mat-overlay-transparent-backdrop', this._backdropHarnessClass],
				direction: this._dir,
				scrollStrategy: isDialog ? this._overlay.scrollStrategies.block() : this._scrollStrategy(),
				panelClass: `mat-datepicker-${isDialog ? 'dialog' : 'popup'}`
			})
		));

		this._getCloseStream(overlayRef).subscribe(event => {
			if (event) {
				event.preventDefault();
			}
			this.close();
		});

		// The `preventDefault` call happens inside the calendar as well, however focus moves into
		// it inside a timeout which can give browsers a chance to fire off a keyboard event in-between
		// that can scroll the page (see #24969). Always block default actions of arrow keys for the
		// entire overlay so the page doesn't get scrolled by accident.
		overlayRef.keydownEvents().subscribe(event => {
			const keyCode = event.keyCode;

			if (
				keyCode === UP_ARROW ||
				keyCode === DOWN_ARROW ||
				keyCode === LEFT_ARROW ||
				keyCode === RIGHT_ARROW ||
				keyCode === PAGE_UP ||
				keyCode === PAGE_DOWN
			) {
				event.preventDefault();
			}
		});

		this._componentRef = overlayRef.attach(portal);
		this._forwardContentValues(this._componentRef.instance);

		// Update the position once the calendar has rendered. Only relevant in dropdown mode.
		if (!isDialog) {
			this._ngZone.onStable.pipe(take(1)).subscribe(() => overlayRef.updatePosition());
		}
	}

	/** Gets a position strategy that will open the calendar as a dropdown. */
	private _getDialogStrategy() {
		return this._overlay.position().global().centerHorizontally().centerVertically();
	}

	/** Gets a position strategy that will open the calendar as a dropdown. */
	private _getDropdownStrategy() {
		const strategy = this._overlay
			.position()
			.flexibleConnectedTo(this.datepickerInput.getConnectedOverlayOrigin())
			.withTransformOriginOn('.mat-datepicker-content')
			.withFlexibleDimensions(false)
			.withViewportMargin(8)
			.withLockedPosition();

		return this._setConnectedPositions(strategy);
	}

	private _setConnectedPositions(strategy: FlexibleConnectedPositionStrategy) {
		const primaryX = this.xPosition === 'end' ? 'end' : 'start';
		const secondaryX = primaryX === 'start' ? 'end' : 'start';
		const primaryY = this.yPosition === 'above' ? 'bottom' : 'top';
		const secondaryY = primaryY === 'top' ? 'bottom' : 'top';

		return strategy.withPositions([
			{
				originX: primaryX,
				originY: secondaryY,
				overlayX: primaryX,
				overlayY: primaryY
			},
			{
				originX: primaryX,
				originY: primaryY,
				overlayX: primaryX,
				overlayY: secondaryY
			},
			{
				originX: secondaryX,
				originY: secondaryY,
				overlayX: secondaryX,
				overlayY: primaryY
			},
			{
				originX: secondaryX,
				originY: primaryY,
				overlayX: secondaryX,
				overlayY: secondaryY
			}
		]);
	}

	/** Destroys the current overlay. */
	private _destroyOverlay() {
		if (this._overlayRef) {
			this._overlayRef.dispose();
			this._overlayRef = this._componentRef = null;
		}
	}

	/** Gets an observable that will emit when the overlay is supposed to be closed. */
	private _getCloseStream(overlayRef: OverlayRef) {
		const ctrlShiftMetaModifiers: ModifierKey[] = ['ctrlKey', 'shiftKey', 'metaKey'];

		return merge(
			overlayRef.backdropClick(),
			overlayRef.detachments(),
			overlayRef.keydownEvents().pipe(
				filter(event => {
					// Closing on alt + up is only valid when there's an input associated with the datepicker.
					return (
						(event.keyCode === ESCAPE && !hasModifierKey(event)) ||
						(this.datepickerInput &&
							hasModifierKey(event, 'altKey') &&
							event.keyCode === UP_ARROW &&
							ctrlShiftMetaModifiers.every((modifier: ModifierKey) => !hasModifierKey(event, modifier)))
					);
				})
			)
		);
	}
}
