import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { PrintOrientation, RxVersion } from '@shared/models/enums/enums';
import { EnvironmentParams } from '@shared/models/environment-params';
import { FeatureToggle } from '@shared/models/feature-toggle';
import { IdName } from '@shared/models/id-name';
import { RoleTypeEnum } from '@shared/models/role-type';
import { RxConfiguration } from '@shared/models/rx-configuration';
import { RxComponents } from '@shared/models/rx-models/interfaces/rx-components-model';
import { RxModel } from '@shared/models/rx-models/interfaces/rx-model';
import { UserSettings } from '@shared/models/user-settings';
import { ScannerVersion } from '@shared/models/company-scanner';

export interface ShellState {
	dateFormat: string;
	languageCode: string;
	isLoading: boolean;
	isRxPending: boolean;
	isReadOnly: boolean;
	readOnlyRules: RxComponents;
	validationMode: string;
	rxConfiguration: RxConfiguration;
	userSettings: UserSettings;
	contactId: number;
	companyId: number;
	productType: string;
	applicationMode: string;
	isNiriCaptureVisible: boolean;
	isSleeveConfirmationVisible: boolean;
	isPreTreatmentVisibleForV0: boolean;
	rx: RxModel;
	clonedRx: RxModel;
	clientVersion: string;
	packageVersion: string;
	scannerId: string;
	staticFilesEndpoint: string;
	featureToggles: FeatureToggle[];
	apiEndpoint: string;
	doctorIdPatients: any;
	rxId: string;
	preparedNewRxId: string;
	orderId?: number;
	clonedFromRxId: string;
	isRxValidForSave: boolean;
	appSettings: EnvironmentParams;
	isRxTakenForScan: boolean;
	authToken: string;
	rxsForPrint: RxModel[];
	userRole: RoleTypeEnum;
	rxVersionFlow: RxVersion;
	forceV0: boolean;
	printOrientation: PrintOrientation;
	isHeadlessPrint: boolean;
	isPatientAppDialogOpen: boolean;
	isReturn: boolean;
	shouldAnonymizeRx: boolean;
	enableAllCaseTypesForAddRx: boolean;
	autoDisplayPrintViewInTheDOM?: boolean;
	isProcedureChanged: boolean;
	user: IdName;
	printStyleName: string;
	scannersVersions: ScannerVersion[];
	isRxAttachmentsReady: boolean;
	rxSessionId: string;
}

export const getInitialState = (): ShellState => {
	return {
		dateFormat: 'MM/DD/YYYY',
		languageCode: 'en-US',
		isLoading: false,
		isRxPending: true,
		isReadOnly: false,
		validationMode: null,
		rxConfiguration: null,
		userSettings: null,
		contactId: null,
		companyId: null,
		productType: null,
		applicationMode: null,
		isNiriCaptureVisible: null,
		isSleeveConfirmationVisible: null,
		isPreTreatmentVisibleForV0: null,
		rx: null,
		clonedRx: null,
		clientVersion: null,
		packageVersion: null,
		scannerId: null,
		staticFilesEndpoint: '.',
		featureToggles: null,
		apiEndpoint: null,
		doctorIdPatients: null,
		rxId: null,
		preparedNewRxId: null,
		clonedFromRxId: null,
		isRxValidForSave: null,
		appSettings: {
			patientAppUrl: null,
			rxAttachmentsApiUrl: null,
			biTimberUrl: null,
			isProduction: false,
			loggingEndpoint: '',
			logging: {
				url: null,
				appId: null,
				eventType: null,
				appenderTypes: [],
				level: null,
				requiredFields: [],
				maxDataPartLength: 7000
			},
			retryIntervals: [],
			linkToTheLearnNowForDesignSuite: null,
			pendoAudienceSwos: [],
			pendoApiKey: ''
		},
		isRxTakenForScan: null,
		orderId: null,
		authToken: null,
		readOnlyRules: null,
		rxsForPrint: null,
		userRole: RoleTypeEnum.Doctor,
		rxVersionFlow: RxVersion.CaseTypeFlow,
		forceV0: null,
		printOrientation: null,
		isHeadlessPrint: false,
		isPatientAppDialogOpen: false,
		isReturn: false,
		shouldAnonymizeRx: false,
		autoDisplayPrintViewInTheDOM: false,
		enableAllCaseTypesForAddRx: false,
		isProcedureChanged: false,
		user: null,
		printStyleName: null,
		scannersVersions: null,
		isRxAttachmentsReady: true,
		rxSessionId: null
	};
};

@Injectable({
	providedIn: 'root'
})
@StoreConfig({ name: 'Shell' })
export class ShellStore extends Store<ShellState> {
	constructor() {
		super(getInitialState());
	}

	updateProcedureChangedFlag(newValue: boolean) {
		this.update({ isProcedureChanged: newValue });
	}
}
