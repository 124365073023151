import { Injectable } from '@angular/core';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { ApplicationModes, FeatureToggleSettings, SoftwareOptions } from '@shared/models/enums/enums';
import { map, startWith, switchMap } from 'rxjs/operators';
import { combineLatest, from, Observable, of } from 'rxjs';
import { SoftwareOptionsService } from '../services/software-options.service';
import { OrderQuery } from '@modules/order/state/order-query';
import { ProcedureEnum } from '@core/procedure-helpers/models/procedure.enum';
import { RxAttachmentsApiService } from './rx-attachments-api.service';
import { AuthInfoQuery } from '@shared/store/authInfo/auth-info-query';
import { HostPlatformService } from '@shared/services/host-platform.service';
import { RxAttachmentsVisibiltyEnum } from '@shared/models/enums/rx-attachments-visiblity.enum';
import { OrderStore } from '@modules/order/state/order-store';
import { ShellStore } from '@shared/store/shell/shell-store';
import { LoggerService } from '@core/services/logger/logger.service';

@Injectable()
export class RxAttachmentsFacade {
	constructor(
		private orderStore: OrderStore,
		private rxAttachmentsApiService: RxAttachmentsApiService,
		private orderQuery: OrderQuery,
		private shellQuery: ShellQuery,
		private authInfoQuery: AuthInfoQuery,
		private softwareOptionsService: SoftwareOptionsService,
		private hostPlatformService: HostPlatformService,
		private shellStore: ShellStore,
		private logger: LoggerService
	) {}

	public isAttachmentsReadOnly$ = combineLatest([
		this.shellQuery.isReadOnly$,
		this.shellQuery.isRxSent$,
		this.hostPlatformService.isScanner$,
		this.shellQuery.orderId$
	]).pipe(
		map(([isReadOnly, isRxSent, isScanner, orderId]) => (isScanner ? isReadOnly : orderId != null ? isReadOnly && isRxSent : false))
	);

	private isProcedureEmptyOrNotSupported$: Observable<boolean> = this.orderQuery.procedureMap$.pipe(
		map(procedureMap => procedureMap == null || procedureMap?.ProcedureId === ProcedureEnum.Invisalign)
	);

	private hasAddImagesFeatureFlag$: Observable<boolean> = this.shellQuery.featureToggles$.pipe(
		map(featureToggles => featureToggles?.find(f => f.id === FeatureToggleSettings.AddImages)?.isActive)
	);
	private hasAllowToSendLaterFeatureFlag$: Observable<boolean> = this.shellQuery.featureToggles$.pipe(
		map(featureToggles => featureToggles?.find(f => f.id === FeatureToggleSettings.AllowToSendLater)?.isActive)
	);

	private hasAllowToSend$: Observable<boolean> = combineLatest([
		this.hasAllowToSendLaterFeatureFlag$,
		this.softwareOptionsService.hasCompanySoftwareOption$(SoftwareOptions.AllowToSendLater)
	]).pipe(
		map(([hasFf, hasSwo]) => {
			return hasFf || hasSwo;
		})
	);
	isPlatformSupportedByRxAttachments$: Observable<boolean> = combineLatest([
		this.shellQuery.applicationMode$,
		this.shellQuery.isHeadlessPrint$
	]).pipe(
		map(([applicationMode, isHeadlessPrint]) => {
			const isWeb = HostPlatformService.isWeb(applicationMode as ApplicationModes);
			const isMAT = this.hostPlatformService.isMat;

			const isSupported =
				isWeb &&
				!isHeadlessPrint &&
				// TODO: Remove mycadent check once it's decided to enable Rx Attachments on MAT
				!isMAT;

			return isSupported;
		})
	);

	private dontShowEmptyReadonlyAttachments$: Observable<boolean> = combineLatest([
		combineLatest([this.hasAddImagesFeatureFlag$, this.softwareOptionsService.hasCompanySoftwareOption$(SoftwareOptions.AddImages)]),
		this.isAttachmentsReadOnly$,
		this.rxAttachmentsApiService.isApiInitialized$,
		this.shellQuery.attachmentsRxId$,
		this.isPlatformSupportedByRxAttachments$,
		this.authInfoQuery.authInfo$
	]).pipe(
		switchMap(
			([[hasFf, hasSwo], isAttachmentsReadOnly, isApiInitialized, attachmentsRxId, isPlatformSupportedByRxAttachments, authInfo]) => {
				// The below condition checks if the Rx is new or the Rx State is 'In Progress' section
				if (!isAttachmentsReadOnly && !!attachmentsRxId) {
					return of(false);
				}

				if (
					!(hasSwo || hasFf) ||
					!isPlatformSupportedByRxAttachments ||
					!isAttachmentsReadOnly ||
					!isApiInitialized ||
					attachmentsRxId == null ||
					!authInfo.accessToken
				) {
					return of(true);
				}

				const hasNoAttachments$ = from(
					this.rxAttachmentsApiService.getAttachmentsCount([attachmentsRxId]).then(response => {
						return response[0].attachmentsCount === 0;
					})
				);

				return hasNoAttachments$;
			}
		)
	);

	isAttachmentsReplacementSectionVisibleForScanner$ = combineLatest([
		combineLatest([this.shellQuery.attachmentsRxId$, this.isProcedureEmptyOrNotSupported$]),
		this.hasAddImagesFeatureFlag$,
		this.softwareOptionsService.hasCompanySoftwareOption$(SoftwareOptions.AddImages),
		this.shellQuery.isProcedureFlow$,
		this.isAttachmentsReadOnly$,
		this.shellQuery.applicationMode$
	]).pipe(
		map(
			([
				[attachmentsRxId, isProcedureEmptyOrNotSupported],
				hasFf,
				hasSwo,
				isProcedureFlow,
				isAttachmentsReadOnly,
				applicationMode
			]) => {
				const isScanner = HostPlatformService.isScanner(applicationMode as ApplicationModes);
				const isVisible =
					attachmentsRxId &&
					!isProcedureEmptyOrNotSupported &&
					(hasSwo || hasFf) &&
					isProcedureFlow &&
					!isAttachmentsReadOnly &&
					isScanner;

				return isVisible;
			}
		),
		startWith(false)
	);

	isAttachmentsSectionVisible$ = combineLatest([
		combineLatest([
			this.shellQuery.attachmentsRxId$,
			this.hasAddImagesFeatureFlag$,
			this.shellQuery.isProcedureFlow$,
			this.isProcedureEmptyOrNotSupported$
		]),
		this.authInfoQuery.authInfo$,
		this.isPlatformSupportedByRxAttachments$,
		this.softwareOptionsService.hasCompanySoftwareOption$(SoftwareOptions.AddImages),
		this.rxAttachmentsApiService.isApiInitialized$,
		this.dontShowEmptyReadonlyAttachments$
	]).pipe(
		map(
			([
				[attachmentsRxId, hasFf, isProcedureFlow, isProcedureEmptyOrNotSupported],
				authInfo,
				isPlatformSupportedByRxAttachments,
				hasSwo,
				isApiInitialized,
				dontShowEmptyReadonlyAttachments
			]) => {
				const isVisible =
					authInfo.accessToken &&
					authInfo.authUrl &&
					authInfo.sessionId &&
					!dontShowEmptyReadonlyAttachments &&
					isApiInitialized &&
					attachmentsRxId &&
					!isProcedureEmptyOrNotSupported &&
					(hasSwo || hasFf) &&
					isPlatformSupportedByRxAttachments &&
					isProcedureFlow;

				return isVisible;
			}
		),
		startWith(false)
	);

	rxAttachmentsSectionVisibility$: Observable<RxAttachmentsVisibiltyEnum> = combineLatest([
		this.isAttachmentsReplacementSectionVisibleForScanner$,
		this.isAttachmentsSectionVisible$,
		this.hasAllowToSend$
	]).pipe(
		map(([isAttachmentsReplacementSectionVisibleForScanner, isAttachmentsSectionVisible, hasAllowToSend]) => {
			if (isAttachmentsReplacementSectionVisibleForScanner && hasAllowToSend) {
				return RxAttachmentsVisibiltyEnum.ShowAllowSendLater;
			} else if (isAttachmentsReplacementSectionVisibleForScanner) {
				return RxAttachmentsVisibiltyEnum.ShowReplacement;
			} else if (isAttachmentsSectionVisible) {
				return RxAttachmentsVisibiltyEnum.ShowAttachments;
			} else {
				return RxAttachmentsVisibiltyEnum.Invisible;
			}
		}),
		startWith(RxAttachmentsVisibiltyEnum.Invisible)
	);

	isDraftSelected$: Observable<boolean> = this.orderQuery.isDraftSelected$;

	shouldShowIsDraftMessage$: Observable<boolean> = combineLatest([
		this.isDraftSelected$,
		this.isAttachmentsReplacementSectionVisibleForScanner$,
		this.hasAllowToSend$
	]).pipe(map(args => args.every(arg => arg)));

	updateAttachmentLater(isDraft: boolean): void {
		this.logger.sendUpdateAttachmentLaterBi(isDraft);

		this.orderStore.update({ isDraftSelected: isDraft });
	}

	updateRxAttachmentReadyToSaveState(isReady: boolean): void {
		this.shellStore.update({ isRxAttachmentsReady: isReady });
	}
}
