import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { akitaConfig, persistState } from '@datorama/akita';
import 'zone.js';
import { localSettingsStoreName } from '@shared/store/localSettings/local-settings-store';
import * as localForage from 'localforage';

if (environment.production) {
	enableProdMode();
}

akitaConfig({ resettable: true });

// MIDC clear storage on logout without caring about us. In 21b it will be changed to use user settings
localForage.config({
	driver: localForage.INDEXEDDB,
	name: 'Akita',
	version: 1.0,
	storeName: 'akita-storage'
});

export const localSettingsPersistStorage = persistState({
	include: [localSettingsStoreName],
	key: 'localSettingsStore',
	persistOnDestroy: true,
	storage: localForage
});

const providers = [{ provide: 'persistStorage', useValue: localSettingsPersistStorage }];

platformBrowserDynamic(providers)
	.bootstrapModule(AppModule)
	.catch(err => console.error(err));
