import { Injectable } from '@angular/core';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { Tooth } from '@modules/teeth-diagram/models/tooth';
import { UnitTypes } from '@modules/teeth-diagram/models/unit-type.enum';
import { UnitTypesInBridge } from '@modules/teeth-diagram/models/unit-type-in-bridge.enum';
import { getJawByToothNumber } from '@modules/teeth-diagram/models/teeth-numbering';
import { unitTypeFolderNames, unitTypeInBridgeFolderNames } from '@modules/teeth-diagram/models/tooth-images-folder-names';

@Injectable({
	providedIn: 'root'
})
export class TeethImagesService {
	private toothImagePrefix = `${this.shellQuery.staticFilesEndpoint}/assets/teeth`;

	constructor(private shellQuery: ShellQuery) {}

	getToothImageSrc(tooth: Tooth, overriddenUnitType?: UnitTypes): string {
		if (TeethImagesService.isBridgeTooth(tooth)) {
			return this.getBridgeToothImageSrc(tooth);
		}
		
		return this.getNoBridgeToothImageSrc(tooth, overriddenUnitType);
	}

	private static isBridgeTooth(tooth: Tooth): boolean {
		return !!tooth.BridgeIndex && tooth.BridgeIndex > 0;
	}

	private getBridgeToothImageSrc(tooth: Tooth): string {
			switch (tooth.ToothInBridgeTypeID) {
				case UnitTypesInBridge.ScanBody:
					return `${this.toothImagePrefix}/${unitTypeInBridgeFolderNames[tooth.ToothInBridgeTypeID]}/tooth_scan_body_${getJawByToothNumber({
						toothId: tooth.ToothID
					})}.svg`;
				case UnitTypesInBridge.ImplantAbutment:
				case UnitTypesInBridge.ImplantBased:
					return `${this.toothImagePrefix}/${unitTypeInBridgeFolderNames[tooth.ToothInBridgeTypeID]}/tooth_implant_abutment_${getJawByToothNumber({
						toothId: tooth.ToothID
					})}.svg`;
				case null:
				case undefined:
					return tooth ? `${this.toothImagePrefix}/regular/tooth_${tooth.ToothID}.svg` : 'no image';
				default:
					return tooth ? `${this.toothImagePrefix}/${unitTypeInBridgeFolderNames[tooth.ToothInBridgeTypeID]}/tooth_${tooth.ToothID}.svg` : 'no image';
			}	
	}

	private getNoBridgeToothImageSrc(tooth: Tooth, overriddenUnitType?: UnitTypes): string {
		if (!!overriddenUnitType) {
			return `${this.toothImagePrefix}/${unitTypeFolderNames[overriddenUnitType]}/tooth_${tooth.ToothID}.svg`;
		}

		switch (tooth.UnitTypeID) {
			case UnitTypes.ScanBody:
			case UnitTypes.ImplantBased:
				return `${this.toothImagePrefix}/${unitTypeFolderNames[tooth.UnitTypeID]}/tooth_scan_body_${getJawByToothNumber({
					toothId: tooth.ToothID
				})}.svg`;
			case UnitTypes.ImplantAbutment:
				return `${this.toothImagePrefix}/${unitTypeFolderNames[tooth.UnitTypeID]}/tooth_implant_abutment_${getJawByToothNumber({
					toothId: tooth.ToothID
				})}.svg`;
			default:
				return tooth ? `${this.toothImagePrefix}/${unitTypeFolderNames[tooth.UnitTypeID]}/tooth_${tooth.ToothID}.svg` : 'no image';
		}
	}
}