<mat-form-field fxFlex hideRequiredMarker [class.read-only-due-date-picker]="isReadOnly">
    <mat-label [class.read-only-label-no-value]="isReadOnly && !control.value"
        >{{ 'OrderSection.DueDate' | translate }}:
        <span class="mat-form-field-required-marker" *ngIf="isRequired && control.enabled">&#32;*</span>
    </mat-label>
    <input
        matInput
        [formControl]="control"
        [matDatepicker]="dueDateDatepicker"
        [min]="minimalAvailableDueDate"
        [max]="maxDueDate"
        autocomplete="off"
        [readonly]="true"
        name="dueDateDatepicker"
        [rxIsRequired]="isRequired"
        (click)="dueDateDatepicker.open()"
        [errorStateMatcher]="matcher"
        [class.read-only]="isReadOnly"
    />
    <mat-datepicker-toggle matSuffix [for]="dueDateDatepicker" *ngIf="!isReadOnly">
        <mat-icon matDatepickerToggleIcon class="due-datepicker-icon" svgIcon="calendar_icon"></mat-icon>
    </mat-datepicker-toggle>

    <rx-datepicker #dueDateDatepicker panelClass="rx-auto-due-datepicker-panel-class">
        <!--  mat-datepicker-actions cannot be placed right here -->
    </rx-datepicker>
</mat-form-field>

<ng-template #footer>
    <footer *ngIf="isCancellable">
        <button
            [id]="id + '-cancel-selection'"
            [disabled]="isCancelButtonDisabled"
            mat-raised-button
            class="gradient"
            (click)="handleCancelSelection($event)"
        >{{ 'Selector.ClearSelection' | translate }}</button>
    </footer>
</ng-template>
