import { IdName } from '@shared/models/id-name';

export const getOptionId = (value: string, id: string, label: string): string => {
	const labelPart = label?.toLowerCase().replace(/[ ]/g, '-');
	const idPart = id ? '-' + id : '';
	const valuePart = value ? '-' + value?.replace(/[\W_]+/g, '')?.toLowerCase() : '';

	return 'simple-select-option-' + labelPart + idPart + valuePart;
};

export const idNameCompareWith = <T extends IdName, U extends IdName>(o1: T, o2: U) => {
	return o1 && o2 && o1.Id === o2.Id;
};

export const simpleCompareWith = (o1: any, o2: any) => {
	return o1 === o2;
};

export const getOptionClassName = (index: number): Record<string, boolean> => {
	return {
		[`rx-auto-simple-select-option-${String(index)}`]: true
	};
};
