import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Tooth } from '@modules/teeth-diagram/models/tooth';
import { Observable } from 'rxjs';
import { ToothEditorState } from '../models/tooth-editor';
import { ToothEditorStore } from './tooth-editor-store';
import { UnitTypes } from '@modules/teeth-diagram/models/unit-type.enum';

@Injectable({ providedIn: 'root' })
export class ToothEditorQuery extends Query<ToothEditorState> {
	toothEditorState$: Observable<ToothEditorState> = this.select(state => state);
	teeth$: Observable<Tooth[]> = this.select(state => state.teeth);
	toothClickedOn$: Observable<Tooth> = this.select(state => state.toothClickedOn);
	toothClickedOnId$: Observable<number> = this.select(state => state.toothClickedOn?.ToothID);
	unitTypeId$: Observable<UnitTypes> = this.select(state => state.toothClickedOn?.UnitTypeID);
	toothInBridgeTypeId$: Observable<UnitTypes> = this.select(state => state.toothClickedOn?.ToothInBridgeTypeID);
	specificationId$: Observable<number> = this.select(state => state.toothClickedOn?.SpecificationId);
	materialId$: Observable<number> = this.select(state => state.toothClickedOn?.MaterialID);
	isBridge$: Observable<boolean> = this.select(state => state.isBridge);
	allTeethInJaw$: Observable<Tooth[]> = this.select(state => state.allTeethInJaw);
	restorationTypeId$: Observable<number> = this.select(state => state.toothClickedOn?.ImplantBasedRestorationTypeId);
	abutmentTypeId$: Observable<number> = this.select(state => state.toothClickedOn?.AbutmentType);

	get teeth(): Tooth[] {
		return this.getValue().teeth;
	}
	get toothClickedOn(): Tooth {
		return this.getValue().toothClickedOn;
	}
	get isToothEditorOpen(): boolean {
		return this.getValue().isToothEditorOpen;
	}

	constructor(store: ToothEditorStore) {
		super(store);
	}
}
