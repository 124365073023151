import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { MaterialModule } from './material/material.module';
import { AlertComponent } from './components/alert/alert.component';
import { ToothComponent } from '@modules/teeth-diagram/components/tooth/tooth.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ConfirmationPopUpComponent } from './components/confirmation-pop-up/confirmation-pop-up.component';
import { ObjectSelectorComponent, StringSelectorComponent } from './components/object-selector/object-selector.component';
import { RxBannerComponent } from './components/rx-banner/rx-banner.component';
import { MapperPipe } from './pipes/mapper.pipe';
import { IsRequiredDirective } from './directives/is-required.directive';
import { IsDisabledDirective } from './directives/is-disabled/is-disabled.directive';
import { StaticFilesEndpointPipe } from './pipes/static-files-endpoint.pipe';
import { RxStarCheckboxComponent } from '@shared/components/rx-star-checkbox/rx-star-checkbox.component';
import { FavoriteSelectorComponent } from './components/favorite-selector/favorite-selector.component';
import { FavoritesPipe } from './pipes/favorites.pipe';
import { TranslateOnDemandPipe } from './pipes/translate-on-demand.pipe';
import { RxDatepickerModule } from '@shared/components/rx-datepicker/rx-datepicker.module';
import { SelectComponent } from '@shared/components/select/select.component';
import { TlkSlideToggleComponent } from '@itero/ui-toolkit-angular/slide-toggle';
import { TlkButtonComponent } from '@itero/ui-toolkit-angular/button';
import { TextInputComponent } from './components/text-input/text-input.component';
import { TlkTextInputComponent } from '@itero/ui-toolkit-angular/text-input';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        HttpClientModule,
        FlexLayoutModule,
        RxDatepickerModule,
        SelectComponent,
        TlkSlideToggleComponent,
        TlkButtonComponent,
        TextInputComponent,
        SpinnerComponent,
        AlertComponent,
        ToothComponent,
        ConfirmationPopUpComponent,
        ObjectSelectorComponent,
        FavoriteSelectorComponent,
        RxBannerComponent,
        MapperPipe,
        FavoritesPipe,
        IsRequiredDirective,
        IsDisabledDirective,
        StaticFilesEndpointPipe,
        RxStarCheckboxComponent,
        TranslateOnDemandPipe,
        StringSelectorComponent
    ],
    exports: [
        SpinnerComponent,
        AlertComponent,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        HttpClientModule,
        ToothComponent,
        FlexLayoutModule,
        ConfirmationPopUpComponent,
        ObjectSelectorComponent,
        FavoriteSelectorComponent,
        RxBannerComponent,
        MapperPipe,
        IsRequiredDirective,
        IsDisabledDirective,
        StaticFilesEndpointPipe,
        RxStarCheckboxComponent,
        TranslateOnDemandPipe,
        RxDatepickerModule,
        StringSelectorComponent,
        SelectComponent,
        TlkSlideToggleComponent,
        TlkButtonComponent,
        TextInputComponent
    ],
    providers: []
})
export class SharedModule {}
