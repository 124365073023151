import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotesComponent } from './containers/notes/notes.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { AuthorInitialsPipe } from './services/author-initials.pipe';
import { RoleColorsPipe } from './services/role-color.pipe';
import { NotesService } from './services/notes.service';

@NgModule({
	declarations: [
		NotesComponent,
		AuthorInitialsPipe,
		RoleColorsPipe
	],
	providers: [
		NotesService
	],
	imports: [
		CommonModule,
		 SharedModule
		],
	exports: [
		NotesComponent
	]
})
export class NotesModule {}
