import { Component } from '@angular/core';
import { GeneralRxDataV0Facade } from '@modules/general-rx-data/general-rx-data-v0-facade';
import { InfoBlockInput } from '@modules/general-rx-data/models/info-block-inputs-model';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

@Component({
	selector: 'rx-general-data-v0',
	templateUrl: './general-rx-data-v0.component.html',
	styleUrls: ['./general-rx-data-v0.component.scss'],
	providers: [GeneralRxDataV0Facade]
})
export class GeneralRxDataV0Component {

	doctorName$: Observable<string> = this.facade.doctorName$;
	patientName$: Observable<string> = this.facade.patientName$;

	caseType$: Observable<string> = this.facade.caseTypeId$.pipe(
		filter(caseTypeId => !!caseTypeId),
		switchMap(caseTypeId => this.facade.getCaseTypeTranslationById(caseTypeId))
	);
	scanDate$: Observable<string> = this.facade.scanDate$;
	dueDate$: Observable<string> = this.facade.dueDate$;
	signature$: Observable<string> = this.facade.signature$;
	license$: Observable<string> = this.facade.license$;
	dateFormat$: Observable<string> = this.facade.dateFormat$;
	state$: Observable<string> = this.facade.state$;
	sendToLab$: Observable<string> = this.facade.sendToLab$;
	preTreatmentScan$: Observable<boolean> = this.facade.preTreatmentScan$;
	chartNumber$ = this.facade.chartNumber$;

	constructor(private facade: GeneralRxDataV0Facade) {
	}
}