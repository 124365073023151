import { Component, Input } from '@angular/core';
import { BaseDestroyableComponent } from '@shared/base-classes/base-destroyable';
import { RxModel } from '@shared/models/rx-models/interfaces/rx-model';
import { TreatmentInformationComponentPart } from './models/treatment-information-component.enum';

@Component({
	selector: 'rx-treatment-information',
	template: `
		<ng-container [ngSwitch]="isProcedureFlow">
			<rx-treatment-information-v0
				*ngSwitchCase="false"
				[isTreatmentsTableShownEmpty]="isTreatmentsTableShownEmpty"
				[rxForPrint]="rxForPrint"
			>
			</rx-treatment-information-v0>
			<rx-treatment-information-v1
				*ngSwitchCase="true"
				[isTreatmentsTableShownEmpty]="isTreatmentsTableShownEmpty"
				[rxForPrint]="rxForPrint"
			>
			</rx-treatment-information-v1>
		</ng-container>
	`,
})
export class TreatmentInformationComponent extends BaseDestroyableComponent {
	@Input() rxForPrint: { rx: RxModel; componentPart?: TreatmentInformationComponentPart };
	@Input() isTreatmentsTableShownEmpty = true;
	@Input() isProcedureFlow: boolean;

	constructor() {
		super();
	}
}
