import { Component, Input } from '@angular/core';
import { ScanOptionsFacade } from '../../scan-options.facade';
import { additionalBites } from '../../../../shared/data/bites';
import { RxModel } from '@shared/models/rx-models/interfaces/rx-model';

@Component({
	selector: 'rx-scan-options-print',
	templateUrl: './scan-options-print.component.html',
	providers: [ScanOptionsFacade]
})
export class ScanOptionsPrintComponent {
	additionalBites = additionalBites;
	@Input() rx: RxModel;

	constructor(private facade: ScanOptionsFacade) {}

	isRestoMultiBiteSelected = (): boolean => this.rx?.MultiBiteRestoScan?.Enabled;

	getSrcForRadioButtonImg(checked: boolean): string {
		return this.facade.getSrcForRadioButtonImg(checked);
	}

	isBiteSelected(biteId): boolean {
		return this.rx?.MultiBiteRestoScan.Includes?.includes(biteId);
	}
}
