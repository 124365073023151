import { AfterViewInit, Component, OnInit } from '@angular/core';
import { UnitTypesInBridge } from '@modules/teeth-diagram/models/unit-type-in-bridge.enum';
import { PreDefinedNotesService } from '@modules/teeth-diagram/services/pre-defined-notes.service';
import { TeethDiagramFacade } from '@modules/teeth-diagram/teeth-diagram.facade';
import { TranslateService } from '@ngx-translate/core';
import { BridgeService } from '@shared/services/bridge.service';
import { ToothEditorService } from '@shared/services/tooth-editor.service';
import { TeethDiagramBaseDirective } from '@modules/teeth-diagram/containers/teeth-diagram/base/teeth-diagram-base.directive';
import { TOOTH_EDITOR_PROVIDERS } from '@modules/tooth-editor/injection/providers';
import { AligntechNotesService } from '@modules/aligntech-notes/services/aligntech-notes.service';
import { TeethManagerService } from '@modules/tooth-editor/state/teeth-manager-service';

@Component({
	selector: 'rx-teeth-diagram-v0',
	templateUrl: './teeth-diagram-v0.component.html',
	styleUrls: ['../base/teeth-diagram-base.component.scss'],
	providers: [TeethDiagramFacade, TOOTH_EDITOR_PROVIDERS]
})
export class TeethDiagramV0Component extends TeethDiagramBaseDirective implements OnInit, AfterViewInit {
	constructor(
		teethDiagramFacade: TeethDiagramFacade,
		toothEditorService: ToothEditorService,
		bridgeService: BridgeService,
		translateService: TranslateService,
		preDefinedNotesService: PreDefinedNotesService,
		aligntechNotesService: AligntechNotesService,
		teethManagerService: TeethManagerService
	) {
		super(
			teethDiagramFacade,
			toothEditorService,
			bridgeService,
			translateService,
			preDefinedNotesService,
			aligntechNotesService,
			teethManagerService
		);
	}

	isImmediatelyChangeUnitType(unitTypeInBridge: number): boolean {
		const immediatelyChangeUnitTypes = [UnitTypesInBridge.MarylandAbutment, UnitTypesInBridge.Skipped, UnitTypesInBridge.Pontic];

		return immediatelyChangeUnitTypes.includes(unitTypeInBridge);
	}
}
