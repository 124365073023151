import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Tooth } from '@modules/teeth-diagram/models/tooth';
import { TeethDiagramStore } from '@modules/teeth-diagram/state/teeth-diagram-store';
import { getToothNumberById } from '@modules/teeth-diagram/models/teeth-numbering';
import { TeethDiagramQuery } from '@modules/teeth-diagram/state/teeth-diagram-query';
import { unitTypesWithoutDetails } from '@shared/models/consts';
import { IdName } from '@shared/models/id-name';
import { filter, map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class CopyToothService {
	toothNumber$: Observable<string | number> = this.teethDiagramQuery.copiedToothId$.pipe(
		filter(id => id > 0),
		map(toothId => {
			return getToothNumberById({
				toothId,
				teethNumberingSystem: this.teethDiagramQuery.teethNumberingSystem
			});
		})
	);
	get tooth() {
		const teeth = [...this.teethDiagramQuery?.teeth?.upperJaw, ...this.teethDiagramQuery?.teeth?.lowerJaw];
		return teeth.find(t => t.ToothID === this.teethDiagramQuery.copiedToothId);
	}
	constructor(private teethDiagramStore: TeethDiagramStore, private teethDiagramQuery: TeethDiagramQuery) {}

	setTooth(tooth: Tooth) {
		if (!this.isToothSuitableForCopy(tooth)) {
			return;
		}
		this.teethDiagramStore.update({ copiedToothId: tooth.ToothID });
	}

	copyFromTooth(tooth: Tooth) {
		const toothClickedOn = Object.assign({}, this.tooth);
		toothClickedOn.ToothID = tooth.ToothID;
		this.teethDiagramStore.updateTeeth({ teethToUpdate: [toothClickedOn] });
	}

	isCopyAvailable(clickedTooth: Tooth, unitTypes: IdName[]): boolean {
		const isPrep = !unitTypesWithoutDetails.includes(this.tooth?.UnitTypeID);
		const isBridge = clickedTooth?.BridgeIndex > 0;
		return (
			clickedTooth?.ToothID !== this.tooth?.ToothID &&
			unitTypes.some(unitType => unitType.Id === this.tooth?.UnitTypeID) &&
			isPrep &&
			!isBridge
		);
	}

	private isToothSuitableForCopy(tooth: Tooth): boolean {
		const isBridge = tooth?.BridgeIndex > 0;
		const isPrep = !unitTypesWithoutDetails.includes(tooth?.UnitTypeID);
		return tooth && !isBridge && isPrep;
	}
}
