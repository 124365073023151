import { Component, Injector, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { CompareOptionsService } from '@modules/order/services/compare-options';
import { ControlValueAccessorDirective } from '@shared/base-classes/control-value-accessor.directive';
import { CaseType } from '@shared/models/case-type';

@Component({
	selector: 'rx-case-type-selector',
	templateUrl: './case-type-selector.component.html',
	styleUrls: ['./case-type-selector.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: CaseTypeSelectorComponent,
			multi: true
		}
	]
})
export class CaseTypeSelectorComponent extends ControlValueAccessorDirective<CaseType> {
	@Input() options: CaseType[];
	@Input() isRequired: boolean;

	constructor(injector: Injector) {
		super(injector);
	}

	compareOptions = CompareOptionsService.compare.bind(this);

	generateOptionId(caseType: CaseType) {
		const caseTypeId: string = caseType?.Id;

		return `case-type-option-${caseTypeId}`;
	}
}
