import { Directive, Injector, Input } from '@angular/core';
import { FormControl, FormControlDirective, NG_VALIDATORS, ValidationErrors, Validator, Validators } from '@angular/forms';

/**
 * @description
 * Custom isRequired validator without emit event (see this.control.updateValueAndValidity({emitEvent: false});
 * Based on:
 * https://github.com/angular/angular/blob/437759ba471283cfb00d326d9c971a5fbfb8e0fe/packages/forms/src/directives/validators.ts#L343
 */
@Directive({
    selector: '[rxIsRequired][formControl]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: IsRequiredDirective,
            multi: true
        }
    ],
    standalone: true
})
export class IsRequiredDirective implements Validator {
	private requiredField = false;
	/**
	 * @description
	 * Tracks changes to the required attribute bound to this directive.
	 */
	@Input('rxIsRequired')
	get required(): boolean | string {
		return this.requiredField;
	}

	set required(value: boolean | string) {
		this.requiredField = !!value && value !== 'false';
		this.updateValidity();
	}

	get control() {
		return this.injector.get(FormControlDirective).control;
	}

	/**
	 * Method that validates whether the control is empty.
	 * Returns the validation result if enabled, otherwise null.
	 *
	 * @nodoc
	 */
	validate(control: FormControl): ValidationErrors | null {
		return this.required ? Validators.required(control) : null;
	}

	private updateValidity() {
		this.control?.updateValueAndValidity({ emitEvent: false });
	}

	constructor(private injector: Injector) {}
}
