import { Injectable } from '@angular/core';
import { Doctor } from '@shared/models/doctor';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { Observable } from 'rxjs';
import { RxNote } from './models/rx-note';
import { NotesService } from './services/notes.service';
import { NotesQuery } from './state/notes-query';
import { HostPlatformService } from '@shared/services/host-platform.service';

@Injectable()
export class NotesFacade {
	isReadOnly$: Observable<boolean> = this.shellQuery.getReadOnlyState('notes');
	user$: Observable<Doctor> = this.shellQuery.user$;

	notesArray$: Observable<RxNote[]> = this.notesQuery.notesArray$;
	dateFormat$: Observable<string> = this.shellQuery.dateFormat$;
	isHostPlatformScanner$: Observable<boolean> = this.hostPlatformService.isScanner$;
	isRxForModelingContext$: Observable<boolean> = this.hostPlatformService.isIteroModeling$;

	get isReadOnly() {
		return this.shellQuery.isReadOnly;
	}

	get isRxForModelingContext() {
		return this.hostPlatformService.isIteroModeling;
	}

	constructor(
		private shellQuery: ShellQuery,
		private notesService: NotesService,
		private notesQuery: NotesQuery,
		private hostPlatformService: HostPlatformService
	) {}

	isNotesReadOnly() {
		return this.shellQuery.getComponentReadOnlyState('notes');
	}

	updateNotes(notes: RxNote[]) {
		return this.notesService.updateNotes(notes);
	}
}
