<mat-grid-list
  cols="16"
  [rowHeight]="'5px'"
  [gutterSize]="'0'"
  *ngIf="!isPrintContext"
>
  <mat-grid-tile
    class="bridge-indicator"
    [id]="
      'bridge-index-' + tooth.BridgeIndex + '-tooth-indicator-' + tooth.ToothID
    "
    [ngClass]="{
      upper: !!tooth.BridgeIndex && tooth.BridgeIndex > 0 && jaw === 'upper',
      lower: !!tooth.BridgeIndex && tooth.BridgeIndex > 0 && jaw === 'lower',
      'rightmost-tooth-in-bridge':
        bridgeEdges[tooth.BridgeIndex]?.lastToothOnRightToothId ===
        tooth.ToothID,
      'leftmost-tooth-in-bridge':
        bridgeEdges[tooth.BridgeIndex]?.firstToothOnLeftToothId ===
        tooth.ToothID
    }"
    *ngFor="let tooth of teethInJaw; index as i"
  >
  </mat-grid-tile>
</mat-grid-list>
<!-- for print -->
<div *ngIf="isPrintContext" class="bridge-indicator-container">
  <div
    class="bridge-indicator__wrapper"
    [id]="
      'bridge-index-' + tooth.BridgeIndex + '-tooth-indicator-' + tooth.ToothID
    "
    *ngFor="let tooth of teethInJaw; index as i"
  >
  <div
    class="bridge-indicator"
    [ngClass]="{
      'bridge-indicator-upper': !!tooth.BridgeIndex && tooth.BridgeIndex > 0 && jaw === 'upper',
      'bridge-indicator-lower': !!tooth.BridgeIndex && tooth.BridgeIndex > 0 && jaw === 'lower',
      'rightmost-tooth-in-bridge':
        tooth.BridgeIndex && tooth.BridgeIndex > 0 && (i+1 === teethInJaw.length || teethInJaw[i]?.BridgeIndex !== teethInJaw[i+1]?.BridgeIndex),
      'leftmost-tooth-in-bridge':
        tooth.BridgeIndex && tooth.BridgeIndex > 0 && (i-1 === -1 || teethInJaw[i]?.BridgeIndex !== teethInJaw[i-1]?.BridgeIndex)
    }">
  </div>
</div>
