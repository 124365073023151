import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { RxNote } from '@modules/notes/models/rx-note';
import { Observable } from 'rxjs';
import { AlignTechNotesState, AlignTechNotesStore } from './aligntech-notes-store';

@Injectable({ providedIn: 'root' })
export class AlignTechNotesQuery extends Query<AlignTechNotesState> {
	alignTechNotesArray$: Observable<RxNote[]> = this.select(state => state.alignTechNotesArray);

	get alignTechNotes(): string {
		return this.getValue().alignTechNotes;
	}
	get alignTechNotesArray(): RxNote[] {
		return this.getValue().alignTechNotesArray;
	}

	constructor(store: AlignTechNotesStore) {
		super(store);
	}
}
