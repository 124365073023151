import { ControlContainer, ControlValueAccessor, FormControl, FormControlDirective } from '@angular/forms';
import { Directive, Injector, Input, ViewChild } from '@angular/core';

@Directive()
export abstract class ControlValueAccessorDirective<T> implements ControlValueAccessor {
	@ViewChild(FormControlDirective, { static: true }) formControlDirective: FormControlDirective;
	@Input() formControl: FormControl<T>;
	@Input() formControlName: string;

	get control() {
		return this.formControl || (this.controlContainer.control.get(this.formControlName) as FormControl<T>);
	}

	get controlContainer() {
		return this.injector.get(ControlContainer);
	}

	constructor(protected injector: Injector) {}

	registerOnTouched(fn: any): void {
		this.formControlDirective?.valueAccessor.registerOnTouched(fn);
	}

	registerOnChange(fn: any): void {
		this.formControlDirective?.valueAccessor.registerOnChange(fn);
	}

	writeValue(obj: any): void {
		this.formControlDirective?.valueAccessor.writeValue(obj);
	}

	setDisabledState(isDisabled: boolean): void {
		this.formControlDirective?.valueAccessor.setDisabledState(isDisabled);
	}

	setValue(value: any): void {
		this.control.setValue(value);
	}
}
