import { Injectable } from '@angular/core';
import { OrderStore } from '@modules/order/state/order-store';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { ShellStore } from '@shared/store/shell/shell-store';
import { isLegacyRestorative, isResorative } from '@shared/utils/restorative-check-util';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HostPlatformService } from '@shared/services/host-platform.service';
import { AuthInfoQuery } from '@shared/store/authInfo/auth-info-query';
import { RoleTypeEnum } from '@shared/models/role-type';

@Injectable()
export class RxForLabFacade {
	caseTypeId$: Observable<number> = this.shellQuery.rx$.pipe(map(rx => rx?.Order?.CaseTypeId));
	procedureId$: Observable<number> = this.shellQuery.rx$.pipe(map(rx => rx?.Order?.ProcedureId));
	rx$ = this.shellQuery.rx$;
	isReadOnly$: Observable<boolean> = this.shellQuery.isReadOnly$;
	isRxloadedAndReady$: Observable<boolean> = this.isRxLoadedAndReady();
	isTeethDiagramAreaVisible$: Observable<boolean> = this.shellQuery.rx$.pipe(
		map(rx => {
			const isResto = isResorative({ caseTypeId: rx?.Order?.CaseTypeId });
			const isLegacyResto = isLegacyRestorative({ caseTypeId: rx?.Order?.CaseTypeId });

			return isResto || isLegacyResto;
		})
	);

	attachmentsRxId$ = this.shellQuery.attachmentsRxId$;
	companyId$ = this.shellQuery.companyId$;
	authInfo$ = this.authInfoQuery.authInfo$;
	contactId$: Observable<number> = this.shellQuery.contactId$;
	userRole$: Observable<RoleTypeEnum> = this.shellQuery.userRole$;
	appSettings$ = this.shellQuery.appSettings$;

	get isProcedureFlow(): boolean {
		return this.shellQuery.isProcedureFlow;
	}

	get isIteroLab(): boolean {
		return this.hostPlatformService.isIteroLab;
	}

	constructor(
		private shellStore: ShellStore,
		private shellQuery: ShellQuery,
		private orderStore: OrderStore,
		private hostPlatformService: HostPlatformService,
		private authInfoQuery: AuthInfoQuery
	) {}

	updateIsReadOnly({ isReadOnly }: { isReadOnly: boolean }) {
		this.shellStore.update({ isReadOnly });
	}

	updateCaseTypeId({ caseTypeId }: { caseTypeId: number }) {
		this.orderStore.update({ caseType: { Id: caseTypeId, Name: '' } });
	}

	private isRxLoadedAndReady(): Observable<boolean> {
		return combineLatest([this.shellQuery.isLoading$, this.shellQuery.isRxPending$]).pipe(
			map(([isLoading, isRxPending]) => {
				return !isLoading && !isRxPending;
			})
		);
	}
}
