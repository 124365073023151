import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { BaseDestroyableComponent } from '@shared/base-classes/base-destroyable';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { takeUntil, tap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class MatIconService extends BaseDestroyableComponent {
	staticFilesEndpoint: string = '';
	iconsAssetsPath = `/assets/icons`;
	icons = {};
	constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer, private shellQuery: ShellQuery) {
		super();
		this.subscribeToStaticFilesEndpointUrlChanges();
	}

	registerMatIcons() {
		Object.keys(this.icons).forEach(icon_name => {
			this.matIconRegistry.addSvgIcon(icon_name, this.domSanitizer.bypassSecurityTrustResourceUrl(this.icons[icon_name]));
		});
	}

	private subscribeToStaticFilesEndpointUrlChanges(): void {
		this.shellQuery.staticFilesEndpoint$
			.pipe(
				tap(staticFilesEndpoint => (this.staticFilesEndpoint = staticFilesEndpoint)),
				tap(staticFilesEndpoint => (this.icons = this.initIcons(this.staticFilesEndpoint))),
				tap(staticFilesEndpoint => this.registerMatIcons()),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}

	initIcons(iconsEndpoint) {
		const assetEnpoint = `${iconsEndpoint}${this.iconsAssetsPath}`;
		return {
			calendar_icon: `${assetEnpoint}/calendar-icon.svg`,
			calendar_icon_disabled: `${assetEnpoint}/calendar-icon-disabled.svg`,
			trash_icon: `${assetEnpoint}/trash-icon.svg`,
			trash_icon_disabled: `${assetEnpoint}/trash-icon-disabled.svg`,
			pencil_icon: `${assetEnpoint}/pencil-icon.svg`,
			pencil_icon_disabled: `${assetEnpoint}/pencil-icon-disabled.svg`,
			pencil_dark: `${assetEnpoint}/pencil-dark.svg`
		};
	}
}
