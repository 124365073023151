import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { PatientAppPupupInput } from '@modules/patient/models/patient-app-pupup-input';
import { BaseDestroyableComponent } from '@shared/base-classes/base-destroyable';

@Component({
	selector: 'rx-patient-popup-app',
	templateUrl: './patient-app-popup.component.html',
	styleUrls: ['./patient-app-popup.component.scss']
})
export class PatientAppPopUpComponent implements OnInit {
	editPatientAppUrl: SafeResourceUrl;
	searchPatientAppUrl: SafeResourceUrl;

	constructor(
		@Inject(MAT_DIALOG_DATA) public dialogInput: PatientAppPupupInput,
		private sanitizer: DomSanitizer
	) {
	}

	ngOnInit(): void {
		if (this.dialogInput?.editPatientAppUrl) {
			this.editPatientAppUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.dialogInput?.editPatientAppUrl);
		}
		if (this.dialogInput?.searchPatientAppUrl) {
			this.searchPatientAppUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.dialogInput?.searchPatientAppUrl);
		}
	}

}
