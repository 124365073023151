import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Tooth } from '@modules/teeth-diagram/models/tooth';

export type TeethManagerState = EntityState<ToothManagedItem, number>;
export type ToothManagedItem = ToothManagedID & ToothPropertiesState;
export type ToothManagedID = Pick<Tooth, 'ToothID' | 'BridgeIndex'>;
export type ToothPropertiesState = {
	[P in keyof Omit<Tooth, 'ToothID' | 'BridgeIndex'>]: ToothPropertyStateEnum;
};
export enum ToothPropertyStateEnum {
	WasSharedFromOtherTooth = 'WasSharedFromOtherTooth',
	WasTakenFromRxModel = 'WasTakenFromRxModel',
	WasAssignedByUser = 'WasAssignedByUser',
	WasRemovedByUser = 'WasRemovedByUser'
}

export const notIndexesOfTooth = (prop: keyof Tooth): prop is keyof ToothPropertiesState => {
	return prop !== 'ToothID' && prop !== 'BridgeIndex';
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'teeth-manager', resettable: true, idKey: 'ToothID' })
export class TeethManagerStore extends EntityStore<TeethManagerState> {
	constructor() {
		super();
	}
}
