import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})

export class SpinnerService {
	readonly delayTime = 200;
	private isPinned = false;
	private status = new Subject<boolean>();
	onActiveChanged: any = this.status
		.asObservable()
		.pipe(debounceTime(this.delayTime));
	counter = 0;

	private setActive() {
		this.status.next(this.isPinned || this.counter > 0);
	}

	start(resetCounter = false): void {
		this.counter = resetCounter ? 0 : ++this.counter;
		this.setActive();
	}

	stop(): void {
		this.counter = this.counter <= 0 ? 0 : --this.counter;
		this.setActive();
	}

	pin(): void {
		this.isPinned = true;
		this.setActive();
	}

	unpin(): void {
		this.isPinned = false;
		this.setActive();
	}
}
