<div class="rectangle-container denture-details-info">
    <div fxLayout="column">
        <div class="denture-details-info__title">
            {{ 'DentureDetails.SectionTitle' | translate }}
        </div>
        <div fxLayout="row" [fxLayout.lt-sm]="isResponsive | mapper : getResponsiveDirection" class="general-info-body">
            <div fxLayout="column" fxFlex class="info-box">
                <div class="fields-container" fxLayout="column" fxLayoutAlign="space-between">
                    <div class="field" id="denture-print-stage">
                        <div class="field-name">
                            {{ 'DentureDetails.StageTitle' | translate }}:
                        </div>
                        <div class="field-info">{{ stage | doubleHyphen }}</div>
                    </div>
                </div>
                <div class="fields-container denture-details-info__selectors-container" fxLayout="column">
                    <div class="field">
                        <div class="field-name" fxFlex fxLayout="row" fxLayoutAlign="start center" id="denture-print-upperDenture">
                            {{ 'DentureDetails.UpperDentureTitle' | translate }}:
                            <img class="denture-details-info__selector"
                                [src]="isUpperJawChecked | mapper : getSrcForRadioButtonImg" alt="checked">
                        </div>
                    </div>
                    <div class="field">
                        <div class="field-name" fxFlex fxLayout="row" fxLayoutAlign="start center" id="denture-print-lowerDenture">
                            {{ 'DentureDetails.LowerDentureTitle' | translate }}:
                            <img class="denture-details-info__selector"
                                [src]="isLowerJawChecked | mapper : getSrcForRadioButtonImg" alt="checked">
                        </div>
                    </div>
                </div>
            </div>
            <div [ngClass.lt-sm]="{'horizontal' : isResponsive}" class="rx-separator vertical"></div>
            <div fxLayout="column" fxFlex class="info-box">
                <div class="fields-container" fxLayout="column" fxLayoutAlign="space-between">
                    <div class="field" fxFlex fxLayout="row" fxLayoutAlign="start start" id="denture-print-mould">
                        <div>
                            <div class="field-name">
                                {{ 'DentureDetails.MouldTitle' | translate }}:
                            </div>
                            <div class="field-info">{{ mould?.Name | doubleHyphen }}</div>
                        </div>
                        <rx-mould-element *ngIf="mould" class="denture-details-info__mould-image" [mould]="mould" [showName]="false"></rx-mould-element>
                    </div>
                </div>
                <div class="fields-container" fxLayout="column">
                    <div class="field" id="denture-print-gingival">
                        <div class="field-name">
                            {{ 'DentureDetails.GingivalShadeTitle' | translate }}:
                        </div>
                        <div class="field-info">{{gingivalShade | doubleHyphen }}</div>
                    </div>
                </div>
            </div>
            <div [ngClass.lt-sm]="{'horizontal' : isResponsive}" class="rx-separator vertical"></div>
            <div fxLayout="column" fxFlex>
                <rx-info-block fxLayout="column" class="info-box" fxFlex [fields]="[
                            { name: 'DentureDetails.ShadeSystemTitle', value: shadeSystem, id: 'denture-print-shadeSystem' },
                            { name: 'DentureDetails.TeethShadeTitle', value: teethShade, id: 'denture-print-teethShade'}
                        ]"></rx-info-block>
            </div>
        </div>
    </div>
</div>
