import { Component, Output, EventEmitter, Input } from '@angular/core';
import { ToothEditorModalActions } from '@modules/teeth-diagram/models/tooth-editor-actions.enum';

@Component({
	selector: 'rx-tooth-editor-header',
	styleUrls: ['./tooth-editor-header.component.scss'],
	templateUrl: 'tooth-editor-header.component.html'
})
export class ToothEditorHeaderComponent {
	@Input() isBridge: boolean;
	@Input() unitTypeName: string;
	@Input() isReadOnly: boolean;
	@Output() modalClosed = new EventEmitter<{ action: ToothEditorModalActions }>();

	handleApply() {
		this.modalClosed.emit({ action: ToothEditorModalActions.Apply });
	}
}