import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { ConfirmationPopUpInput } from '@shared/models/confirmation-pop-up-input';
import { PopUpActions } from '@shared/models/enums/popup-modal-actions.enum';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { TranslateModule } from '@ngx-translate/core';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { FormsModule } from '@angular/forms';
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';
import { FlexModule } from '@angular/flex-layout/flex';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgStyle, NgIf } from '@angular/common';

@Component({
    selector: 'rx-confirmation-pop-up',
    templateUrl: './confirmation-pop-up.component.html',
    styleUrls: ['./confirmation-pop-up.component.scss'],
    standalone: true,
    imports: [MatLegacyDialogModule, NgStyle, ExtendedModule, FlexModule, NgIf, MatLegacyCheckboxModule, FormsModule, MatLegacyButtonModule, TranslateModule]
})
export class ConfirmationPopUpComponent implements OnInit {

	popUpActions = PopUpActions;
	iconSrc: string;
	private defaultIconName = 'information-icon';
	dontShowAgain = false;
	isDontShowAgainVisible = !!this.dialogInput.dontShowAgainAction;

	constructor(
		public dialogRef: MatDialogRef<ConfirmationPopUpComponent>,
		@Inject(MAT_DIALOG_DATA) public dialogInput: ConfirmationPopUpInput,
		private shellQuery: ShellQuery
	) { }

	ngOnInit(): void {
		const iconName = this.dialogInput.iconName ?? this.defaultIconName;
		this.iconSrc = `${this.shellQuery.staticFilesEndpoint}/assets/icons/${iconName}.png`
	}

}
