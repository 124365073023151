<div
    class="layout-container"
    fxLayout="row"
    fxLayoutAlign="center"
    id="rx-for-lab"
    [ngClass]="(isRxloadedAndReady$ | async) ? 'isVisible' : 'isHidden'"
>
    <div class="lab-container">
        <div class="lab-components-container" fxLayout="column" fxLayoutAlign="start">
            <rx-general-data-v0></rx-general-data-v0>
            <div *ngIf="isTeethDiagramAreaVisible$ | async">
                <rx-teeth-diagram-v0></rx-teeth-diagram-v0>
                <rx-treatment-information-v0></rx-treatment-information-v0>
            </div>
            <ng-content></ng-content>
        </div>
    </div>
</div>
