
function getResponsiveDirection(isResponsive: boolean): string {
    return isResponsive ? 'column' : 'row';
} 
    
function getResponsiveWidth(isResponsive: boolean): string{
    return (isResponsive ? '' : '40');
} 


export { getResponsiveDirection,  getResponsiveWidth }