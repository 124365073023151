<div
        cdkTrapFocus
        role="dialog"
        [attr.aria-modal]="true"
        [attr.aria-labelledby]="_dialogLabelId ?? undefined"
        class="mat-datepicker-content-container"
        [class.mat-datepicker-content-container-with-custom-header]="datepicker.calendarHeaderComponent"
        [class.mat-datepicker-content-container-with-actions]="_actionsPortal">
    <mat-calendar
            [id]="datepicker.id"
            [startAt]="datepicker.startAt"
            [ngClass]="datepicker.panelClass"
            [startView]="datepicker.startView"
            [minDate]="datepicker._getMinDate()"
            [maxDate]="datepicker._getMaxDate()"
            [dateFilter]="datepicker._getDateFilter()"
            [headerComponent]="datepicker.calendarHeaderComponent"
            [selected]="_getSelected()"
            [comparisonStart]="comparisonStart"
            [comparisonEnd]="comparisonEnd"
            [@fadeInCalendar]="'enter'"
            [startDateAccessibleName]="startDateAccessibleName"
            [endDateAccessibleName]="endDateAccessibleName"
            (_userSelection)="_handleUserSelection($event)"
            (_userDragDrop)="_handleUserDragDrop($event)"></mat-calendar>

    <ng-template [cdkPortalOutlet]="contentPortal"></ng-template>

    <!-- implement passing _actionsPortal by yourself -->
    <ng-template [cdkPortalOutlet]="_actionsPortal"></ng-template>

    <!-- Invisible close button for screen reader users. -->
    <button
            type="button"
            mat-raised-button
            [color]="color || 'primary'"
            class="mat-datepicker-close-button"
            [class.cdk-visually-hidden]="!_closeButtonFocused"
            (focus)="_closeButtonFocused = true"
            (blur)="_closeButtonFocused = false"
            (click)="datepicker.close()">{{ _closeButtonText }}</button>
</div>
