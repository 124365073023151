
<div class="teeth-section">
  <div *ngFor="let tooth of teeth; trackBy: trackByMethod" class="tooth-container" [class.selected]="tooth.ToothID === toothClickedOn.ToothID">
      <rx-tooth [isReadOnly]="isReadOnly" 
                [isMiniDiagramBridgeSelectionEnabled]="enableToothSelectionInReadOnlyBridge" 
                [usedFromMiniDiagram]="true" class="rx-tooth" 
                [tooth]="tooth"
                (toothClicked)="handleToothClick($event)"
                [isGlidewellOrder]="isGlidewellOrder"
      ></rx-tooth>
      <div class="tooth-number">
        {{ tooth.ToothID | mapper : getToothNumberById : teethNumberingSystem }}
      </div>
    </div>
  </div>