import { Component, ElementRef, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { RxNote } from '@modules/notes/models/rx-note';
import { NotesFacade } from '@modules/notes/notes.facade';
import { RoleTypeEnum } from '@shared/models/role-type';
import { combineLatest, Observable, of } from 'rxjs';
import { BaseDestroyableComponent } from '@shared/base-classes/base-destroyable';
import { map, takeUntil, tap } from 'rxjs/operators';
import { Doctor } from '@shared/models/doctor';
import { RxModel } from '@shared/models/rx-models/interfaces/rx-model';
import { NotesService } from '@modules/notes/services/notes.service';
import { IdName } from '@shared/models/id-name';
import { createdByNameIteroTeam } from '@shared/models/consts';
import { Form } from '@shared/utils/type-util';

@Component({
	selector: 'rx-notes',
	templateUrl: './notes.component.html',
	styleUrls: ['./notes.component.scss'],
	providers: [NotesFacade]
})
export class NotesComponent extends BaseDestroyableComponent implements OnInit {
	@Input() rxForPrint: RxModel;
	@Input() role: RoleTypeEnum = RoleTypeEnum.Doctor;
	isReadOnly$: Observable<boolean> = this.facade.isReadOnly$;
	notesArray$: Observable<RxNote[]> = this.facade.notesArray$;
	dateFormat$: Observable<string> = this.facade.dateFormat$;
	isRxForModelingContext$: Observable<boolean> = this.facade.isRxForModelingContext$;
	user$: Observable<Doctor> = this.facade.user$;
	notesFocusedElement: Element = null;
	isNotesFocused = false;
	isAddNotesDisabled = false;
	createdByName: string;
	createdById: number;
	iteroModelingNoteCreatorName = createdByNameIteroTeam; // Required for unit tests

	notesForm = this.formBuilder.group({
		newNote: this.formBuilder.control('', { updateOn: 'blur' }),
		notes: this.formBuilder.array<FormGroup<Form<RxNote>>>([])
	});

	get newNote() {
		return this.notesForm.controls.newNote;
	}

	notes = this.notesForm.controls.notes;

	editableNoteIndex: number = null;
	@ViewChildren('noteinput') noteinputs: QueryList<ElementRef<HTMLInputElement>>;

	constructor(
		private facade: NotesFacade,
		private notesService: NotesService,
		private formBuilder: FormBuilder
	) {
		super();
	}

	get isPrintContext() {
		return !!this.rxForPrint;
	}

	canEdit(noteIndex: number): boolean {
		const note = this.notes.controls[noteIndex]?.value as RxNote;
		if (!note || this.isPrintContext || (this.facade.isReadOnly && this.facade.isNotesReadOnly())) {
			return false;
		}

		if (this.facade.isRxForModelingContext) {
			// only modeling technician who created note can edit it (name of technicians are the same)
			return note.createdById === this.createdById && !note.isPreDefinedNote;
		}

		return this.isCreatedByTheCurrentUser(note);
	}

	canDelete(noteIndex: number): boolean {
		const note = this.notes.controls[noteIndex]?.value as RxNote;

		if (!note || this.isPrintContext || (this.facade.isReadOnly && this.facade.isNotesReadOnly())) {
			return false;
		}

		if (this.facade.isRxForModelingContext) {
			// any modeling technician can delete note
			return note.createdById === this.createdById || note.createdByName === this.iteroModelingNoteCreatorName;
		}

		return this.isCreatedByTheCurrentUser(note);
	}

	ngOnInit(): void {
		combineLatest([this.user$, this.isRxForModelingContext$])
			.pipe(
				map(([user, isRxForModelingContext]: [IdName, boolean]) => {
					this.createdById = user?.Id;
					this.createdByName = isRxForModelingContext ? this.iteroModelingNoteCreatorName : user?.Name;
				}),
				takeUntil(this.componentAlive$)
			)
			.subscribe();

		if (this.isPrintContext) {
			const notesArray: RxNote[] = this.notesService.getNotesArray(this.rxForPrint.Notes);

			this.notesArray$ = of(notesArray);
		}

		this.notesArray$
			.pipe(
				map((notes: RxNote[]) => {
					if (!notes) {
						return;
					}
					this.notes.clear();
					notes = this.notesService.notesFilter(notes);
					notes = this.notesService.notesSortByDateDescending(notes);
					for (const note of notes) {
						this.notes.push(
							this.formBuilder.group<Form<RxNote>>({
								content: this.formBuilder.control(note.content, { updateOn: 'blur' }),
								createdByName: this.formBuilder.control(note.createdByName),
								dateCreated: this.formBuilder.control(note.dateCreated),
								createdById: this.formBuilder.control(note.createdById),
								role: this.formBuilder.control(note.role),
								isPreDefinedNote: this.formBuilder.control(note.isPreDefinedNote)
							})
						);
					}
				}),
				tap(() => this.registerToNewNoteChanges()),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
		this.handleScannerModeVirtualKeyboard();
	}

	addNote(): void {
		if (this.newNote.value.trim() === '') {
			return;
		}

		const contentControl = this.formBuilder.control(this.newNote.value.trim(), { updateOn: 'blur' });

		this.notes.insert(
			0,
			this.formBuilder.group<Form<RxNote>>({
				content: contentControl,
				createdByName: this.formBuilder.control(this.createdByName),
				createdById: this.formBuilder.control(this.createdById),
				dateCreated: this.formBuilder.control(new Date().toISOString()),
				role: this.formBuilder.control(this.role)
			})
		);

		this.updateNotes();
		this.newNote.setValue('');
	}

	editNote(noteIndex: number): void {
		this.editableNoteIndex = noteIndex;

		const noteItem = this.noteinputs.find(
			(noteInput: ElementRef<HTMLInputElement>) => noteInput.nativeElement.id === `notes-input-${noteIndex}`
		);

		noteItem.nativeElement.focus();
	}

	removeNote(index: number): void {
		this.notes.removeAt(index);
		this.updateNotes();
	}

	updateNotes(): void {
		this.editableNoteIndex = null;
		this.notes.controls = this.notes.controls.filter(n => (n.value as RxNote).content.trim() !== '');
		this.facade.updateNotes(this.notes.controls.map(x => x.value as RxNote));
	}

	isInEditableMode(noteIndex: number): boolean {
		return noteIndex === this.editableNoteIndex;
	}

	blurNotes() {
		this.isNotesFocused = false;
		this.updateNotes();
	}

	focusNotes(event: Event) {
		this.notesFocusedElement = event.target as Element;
		this.isNotesFocused = true;
	}

	handleScannerModeVirtualKeyboard = () => {
		// issue is described in ITEROBIZ-53841 ticket
		this.facade.isHostPlatformScanner$
			.pipe(
				tap(isScannerMode => this.subscribeToWindowResize(isScannerMode)),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	};

	subscribeToWindowResize = isScannerMode => {
		if (isScannerMode) {
			window.addEventListener('resize', () => {
				if (!this.notesFocusedElement) {
					return;
				}

				const isElementInViewPort = this.isElementInViewport(this.notesFocusedElement);

				if (this.isNotesFocused && !isElementInViewPort) {
					this.notesFocusedElement.scrollIntoView();
				}
			});
		}
	};

	isElementInViewport(notesFocusedElement: Element) {
		const rect = notesFocusedElement.getBoundingClientRect();

		return rect.top >= 0 && rect.left >= 0 && rect.bottom <= window.innerHeight && rect.right <= window.innerWidth;
	}

	private registerToNewNoteChanges(): void {
		this.newNote.valueChanges
			.pipe(
				tap(() => {
					this.addNote();
				}),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}

	private isCreatedByTheCurrentUser({ createdById, createdByName }: RxNote): boolean {
		return typeof createdById === 'number' ? createdById === this.createdById : createdByName === this.createdByName;
	}
}
