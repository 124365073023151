import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { Tooth } from '@modules/teeth-diagram/models/tooth';
import { ToothEditorState } from '../models/tooth-editor';

export const createInitialState = (): ToothEditorState => ({
	teeth: null,
	toothClickedOn: null,
	allTeethInJaw: null,
	isBridge: false,
	bridgeIndex: 0,
	isToothEditorOpen: false
});

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'tooth-editor', resettable: true })
export class ToothEditorStore extends Store<ToothEditorState> {
	constructor() {
		super(createInitialState());
	}

	updateTeeth({ teeth }: { teeth: Tooth[] }) {
		this.update({
			teeth: [...teeth]
		});
	}

	updateToothClickedOn({ toothClickedOn }: { toothClickedOn: Tooth }) {
		this.update({ toothClickedOn });
	}

	updateIsBridge({ isBridge }: { isBridge: boolean }) {
		this.update({ isBridge });
	}

	updateAllTeethInJaw({ allTeethInJaw }: { allTeethInJaw: Tooth[] }) {
		this.update({ allTeethInJaw });
	}

	updateBridgeIndex({ bridgeIndex }: { bridgeIndex: number }) {
		this.update({ bridgeIndex });
	}
}
