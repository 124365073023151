import { Component, OnInit } from '@angular/core';
import { TreatmentInformationFacade } from '@modules/treatment-information/treatment-information.facade';
import { ToothEditorService } from '@shared/services/tooth-editor.service';
import { TreatmentInformationComponentPart } from '../models/treatment-information-component.enum';
import { TOOTH_EDITOR_PROVIDERS } from '@modules/tooth-editor/injection/providers';
import { TreatmentInformationBaseComponent } from '../base/treatment-information-base.component';
import { EmptyStringAsHyphenPipe } from '@modules/treatment-information/pipes/empty-string-as-hyphen.pipe';
import { FormControl, FormGroup } from '@angular/forms';
import { Tooth } from '@modules/teeth-diagram/models/tooth';
import { IdName } from '@shared/models/id-name';

@Component({
	selector: 'rx-treatment-information-v1',
	templateUrl: './treatment-information-v1.component.html',
	styleUrls: ['../base/treatment-information-base.component.scss'],
	providers: [TOOTH_EDITOR_PROVIDERS, EmptyStringAsHyphenPipe, TreatmentInformationFacade]
})
export class TreatmentInformationV1Component extends TreatmentInformationBaseComponent implements OnInit {
	componentPart = {
		treatmentInformation: TreatmentInformationComponentPart.TreatmentInformation,
		bridgeInformation: TreatmentInformationComponentPart.BridgeInformation
	};
	displayedColumns: string[] = ['Tooth No', 'Treatment', 'Specification', 'Material', 'Shade', 'Show details'];

	ponticDesignGroupControls = new FormGroup({});

	get ponticDesigns(): IdName[] {
		return this.facade.ponticDesigns;
	}

	constructor(
		protected facade: TreatmentInformationFacade,
		protected toothEditorService: ToothEditorService,
		private emptyLinePipe: EmptyStringAsHyphenPipe
	) {
		super(facade, toothEditorService);
	}

	managePonticDesignControl(bridge: Tooth[]): FormControl<any> {
		const toothWithPontic = bridge?.find(tooth => tooth.PonticDesignID !== null && tooth.PonticDesignID > 0);
		const ponticControlName = this.getPonticDesignControlName(bridge);

		if (!this.ponticDesignGroupControls.contains(ponticControlName)) {
			this.addOrRemoveControl(toothWithPontic, ponticControlName);
		}
		this.patchControlValue(toothWithPontic, ponticControlName);

		return this.ponticDesignGroupControls.get(ponticControlName) as FormControl<any>;
	}

	getShadeByTooth(incisal, body, gingival) {
		if (this.isPrintContext) {
			return `${this.emptyLinePipe.transform(incisal)}/${this.emptyLinePipe.transform(body)}/${this.emptyLinePipe.transform(
				gingival
			)}`;
		}

		return this.emptyLinePipe.transform(body);
	}

	getFunctionForPonticRow(bridge: Tooth[]): (index: number, rowData: any) => boolean {
		return (index: number) => {
			const hasPontic = bridge?.some(tooth => tooth.PonticDesignID > 0);

			return hasPontic && index === bridge?.length - 1;
		};
	}

	private getPonticDesignControlName(bridge: Tooth[]): string {
		const bridgeIndex = bridge?.find(tooth => tooth.BridgeIndex != null)?.BridgeIndex - 1;

		return bridgeIndex !== null ? `ponticControl-${bridgeIndex}` : null;
	}

	private addOrRemoveControl(toothWithPontic: Tooth, controlName: string) {
		if (toothWithPontic && !this.ponticDesignGroupControls.contains(controlName)) {
			this.ponticDesignGroupControls.addControl(controlName, new FormControl<IdName>(null));
		} else if (!toothWithPontic && this.ponticDesignGroupControls.contains(controlName)) {
			this.ponticDesignGroupControls.removeControl(controlName);
		}
	}

	private patchControlValue(toothWithPontic: Tooth, controlName: string) {
		const ponticDesign = this.ponticDesigns?.find(pontic => pontic.Id === toothWithPontic?.PonticDesignID);

		if (ponticDesign && this.ponticDesignGroupControls.contains(controlName)) {
			this.ponticDesignGroupControls.get(controlName)?.patchValue(ponticDesign);
		}
	}
}
