/* eslint-disable @typescript-eslint/naming-convention */
export enum TypeEnum {
	Empty = 0,
	Ortho_Model_iCast = 1,
	Invisalign_Aligners = 2,
	Invisalign_First_Aligners = 3,
	Invisalign_First_Palatal_Expander = 4,
	Vivera_Retainer = 5,
	Invisalign_Retainer = 6,
	Night_guard = 7,
	Bite_Splint = 8,
	Sports_Mouth_guard = 9,
	Apnea_Sleep_Appliance = 10,
	Ortho_Appliance = 12,
	Surgical_guide_Tooth_supported = 13,
	Surgical_guide_Tissue_Supported = 14,
	Surgical_guide_Bone_supported = 15,
	Fixed_restorative_hidden = 16,
	Partial_Denture_Framework = 17,
	Partial_Immediate_Denture = 18, // renamed from Immediate Denture, in MATDB is old value
	Full_Denture_tissue_based = 19,
	Full_Denture_implant_based = 20,
	Surgical_planning_only_No_guide = 21,
	Full_Immediate_Denture = 22
}
