import { Component, Input } from '@angular/core';
import { RxModel } from '@shared/models/rx-models/interfaces/rx-model';
import { RxVersion } from '@shared/models/enums/enums';

@Component({
	selector: 'rx-general-print',
	template: `
	<ng-container [ngSwitch]="isProcedureFlow">
		<rx-general-print-v1 *ngSwitchCase="true" [rxForPrint]="rxForPrint"></rx-general-print-v1>
		<rx-general-print-v0 *ngSwitchCase="false" [rxForPrint]="rxForPrint"></rx-general-print-v0>
	</ng-container>
	`,
})
export class GeneralRxPrintComponent {
	@Input() rxForPrint: RxModel;

	get isProcedureFlow(): boolean {
		return this.rxForPrint.Version === RxVersion.ProcedureFlow;
	}
}
