<div class="unit-type-menu" *ngIf="isUnitTypeMenuVisible" [style.left]="contextMenuPosition.x" [style.top]="contextMenuPosition.y">
    <div class="menu-container">
        <div
            class="arrow"
            [ngClass]="{
                left: menuOrientation === MenuOrientationEnum.right,
                right: menuOrientation === MenuOrientationEnum.left
            }"
            [style.left]="arrowIndicatorPosition.x"
            [style.top]="arrowIndicatorPosition.y"
            [innerHTML]="triangle"
        ></div>
        <div class="unit-type-container">
            <button
                mat-button
                [matMenuTriggerFor]="preDefinedNotes"
                *ngIf="!!this.preDefinedNoteOption"
                [id]="'unit-type-button-' + this.preDefinedNoteOption.Id"
                [name]="'unit-type-button-' + this.preDefinedNoteOption.Id"
                [ngClass]="{
                    selected: preDefinedNoteOption | mapper : isOptionSelected
                }"
                [style.height]="unitTypeHeight + 'px'"
                [style.width]="unitTypeWidth + 'px'"
                (click)="onContextMenuAction(this.preDefinedNoteOption, this.tooth, $event)"
                class="unit-type"
            >
                {{ this.preDefinedNoteOption.Name }}
            </button>
			<button
					mat-button
					*ngIf="isCopyFromToothEnabled()"
					id="copy-tooth-button"
					name="copy-tooth-button"
					[style.height]="unitTypeHeight + 'px'"
					[style.width]="unitTypeWidth + 'px'"
					(click)="onCopyTooth()"
					class="unit-type"
			>
				{{ 'ToothEditor.CopyFromTooth' | translate}} {{ copiedToothNumber$ | async}}
			</button>
            <button
                mat-button
                [id]="'unit-type-button-' + unitTypeMenuItem.Id"
                [name]="'unit-type-button-' + unitTypeMenuItem.Id"
                [ngClass]="{
                    selected: unitTypeMenuItem | mapper : isOptionSelected
                }"
                [style.height]="unitTypeHeight + 'px'"
                [style.width]="unitTypeWidth + 'px'"
                (click)="onContextMenuAction(unitTypeMenuItem)"
                class="unit-type"
                *ngFor="let unitTypeMenuItem of unitTypes"
            >
                {{ unitTypeMenuItem.Name }}
            </button>
        </div>
    </div>
</div>

<mat-menu #preDefinedNotes="matMenu">
    <div id="pre-defined-notes-selection-menu" #preDefinedNotesSelectionMenu>
        <ng-container *ngFor="let preDefOption of this.preDefinedNotesSelectionOptionsWithToothNum; index as i">
            <button mat-menu-item [matMenuTriggerFor]="subMenu" [id]="'pre-defined-selection-' + i">{{ preDefOption.Name }}</button>
            <mat-menu #subMenu="matMenu" id="pre-defined-notes-sub-menu">
                <button
                    *ngFor="let subItem of preDefOption.subMenuOptions; index as j"
                    mat-menu-item
                    [id]="'pre-defined-sub-menu-selection-' + j"
                    (click)="handleSelectPreDefinedNote(subItem)"
                    class="predefined-note"
                >
                    {{ subItem.Name }}
                </button>
            </mat-menu>
        </ng-container>
    </div>
</mat-menu>
