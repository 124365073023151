import { Injectable } from '@angular/core';
import { OrderQuery } from '@modules/order/state/order-query';
import { ProcedureMap } from '@shared/models/procedure-map';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { ShellStore } from '@shared/store/shell/shell-store';
import { DentureDetailsUtils } from '@modules/denture-details/utils/denture-details-utils';
import { isLegacyRestorative, isResorative } from '@shared/utils/restorative-check-util';
import { combineLatest, Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class RxForModelingFacade {
	isProcedureFlow$: Observable<boolean> = this.shellQuery.isProcedureFlow$;
	rx$ = this.shellQuery.rx$;
	procedureId$: Observable<number> = this.shellQuery.rx$.pipe(map(rx => rx?.Order?.ProcedureId));
	isDentureDetailsVisible$: Observable<boolean> = this.orderQuery.procedureMap$.pipe(
		map(procedureMap => DentureDetailsUtils.isDentureDetailsEnabled(procedureMap?.ProcedureId))
	);
	isProcedureResorative$: Observable<boolean> = this.orderQuery.procedureMap$.pipe(
		withLatestFrom(this.shellQuery.isProcedureFlow$),
		map(
			([procedureMap, isProcedureFlow]: [ProcedureMap, boolean]) =>
				isProcedureFlow && this.isResorativeOrLegacyRestorative({ caseTypeId: procedureMap?.CaseTypeId })
		)
		// TODO: isProcedureFlow && procedureMap?.ToothDiagramIsEnabled when ITEROBIZ-60147 is implemented
	);

	isCaseFlowRestorative$: Observable<boolean> = this.shellQuery.rx$.pipe(
		map(rx => this.isResorativeOrLegacyRestorative({ caseTypeId: rx?.Order?.CaseTypeId }))
	);

	isRestoOrder$: Observable<boolean> = combineLatest([this.isCaseFlowRestorative$, this.isProcedureResorative$]).pipe(
		map(([isCaseFlowRestorative, isProcedureResorative]) => isProcedureResorative || isCaseFlowRestorative)
	);

	constructor(private shellStore: ShellStore, private shellQuery: ShellQuery, private orderQuery: OrderQuery) {}

	private isResorativeOrLegacyRestorative({ caseTypeId }: { caseTypeId: number }): boolean {
		const isResto = isResorative({ caseTypeId });
		const isLegacyResto = isLegacyRestorative({ caseTypeId });
		return isResto || isLegacyResto;
	}
}
