import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { IdName } from '@shared/models/id-name';

// eslint-disable-next-line @typescript-eslint/ban-types
export type Public<T extends object> = Pick<T, keyof T>;
export type ValueOf<T> = T[keyof T];

export type ArrayOfObservables<T extends ArrayLike<unknown>> = { [K in keyof T]: Observable<T[K]> };

export type Form<T> = {
	[P in keyof T]: T[P] extends 'object' ? FormGroup<Form<T[P]>> : FormControl<T[P]>;
};

export const isIdName = (value: IdName | unknown): value is IdName => {
	const idName = value as IdName;

	return !!idName && idName.Id !== undefined && idName.Name !== undefined;
};

export const getMeaningfulValue = <T>(value: IdName | T): T => {
	return isIdName(value) ? value?.Id ?? null : value;
};
