import { Injectable, Injector } from '@angular/core';
import { LoggerService } from '@core/services/logger/logger.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class GlobalErrorHandlerService {
	public readonly name = 'GlobalErrorHandler';
	public readonly clientSideErrorMessage = 'Client side unhandled error';

	constructor(private injector: Injector) {}

	handleError(error: Error | HttpErrorResponse) {
		const logger = this.injector.get(LoggerService);
		if (error instanceof HttpErrorResponse) {
			// Is handled by HttpErrorInterceptor.
			return;
		}

		logger.error(this.clientSideErrorMessage, { module: this.name, error });
	}
}
