import { Injectable } from '@angular/core';
import { ApiService, IApiCall } from '@core/services/api.service';
import { RxModel } from '@shared/models/rx-models/interfaces/rx-model';
import { TracesStore } from '@shared/store/traces/traces-store';
import { HttpUtils } from '@shared/utils/http-utils';
import { map, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RxForLabApiService {
	constructor(
		private apiService: ApiService,
		private tracesStore: TracesStore
	) {}

	saveRx(rx: RxModel, orderId: number, showLoader: boolean) {
		const body = {
			rxFormJson: JSON.stringify(rx),
			orderId
		};
		const config: IApiCall = {
			selector: 'saveRxForLab',
			showLoader,
			options: { observe: 'response', body }
		};

		return this.apiService.request(config).pipe(
			tap(response => this.tracesStore.update({ savingTraceId: HttpUtils.getTraceId(response) })),
			map(response => response?.body)
		);
	}
}
