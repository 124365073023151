<div class="rx-card scan-options lab-context">
	<div>
		<div class="rx-card-title">
			<div class="scan-options-title"> {{ 'ScanOptions.AdditionalScansTitle' | translate }} </div>
		</div>
		<div>
			<div fxFlex fxLayout="row" class="scan-options-section">
				<mat-checkbox class="scan-options-checkbox"
					[disabled]="true"
					[checked]="rx?.PrePrepScan"
					name="preTreatmentScan">
					{{ 'GeneralRxData.PreTreatmentScan' | translate }}
				</mat-checkbox>
				<mat-checkbox class="scan-options-checkbox"
					*ngIf="rx?.EmergenceProfile || (isRxForModelingContext$ | async)"
					[disabled]="true"
					[checked]="rx?.EmergenceProfile"
					name="emergenceProfile">
					{{ 'ScanOptions.EmergenceProfile' | translate }}
				</mat-checkbox>
			</div>
		</div>
	</div>
	<div class="additional-bites" *ngIf="(isRestoMultiBiteSelected$ | async)">
		<div class="rx-card-title">
			<div class="scan-options-title resto-multi-bite-title"> {{ 'ScanOptions.MultiBite' | translate }} </div>
		</div>
		<div>
			<div class="additional-bites-row" fxLayout="row" fxFlex>
				<div class="additional-bite"  *ngFor="let bite of additionalBites" [id]= "bite.textKey.toLowerCase()">
					<mat-checkbox class="additional-bite-checkbox read-only"
						[disabled]="true"
						[checked]="isBiteSelected(bite.id) | async"
						[name]= "bite.textKey">
						{{ 'RestoMultiBite.' + bite.textKey | translate }}
					</mat-checkbox>
				</div>
			</div>
		</div>
	</div>
</div>