import { Component, ElementRef, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AlignTechNotesFacade } from '@modules/aligntech-notes/aligntech-notes.facade';
import { RxNote } from '@modules/notes/models/rx-note';
import { BaseDestroyableComponent } from '@shared/base-classes/base-destroyable';
import { createdByNameIteroTeam } from '@shared/models/consts';
import { IdName } from '@shared/models/id-name';
import { RoleTypeEnum } from '@shared/models/role-type';
import { Observable } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { Form } from '@shared/utils/type-util';

@Component({
	selector: 'rx-aligntech-notes',
	templateUrl: './aligntech-notes.component.html',
	styleUrls: ['./aligntech-notes.component.scss'],
	providers: [AlignTechNotesFacade]
})
export class AlignTechNotesComponent extends BaseDestroyableComponent implements OnInit {
	@Input() role: RoleTypeEnum = RoleTypeEnum.Technician;
	isReadOnly$: Observable<boolean> = this.facade.isReadOnly$;
	notesArray$: Observable<RxNote[]> = this.facade.notesArray$;
	dateFormat$: Observable<string> = this.facade.dateFormat$;
	user$: Observable<IdName> = this.facade.user$;
	notesFocusedElement: any = null;
	isNotesFocused = false;
	isAddNotesDisabled = false;
	createdByName: string;
	createdById: number;
	iteroModelingNoteCreatorName = createdByNameIteroTeam; // Required for unit tests

	alignTechNotesForm = this.formBuilder.group({
		newNote: this.formBuilder.control('', { updateOn: 'blur' }),
		alignTechNotes: this.formBuilder.array<FormGroup<Form<RxNote>>>([])
	});

	get newNote() {
		return this.alignTechNotesForm.controls.newNote;
	}
	alignTechNotes = this.alignTechNotesForm.controls.alignTechNotes;
	editableNoteIndex: number = null;
	@ViewChildren('noteinput') noteinputs: QueryList<ElementRef>;

	constructor(private facade: AlignTechNotesFacade, private formBuilder: FormBuilder) {
		super();
	}

	ngOnInit(): void {
		this.user$
			.pipe(
				map((user: IdName) => {
					this.createdById = user?.Id;
					this.createdByName = this.facade.isRxForModelingContext ? this.iteroModelingNoteCreatorName : user?.Name;
				}),
				takeUntil(this.componentAlive$)
			)
			.subscribe();

		this.notesArray$
			.pipe(
				map((notes: RxNote[]) => {
					if (!notes) {
						return;
					}
					this.alignTechNotes.clear();
					for (const note of notes) {
						this.alignTechNotes.push(
							this.formBuilder.group<Form<RxNote>>({
								content: this.formBuilder.control(note.content, { updateOn: 'blur' }),
								createdByName: this.formBuilder.control(note.createdByName),
								dateCreated: this.formBuilder.control(note.dateCreated),
								createdById: this.formBuilder.control(note.createdById),
								role: this.formBuilder.control(note.role),
								isPreDefinedNote: this.formBuilder.control(note.isPreDefinedNote)
							})
						);
					}
				}),
				tap(_ => this.registerToNewNoteChanges()),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}

	addNote(): void {
		if (this.newNote.value === '') {
			return;
		}

		const contentControl = this.formBuilder.control(this.newNote.value.trim(), { updateOn: 'blur' });

		this.alignTechNotes.insert(
			0,
			this.formBuilder.group<Form<RxNote>>({
				content: contentControl,
				createdByName: this.formBuilder.control(this.createdByName),
				createdById: this.formBuilder.control(this.createdById),
				dateCreated: this.formBuilder.control(new Date().toISOString()),
				role: this.formBuilder.control(this.role)
			})
		);

		this.updateNotes();
		this.newNote.setValue('');
	}

	editNote(noteIndex: number): void {
		this.editableNoteIndex = noteIndex;

		const noteItem = this.noteinputs.find((noteInput: ElementRef) => noteInput.nativeElement.id === `notes-input-${noteIndex}`);

		// eslint-disable-next-line @typescript-eslint/no-unsafe-call
		noteItem.nativeElement.focus();
	}

	removeNote(index: number): void {
		this.alignTechNotes.removeAt(index);
		this.updateNotes();
	}

	updateNotes(): void {
		this.editableNoteIndex = null;
		this.facade.updateNotes(this.alignTechNotes.controls.map(x => x.value as RxNote));
	}

	isInEditableMode(noteIndex: number): boolean {
		return noteIndex === this.editableNoteIndex;
	}

	canEdit(noteIndex: number): boolean {
		const note = this.alignTechNotes.controls[noteIndex].value;
		const isPreDefinedNote = !!note.isPreDefinedNote;
		const isCreatedByTheCurrentUser = note.createdById === this.createdById;

		return !this.facade.isReadOnly && isCreatedByTheCurrentUser && !isPreDefinedNote;
	}

	canDelete(noteIndex: number): boolean {
		const note = this.alignTechNotes.controls[noteIndex].value;
		const isCreatedByTheCurrentUser = note.createdById === this.createdById;

		return (
			!this.facade.isReadOnly &&
			(isCreatedByTheCurrentUser || (this.facade.isRxForModelingContext && note.createdByName === this.iteroModelingNoteCreatorName))
		);
	}

	blurNotes() {
		this.isNotesFocused = false;
		this.updateNotes();
	}

	focusNotes(event: Event) {
		this.notesFocusedElement = event.target || event.currentTarget;
		this.isNotesFocused = true;
	}

	private registerToNewNoteChanges(): void {
		this.newNote.valueChanges
			.pipe(
				tap(() => {
					this.addNote();
				}),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}
}
