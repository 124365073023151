import { Injectable } from '@angular/core';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { filter, map, shareReplay, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { JsonApiRegistry } from 'api-registry';
import { AuthInfoService } from '@shared/services/auth-info.service';
import { AuthInfoQuery } from '@shared/store/authInfo/auth-info-query';

@Injectable({
	providedIn: 'root'
})
export class RxAttachmentsApiService {
	constructor(private shellQuery: ShellQuery, private authInfoQuery: AuthInfoQuery, private authInfoService: AuthInfoService) {}

	private static baseApiUrl?: string;

	public isApiInitialized$: Observable<boolean> = this.shellQuery.appSettings$.pipe(
		map(appSettings => appSettings.rxAttachmentsApiUrl),
		filter(url => url != null),
		take(1),
		map(rxAttachmentsApiUrl => {
			// baseApiUrl is static to eliminate attempts to init the API twice
			// The API must be initialized only once per browser window
			if (RxAttachmentsApiService.baseApiUrl != null) {
				return true;
			}

			RxAttachmentsApiService.baseApiUrl = rxAttachmentsApiUrl;

			const requestHeaders = (): Promise<RequestInit> => {
				// See https://wiki.aligntech.com/display/~azer-kavod/Online+users+-+Service+Authentication+and+business+Context
				const stdBizCtx = {
					bizCtx: {
						contactId: this.shellQuery.contactId,
						companyId: this.shellQuery.companyId,
						orderId: this.shellQuery.orderId
					}
				};

				return Promise.resolve({
					headers: {
						Authorization: 'Bearer ' + this.authInfoQuery.authInfo.accessToken,
						// eslint-disable-next-line @typescript-eslint/naming-convention
						'X-ITERO-BIZCTX': btoa(JSON.stringify(stdBizCtx))
					}
				});
			};

			// Register Rx Attachments API
			JsonApiRegistry.api('rx-attachments', () => Promise.resolve(RxAttachmentsApiService.baseApiUrl))
				.withOptions(requestHeaders)
				.intercept((request: Request, next: () => Promise<Response>) => {
					return next().then(response => {
						// Handle redirects to the proper region
						if (response.redirected) {
							RxAttachmentsApiService.baseApiUrl = new URL(response.url).origin;

							if (response.status === 401) {
								// Authorization header got removed after redirect
								// Send another request with all headers
								return next();
							}
						}

						if (response.status === 401) {
							return this.authInfoService
								.refreshAuthInfo()
								.toPromise()
								.then(() => {
									return next();
								});
						}

						return response;
					});
				});

			return true;
		}),
		shareReplay(1)
	);

	public deleteRxAttachments = JsonApiRegistry.api('rx-attachments')
		.endpoint('api/v1/attachments{?rxId,companyId}', 'delete')
		.receives<{ rxId: string; companyId: number }>()
		.build();

	public getAttachments = JsonApiRegistry.api('rx-attachments')
		.endpoint('api/v1/attachments{?rxId,companyId}')
		.receives<{ rxId: string; companyId: number }>()
		.returns<{ id: string }[]>()
		.withCache(5000) // Set caching for 5 seconds to avoid a duplicate API call in the rx-attachments component
		.buildWithParse();

	public getAttachmentsCount = JsonApiRegistry.api('rx-attachments')
		.endpoint('api/v1/attachments/CountByRxIds', 'post')
		.receives<string[]>()
		.returns<{ rxId: string; attachmentsCount: number }[]>()
		.buildWithParse();
}
