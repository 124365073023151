import { Injectable } from '@angular/core';
import { IdName } from '@shared/models/id-name';
import { OrderInformationModel } from '@shared/models/rx-models/interfaces/order-information-model';
import { RxModel } from '@shared/models/rx-models/interfaces/rx-model';
import { ValidateRxService } from '@shared/services/validate-rx';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { combineLatest, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { OrderInformationApiService } from './api/order-information.api';
import { OrderInformationQuery } from './state/order-information.query';
import { OrderInformationState, OrderInformationStore } from './state/order-information.store';

@Injectable()
export class OrderInformationFacade {
	constructor(
		private shellQuery: ShellQuery,
		private query: OrderInformationQuery,
		private store: OrderInformationStore,
		private api: OrderInformationApiService,
		private validateRxService: ValidateRxService
	) {}

	cadCamSystemOptions$: Observable<IdName[]> = this.query.cadCamSystemOptions$;
	canOrderPhysicalModel$: Observable<boolean> = this.query.canOrderPhysicalModel$;

	orderInformation$: Observable<OrderInformationModel> = this.shellQuery.rx$.pipe(map((rx: RxModel) => rx?.OrderInformation));

	isReadOnly$: Observable<boolean> = this.shellQuery.isReadOnly$;
	isInvalidForSend$: Observable<boolean> = combineLatest([this.query.isInvalidForSend$, this.query.orderInfo$]).pipe(
		map(([isInvalidForSend, orderInfo]) => isInvalidForSend && this.validateRxService.isOrderInfoInvalidForSend(orderInfo))
	);
	shipToId$ = this.shellQuery.shipToId$;

	updateOrderInformation({ orderInformation }: { orderInformation: OrderInformationState }) {
		this.store.updateOrderInformation({ orderInformation });
	}

	getCadCamSystemTypes(): Observable<IdName[]> {
		return this.api.getCadCamSystemTypes().pipe(tap(cadCamSystemOptions => this.store.update({ cadCamSystemOptions })));
	}

	getCanOrderPhysicalModel(companyId: number): Observable<boolean> {
		return this.api
			.getCanOrderPhysicalModel(companyId)
			.pipe(tap(canOrderPhysicalModel => this.store.update({ canOrderPhysicalModel })));
	}
}
