import { ProcedureUnitTypeMaterialRulesConfig } from '../models/procedure-unit-type-material-rules-config';
import { rulesForFixedRestorativeGlidewellIo } from './rules/fixed-restorative-glidewell-io';
import { rulesForImplantPlanning } from '@core/procedure-helpers/teeth-diagram/rules/implant-planning';
import { rulesForImplantPlanningGuideTooth } from '@core/procedure-helpers/teeth-diagram/rules/implant-planning-guide-tooth';
import { rulesForDentureRemovableImplantBased } from '@core/procedure-helpers/teeth-diagram/rules/denture-removable-implant-based';
import { rulesForRemovablePartialDentureFramework } from './rules/denture-removable-partial-framework';
import { rulesForRemovableImmediateDentureFramework } from './rules/denture-removable-immediate';
import { rulesForFixedRestorative } from './rules/fixed-restorative';

export const procedureUnitTypeMaterialRules: ProcedureUnitTypeMaterialRulesConfig = {
	ProcedureRules: [
		rulesForFixedRestorative,
		rulesForFixedRestorativeGlidewellIo,
		rulesForImplantPlanning,
		rulesForImplantPlanningGuideTooth,
		rulesForDentureRemovableImplantBased,
		rulesForRemovablePartialDentureFramework,
		rulesForRemovableImmediateDentureFramework
	]
};
