import { Component, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { IdName } from '@shared/models/id-name';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { ControlValueAccessorDirective } from '@shared/base-classes/control-value-accessor.directive';

@Component({
	selector: 'rx-icast-ortho-model-toggle',
	templateUrl: './icast-ortho-model-toggle.component.html',
	styleUrls: ['./icast-ortho-model-toggle.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: IcastOrthoModelToggleComponent,
			multi: true
		}
	]
})
export class IcastOrthoModelToggleComponent extends ControlValueAccessorDirective<IdName> {
	get checked(): boolean {
		return this.turnedOnValue && this.value && this.value.Id === this.turnedOnValue.Id;
	}

	@Input() turnedOnValue: IdName;

	value = null;
	disabled = false;
	touched = false;

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	writeValue(obj: IdName): void {
		this.value = obj;
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	onCheck(event: MatSlideToggleChange) {
		this.value = event.checked ? this.turnedOnValue : null;
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		this.onChange(this.value);

		if (!this.touched) {
			this.touched = true;
			this.onTouched();
		}
	}

	private onChange = () => {};
	private onTouched = () => {};
}
