import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FlexModule } from '@angular/flex-layout/flex';

@Component({
	selector: 'rx-license-read-only',
	templateUrl: './license-read-only.component.html',
	styleUrls: ['./license-read-only.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [FlexModule, TranslateModule]
})
export class LicenseReadOnlyComponent {
	@Input() license: string;
}
