import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { OrderInformationFormModel } from '@modules/order-information/models/order-information-form-model';
import { OrderInformationFacade } from '@modules/order-information/order-information.facade';
import { BaseDestroyableComponent } from '@shared/base-classes/base-destroyable';
import { IdName } from '@shared/models/id-name';
import { OrderInformationModel } from '@shared/models/rx-models/interfaces/order-information-model';
import { Form } from '@shared/utils/type-util';
import { combineLatest, Observable } from 'rxjs';
import { filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';

@Component({
	selector: 'rx-order-information',
	templateUrl: './order-information.component.html',
	styleUrls: ['./order-information.component.scss'],
	providers: [OrderInformationFacade],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderInformationComponent extends BaseDestroyableComponent implements OnInit {
	cadCamSystemOptions$: Observable<IdName[]> = this.facade.cadCamSystemOptions$;
	orderInformation$: Observable<OrderInformationModel> = this.facade.orderInformation$;
	isReadOnly$: Observable<boolean> = this.facade.isReadOnly$;
	isInvalidForSend$: Observable<boolean> = this.facade.isInvalidForSend$;
	isPhysicalModelOrderVisible$: Observable<boolean> = this.facade.canOrderPhysicalModel$;

	localExportIde = new FormControl<boolean>({ value: true, disabled: false });
	physicalModelOrder = new FormControl<boolean>({ value: false, disabled: false });

	orderInformationForm = new FormGroup<Form<OrderInformationFormModel>>({
		cadCamSystem: new FormControl({ value: null, disabled: false }),
		numberOfModels: new FormControl({ value: 0, disabled: false }),
		additionalDies: new FormControl({ value: 0, disabled: false }),
		dieDitch: new FormControl({ value: false, disabled: false })
	});

	get localExportIdeControl() {
		return this.localExportIde;
	}
	get physicalModelOrderControl() {
		return this.physicalModelOrder;
	}
	get cadCamSystemControl() {
		return this.orderInformationForm.controls.cadCamSystem;
	}
	get numberOfModelsControl() {
		return this.orderInformationForm.controls.numberOfModels;
	}
	get additionalDiesControl() {
		return this.orderInformationForm.controls.additionalDies;
	}
	get dieDitchControl() {
		return this.orderInformationForm.controls.dieDitch;
	}

	constructor(private facade: OrderInformationFacade) {
		super();
	}

	ngOnInit(): void {
		this.facade.getCadCamSystemTypes().pipe(take(1), takeUntil(this.componentAlive$)).subscribe();
		this.facade.shipToId$
			.pipe(
				filter(shipToId => shipToId != null),
				switchMap(shipToId => this.facade.getCanOrderPhysicalModel(shipToId)),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
		combineLatest([this.orderInformation$, this.cadCamSystemOptions$])
			.pipe(
				filter(([orderInformation, cadCamSystemOptions]) => !!orderInformation && !!cadCamSystemOptions),
				take(1),
				tap(([orderInformation, cadCamSystemOptions]) => {
					this.updatePhysicalModel(orderInformation);
					this.updateCamSystem(orderInformation.IdeCadCamSystemId, cadCamSystemOptions);
				}),

				takeUntil(this.componentAlive$)
			)
			.subscribe();

		this.orderInformationForm.valueChanges
			.pipe(
				tap(orderInformationValue => {
					const tempOrderInformationValue = { ...orderInformationValue };

					if (this.physicalModelOrderControl && orderInformationValue.numberOfModels === 0) {
						this.physicalModelOrderControl.patchValue(false, { emitEvent: false });
						tempOrderInformationValue.additionalDies = 0;
						tempOrderInformationValue.dieDitch = false;
						this.orderInformationForm.patchValue(tempOrderInformationValue, { emitEvent: false });
					}

					this.facade.updateOrderInformation({
						orderInformation: {
							ideCadCamSystemId: tempOrderInformationValue.cadCamSystem?.Id || -1,
							localIdeCadCamSystemId: tempOrderInformationValue.cadCamSystem?.Id || -1,
							numOfModels: tempOrderInformationValue.numberOfModels,
							additionalDies: tempOrderInformationValue.additionalDies,
							dieDitch: tempOrderInformationValue.dieDitch
						}
					});
				}),
				takeUntil(this.componentAlive$)
			)
			.subscribe();

		this.localExportIdeControl.valueChanges
			.pipe(
				tap((localExportIdevalue: boolean) => {
					if (localExportIdevalue === false) {
						this.cadCamSystemControl.patchValue(null);
					}
				}),
				takeUntil(this.componentAlive$)
			)
			.subscribe();

		this.physicalModelOrderControl.valueChanges
			.pipe(
				tap((physicalModelOrderValue: boolean) => {
					this.facade.orderInformation$
						.pipe(
							take(1),
							tap(orderInformation => {
								if (physicalModelOrderValue && orderInformation.NumOfModels > 0) {
									this.numberOfModelsControl.patchValue(orderInformation.NumOfModels);
									this.additionalDiesControl.patchValue(orderInformation.AdditionalDies);
									this.dieDitchControl.patchValue(orderInformation.DieDitch);
								} else if (physicalModelOrderValue && orderInformation.NumOfModels === 0) {
									this.numberOfModelsControl.patchValue(1);
									this.additionalDiesControl.patchValue(0);
									this.dieDitchControl.patchValue(false);
								} else {
									this.numberOfModelsControl.patchValue(0);
									this.additionalDiesControl.patchValue(0);
									this.dieDitchControl.patchValue(false);
								}
							}),
							takeUntil(this.componentAlive$)
						)
						.subscribe();
				}),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}

	private updatePhysicalModel(orderInformation: OrderInformationModel): void {
		this.numberOfModelsControl.patchValue(orderInformation.NumOfModels, { emitEvent: false });
		this.additionalDiesControl.patchValue(orderInformation.AdditionalDies, { emitEvent: false });
		this.dieDitchControl.patchValue(orderInformation.DieDitch, { emitEvent: false });

		if (orderInformation.NumOfModels > 0) {
			this.physicalModelOrderControl.patchValue(true);
		}
	}

	private updateCamSystem(ideCadCamSystemId: number, cadCamSystemOptions: IdName[]): void {
		const selectedCadCamOption = cadCamSystemOptions?.find(option => option.Id === ideCadCamSystemId);

		this.cadCamSystemControl.patchValue(selectedCadCamOption, { emitEvent: false });
		this.localExportIdeControl.patchValue(!!selectedCadCamOption, { emitEvent: false });
	}
}
