import { ConnectedPosition } from '@angular/cdk/overlay';

export const ALIGNED_LEFT_POSITIONS: ConnectedPosition[] = [
	{
		originX: 'start', // left corner of the trigger
		originY: 'bottom', // bottom corner of the trigger
		overlayX: 'start', // left corner of the overlay to the origin
		overlayY: 'top', // top corner of the overlay to the origin,
		offsetY: 8 // offset to bottom
	},
	{
		originX: 'start',
		originY: 'top', // top corner of the trigger
		overlayX: 'start',
		overlayY: 'top'
	},
	{
		originX: 'start', // left corner of the trigger
		originY: 'top', // top corner of the trigger
		overlayX: 'start', // left corner of the overlay to the origin
		overlayY: 'bottom', // bottom corner of the overlay to the origin
		offsetY: -8 // offset to top
	},
	{
		originX: 'start',
		originY: 'bottom', // bottom corner of the trigger
		overlayX: 'start',
		overlayY: 'bottom'
	}
];

export const ALIGNED_RIGHT_POSITIONS: ConnectedPosition[] = [
	{
		originX: 'end', // right corner of the trigger
		originY: 'bottom', // bottom corner of the trigger
		overlayX: 'end', // right corner of the overlay to the origin
		overlayY: 'top', // top corner of the overlay to the origin
		offsetY: 8 // offset to bottom
	},
	{
		originX: 'end',
		originY: 'top',
		overlayX: 'end',
		overlayY: 'top'
	},
	{
		originX: 'end', // right corner of the button
		originY: 'top', // top corner of the button
		overlayX: 'end', // right corner of the overlay to the origin
		overlayY: 'bottom', // top corner of the overlay to the origin
		offsetY: -8 // offset to top
	},
	{
		originX: 'end',
		originY: 'bottom',
		overlayX: 'end',
		overlayY: 'bottom'
	}
];
