import { Component, Input } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { PatientFacade } from '@modules/patient/patient.facade';
import { Observable } from "rxjs";

@Component({
	selector: 'rx-patient-app-inline',
	templateUrl: './patient-app-inline.component.html',
	styleUrls: ['./patient-app-inline.component.scss'],
	providers: [PatientFacade]
})
export class PatientAppInlineComponent {
	@Input() url$: Observable<SafeResourceUrl>;
	@Input() isPatientInConflict: boolean;
	@Input() isResizePatientAppDialog: boolean;
}

