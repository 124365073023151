import { Injectable } from '@angular/core';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { FavoritesEnum } from '@shared/models/enums/favorites.enum';
import { LocalSettingsStore } from '@shared/store/localSettings/local-settings-store';
import { LocalSettingsQuery } from '@shared/store/localSettings/local-settings-query';

@Injectable({
	providedIn: 'root'
})
export class SavingFavoritesService {
	constructor(
		private shellQuery: ShellQuery,
		private localSettingsQuery: LocalSettingsQuery,
		private localSettingsStore: LocalSettingsStore
	) {}

	save(name: FavoritesEnum, items: string[]) {
		this.localSettingsStore.updateFavoritesSettings(this.shellQuery.contactId, name, items);
	}

	get(name: FavoritesEnum) {
		const favoritesSettings = this.localSettingsQuery.favoritesSettings;
		const favorites = favoritesSettings[this.shellQuery.contactId] ?? {};
		return favorites[name];
	}
}
