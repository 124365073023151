import { Component, Injector, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, Validators, ReactiveFormsModule } from '@angular/forms';
import { ControlValueAccessorDirective } from '@shared/base-classes/control-value-accessor.directive';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';

@Component({
	selector: 'rx-license-input',
	templateUrl: './license-input.component.html',
	styleUrls: ['./license-input.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: LicenseInputComponent,
			multi: true
		}
	],
	standalone: true,
	imports: [MatLegacyFormFieldModule, MatLegacyInputModule, ReactiveFormsModule, NgIf, TranslateModule]
})
export class LicenseInputComponent extends ControlValueAccessorDirective<string> implements OnInit {
	constructor(injector: Injector) {
		super(injector);
	}

	ngOnInit(): void {
		this.control.setValidators([Validators.required.bind(this), Validators.maxLength(36)]);
	}
}
