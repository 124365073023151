export enum SpecificationEnum {
	None = -1,
	FullContour = 1,
	PFM_PFZ = 2,
	PFM_PFZ_CopingOnly = 3,
	Telescopic = 4,
	Temporary = 5,
	Veneer = 6,
	Abutment = 7
}
