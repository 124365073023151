<div
  class="teeth-diagram-container"
  [class.appear-read-only]="isReadOnly$ | async"
>
  <div class="rx-card-title" [class.print-context]="isPrintContext">
    <div class="rx-title-row">
      <div
        class="rx-card-title-text teeth-title"
        fxLayout="row"
        fxLayout.xs="column flex-start"
        fxLayoutAlign="flex-start"
      >
        {{ 'PrintRx.ToothDiagram' | translate }}
      </div>
    </div>
  </div>
  <rx-unit-type
    [toothClicked]="unitTypeMenuInput$ | async"
    [preDefinedNoteOption]="preDefinedNoteOption"
    (unitTypeSelected)="handleUnitTypeSelection($event)"
    (unitTypeInBridgeSelected)="handleUnitTypeInBridgeSelection($event)"
    (preDefinedNoteSelected)="handlePreDefinedNoteSelected($event)"
    (unitTypeMenuClosed)="handleUnitTypeMenuClosed()"
    [isProcedureFlow]= "false"
  ></rx-unit-type>
  <div class="teeth-diagram" *ngIf="!isPrintContext">
    <rx-bridge-indication
      [bridgeEdges]="bridgeEdges"
      [teethInJaw]="upperJaw$ | async"
      jaw="upper"
    ></rx-bridge-indication>
    <mat-grid-list cols="16" [rowHeight]="rowHeight">
      <mat-grid-tile
        class="jaw-tooth-container upper"
        *ngFor="let tooth of upperJaw$ | async; index as i"
      >
        <rx-tooth
          [isReadOnly]="isReadOnly$ | async"
          (toothLoaded)="handleToothLoaded()"
          [tooth]="tooth"
          (toothClicked)="handleToothClick($event)"
          [isGlidewellOrder]="isGlidewellOrder"
        ></rx-tooth>
      </mat-grid-tile>
    </mat-grid-list>
    <rx-teeth-numbers
      [teethNumberingSystem]="teethNumberingSystem$ | async"
      [toothBridges]="toothBridge"
      [isPrintContext]="isPrintContext"
    ></rx-teeth-numbers>
    <mat-grid-list cols="16" [rowHeight]="rowHeight">
      <mat-grid-tile
        class="jaw-tooth-container lower"
        *ngFor="let tooth of lowerJaw$ | async; index as i"
      >
        <rx-tooth
          [isReadOnly]="isReadOnly$ | async"
          (toothLoaded)="handleToothLoaded()"
          [tooth]="tooth"
          (toothClicked)="handleToothClick($event)"
          [isGlidewellOrder]="isGlidewellOrder"
        ></rx-tooth>
      </mat-grid-tile>
    </mat-grid-list>
    <rx-bridge-indication
      [bridgeEdges]="bridgeEdges"
      [teethInJaw]="lowerJaw$ | async"
      jaw="lower"
    ></rx-bridge-indication>
  </div>
  <!-- for print -->
  <div class="teeth-diagram" *ngIf="isPrintContext">
    <rx-bridge-indication
      [bridgeEdges]="bridgeEdges"
      [teethInJaw]="upperJaw$ | async"
      jaw="upper"
      [isPrintContext]="true"
    ></rx-bridge-indication>
    <div class="jaw-teeth-container">
      <rx-tooth
        class="jaw-tooth-container upper"
        *ngFor="let tooth of upperJaw$ | async; index as i"
        [isReadOnly]="isReadOnly$ | async"
        (toothLoaded)="handleToothLoaded()"
        [tooth]="tooth"
        (toothClicked)="handleToothClick($event)"
        [isGlidewellOrder]="isGlidewellOrder"
      ></rx-tooth>
    </div>
    <rx-teeth-numbers
      [teethNumberingSystem]="teethNumberingSystem$ | async"
      [toothBridges]="toothBridge"
      [isPrintContext]="isPrintContext"
    ></rx-teeth-numbers>
    <div class="jaw-teeth-container">
      <rx-tooth
        class="jaw-tooth-container lower"
        *ngFor="let tooth of lowerJaw$ | async; index as i"
        [isReadOnly]="isReadOnly$ | async"
        (toothLoaded)="handleToothLoaded()"
        [tooth]="tooth"
        (toothClicked)="handleToothClick($event)"
        [isGlidewellOrder]="isGlidewellOrder"
      ></rx-tooth>
    </div>
    <rx-bridge-indication
      [bridgeEdges]="bridgeEdges"
      [teethInJaw]="lowerJaw$ | async"
      jaw="lower"
      [isPrintContext]="true"
    ></rx-bridge-indication>
  </div>
</div>
