import { Component, Inject, OnInit } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { RxForLabFacade } from '../../rx-for-lab.facade';
import { DentureDetailsUtils } from '@modules/denture-details/utils/denture-details-utils';
import { ProcedureLoadRxService } from '@modules/order/services/procedure-load-rx.service';
import { BaseDestroyableDirective } from '@shared/base-classes/base-destroyable';
import { RxAttachmentsFacade } from '@shared/rx-attachments/rx-attachments.facade';
import { WINDOW } from '@shared/tokens';
import { BiSessionInfo } from '@itero/rx-attachments/lib/models/analytics/BiSessionInfo';

@Component({
	selector: 'rx-for-lab-v1',
	templateUrl: './rx-for-lab-v1.component.html',
	styleUrls: ['./rx-for-lab-v1.component.scss'],
	providers: [RxForLabFacade, RxAttachmentsFacade]
})
export class RxForLabV1Component extends BaseDestroyableDirective implements OnInit {
	isRxloadedAndReady$: Observable<boolean> = this.facade.isRxloadedAndReady$;
	isTeethDiagramAreaVisible$: Observable<boolean> = this.facade.isTeethDiagramAreaVisible$;
	procedureId$: Observable<number> = this.facade.procedureId$;
	rx$ = this.facade.rx$;
	isAttachmentsSectionVisible$: Observable<boolean> = this.rxAttachmentsFacade.isAttachmentsSectionVisible$;
	attachmentsRxId$: Observable<string> = this.facade.attachmentsRxId$;
	companyId$: Observable<number> = this.facade.companyId$;
	isAttachmentsReadOnly$ = this.rxAttachmentsFacade.isAttachmentsReadOnly$;
	sessionInfo$: Observable<BiSessionInfo> = combineLatest([this.facade.authInfo$, this.facade.appSettings$]).pipe(
		map(([{ sessionId, sessionType }, { biTimberUrl }]) => {
			return {
				sessionId,
				sessionType,
				biTimberUrl
			} as BiSessionInfo;
		})
	);
	contactId$ = this.facade.contactId$;
	userRole$: Observable<any> = this.facade.userRole$;
	timberUrl$: Observable<string> = this.facade.appSettings$.pipe(
		map(({ logging }) => {
			return logging.url;
		}),
		takeUntil(this.componentAlive$)
	);

	constructor(
		private facade: RxForLabFacade,
		private procedureLoadRxService: ProcedureLoadRxService,
		private rxAttachmentsFacade: RxAttachmentsFacade,
		@Inject(WINDOW) private window: Window
	) {
		super();
	}

	isDentureDetailsVisible(procedureId: number): boolean {
		return DentureDetailsUtils.isDentureDetailsEnabled(procedureId);
	}

	ngOnInit(): void {
		// fill order store
		this.procedureLoadRxService.loadProcedureFlowOrder().pipe(takeUntil(this.componentAlive$)).subscribe();
	}

	onAttachmentsListUpdated(): void {
		const shouldScrollToAttachments = this.window.location.href.includes('#attachments-section');

		if (shouldScrollToAttachments) {
			document.getElementById('attachments-section')?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
		}
	}
}
