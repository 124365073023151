import { Component, Input, OnInit } from '@angular/core';
import { GeneralRxDataV0Facade } from '@modules/general-rx-data/general-rx-data-v0-facade';
import { RxModel } from '@shared/models/rx-models/interfaces/rx-model';
import { Observable } from 'rxjs';

@Component({
	selector: 'rx-general-print-v0',
	templateUrl: './general-rx-print-v0.component.html',
	providers: [GeneralRxDataV0Facade]
})
export class GeneralRxPrintV0Component implements OnInit {
	@Input() rxForPrint: RxModel;

	doctorName: string;
	caseType$: Observable<string>;
	scanDate: string;
	dueDate: string;
	signature: string;
	license: string;
	shipToAddress: string;
	practice: string;
	dateFormat$: Observable<string> = this.facade.dateFormat$;

	constructor(private facade: GeneralRxDataV0Facade) {}

	ngOnInit() {
		if (!this.rxForPrint) {
			return;
		}

		this.practice = this.rxForPrint.CompanyName;
		this.shipToAddress = this.rxForPrint.ShipToAddress;
		this.doctorName = this.rxForPrint.Doctor?.Name;
		this.scanDate = this.rxForPrint.Order.ScanDate as string;
		this.dueDate = this.rxForPrint.Order.DueDate as string;
		this.signature = this.rxForPrint.Signature;
		this.license = this.rxForPrint.Doctor?.Licence;

		this.caseType$ = this.facade.getCaseTypeTranslationById(this.rxForPrint.Order?.CaseTypeId);
	}
}
