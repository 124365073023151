import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RxForLabV0Component } from './rx-for-lab/v0/rx-for-lab-v0.component';
import { SharedModule } from '@shared/shared.module';
import { GeneralRxDataModule } from '@modules/general-rx-data/general-rx-data.module';
import { TeethDiagramModule } from '@modules/teeth-diagram/teeth-diagram.module';
import { ToothEditorModule } from '@modules/tooth-editor/tooth-editor.module';
import { TreatmentInformationModule } from '@modules/treatment-information/treatment-information.module';
import { RxForLabV1Component } from '@modules/rx-for-lab/rx-for-lab/v1/rx-for-lab-v1.component';
import { RxForLabComponent } from '@modules/rx-for-lab/rx-for-lab/rx-for-lab.component';
import { NotesModule } from '@modules/notes/notes.module';
import { OrderInformationModule } from '@modules/order-information/order-information.module';
import { RxAttachmentsModule } from '@itero/rx-attachments';
import { ScanOptionsModule } from '@modules/scan-options/scan-options.module';
import { DentureDetailsInfoComponent } from '@modules/denture-details';

@NgModule({
	declarations: [RxForLabV0Component, RxForLabV1Component, RxForLabComponent],
	imports: [
		CommonModule,
		SharedModule,
		GeneralRxDataModule,
		TeethDiagramModule,
		ToothEditorModule,
		TreatmentInformationModule,
		DentureDetailsInfoComponent,
		NotesModule,
		OrderInformationModule,
		RxAttachmentsModule,
		ScanOptionsModule
	],
	exports: [RxForLabComponent]
})
export class RxForLabModule {}
