<div class="rx-card mt-4" [class.read-only]="isReadOnly$ | async">
	<div class="rx-card-title doctor-card-title">
		<div class="rx-title-row" fxLayout="row" fxLayoutAlign="space-between">
			<div fxFlex="0 1 calc(50% - 15px)">
				<rx-doctor-name fxFlex [doctorName]="(doctor$ | async)?.Name"></rx-doctor-name>
			</div>
			<div *ngIf="(isLicenseEditable$ | async); then licenseEditor else licenseReadOnly"></div>
			<ng-template #licenseEditor>
				<div fxFlex="0 1 calc(50% - 15px)">
					<rx-license-input [formControl]="licenseFormControl"></rx-license-input>
				</div>
			</ng-template>
			<ng-template #licenseReadOnly>
				<div fxFlex="0 1 calc(50% - 15px)">
					<rx-license-read-only fxFlex [license]="(license$ | async)"></rx-license-read-only>
				</div>
			</ng-template>
		</div>
	</div>
</div>