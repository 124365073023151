<div class="rx-card order-information"
    [class.read-only]="isReadOnly$ | async" [class.invalid-for-send]="isInvalidForSend$ | async"  >
    <div class="rx-card-title">
        <div class="rx-title-row" fxLayout="row" fxLayoutAlign="start center">
            <div class="order-information-title">{{ 'OrderInformation.OrderInformation' | translate }}</div>
        </div>
    </div>
    <div class="rx-card-body">
        <form [formGroup]="orderInformationForm">
            <div
                fxFlex
                fxLayout="row"
                fxLayoutAlign="space-between"
                fxLayout.sm="column stretch"
                fxLayout.xs="column stretch"
                class="abutment-section"
            >
                <div fxFlex="1 1 49%">
                    <mat-checkbox
						id="auto-local-export-ide-checkbox"
						[disabled]="false"
						[formControl]="localExportIdeControl"
						name="localExportIdeControl"
					>
                        {{ 'OrderInformation.LocalExportIDE' | translate }}
                    </mat-checkbox>
                    <div class="local-export-ide-info-container" *ngIf="!!localExportIdeControl.value">
                        <rx-object-selector
							id="auto-cad-cam-systems-selector"
                            class="mt-4"
                            [label]="'OrderInformation.CadCamSystem' | translate"
                            [formControl]="cadCamSystemControl"
                            [options]="cadCamSystemOptions$ | async"
                            name="cadCamSystemControl"
                            ngDefaultControl
                        >
                        </rx-object-selector>
                    </div>
                </div>
                <div *ngIf="isPhysicalModelOrderVisible$ | async" fxFlex="1 1 49%">
                    <mat-checkbox
						id="auto-physical-model-order-checkbox"
						[disabled]="false"
						[formControl]="physicalModelOrderControl"
						name="physicalModelOrderControl"
					>
                        {{ 'OrderInformation.PhysicalModelOrder' | translate }}
                    </mat-checkbox>
                    <div class="physical-model-info-container" *ngIf="!!physicalModelOrderControl.value">
                        <rx-incremental-selector
							id="auto-no-of-models-incremental-selector"
                            class="mb-4 mt-4"
                            [formControl]="numberOfModelsControl"
                            [label]="'OrderInformation.NoOfModels' | translate"
                            name="numberOfModelsControl"
                            ngDefaultControl
                        >
                        </rx-incremental-selector>
                        <rx-incremental-selector
							id="auto-additional-dies-incremental-selector"
                            class="mb-4"
                            [formControl]="additionalDiesControl"
                            [label]="'OrderInformation.AdditionalDies' | translate"
                            name="additionalDiesControl"
                            ngDefaultControl
                        >
                        </rx-incremental-selector>
                        <mat-checkbox
							id="auto-die-ditch-checkbox"
							[disabled]="false"
							[formControl]="dieDitchControl"
							name="dieDitchControl"
						>
                            {{ 'OrderInformation.DieDitch' | translate }}
                        </mat-checkbox>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
