import { Injectable } from "@angular/core";
import { PopupIconNames } from "@shared/models/enums/popup-icon.enum";
import { PopUpActions } from "@shared/models/enums/popup-modal-actions.enum";
import { IdName } from "@shared/models/id-name";
import { PopupService } from "@shared/services/popup.service";
import { isResorative } from "@shared/utils/restorative-check-util";
import { Observable } from "rxjs";

@Injectable()
export class OrderFormConfirmationService {
	constructor(
		private popupService: PopupService
	) { }

	shouldShowPopUpWhenCaseTypeChanged({currentCaseType, updatedCaseType}: {currentCaseType: IdName, updatedCaseType: IdName}): boolean {
		const isResto = isResorative({caseTypeId: currentCaseType?.Id}) || isResorative({caseTypeId: updatedCaseType?.Id})
		return this.hasValueAndChanged(currentCaseType?.Id, updatedCaseType?.Id) && isResto;
	}

	openPopUp$(isProcedureFlow = false): Observable<PopUpActions> {
		const popUpInput = {
			titleTranslationKey: 'Popup.Confirmation',
			contentTranslationKey: isProcedureFlow ? 'OrderSection.ProcedureChangeConfirmationMessage' : 'OrderSection.CaseTypeChangeConfirmationMessage',
			iconName: PopupIconNames.Information
		}
		return this.popupService.openConfirmationPopUp({ popUpInput });
	}

	private hasValueAndChanged(currentId: number, updatedId: number): boolean {
		return (currentId !== undefined) && (updatedId !== undefined) && (currentId !== updatedId);
	}
}