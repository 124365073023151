import { RestorationConfiguration } from '@shared/models/restoration-configuration-interface';
import { AbutmentTypeV1 } from '@shared/models/abutment-type.v1';
import { MaterialEnum } from '@core/procedure-helpers/models/material.enum';

export const restorationConfigurationRules: RestorationConfiguration = {
	DisplayedAbutmentTypeIds: [AbutmentTypeV1.StockAbutment, AbutmentTypeV1.CustomAbutment],
	AbutmentTypeMappings: [
		{
			TargetId: AbutmentTypeV1.StockAbutment,
			VisibleId: AbutmentTypeV1.StockAbutment,
			VisibleIsTiBase: false
		},
		{
			TargetId: AbutmentTypeV1.CustomAbutment,
			VisibleId: AbutmentTypeV1.CustomAbutment,
			VisibleIsTiBase: false
		},
		{
			TargetId: AbutmentTypeV1.StockAbutmentTiBased,
			VisibleId: AbutmentTypeV1.StockAbutment,
			VisibleIsTiBase: true
		},
		{
			TargetId: AbutmentTypeV1.CustomAbutmentTiBased,
			VisibleId: AbutmentTypeV1.CustomAbutment,
			VisibleIsTiBase: true
		}
	],
	AvailableMaterials: [
		{
			AbutmentTypeIds: [AbutmentTypeV1.StockAbutment, AbutmentTypeV1.StockAbutmentTiBased],
			MaterialIds: [MaterialEnum.CeramicZirconia, MaterialEnum.MetalTitanium]
		},
		{
			AbutmentTypeIds: [AbutmentTypeV1.CustomAbutment, AbutmentTypeV1.CustomAbutmentTiBased],
			MaterialIds: [MaterialEnum.CeramicZirconia, MaterialEnum.MetalTitanium, MaterialEnum.MetalCobaltChrome]
		}
	]
};
