<ng-container *ngIf="(patientSectionMode$ | async) as patientSectionVersion">
  <rx-patient-read-only
    [hidden]="patientSectionVersion !== patientSectionVersionEnum.ReadOnly"
    [patient]="patient$ | async"
    [regulatoryDOBMask]="regulatoryDOBMask$ | async"
    [dateFormat]="dateFormat$ | async"
    [isEditButtonVisible]="isEditButtonVisibleForReadOnly$ | async"
    [isClearButtonVisible]="isClearButtonVisibleForReadOnly$ | async"
    [maxPatientLastNameLength]="maxPatientLastNameLength$ | async"
    [isButtonsVisible]="!(isRxFromReferralDoctorAndUserIsScannigCenter$ | async)"
    (patientCleared)="clearPatient()"
    (patientEdited)="editPatient()"
  >
  </rx-patient-read-only>
  <rx-patient-app-inline
    [hidden]="patientSectionVersion !== patientSectionVersionEnum.InlinePatientApp"
    [url$]="patientAppInlineUrl$"
    [isPatientInConflict]="isPatientInConflict$ | async"
    [isResizePatientAppDialog]="isResizePatientAppDialog$ | async"
  >
  </rx-patient-app-inline>
</ng-container> 