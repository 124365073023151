<div [class.partially-read-only]="isReadOnly$ | async">
  <rx-unit-type *ngIf="(isReadOnly$ | async) === false" [toothClicked]="unitTypeMenuInput$ | async" (unitTypeSelected)="handleUnitTypeSelection($event)"
    (unitTypeInBridgeSelected)="handleUnitTypeInBridgeSelection($event)"></rx-unit-type>
  <div class="tooth-editor">
    <rx-tooth-editor-header [isReadOnly]="isReadOnly$ | async" [isBridge]="isBridge$ | async" [unitTypeName]="unitTypeName$ | async"
      (modalClosed)="handleModalClose($event)">
    </rx-tooth-editor-header>
    <rx-banner *ngIf="isReadOnlyBannerVisible() | async" [type]="'info'" [message]="'ReadOnlyMode.ReadOnlyMessage' | translate"></rx-banner>
    <rx-mini-teeth-diagram [isReadOnly]="isReadOnly$ | async" [teeth]="teeth$ | async" [toothClickedOn]="toothClickedOn$ | async"
      [teethNumberingSystem]="teethNumberingSystem$ | async" (toothClicked)="handleToothClick($event)" [isGlidewellOrder]="isGlidewellOrder">
    </rx-mini-teeth-diagram>
  </div>
  <tlk-modal-content class="tooth-editor-body">
    <rx-crown-section *ngIf="!((isImplantBased$ | async) || (isImplantPosition$ | async))"
      [toothClickedOn]="toothClickedOn$ | async" [isReadOnly]="isReadOnly$ | async"></rx-crown-section>
    <rx-implant-position *ngIf="isImplantPosition$ | async"
      [toothClickedOn]="toothClickedOn$ | async" [isReadOnly]="isReadOnly$ | async"></rx-implant-position>

    <div *ngIf="isImplantBased$ | async" id="tooth-editor-body-container">
      <ng-container *ngIf="procedureMap.TypeId !== TypeEnum.Full_Denture_implant_based; else fullDentureImplantBased">
        <rx-banner [type]="'notification'" *ngIf="(isReadOnly$ | async) === false" [message]="'ToothEditor.Implant Abutment Disclaimer' | translate"></rx-banner>

        <rx-manufacture-section [toothClickedOn]="toothClickedOn$ | async"
              class="manufacture-section"
              [isReadOnly]="isReadOnly$ | async">
        </rx-manufacture-section>
        <mat-accordion [multi]="true">

          <mat-expansion-panel *ngIf="(isImplantBased$ | async)"
            togglePosition="before"
            id="restoration-type-expansion-panel"
          >
            <mat-expansion-panel-header class="rx-expansion-header">
              <mat-panel-title id="restoration-type-panel-title">
                {{ 'ToothEditor.RestorationType' | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <rx-restoration-type-section></rx-restoration-type-section>
          </mat-expansion-panel>

          <mat-expansion-panel id="crown-expansion-panel" togglePosition="before" >
            <mat-expansion-panel-header class="rx-expansion-header">
              <mat-panel-title id="crown-expansion-panel-title">
                {{ 'ToothEditor.Crown' | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <rx-crown-section [toothClickedOn]="toothClickedOn$ | async"  [isReadOnly]="isReadOnly$ | async"></rx-crown-section>
          </mat-expansion-panel>
        </mat-accordion>
      </ng-container>

      <ng-template #fullDentureImplantBased>
        <rx-manufacture-section [toothClickedOn]="toothClickedOn$ | async"
              class="manufacture-section"
              [isReadOnly]="isReadOnly$ | async">
            </rx-manufacture-section>
      </ng-template>
    </div>
  </tlk-modal-content>
  <rx-tooth-editor-footer *ngIf="(isReadOnly$ | async) === false" (modalClosed)="handleModalClose($event)"></rx-tooth-editor-footer>
</div>