<tlk-modal-footer class="navigation-action">
	<button
			tlkButton
			variant="danger"
			size="s"
			id="delete-button"
			(click)="handleDelete()"
			right-buttons>
		{{ 'ToothEditor.Delete' | translate }}
	</button>
</tlk-modal-footer>
