import { Component, Input } from '@angular/core';
import { RxModel } from '@shared/models/rx-models/interfaces/rx-model';
import { Observable } from 'rxjs';
import { GeneralRxDataV1Facade } from '@modules/general-rx-data/general-rx-data-v1.facade';

@Component({
	selector: 'rx-general-print-v1',
	templateUrl: './general-rx-print-v1.component.html',
	providers: [GeneralRxDataV1Facade]
})
export class GeneralRxPrintV1Component {
	@Input() rxForPrint: RxModel;

	dateFormat$: Observable<string> = this.facade.dateFormat$;
	rxConfiguration$ = this.facade.rxConfiguration$;

	constructor(private facade: GeneralRxDataV1Facade) {}
}
