import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgModule } from '@angular/core';
import { RxDatepicker, RxDatepickerContent } from '@shared/components/rx-datepicker/rx-datepicker.component';
import { PortalModule } from '@angular/cdk/portal';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import {A11yModule} from "@angular/cdk/a11y";

@NgModule({
    imports: [CommonModule, MatDatepickerModule, PortalModule, MatLegacyButtonModule, A11yModule, RxDatepicker, RxDatepickerContent],
    exports: [RxDatepicker, RxDatepickerContent]
})
export class RxDatepickerModule {}
