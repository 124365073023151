<form
  [formGroup]="crownSectionForm"
  class="crown-section"
  fxLayout="column"
  [class.read-only]="isReadOnly || (isCrownSectionDisabled$ | async)"
>
  <div
    fxFlex
    fxLayout="row"
    fxLayoutAlign="center"
    fxLayout.sm="column stretch"
    fxLayout.xs="column stretch"
  >
    <div
      class="left-content"
      fxFlex="1 1 60%"
      fxLayout="row wrap"
      fxLayoutAlign="space-between stretch"
    >
        <rx-object-selector
            *ngIf="specifications?.length"
            fxFlex="100"
            [id]="'specification'"
            [label]="'ToothEditor.Specification' | translate"
            [isRequired]="shouldValidateForSend$ | async"
            [isCancellable]="(shouldValidateForSend$ | async) === false"
            [isDisabled]="isCrownSectionDisabled$ | async"
            [isReadOnly]="isReadOnly"
            [formControl]="specificationControl"
            [options]="specifications"
            (cancelSelection)="handleCancelSpecification($event)"
            name="specificationControl"
        ></rx-object-selector>
        <rx-favorite-selector
            fxFlex="100"
            [id]="'material'"
            [label]="'ToothEditor.Material' | translate"
            [isRequired]="shouldValidateForSend$ | async"
            [isCancellable]="(shouldValidateForSend$ | async) === false"
            [formControl]="materialControl"
            [isReadOnly]="isReadOnly"
            [isDisabled]="(isCrownSectionDisabled$ | async) || (specifications?.length && !specificationControl.value) ||
            !this.materialOptions?.length"
            [options]="materialOptions"
            [favoriteName]="favoriteMaterial"
            name="materialControl"
			[enableSimpleMode]="isNotDoctor$ | async"
            (cancelSelection)="handleCancelMaterial($event)"
        ></rx-favorite-selector>
        <rx-pontic-selector
            fxFlex="48"
            *ngIf="isPonticIndicationSelected$ | async"
            [formControl]="ponticDesignControl"
            [isReadonly]="isReadOnly"
            [rxIsDisabled]="isCrownSectionDisabled$ | async"
            [options]="ponticDesigns"
            name="ponticDesignControl"
        ></rx-pontic-selector>
    </div>
    <div
      fxFlex="1 1 40%"
      fxLayout="column start"
      fxLayout.sm="column"
      fxLayout.xs="column"
    >
      <rx-object-selector
        [id]="'shade-system'"
        [label]="'ToothEditor.ShadeSystem' | translate"
        [isDisabled]="isShadeDisabled"
        [isCancellable]="false"
        [isReadOnly]="isReadOnly"
        [formControl]="shadeSystemControl"
        [options]="shadeSystemOptions"
        name="shadeSystemControl"
      ></rx-object-selector>

      <rx-object-selector
        *ngIf="!isOtherShadeSystemSelected"
          [id]="'body'"
          [label]="'ToothEditor.Body' | translate"
          [isRequired]="(shouldValidateForSend$ | async) && materialOption?.BodyShadeMandatory"
          [isCancellable]="(shouldValidateForSend$ | async) === false || !materialOption?.BodyShadeMandatory"
          [isDisabled]="isShadeDisabled"
          [isReadOnly]="isReadOnly"
          [formControl]="bodyControlAsIdName"
          [options]="incisalBodyGingivalOptions"
          (cancelSelection)="handleCancelSelection($event)"
          name="bodyControl"
      ></rx-object-selector>
      <div *ngIf="isOtherShadeSystemSelected">
          <rx-text-input
              [label]="'ToothEditor.Body' | translate"
              [maxlength]="maxInputLengthForOtherShadeSystem"
              [formControl]="bodyControlAsAString"
              [isDisabled]="isShadeDisabled || isReadOnly"
              [isRequired]="materialOption?.BodyShadeMandatory && (shouldValidateForSend$ | async)"
              [name]="'bodyControl'"
          >

          </rx-text-input>
<!--          <mat-form-field fxFlex="1 1 100%">
              <mat-label>{{ 'ToothEditor.Body' | translate }}</mat-label>
              <input
                  matInput
                  [maxlength]="maxInputLengthForOtherShadeSystem"
                  [formControl]="bodyControl"
                  [rxIsDisabled]="isShadeDisabled"
                  [readonly]="isReadOnly"
                  [required]="materialOption?.BodyShadeMandatory && (shouldValidateForSend$ | async)"
                  autocomplete="off"
                  name="bodyControl"
              />
          </mat-form-field>-->
      </div>
    </div>
</div>
<mat-expansion-panel id="additional-info-expansion-panel"
  [expanded]="expandAdditionalInfo"
  togglePosition="before"
  class="additional-info-expansion-panel">
  <mat-expansion-panel-header class="rx-expansion-header">
    <mat-panel-title id="additional-info-expansion-panel-title">
      {{ 'ToothEditor.AdditionalInfo' | translate }}
    </mat-panel-title>
  </mat-expansion-panel-header>

  <div
    class="left-content"
    fxFlex="1 1 60%"
    fxLayout="row wrap"
    fxLayoutAlign="space-between stretch"
  >
    <rx-object-selector
        fxFlex="45"
      [id]="'preparation-design-buccal'"
      [label]="'ToothEditor.PreparationDesign' | translate"
      [secondaryLabel]="'ToothEditor.Buccal' | translate"
      [isRequired]="(shouldValidateForSend$ | async) && materialOption?.PrepDesignMandatory"
      [isCancellable]="(shouldValidateForSend$ | async) === false || !materialOption?.PrepDesignMandatory"
      [formControl]="preparationDesignBuccalControl"
      [isDisabled]="!materialOption?.PrepDesignVisible"
      [isReadOnly]="isReadOnly"
      [options]="preparationDesignOptions"
      (cancelSelection)="handleCancelSelection($event)"
      name="preparationDesignBuccalControl"
    ></rx-object-selector>
    <rx-object-selector
        fxFlex="45"
      [id]="'preparation-design-lingual'"
      [label]="'ToothEditor.PreparationDesign' | translate"
      [secondaryLabel]="'ToothEditor.Lingual' | translate"
      [isRequired]="(shouldValidateForSend$ | async) && materialOption?.PrepDesignMandatory"
      [isCancellable]="(shouldValidateForSend$ | async) === false || !materialOption?.PrepDesignMandatory"
      [formControl]="preparationDesignLingualControl"
      [isDisabled]="!materialOption?.PrepDesignVisible"
      [isReadOnly]="isReadOnly"
      [options]="preparationDesignOptions"
      (cancelSelection)="handleCancelSelection($event)"
      name="preparationDesignLingualControl"
    ></rx-object-selector>
    <rx-object-selector
        fxFlex="45"
      [id]="'margin-design-buccal'"
      [label]="'ToothEditor.MarginDesign' | translate"
      [secondaryLabel]="'ToothEditor.Buccal' | translate"
      [isRequired]="(shouldValidateForSend$ | async) && materialOption?.MarginDesignMandatory"
      [isCancellable]="(shouldValidateForSend$ | async) === false || !materialOption?.MarginDesignMandatory"
      [formControl]="marginDesignBuccalControl"
      [isDisabled]="!materialOption?.MarginDesignVisible"
      [isReadOnly]="isReadOnly"
      [options]="marginDesignOptions"
      (cancelSelection)="handleCancelSelection($event)"
      name="marginDesignBuccalControl"
    ></rx-object-selector>
    <rx-object-selector
        fxFlex="45"
      [id]="'margin-design-lingual'"
      [label]="'ToothEditor.MarginDesign' | translate"
      [secondaryLabel]="'ToothEditor.Lingual' | translate"
      [isRequired]="(shouldValidateForSend$ | async) && materialOption?.MarginDesignMandatory"
      [isCancellable]="(shouldValidateForSend$ | async) === false || !materialOption?.MarginDesignMandatory"
      [formControl]="marginDesignLingualControl"
      [isDisabled]="!materialOption?.MarginDesignVisible"
      [isReadOnly]="isReadOnly"
      [options]="marginDesignOptions"
      (cancelSelection)="handleCancelSelection($event)"
      name="marginDesignLingualControl"
    ></rx-object-selector>
  </div>
  <div
      fxFlex="1 1 40%"
      fxLayout="column start"
      fxLayout.sm="column"
      fxLayout.xs="column"
    >
      <ng-container *ngIf="!isOtherShadeSystemSelected">
        <rx-object-selector
            [id]="'incisal'"
            [label]="'ToothEditor.Incisal' | translate"
            [isDisabled]="isShadeDisabled"
            [isReadOnly]="isReadOnly"
            [isCancellable]="true"
            [formControl]="incisalControlAsIdName"
            [options]="incisalBodyGingivalOptions"
            (cancelSelection)="handleCancelSelection($event)"
            name="incisalControl"
        ></rx-object-selector>
        <rx-object-selector
            [id]="'gingival'"
            [label]="'ToothEditor.Gingival' | translate"
            [isDisabled]="isShadeDisabled"
            [isReadOnly]="isReadOnly"
            [isCancellable]="true"
            [formControl]="gingivalControlAsIdName"
            [options]="incisalBodyGingivalOptions"
            (cancelSelection)="handleCancelSelection($event)"
            name="gingivalControl"
        ></rx-object-selector>
      </ng-container>
      <ng-container *ngIf="isOtherShadeSystemSelected">
        <div>
            <mat-form-field fxFlex="1 1 100%">
                <mat-label>{{ 'ToothEditor.Incisal' | translate }}</mat-label>
                <input
                    matInput
                    [maxlength]="maxInputLengthForOtherShadeSystem"
                    [formControl]="incisalControl"
                    [rxIsDisabled]="isShadeDisabled"
                    [readonly]="isReadOnly"
                    autocomplete="off"
                    name="incisalControl"
                />
            </mat-form-field>
        </div>
        <div>
            <mat-form-field fxFlex="1 1 100%">
                <mat-label>{{ 'ToothEditor.Gingival' | translate }}</mat-label>
                <input
                    matInput
                    [maxlength]="maxInputLengthForOtherShadeSystem"
                    [formControl]="gingivalControl"
                    [rxIsDisabled]="isShadeDisabled"
                    [readonly]="isReadOnly"
                    autocomplete="off"
                    name="gingivalControl"
                />
            </mat-form-field>
        </div>
      </ng-container>
      <rx-object-selector
        [id]="'stump-shade'"
        [label]="'ToothEditor.StumpShade' | translate"
        [isRequired]="(shouldValidateForSend$ | async) && materialOption?.StumpShadeMandatory"
        [isCancellable]="(shouldValidateForSend$ | async) === false || !materialOption?.StumpShadeMandatory"
        [isDisabled]="!materialOption?.StumpShadeVisible"
        [isReadOnly]="isReadOnly"
        [formControl]="stumpShadeControl"
        [options]="stumpShadeOptions"
        (cancelSelection)="handleCancelSelection($event)"
        name="stumpShadeControl"
    ></rx-object-selector>
  </div>
</mat-expansion-panel>

</form>
