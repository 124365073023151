import { Injectable } from '@angular/core';
import { ApiService, IApiCall } from '@core/services/api.service';
import { Observable } from 'rxjs';
import { TracesStore } from '@shared/store/traces/traces-store';
import { map, tap } from 'rxjs/operators';
import { HttpUtils } from '@shared/utils/http-utils';

@Injectable({
	providedIn: 'root'
})
export class OrderApiService {
	constructor(private apiService: ApiService, private tracesStore: TracesStore) {}

	getAvailableCaseTypeIds({ contactId, companyId }: { contactId: number; companyId: number }): Observable<number[]> {
		const apiCallConfig: IApiCall = {
			selector: 'getAvailableCaseTypes',
			pathParams: { contactId },
			queryParams: { companyId },
			options: { observe: 'response' }
		};

		return this.apiService.request(apiCallConfig).pipe(
			tap(response => this.tracesStore.update({ availableCaseTypesTraceId: HttpUtils.getTraceId(response) })),
			map(response => response.body)
		);
	}

	getAvailableProcedureMapIds(contactId: number, companyId: number): Observable<number[]> {
		const apiCallConfig: IApiCall = {
			selector: 'getAvailableProcedureMaps',
			pathParams: { contactId },
			queryParams: { companyId },
			options: { observe: 'response' }
		};

		return this.apiService.request(apiCallConfig).pipe(
			tap(response => this.tracesStore.update({ availableProcedureMapIdsTraceId: HttpUtils.getTraceId(response) })),
			map(response => response.body)
		);
	}
}
