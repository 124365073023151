<mat-form-field fxFlex>
	<mat-label>{{ "OrderSection.CaseType" | translate }}:</mat-label>
	<mat-select
		[value]="control.value"
		[formControl]="control"
		[required]="isRequired"
		[compareWith]="compareOptions"
		name="caseType" ngDefaultControl
	>
		<mat-option
			*ngFor="let caseType of options"
			[value]="caseType"
			[id]="generateOptionId(caseType)"
		>
			{{caseType.Name}}
		</mat-option>
	</mat-select>
	<mat-error *ngIf="isRequired && control.enabled">{{ 'Validation.RequiredField' | translate }}</mat-error>
</mat-form-field>