export interface DefaultCrownState {
	crownDataMap: Record<number, DefaultCrownData>;
}

export interface DefaultCrownData {
	SpecificationId?: number;
	MaterialID?: number;
	Body?: Record<number, string>;
	otherShadeSystemToothId?: number;
}

export enum CrownDefaultProperties {
	SpecificationId = 'SpecificationId',
	MaterialId = 'MaterialID'
}
