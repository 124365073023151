import { Injectable } from "@angular/core";
import { IdName } from "@shared/models/id-name";
import { CaseTypeEnum } from "../models/case-type.enum";
import { TreatmentStageEnum } from "../models/treatment-stage.enum";
import { OrderQuery } from "../state/order-query";
import { OrderStore } from "../state/order-store";
import { CaseTypeFormStateService } from "./case-type-form-state.service";
import { applyTransaction } from "@datorama/akita";
import { RxForDoctorQuery } from "@modules/rx-for-doctor/state/rx-for-doctor-query";

@Injectable()
export class CaseTypeChangedService {

	constructor(
		private store: OrderStore,
		private query: OrderQuery,
		private orderFormControlsStatusService: CaseTypeFormStateService,
		private rxForDoctorsQuery: RxForDoctorQuery
	) { }

	resetControls(
		{ caseType, dentalLabs, orthoLabs, dueDate }: { caseType: IdName, dentalLabs: IdName[],
			orthoLabs: IdName[], dueDate: Date }
	): void {
		applyTransaction(() => {
			this.clearDisabledOrHiddenControls({caseType});
			this.resetDueDate({caseType, dueDate});
			this.updateTreatmentStage({caseType});
			this.updateAvailableLabs({caseType, dentalLabs, orthoLabs});
		});
	}

	private clearDisabledOrHiddenControls({ caseType }: { caseType: IdName }): void {
		if (!this.orderFormControlsStatusService.isSendToEnabled({ caseType })) {
			this.store.update({ sendTo: null });
		}

		if (!this.orderFormControlsStatusService.isMultiBiteVisible({ caseType })) {
			this.store.update({ isMultiBiteSelected: false });
		}

		if (!this.orderFormControlsStatusService.isTreatmentStageVisible({ caseType })) {
			this.store.update({ treatmentStage: null });
		}

		if (!this.rxForDoctorsQuery.isReferralWorkflowPractice && !this.orderFormControlsStatusService.isDueDateEnabled({ caseType })) {
			this.store.update({ dueDate: null });
		}

		if (!this.orderFormControlsStatusService.isCurrentAlignerIdVisible({ caseType })) {
			this.store.update({ currentAlignerId: null });
		}
	}

	private updateAvailableLabs(
		{ caseType, dentalLabs, orthoLabs }: { caseType: IdName, dentalLabs: IdName[], orthoLabs: IdName[] }
	): void {
		if ([CaseTypeEnum.Restorative].includes(caseType?.Id)) {
			this.store.update({ availableLabs: dentalLabs });
			return;
		}
		if ([CaseTypeEnum.iRecord].includes(caseType?.Id)) {
			this.store.update({ availableLabs: orthoLabs });
			return;
		}
		this.store.update({ availableLabs: null });
	}

	private resetDueDate({ caseType, dueDate }: { caseType: IdName, dueDate: Date }): void {
		if (!dueDate && this.orderFormControlsStatusService.isDueDateEnabled({ caseType })) {
			this.store.update({ dueDate: this.getDefaultDueDate() });
		}
	}

	private updateTreatmentStage({ caseType }: { caseType: IdName }): void {
		if ([CaseTypeEnum.Vivera, CaseTypeEnum.ViveraPreDebond].includes(caseType?.Id)) {
			const finalRecord = this.query.treatmentStages.find(stage => stage.Id === TreatmentStageEnum.FinalRecord);
			this.store.update({ treatmentStage: finalRecord });
		}
	}

	private getDefaultDueDate(): Date {
		const inTwoWeeks = new Date();
		inTwoWeeks.setDate(new Date().getDate() + 14);
		inTwoWeeks.setHours(0, 0, 0, 0);
		return inTwoWeeks;
	}
}
