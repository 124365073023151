import { Component, Input } from '@angular/core';
import { Tooth } from '@modules/teeth-diagram/models/tooth';

@Component({
    selector: 'rx-bridge-indication',
    templateUrl: './bridge-indication.component.html',
    styleUrls: ['./bridge-indication.component.scss']
})
export class BridgeIndicationComponent {
    @Input() bridgeEdges: {
		[bridgeIndex: number]: {
			firstToothOnLeftToothId: number;
			lastToothOnRightToothId: number;
		}
	};
    @Input() teethInJaw: Tooth[];
    @Input() jaw: 'upper' | 'lower';
    @Input() isPrintContext: boolean;
}
