<div class="rectangle-container">
    <div fxLayout="column">
        <div fxLayout="row" fxFlex class="rx-card-title-print">
            <div class="general-info">
                <strong>{{ 'DoctorSection.Doctor' | translate }}:</strong>
                {{ doctorName || '--' }}
            </div>
        </div>
        <div fxLayout="row" class="general-info-body">
            <div class="column-primary">
                <rx-info-block
                    fxLayout="column"
                    class="info-box"
                    fxFlex
                    [fields]="[
                        { name: 'PrintRx.Practice', value: practice, isInline: true },
                        { name: 'PrintRx.Ship-to-Address', value: shipToAddress, isInline: true }
                    ]"
                ></rx-info-block>
            </div>
            <hr class="separator" />
            <div fxLayout="column" fxFlex class="info-box">
                <rx-info-block
                    [fields]="[
                        { name: 'GeneralRxData.CaseType', value: caseType$ | async, isInline: true },
                        { name: 'GeneralRxData.ScanDate', value: scanDate | date: (dateFormat$ | async), isInline: true },
                        { name: 'GeneralRxData.DueDate', value: dueDate | date: (dateFormat$ | async):'UTC', isInline: true }
                    ]"
                ></rx-info-block>
            </div>
            <hr class="separator" />
            <div fxLayout="column" fxFlex>
                <rx-info-block
                    fxLayout="column"
                    class="info-box info-box-signature"
                    fxFlex
                    [fields]="[
                        {
                            name: 'GeneralRxData.Signature',
                            value: signature,
                            isSignature: true
                        },
                        { name: 'GeneralRxData.License', value: license, isInline: true }
                    ]"
                ></rx-info-block>
            </div>
        </div>
    </div>
</div>
