<ng-container *ngIf="showEarlyAccessSWO$ | async as showEarlyAccess">
	<rx-awareness-banner
		[showBannerButton]="false"
		[qrName]="'Early_Access'"
		*ngIf="showEarlyAccess.enableInformAccessBannerInRXAndMiniApp; else designSuiteBeforeRegisterBanner"
	>
		<div class="rx-designsuite-banner-container">
			<span class="banner-title" [innerHtml]="'GetAccessBanner.GetAccessBanner' | translate"></span>
			<div class="banner-subtitle-container" *ngIf="hostPlatform === platforms.MIDC">
				<a
					cdkFocusInitial
					target="_blank"
					rel="noopener noreferrer"
					class="banner-subtitle"
					[href]="linkToTheLearnNow$ | async"
					(click)="onRegisterOrLearnNowClick(null, 'GetAccessBanner', 'GetAccessBanner', 'banner-LearnMore', 'LearnMore')"
				>
					{{ 'GetAccessBanner.LearnMore' | translate }}
				</a>
			</div>
		</div>
	</rx-awareness-banner>
	<ng-template #designSuiteBeforeRegisterBanner>
		<ng-container *ngIf="showEarlyAccess.enableBannerMiniAppsInRX">
			<rx-awareness-banner [showBannerButton]="false" *ngIf="!showEarlyAccess.isRegistered; else designSuiteAfterRegisterBanner">
				<div class="rx-designsuite-banner-container">
					<span class="banner-title" [innerHtml]="'BeforeRegistrationAwarenessBanner.iTeroDesignSuite' | translate"></span>
					<div class="banner-subtitle-container">
						<span class="banner-subtitle">
							{{ 'BeforeRegistrationAwarenessBanner.3Dprinter' | translate }}
						</span>
						<a
							*ngIf="companyId$ | async as companyId"
							cdkFocusInitial
							id="registerNow"
							(click)="
								onRegisterOrLearnNowClick(
									companyId,
									'BeforeRegistrationAwarenessBanner',
									'iTeroDesignSuite',
									'banner-Register'
								)
							"
							target="_blank"
							rel="noopener noreferrer"
							class="banner-subtitle subtitle-link"
							[innerHtml]="'BeforeRegistrationAwarenessBanner.RegisterNow' | translate"
						>
						</a>
					</div>
				</div>
			</rx-awareness-banner>
			<ng-template #designSuiteAfterRegisterBanner>
				<rx-awareness-banner [showBannerButton]="false">
					<div class="rx-designsuite-banner-container">
						<span
							class="banner-title"
							[innerHtml]="'AfterRegistrationAwarenessBanner.AfterRegistrationAwarenessBanner' | translate"
						></span>
					</div>
				</rx-awareness-banner>
			</ng-template>
		</ng-container>
	</ng-template>
</ng-container>
