import { Injectable } from '@angular/core';
import { combineLatest, Observable, of, switchMap } from 'rxjs';
import { ScanOptionsQuery } from './state/scan-options-query';
import { initializeAdditionalBites, ScanOptionsStore } from './state/scan-options-store';
import { LoggerService } from '@core/services/logger/logger.service';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { map, tap } from 'rxjs/operators';
import { RxModel } from '@shared/models/rx-models/interfaces/rx-model';
import { OrderStore } from '@modules/order/state/order-store';
import { OrderQuery } from '@modules/order/state/order-query';
import { ProcedureMap } from '@shared/models/procedure-map';
import { DentureDetailsStore } from '@modules/denture-details/state/denture-details.store';
import { DentureDetailsQuery } from '@modules/denture-details/state/denture-details.query';
import { combineQueries } from '@datorama/akita';
import { ProcedureEnum } from '@core/procedure-helpers/models/procedure.enum';
import { TeethDiagramQuery } from '@modules/teeth-diagram/state/teeth-diagram-query';
import { FeatureToggleSettings, SoftwareOptions } from '@shared/models/enums/enums';
import { SoftwareOptionsService } from '@shared/services/software-options.service';
import { HostPlatformService } from '@shared/services/host-platform.service';
import { VersionsService } from '@shared/services/versions.service';
import { LimitedFeatures } from '@shared/models/limited-features';
import { AddRxService } from '@shared/services/add-rx.service';
import { Bites } from '@modules/teeth-diagram/models/bites.enum';

@Injectable()
export class ScanOptionsFacade {
	isReadOnly$: Observable<boolean> = this.shellQuery.getReadOnlyState('scanOptions');
	isRxTakenForScan$ = this.shellQuery.isRxTakenForScan$;

	isNiriCaptureVisible$: Observable<boolean> = this.shellQuery.isNiriCaptureVisible$;
	isNiriCaptureChecked$: Observable<boolean> = this.query.isNiriCaptureChecked$;
	isRxForModelingContext$: Observable<boolean> = this.hostPlatformService.isIteroModeling$;
	isNiriCaptureDisabled$: Observable<boolean> = combineLatest([
		this.isReadOnly$,
		this.shellQuery.isRxTakenForScan$,
		this.addRxService.isNiriReadOnly$
	]).pipe(
		map(([isReadOnly, isRxTakenForScan, isNiriReadOnlyAddRx]) => {
			return isReadOnly || isRxTakenForScan || isNiriReadOnlyAddRx;
		})
	);

	isSleeveConfirmationVisible$: Observable<boolean> = this.shellQuery.isSleeveConfirmationVisible$;
	isSleeveChecked$: Observable<boolean> = this.query.isSleeveChecked$.pipe(map(x => x.value));
	isSleeveConfirmationReadOnly$: Observable<boolean> = this.shellQuery.isSleeveConfirmationReadOnly$;

	isEmergenceProfileChecked$: Observable<boolean> = this.query.isEmergenceProfileChecked$;
	isEmergencyProfileVisible$: Observable<boolean> = combineLatest([
		this.hostPlatformService.isScanner$,
		this.orderQuery.procedureMap$,
		this.softwareOptionsService.hasCompanySoftwareOption$(SoftwareOptions.EmergenceProfileScan),
		this.shellQuery.isRxSent$,
		this.isEmergenceProfileChecked$
	]).pipe(
		switchMap(([isScanner, procedureMap, hasCompanySwo, isRxSent, isEmergenceProfileChecked]) => {
			if (isRxSent && (procedureMap?.ProcedureId as ProcedureEnum) === ProcedureEnum.FixedRestorative) {
				return of(isEmergenceProfileChecked);
			}

			if ((procedureMap?.ProcedureId as ProcedureEnum) !== ProcedureEnum.FixedRestorative || !hasCompanySwo) {
				return of(false);
			}

			return isScanner ? this.isEmergencyProfileAvailableForScanner$ : this.isEmergencyProfileAvailableForAllScanners$;
		})
	);

	isEmergenceProfileReadOnly$: Observable<boolean> = combineLatest([this.shellQuery.isRxSent$, this.shellQuery.isRxScanned$]).pipe(
		map(([isRxSent, isRxScanned]) => {
			return isRxSent || isRxScanned;
		})
	);

	isPreTreatmentVisible$: Observable<boolean> = combineQueries([
		this.shellQuery.isPreTreatmentVisibleForV0$,
		this.orderQuery.procedureMap$
	]).pipe(map(([isPreTreatmentVisibleForV0, procedureMap]) => isPreTreatmentVisibleForV0 || !!procedureMap?.PreTreatmentScanIsEnabled));
	isPreTreatmentChecked$: Observable<boolean> = this.query.isPreTreatmentChecked$;
	isPreTreatmentReadOnly$: Observable<boolean> = combineLatest([
		this.shellQuery.isPreTreatmentReadOnly$,
		this.isEmergenceProfileChecked$,
		this.addRxService.isPreTreatmentReadOnly$
	]).pipe(
		map(([isPreTreatmentReadOnly, isEmergencyProfileOn, isPreTreatmentReadOnlyAddRx]) => {
			return isPreTreatmentReadOnly || isEmergencyProfileOn || isPreTreatmentReadOnlyAddRx;
		})
	);

	private isRestoMultiBiteFF$: Observable<boolean> = this.shellQuery.featureToggles$.pipe(
		map(featureToggles => featureToggles?.find(f => f.id === String(FeatureToggleSettings.RestorativeMultiBite))?.isActive)
	);

	isRestoMultiBiteSelected$: Observable<boolean> = this.query.isRestoMultiBiteSelected$;
	isRestoMultiBiteVisible$: Observable<boolean> = combineLatest([
		this.hostPlatformService.isScanner$,
		this.shellQuery.isRxSent$,
		this.isRestoMultiBiteFF$,
		this.softwareOptionsService.hasCompanySoftwareOption$(SoftwareOptions.RestorativeMultiBite),
		this.orderQuery.procedureMap$,
		this.isRestoMultiBiteSelected$
	]).pipe(
		switchMap(
			([isScanner, isRxSent, hasFf, hasSwo, procedureMap, isRestoMultiBiteSelected]: [
				boolean,
				boolean,
				boolean,
				boolean,
				ProcedureMap,
				boolean
			]) => {
				if (isRxSent) {
					return of(isRestoMultiBiteSelected);
				}

				if (
					(procedureMap?.ProcedureId as ProcedureEnum) !== ProcedureEnum.FixedRestorative ||
					(!hasFf && !hasSwo) ||
					this.orderQuery.isGlidewellOrder
				) {
					return of(false);
				}

				return isScanner ? this.isRestoMultiBiteAvailableForScanner$ : this.isRestoMultiBiteAvailableForAllScanners$;
			}
		)
	);

	isMultiBiteSelected$: Observable<boolean> = this.orderQuery.isMultiBiteSelected$;
	additionalBites$: Observable<Record<string, boolean>> = this.query.additionalBites$;
	procedureMap$: Observable<ProcedureMap> = this.orderQuery.procedureMap$;

	isDentureCopyScan$: Observable<boolean> = this.dentureDetailsQuery.isDentureCopyScan$;
	isDentureCopyScanVisible$: Observable<boolean> = this.orderQuery.procedureMap$.pipe(
		map(procedureMap => procedureMap?.DentureCopyScanIsEnabled)
	);

	isEmergencyProfileDisabled$: Observable<boolean> = combineLatest([
		this.isPreTreatmentChecked$,
		this.teethDiagramQuery.hasImplantBasedPrep$,
		this.isEmergenceProfileReadOnly$,
		this.addRxService.isEmergencyProfileReadOnly$
	]).pipe(
		map(([isPreTreatmentChecked, hasImplantBasedPrep, isEmergenceProfileReadOnly, isEmergencyProfileReadOnlyAddRx]) => {
			return !hasImplantBasedPrep || isPreTreatmentChecked || isEmergenceProfileReadOnly || isEmergencyProfileReadOnlyAddRx;
		})
	);

	shouldShowMultiBiteInformationMessage$: Observable<boolean> = combineQueries([
		this.orderQuery.procedureMap$,
		this.isMultiBiteSelected$
	]).pipe(
		map(
			([procedureMap, isMultiBiteSelected]) =>
				(procedureMap?.ProcedureId as ProcedureEnum) === ProcedureEnum.StudyModel_iRecord && isMultiBiteSelected
		)
	);

	private isEmergencyProfileAvailableForScanner$: Observable<boolean> = combineLatest([
		this.shellQuery.clientVersion$,
		this.shellQuery.packageVersion$
	]).pipe(
		map(([clientVersion, packageVersion]) => {
			return this.versionService.isFeatureAvailableForScanner(LimitedFeatures.EmergenceProfile, clientVersion, packageVersion);
		})
	);
	private isEmergencyProfileAvailableForAllScanners$: Observable<boolean> = this.shellQuery.scannersVersions$.pipe(
		map(scannersVersions => {
			const versions = scannersVersions.map(s => s.VersionNumber);

			return this.versionService.isFeatureAvailableForAllScanners(LimitedFeatures.EmergenceProfile, versions);
		})
	);

	private isRestoMultiBiteAvailableForScanner$: Observable<boolean> = combineLatest([
		this.shellQuery.clientVersion$,
		this.shellQuery.packageVersion$
	]).pipe(
		map(([clientVersion, packageVersion]) => {
			return this.versionService.isFeatureAvailableForScanner(LimitedFeatures.RestorativeMultiBite, clientVersion, packageVersion);
		})
	);
	private isRestoMultiBiteAvailableForAllScanners$: Observable<boolean> = this.shellQuery.scannersVersions$.pipe(
		map(scannersVersions => {
			const versions = scannersVersions.map(s => s.VersionNumber);

			return this.versionService.isFeatureAvailableForAllScanners(LimitedFeatures.RestorativeMultiBite, versions);
		})
	);

	constructor(
		private store: ScanOptionsStore,
		private query: ScanOptionsQuery,
		private shellQuery: ShellQuery,
		private logger: LoggerService,
		private orderStore: OrderStore,
		private orderQuery: OrderQuery,
		private dentureDetailsStore: DentureDetailsStore,
		private dentureDetailsQuery: DentureDetailsQuery,
		private teethDiagramQuery: TeethDiagramQuery,
		private softwareOptionsService: SoftwareOptionsService,
		private hostPlatformService: HostPlatformService,
		private versionService: VersionsService,
		private addRxService: AddRxService
	) {}

	updateSleeveConfirmation(value: boolean) {
		this.logger.info(`updateSleeveConfirmation: with-> ${value} `, { module: 'ScanOptionsFacade' });
		this.store.update({ isSleeveChecked: { value, isValueChangedByUser: true } });
	}

	updatePreTreatmentConfirmation({ isPreTreatmentChecked }: { isPreTreatmentChecked: boolean }) {
		this.logger.info(`updatePreTreatment: with-> ${isPreTreatmentChecked} `, { module: 'ScanOptionsFacade' });
		this.store.update({ isPreTreatmentChecked });
	}

	updateNiriCapture({ isNiriCaptureChecked }: { isNiriCaptureChecked: boolean }) {
		this.logger.info(`updateNiriCapture: with-> ${isNiriCaptureChecked} `, { module: 'ScanOptionsFacade' });
		this.store.update({ isNiriCaptureChecked });
	}

	loadNiri(): Observable<[boolean, RxModel, boolean]> {
		return combineLatest([this.shellQuery.niriCaptureDefaultValue$, this.shellQuery.rx$, this.shellQuery.isNiriCaptureVisible$]).pipe(
			tap(([niriCaptureDefaultValue, rx, isNiriCaptureVisible]) => {
				const isNiriChecked = typeof rx?.NIRIEnabled === 'boolean' ? rx.NIRIEnabled : niriCaptureDefaultValue;

				this.store.update({ isNiriCaptureChecked: (isNiriChecked && isNiriCaptureVisible) ?? false });
			})
		);
	}

	loadAdditionalBites(): Observable<RxModel> {
		return this.shellQuery.rx$.pipe(
			tap(rx => {
				if (rx) {
					const additionalBites = initializeAdditionalBites();

					if (rx.MultiBiteRestoScan?.Includes?.length) {
						rx.MultiBiteRestoScan.Includes.forEach(key => (additionalBites[key] = true));
					}

					this.updateAdditionalBites(additionalBites);
				}
			})
		);
	}

	loadMultiBiteRestoScan(): Observable<RxModel> {
		return this.shellQuery.rx$.pipe(
			tap(rx => {
				if (rx) {
					const isRestoMultiBiteSelected = rx.MultiBiteRestoScan?.Enabled ?? false;

					this.store.update({ isRestoMultiBiteSelected });
				}
			})
		);
	}

	updateScanOptionsMultiBite(isMultiBite: boolean): void {
		this.orderStore.update({ isMultiBiteSelected: isMultiBite });
	}

	updateScanOptionsRestoMultiBite(isRestoMultiBiteSelected: boolean): void {
		this.store.update({ isRestoMultiBiteSelected });

		if (!isRestoMultiBiteSelected) {
			const bites = initializeAdditionalBites();

			this.updateAdditionalBites(bites);
		}
	}

	loadSleeveConfirmation(): Observable<boolean> {
		return combineLatest([this.shellQuery.isRxSent$, this.shellQuery.rx$, this.query.isSleeveCheckedSentByScanner$]).pipe(
			map(([isRxSent, rx, isSleeveCheckedSentByScanner]) => {
				// this value is used by scanner in cases when doctor returns from scan page to rx page and already confirmed new sleeve.
				if (!!isSleeveCheckedSentByScanner) {
					return true;
				}
				if (!isRxSent) {
					return false;
				}

				return !!rx.IsSleeveConfirmed;
			}),
			tap((value: boolean) => this.store.update({ isSleeveChecked: { value, isValueChangedByUser: false } }))
		);
	}

	loadPreTreatmentAndEmergenceProfile(): Observable<[boolean, RxModel]> {
		return combineLatest([this.shellQuery.isRxSent$, this.shellQuery.rx$]).pipe(
			tap(([, rx]) => {
				const isPreTreatmentChecked = typeof rx?.PrePrepScan === 'boolean' ? rx.PrePrepScan : false;
				const isEmergenceProfileChecked = rx?.EmergenceProfile ?? false;

				this.store.update({
					isPreTreatmentChecked: isPreTreatmentChecked ?? false,
					isEmergenceProfileChecked
				});
			})
		);
	}

	updateScanOptionsDentureCopyScan(isDentureCopyScan: boolean): void {
		this.dentureDetailsStore.update({ isDentureCopyScan });
	}

	updateEmergencyProfile(isEmergenceProfileChecked: boolean): void {
		this.store.update({ isEmergenceProfileChecked });
	}

	updateAdditionalBites(bites: Partial<Record<Bites, boolean>>) {
		this.store.update({ additionalBites: { ...this.query.additionalBites, ...bites } });
	}

	getSrcForRadioButtonImg(checked: boolean): string {
		return `${this.shellQuery.staticFilesEndpoint}/assets/icons/${checked ? '' : 'un'}check.svg`;
	}

	get isProcedureFlow(): boolean {
		return this.shellQuery.isProcedureFlow;
	}
}
