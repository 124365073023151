import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { GeneralRxDataModule } from '@modules/general-rx-data/general-rx-data.module';
import { TeethDiagramModule } from '@modules/teeth-diagram/teeth-diagram.module';
import { ToothEditorModule } from '@modules/tooth-editor/tooth-editor.module';
import { TreatmentInformationModule } from '@modules/treatment-information/treatment-information.module';
import { RxForModelingComponent } from './containers/rx-for-modeling/rx-for-modeling.component';
import { OrderInformationModule } from '@modules/order-information/order-information.module';
import { NotesModule } from '@modules/notes/notes.module';
import { AlignTechNotesModule } from '@modules/aligntech-notes/aligntech-notes.module';
import { OrderModule } from '@modules/order/order.module';
import { ScanOptionsModule } from '@modules/scan-options/scan-options.module';
import { DentureDetailsComponent, DentureDetailsInfoComponent } from '@modules/denture-details';

@NgModule({
	declarations: [RxForModelingComponent],
	imports: [
		CommonModule,
		SharedModule,
		GeneralRxDataModule,
		TeethDiagramModule,
		ToothEditorModule,
		TreatmentInformationModule,
		OrderModule,
		OrderInformationModule,
		NotesModule,
		AlignTechNotesModule,
		DentureDetailsComponent,
		DentureDetailsInfoComponent,
		ScanOptionsModule
	],
	exports: [RxForModelingComponent]
})
export class RxForModelingModule {}
