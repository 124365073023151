import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScanOptionsComponent } from './containers/scan-options/scan-options.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ScanOptionsPrintComponent } from '@modules/scan-options/containers/scan-options-print/scan-options-print.component';
import { ScanOptionsLabComponent } from '@modules/scan-options/containers/scan-options-lab/scan-options-lab.component';

@NgModule({
	declarations: [ScanOptionsComponent, ScanOptionsPrintComponent, ScanOptionsLabComponent],
	imports: [CommonModule, SharedModule],
	exports: [ScanOptionsComponent, ScanOptionsPrintComponent, ScanOptionsLabComponent]
})
export class ScanOptionsModule {}
