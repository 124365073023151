import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { DefaultCrownStore } from '@modules/tooth-editor/state/default-crown-store';
import { DefaultCrownState } from '@modules/tooth-editor/models/default-crown-data';
import { ValueOf } from '@shared/utils/type-util';
import { Tooth } from '@modules/teeth-diagram/models/tooth';

@Injectable({ providedIn: 'root' })
export class DefaultCrownQuery extends Query<DefaultCrownState> {
	constructor(store: DefaultCrownStore) {
		super(store);
	}

	getDefaultProperty(index: number, propertyName: string): ValueOf<Tooth> {
		return this.getValue().crownDataMap[index]?.[propertyName] ?? null;
	}

	getDefaultShadeBody(index: number, shadeId: number): string {
		const crownBodyData = this.getValue().crownDataMap[index]?.Body;

		return crownBodyData?.[shadeId] ?? null;
	}
}
