import { Component, Input } from '@angular/core';
import { InfoBlockInput } from '@modules/general-rx-data/models/info-block-inputs-model';
import { TranslateModule } from '@ngx-translate/core';
import { DoubleHyphenPipe } from '@modules/general-rx-data/pipes/double-hyphen.pipe';
import { FlexModule } from '@angular/flex-layout/flex';
import { NgForOf, NgIf } from '@angular/common';

@Component({
	selector: 'rx-info-block',
	templateUrl: './info-block.component.html',
	styleUrls: ['./info-block.component.scss'],
	standalone: true,
	imports: [TranslateModule, DoubleHyphenPipe, FlexModule, NgForOf, NgIf]
})
export class InfoBlockComponent {
	@Input() fields: InfoBlockInput[];
}
