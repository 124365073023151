<div class="rx-card" [class.read-only]="isReadOnly$ | async">
    <div class="rx-card-title">
        <div class="rx-title-row" fxLayout="row" fxLayoutAlign="start center">
            <div class="rx-card-title-text">{{ 'ScanOptions.ScanOptionsTitle' | translate }}:</div>
        </div>
    </div>
    <div class="rx-card-body" >
        <div
			#NIRICapture
			[ngStyle]="{'flex': flexProperties}"
            *ngIf="isNiriCaptureVisible$ | async"
            class="rx-scan-option"
            id="scan-option-niri-toggle-forSonic">
            <label class="mr-3">{{ 'ScanOptions.NIRICapture' | translate }}:</label>
            <mat-slide-toggle
                [color]="'primary'"
                [disabled]="(isNiriCaptureDisabled$ | async)"
                (change)="onNiriCaptureUpdate($event.checked)"
                [checked]="isNiriCaptureChecked$ | async"
                [class.read-only]="isReadOnly$ | async"
            ></mat-slide-toggle>
        </div>
        <div
			#NewSleeveAttached
			[ngStyle]="{'flex': flexProperties}"
            *ngIf="isSleeveConfirmationVisible$ | async"
            class="rx-scan-option"
            id="scan-option-sleeve-toggle-forSonic">
            <label class="mr-3">{{ 'ScanOptions.NewSleeveAttached' | translate }}:</label>
            <mat-slide-toggle
                [color]="'primary'"
                [disabled]="isSleeveConfirmationReadOnly$ | async"
                (change)="onSleeveConfirmationUpdate($event.checked)"
                [checked]="isSleeveChecked$ | async"
                [class.read-only]="isReadOnly$ | async"
            ></mat-slide-toggle>
        </div>
        <div
			#PreTreatmentScan
			[ngStyle]="{'flex': flexProperties}"
			*ngIf="isPreTreatmentVisible$ | async"
            class="rx-scan-option"
            id="scan-option-pre-treatment-toggle-forSonic">
        <label class="mr-3">{{ 'ScanOptions.PreTreatmentScan' | translate }}:</label>
            <mat-slide-toggle
                [color]="'primary'"
                [disabled]="isPreTreatmentDisabled$ | async"
                (change)="onPreTreatmentUpdate($event.checked)"
                [checked]="isPreTreatmentChecked$ | async"
                [class.read-only]="isReadOnly$ | async"
            ></mat-slide-toggle>
        </div>
        <div
			#DentureCopyScan
			[ngStyle]="{'flex': flexProperties}"
            *ngIf="isDentureCopyScanVisible$ | async"
            class="rx-scan-option"
            id="scan-option-dentureCopyScan-toggle-forSonic">
            <label class="mr-3">{{ 'ScanOptions.DentureCopyScan' | translate }}:</label>
            <mat-slide-toggle
                color="primary"
                (change)="onDentureCopyScanUpdate($event.checked)"
                [checked]="isDentureCopyScan$ | async"
				[disabled]="(isRxTakenForScan$ | async)"
                [class.read-only]="isReadOnly$ | async"
			>
            </mat-slide-toggle>
        </div>
		<div
			#EmergenceProfile
			[ngStyle]="{'flex': flexProperties}"
			*ngIf="isEmergencyProfileVisible$ | async"
			class="rx-scan-option"
			id="scan-option-emergenceProfile-toggle">
			<label class="mr-3">{{ 'ScanOptions.EmergenceProfile' | translate }}:</label>
			<mat-slide-toggle
					color="primary"
					(change)="onEmergencyProfileUpdate($event.checked)"
					[checked]="isEmergenceProfileChecked$ | async"
					[disabled]="(isEmergencyProfileDisabled$ | async)"
					[class.read-only]="isReadOnly$ | async"
			>
			</mat-slide-toggle>
		</div>
		<div
			#MultiBite
			[ngStyle]="{'flex': flexProperties}"
            *ngIf="isMultiBiteVisible"
            class="rx-scan-option"
            id="scan-option-multiBite-toggle-forSonic">
            <label class="mr-3">{{ 'ScanOptions.MultiBite' | translate }}:</label>
            <mat-slide-toggle
                [color]="'primary'"
                [disabled]="(isReadOnly$ | async) || (isRxTakenForScan$ | async)"
                (change)="updateScanOptionsMultiBite($event.checked)"
                [checked]="isMultiBiteSelected$ | async"
                [class.read-only]="isReadOnly$ | async"
            ></mat-slide-toggle>
        </div>
        <div
			#RestoMultiBite
			[ngStyle]="{'flex': flexProperties}"
            *ngIf="isRestoMultiBiteVisible$ | async"
            class="lh-50"
            [ngClass]="{'rx-scan-option': (isRestoMultiBiteSelected$ | async) === false || noLineStyle === false }"
            id="scan-option-restoMultiBite-toggle-forSonic">
            <label class="mr-3">{{ 'ScanOptions.MultiBite' | translate }}:</label>
            <mat-slide-toggle
                [color]="'primary'"
                [disabled]="(isReadOnly$ | async) || (isRxTakenForScan$ | async)"
                (change)="updateScanOptionsRestoMultiBite($event.checked)"
                [checked]="isRestoMultiBiteSelected$ | async"
                [class.read-only]="isReadOnly$ | async"
            ></mat-slide-toggle>
        </div>
        <div
            #AdditionalBites
            *ngIf="(isRestoMultiBiteSelected$ | async) && (isRestoMultiBiteVisible$ | async)"
            id="additional-bites"
            class="additional-bites">
            <div class="additional-bite" *ngFor="let bite of additionalBites" [class.active]="isBiteSelected(bite.id) | async"  [id]= "bite.textKey.toLowerCase()">
                <img [src]="'assets/bites' + '/' + bite.img + '.svg' | staticFilesEndpoint" class="bite-img" alt="checked"/>
                <span>{{ 'RestoMultiBite.' + bite.textKey | translate }}</span>
                <mat-checkbox
                    class="pre-treatment-checkbox"
                    [disabled]="(isReadOnly$ | async) || (isRxTakenForScan$ | async)"
                    [checked]="isBiteSelected(bite.id) | async"
                    (change)="onAdditionalBitesUpdate($event.checked, bite.id)"
                    [class.read-only]="isReadOnly$ | async"
                />
            </div>
        </div>
    </div>
    <rx-banner [type]="'notification'" *ngIf="(shouldShowMultiBiteInformationMessage$ | async)"
               [message]="'ScanOptions.MultiBiteInformationMessage' | translate"
               class="bottom-banner"
    ></rx-banner>
</div>
