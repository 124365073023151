import { Injectable } from '@angular/core';
import {
	HttpContextToken,
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
	HttpStatusCode
} from '@angular/common/http';
import { Observable, switchMap, throwError, combineLatest } from 'rxjs';
import { catchError, filter, map, take, tap } from 'rxjs/operators';
import { AuthInfoService } from '@shared/services/auth-info.service';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { AuthInfoQuery } from '@shared/store/authInfo/auth-info-query';
import { AuthInfoStore } from '@shared/store/authInfo/auth-info-store';
import { JWTStateEnum } from '@shared/models/jwt-state.enum';

export const SKIP_JWT_ADDING = new HttpContextToken(() => false);

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(
		private authInfoService: AuthInfoService,
		private shellQuery: ShellQuery,
		private authInfoQuery: AuthInfoQuery,
		private authInfoStore: AuthInfoStore
	) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (this.isBearerAuthenticationSupported(req)) {
			return this.makeRequestWithJWT(req, next);
		} else {
			return this.makeRequestWithoutJWT(req, next);
		}
	}

	private isBearerAuthenticationSupported(req: HttpRequest<any>): boolean {
		return (
			this.authInfoService.isJWTSupported &&
			!(req.url.startsWith(this.shellQuery.staticFilesEndpoint) || req.context.get(SKIP_JWT_ADDING))
		);
	}

	private makeRequestWithoutJWT(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(
			map((event: HttpEvent<any>) => {
				return event;
			}),
			catchError((error: HttpErrorResponse) => {
				if (error.status === HttpStatusCode.Unauthorized) {
					this.authInfoService.backToLogin();
				}

				return throwError(error);
			})
		);
	}

	private makeRequestWithJWT(req: HttpRequest<any>, next: HttpHandler, counter: number = 0): Observable<HttpEvent<any>> {
		switch (this.authInfoQuery.jwtState) {
			case JWTStateEnum.CannotBeRefreshed:
				return throwError(() => Error(`The request with url: ${req.url} was canceled because token cannot be refreshed`));
			case JWTStateEnum.InProcessOfRefresh:
				return this.makeRequestWhenRefreshIsDone(req, next, ++counter);
			default: {
				const token = this.authInfoQuery.jwt;
				const clonedRequest = req.clone({
					headers: req.headers.set('Authorization', `Bearer ${token}`).set('X-Company-Id', String(this.shellQuery.companyId))
				});

				return next.handle(clonedRequest).pipe(
					catchError((error: HttpErrorResponse) => {
						if (error.status === HttpStatusCode.Unauthorized && counter < 3) {
							return this.handleUnauthorizedError(req, next, token, ++counter);
						}

						return throwError(() => error);
					})
				);
			}
		}
	}

	private makeRequestWhenRefreshIsDone(req: HttpRequest<any>, next: HttpHandler, counter: number): Observable<HttpEvent<any>> {
		return this.authInfoQuery.jwtState$.pipe(
			filter(jwtState => jwtState !== JWTStateEnum.InProcessOfRefresh),
			take(1),
			switchMap(() => this.makeRequestWithJWT(req, next, counter))
		);
	}

	private refreshAuthInfo() {
		return this.authInfoService.refreshAuthInfo().pipe(
			take(1),
			tap(() => {
				this.authInfoStore.update({ JWTState: JWTStateEnum.Ready });
			}),
			catchError((e: HttpErrorResponse) => {
				this.authInfoStore.update({ JWTState: JWTStateEnum.CannotBeRefreshed });

				return throwError(() => e);
			})
		);
	}

	private handleUnauthorizedError(req: HttpRequest<any>, next: HttpHandler, token: string, counter: number): Observable<HttpEvent<any>> {
		if (this.authInfoQuery.jwtState === JWTStateEnum.InProcessOfRefresh) {
			return this.makeRequestWhenRefreshIsDone(req, next, counter);
		}

		if (this.authInfoQuery.jwtState === JWTStateEnum.Ready) {
			const hasTokenAlreadyUpdated = token !== this.authInfoQuery.jwt;

			if (hasTokenAlreadyUpdated) {
				return this.makeRequestWhenRefreshIsDone(req, next, counter);
			}
			this.authInfoStore.update({ JWTState: JWTStateEnum.InProcessOfRefresh });

			const refresh$ = this.refreshAuthInfo();
			const request$ = this.makeRequestWhenRefreshIsDone(req, next, counter);

			return combineLatest([request$, refresh$]).pipe(map(([event]) => event));
		}
	}
}
