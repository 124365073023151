import { Component, Injector, Input } from '@angular/core';
import { PonticSelectorService } from '@modules/tooth-editor/services/pontic-selector.service';
import { ControlValueAccessorDirective } from '@shared/base-classes/control-value-accessor.directive';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { IdName } from '@shared/models/id-name';

@Component({
	selector: 'rx-pontic-selector',
	templateUrl: './pontic-selector.component.html',
	styleUrls: ['./pontic-selector.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: PonticSelectorComponent,
			multi: true
		}
	]
})
export class PonticSelectorComponent extends ControlValueAccessorDirective<IdName> {
	@Input()
	isReadonly = false;
	@Input()
	name = '';
	@Input()
	options: IdName[];
	@Input()
	showLabel = true;

	constructor(private ponticSelectorService: PonticSelectorService, injector: Injector) {
		super(injector);
	}

	getSrcForPonticDesignImage(ponticDesign: IdName): string {
		return this.ponticSelectorService.getSrcForPonticDesignImage(ponticDesign);
	}

	getSrcForRadioButtonImg(): string {
		return this.ponticSelectorService.getSrcForRadioButtonImg();
	}

	addDefaultOptionForPontics(options: IdName[]): IdName[] {
		return [null, ...(options ?? [])];
	}
}
