<div
	class="alert text-left"
	[ngClass]="{
		'alert-success': type === 'success',
		'alert-warning': type === 'warning',
		'alert-danger': type === 'danger',
		'alert-info': type === 'info',
		'alert-primary': type === 'primary',
		'alert-secondary': type === 'secondary'
	}"
>
	{{ alertKey | translate}}
</div>