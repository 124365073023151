import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { IdName } from '@shared/models/id-name';
import { ShadeSystem } from '@shared/models/shade-system';

export interface DentureDetailsState {
	isUpperJawChecked: boolean;
	isLowerJawChecked: boolean;
	stage: IdName;
	mould: IdName;
	shadeSystem: ShadeSystem;
	teethShade: string;
	gingivalShade: IdName;
	isDentureCopyScan: boolean;
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function createInitialState(): DentureDetailsState {
	return {
		isUpperJawChecked: false,
		isLowerJawChecked: false,
		stage: null,
		mould: null,
		shadeSystem: null,
		teethShade: null,
		gingivalShade: null,
		isDentureCopyScan: false
	};
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'dentureDetails', resettable: true })
export class DentureDetailsStore extends Store<DentureDetailsState> {
	constructor() {
		super(createInitialState());
	}
}
