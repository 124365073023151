import { Injectable } from '@angular/core';
import { applyTransaction, Store, StoreConfig } from '@datorama/akita';
import { PatientModelDto } from '@shared/models/rx-models/interfaces/patient-model-dto';
import { emptyGuid } from '@shared/models/consts';
import { PatientModel } from '@shared/models/rx-models/interfaces/patient-model';
import { DateOfBirthUtils } from '@shared/utils/date-of-birth-utils';

export interface PatientState {
	patientAppUrl: string;
	patient: PatientModel;
	isPatientInConflict: boolean;
	isPatientAppConfigured: boolean;
}

export const createInitialState = (): PatientState => ({
	patientAppUrl: null,
	patient: null,
	isPatientInConflict: null,
	isPatientAppConfigured: false
});

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'patient' })
export class PatientStore extends Store<PatientState> {
	constructor() {
		super(createInitialState());
	}

	updatePatient(patient: PatientModelDto, dateFormat?: string, regulatoryDOBMask?: string): void {
		applyTransaction(() => {
			const dateOfBirth =
				patient.DateOfBirth instanceof Date
					? patient.DateOfBirth
					: DateOfBirthUtils.getDate(patient.DateOfBirth, dateFormat, regulatoryDOBMask);

			this.update({ patient: { ...patient, DateOfBirth: dateOfBirth } });

			if (patient.UID && patient.UID !== emptyGuid) {
				this.update({ isPatientInConflict: false });
			}
		});
	}
}
