import {
	ProcedureRule,
	SpecIdWithMaterialIds,
	TeethRule,
	TeethUnitType,
	UnitTypeRuleWithSpecs,
	UnitTypesAdditionalRules
} from '@shared/models/rx-rules-json-interface';
import { VersionsService } from '../versions.service';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { HostPlatformService } from '../host-platform.service';
import { SoftwareOptionsService } from '../software-options.service';
import { ApplicationModes, SoftwareOptions } from '@shared/models/enums/enums';
import { RxConfiguration } from '@shared/models/rx-configuration';
import { LimitedFeatures } from '@shared/models/limited-features';
export class ProcedureRulesParser<TUnitTypes extends number> {
	constructor(
		private procedureRules: ProcedureRule[],
		private versionService: VersionsService,
		private hostPlatformService: HostPlatformService,
		private shellQuery: ShellQuery
	) {}

	setRules(rules: ProcedureRule[]): this {
		this.procedureRules = rules;

		return this;
	}

	getUnitTypeIdsByProcedureMapAndToothId(
		procedureMapId: number,
		toothId: number,
		currentUnitTypeOnTooth: TUnitTypes,
		rxConfiguration?: RxConfiguration
	): number[] {
		const teethRules = this.getTeethRules(procedureMapId, toothId);

		const additionalUnitTypeIds: number[] = this.getAdditionalUnitTypeIds({ teethRules, toothId, currentUnitTypeOnTooth });

		const getUnitTypeIdsFromTeethRules = (accumulator: number[], teethRule: TeethRule) => {
			(teethRule.UnitAndMaterialRules.UnitTypeRules as UnitTypeRuleWithSpecs[]).forEach(unitTypeRule => {
				if (unitTypeRule.RestrictionUnitTypes) {
					if (this.isRestrictedUnitTypeAvailability(unitTypeRule, rxConfiguration)) {
						accumulator.push(unitTypeRule.Id);
					}
				} else {
					accumulator.push(unitTypeRule.Id);
				}
			});

			return accumulator;
		};
		const unitTypeIds: number[] = teethRules?.reduce(getUnitTypeIdsFromTeethRules, additionalUnitTypeIds);

		return [...new Set(unitTypeIds)];
	}

	getSpecIdsWithMaterialIds(procedureMapId: number, toothId: number, currentUnitTypeOnTooth: TUnitTypes): SpecIdWithMaterialIds[] {
		const teethRules = this.getTeethRules(procedureMapId, toothId);

		const getSpecIdsFromTeethRules = (accumulator: SpecIdWithMaterialIds[], teethRule: TeethRule) => {
			const unitTypeRule = (teethRule.UnitAndMaterialRules.UnitTypeRules as UnitTypeRuleWithSpecs[]).find(
				({ Id }) => Id === currentUnitTypeOnTooth
			);

			if (unitTypeRule?.Specifications?.length) {
				accumulator.push(...unitTypeRule.Specifications);
			}

			return accumulator;
		};

		return teethRules?.reduce(getSpecIdsFromTeethRules, []);
	}

	getTeethUnitTypeRule(procedureMapId: number): TeethUnitType[] {
		const procedureRule = this.procedureRules?.find((pr: ProcedureRule) => pr.Ids.includes(procedureMapId));

		if (!procedureRule) {
			return null;
		}

		const getUnitTypeRules = (accum: TeethUnitType[], curr: TeethRule) => {
			const unitTypeIds = [];

			(curr.UnitAndMaterialRules.UnitTypeRules as UnitTypeRuleWithSpecs[]).forEach(unitTypeRule => {
				unitTypeIds.push(unitTypeRule.Id);
			});
			curr.UnitAndMaterialRules?.UnitTypesAdditionalRules?.UnitTypesAddedOutput.forEach((id: number) => {
				unitTypeIds.push(id);
			});
			accum.push({
				TeethIds: curr.Ids,
				UnitTypeIds: unitTypeIds
			});

			return accum;
		};

		return procedureRule.TeethRules.reduce(getUnitTypeRules, []);
	}

	private getTeethRules(procedureMapId: number, toothId: number): TeethRule[] {
		const procedureRule = this.getProcedureRule(procedureMapId);

		return procedureRule?.TeethRules?.filter((teethRule: TeethRule) => teethRule.Ids.includes(toothId));
	}

	private getProcedureRule(procedureMapId: number): ProcedureRule {
		return this.procedureRules?.find((procedureRule: ProcedureRule) => procedureRule.Ids.includes(procedureMapId)) ?? null;
	}

	private getAdditionalUnitTypeIds({
		teethRules,
		toothId,
		currentUnitTypeOnTooth
	}: {
		teethRules: TeethRule[];
		toothId: number;
		currentUnitTypeOnTooth: number;
	}): number[] {
		const getAdditionalUnitTypeIdsFromTeethRules = (accumulator: number[], teethRule: TeethRule) => {
			if (teethRule.Ids.includes(toothId) && teethRule.UnitAndMaterialRules.UnitTypesAdditionalRules) {
				const additionalRules: UnitTypesAdditionalRules = teethRule.UnitAndMaterialRules.UnitTypesAdditionalRules;

				if (additionalRules.UnitTypesInput.includes(currentUnitTypeOnTooth)) {
					accumulator.push(...additionalRules.UnitTypesAddedOutput);
				}
			}

			return accumulator;
		};

		return teethRules?.reduce(getAdditionalUnitTypeIdsFromTeethRules, []);
	}

	private isFeatureHasSWO(softwareOptions: SoftwareOptions, rxConfiguration: RxConfiguration): boolean {
		return (
			rxConfiguration &&
			SoftwareOptionsService.checkSoftwareOptions(rxConfiguration.CompanyConfiguration.SoftwareOptionsForCompany, [softwareOptions])
		);
	}

	private isfeatureAvailable(limitedFeatures: LimitedFeatures): boolean {
		if (this.hostPlatformService.isScanner) {
			return this.versionService.isFeatureAvailableForScanner(
				limitedFeatures,
				this.shellQuery.clientVersion,
				this.shellQuery.packageVersion
			);
		}
		if (this.hostPlatformService.isMidc(this.shellQuery.applicationMode as ApplicationModes)) {
			const versions = this.shellQuery.scannersVersions.map(s => s.VersionNumber);

			return this.versionService.isFeatureAvailableForAllScanners(limitedFeatures, versions);
		} else {
			return false;
		}
	}

	private isRestrictedUnitTypeAvailability(unitTypeRule: UnitTypeRuleWithSpecs, rxConfiguration: RxConfiguration): boolean {
		const hasSWORestriction = unitTypeRule.RestrictionUnitTypes.SoftwareOption
			? this.isFeatureHasSWO(unitTypeRule.RestrictionUnitTypes.SoftwareOption, rxConfiguration)
			: true;

		const hasFeatureRestriction = unitTypeRule.RestrictionUnitTypes.LimitedFeatures
			? this.isfeatureAvailable(unitTypeRule.RestrictionUnitTypes.LimitedFeatures)
			: true;

		return hasSWORestriction && hasFeatureRestriction;
	}
}
