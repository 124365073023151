import { TeethNumberingSystem } from './teeth-numbering-system.enum';
export interface DefaultTeethNumbers {
	upperJaw: number[];
	lowerJaw: number[];
}
export interface TeethNumbers {
	[key: number]: {
		upperJaw: (string | number)[];
		lowerJaw: (string | number)[];
	};
}

export const defaultTeethNumbers: DefaultTeethNumbers = {
	upperJaw: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
	lowerJaw: [32, 31, 30, 29, 28, 27, 26, 25, 24, 23, 22, 21, 20, 19, 18, 17]
};

export const teethNumbers: TeethNumbers = {
	[TeethNumberingSystem.FDI]: {
		upperJaw: [18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28],
		lowerJaw: [48, 47, 46, 45, 44, 43, 42, 41, 31, 32, 33, 34, 35, 36, 37, 38]
	}, // FDI
	[TeethNumberingSystem.ADA]: {
		upperJaw: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
		lowerJaw: [32, 31, 30, 29, 28, 27, 26, 25, 24, 23, 22, 21, 20, 19, 18, 17]
	}, // ADA
	[TeethNumberingSystem.Quadrant]: {
		upperJaw: ['UR8', 'UR7', 'UR6', 'UR5', 'UR4', 'UR3', 'UR2', 'UR1', 'UL1', 'UL2', 'UL3', 'UL4', 'UL5', 'UL6', 'UL7', 'UL8'],
		lowerJaw: ['LR8', 'LR7', 'LR6', 'LR5', 'LR4', 'LR3', 'LR2', 'LR1', 'LL1', 'LL2', 'LL3', 'LL4', 'LL5', 'LL6', 'LL7', 'LL8']
	} // Quadrant
};

export const getJawByToothNumber = ({ toothId }: { toothId: number }): 'lower' | 'upper' => {
	const isLowerJaw = defaultTeethNumbers.lowerJaw.includes(toothId);

	return isLowerJaw ? 'lower' : 'upper';
};
export const toothNumberingMap: Record<number, Record<TeethNumberingSystem, string | number>> = {
	1: {
		[TeethNumberingSystem.FDI]: 18,
		[TeethNumberingSystem.ADA]: 1,
		[TeethNumberingSystem.Quadrant]: 'UR8'
	},
	2: {
		[TeethNumberingSystem.FDI]: 17,
		[TeethNumberingSystem.ADA]: 2,
		[TeethNumberingSystem.Quadrant]: 'UR7'
	},
	3: {
		[TeethNumberingSystem.FDI]: 16,
		[TeethNumberingSystem.ADA]: 3,
		[TeethNumberingSystem.Quadrant]: 'UR6'
	},
	4: {
		[TeethNumberingSystem.FDI]: 15,
		[TeethNumberingSystem.ADA]: 4,
		[TeethNumberingSystem.Quadrant]: 'UR5'
	},
	5: {
		[TeethNumberingSystem.FDI]: 14,
		[TeethNumberingSystem.ADA]: 5,
		[TeethNumberingSystem.Quadrant]: 'UR4'
	},
	6: {
		[TeethNumberingSystem.FDI]: 13,
		[TeethNumberingSystem.ADA]: 6,
		[TeethNumberingSystem.Quadrant]: 'UR3'
	},
	7: {
		[TeethNumberingSystem.FDI]: 12,
		[TeethNumberingSystem.ADA]: 7,
		[TeethNumberingSystem.Quadrant]: 'UR2'
	},
	8: {
		[TeethNumberingSystem.FDI]: 11,
		[TeethNumberingSystem.ADA]: 8,
		[TeethNumberingSystem.Quadrant]: 'UR1'
	},
	9: {
		[TeethNumberingSystem.FDI]: 21,
		[TeethNumberingSystem.ADA]: 9,
		[TeethNumberingSystem.Quadrant]: 'UL1'
	},
	10: {
		[TeethNumberingSystem.FDI]: 22,
		[TeethNumberingSystem.ADA]: 10,
		[TeethNumberingSystem.Quadrant]: 'UL2'
	},
	11: {
		[TeethNumberingSystem.FDI]: 23,
		[TeethNumberingSystem.ADA]: 11,
		[TeethNumberingSystem.Quadrant]: 'UL3'
	},
	12: {
		[TeethNumberingSystem.FDI]: 24,
		[TeethNumberingSystem.ADA]: 12,
		[TeethNumberingSystem.Quadrant]: 'UL4'
	},
	13: {
		[TeethNumberingSystem.FDI]: 25,
		[TeethNumberingSystem.ADA]: 13,
		[TeethNumberingSystem.Quadrant]: 'UL5'
	},
	14: {
		[TeethNumberingSystem.FDI]: 26,
		[TeethNumberingSystem.ADA]: 14,
		[TeethNumberingSystem.Quadrant]: 'UL6'
	},
	15: {
		[TeethNumberingSystem.FDI]: 27,
		[TeethNumberingSystem.ADA]: 15,
		[TeethNumberingSystem.Quadrant]: 'UL7'
	},
	16: {
		[TeethNumberingSystem.FDI]: 28,
		[TeethNumberingSystem.ADA]: 16,
		[TeethNumberingSystem.Quadrant]: 'UL8'
	},
	17: {
		[TeethNumberingSystem.FDI]: 38,
		[TeethNumberingSystem.ADA]: 17,
		[TeethNumberingSystem.Quadrant]: 'LL8'
	},
	18: {
		[TeethNumberingSystem.FDI]: 37,
		[TeethNumberingSystem.ADA]: 18,
		[TeethNumberingSystem.Quadrant]: 'LL7'
	},
	19: {
		[TeethNumberingSystem.FDI]: 36,
		[TeethNumberingSystem.ADA]: 19,
		[TeethNumberingSystem.Quadrant]: 'LL6'
	},
	20: {
		[TeethNumberingSystem.FDI]: 35,
		[TeethNumberingSystem.ADA]: 20,
		[TeethNumberingSystem.Quadrant]: 'LL5'
	},
	21: {
		[TeethNumberingSystem.FDI]: 34,
		[TeethNumberingSystem.ADA]: 21,
		[TeethNumberingSystem.Quadrant]: 'LL4'
	},
	22: {
		[TeethNumberingSystem.FDI]: 33,
		[TeethNumberingSystem.ADA]: 22,
		[TeethNumberingSystem.Quadrant]: 'LL3'
	},
	23: {
		[TeethNumberingSystem.FDI]: 32,
		[TeethNumberingSystem.ADA]: 23,
		[TeethNumberingSystem.Quadrant]: 'LL2'
	},
	24: {
		[TeethNumberingSystem.FDI]: 31,
		[TeethNumberingSystem.ADA]: 24,
		[TeethNumberingSystem.Quadrant]: 'LL1'
	},
	25: {
		[TeethNumberingSystem.FDI]: 41,
		[TeethNumberingSystem.ADA]: 25,
		[TeethNumberingSystem.Quadrant]: 'LR1'
	},
	26: {
		[TeethNumberingSystem.FDI]: 42,
		[TeethNumberingSystem.ADA]: 26,
		[TeethNumberingSystem.Quadrant]: 'LR2'
	},
	27: {
		[TeethNumberingSystem.FDI]: 43,
		[TeethNumberingSystem.ADA]: 27,
		[TeethNumberingSystem.Quadrant]: 'LR3'
	},
	28: {
		[TeethNumberingSystem.FDI]: 44,
		[TeethNumberingSystem.ADA]: 28,
		[TeethNumberingSystem.Quadrant]: 'LR4'
	},
	29: {
		[TeethNumberingSystem.FDI]: 45,
		[TeethNumberingSystem.ADA]: 29,
		[TeethNumberingSystem.Quadrant]: 'LR5'
	},
	30: {
		[TeethNumberingSystem.FDI]: 46,
		[TeethNumberingSystem.ADA]: 30,
		[TeethNumberingSystem.Quadrant]: 'LR6'
	},
	31: {
		[TeethNumberingSystem.FDI]: 47,
		[TeethNumberingSystem.ADA]: 31,
		[TeethNumberingSystem.Quadrant]: 'LR7'
	},
	32: {
		[TeethNumberingSystem.FDI]: 48,
		[TeethNumberingSystem.ADA]: 32,
		[TeethNumberingSystem.Quadrant]: 'LR8'
	}
};

export const convertToothNumberToDefaultSystemById = ({
	toothId,
	teethNumberingSystem
}: {
	toothId: number | string;
	teethNumberingSystem: TeethNumberingSystem;
}) => {
	if (teethNumberingSystem === TeethNumberingSystem.ADA) {
		return toothId;
	}

	const filteredToothId = Object.values(toothNumberingMap).filter(toothNumberObj => {
		return toothNumberObj[teethNumberingSystem] === toothId;
	});

	return filteredToothId[0][TeethNumberingSystem.ADA];
};

export const getToothNumberById = ({ toothId, teethNumberingSystem }: { toothId: number; teethNumberingSystem: TeethNumberingSystem }) =>
	toothNumberingMap[toothId][teethNumberingSystem];
