import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Doctor } from '@shared/models/doctor';
import { Observable } from 'rxjs';
import { DoctorState, DoctorStore } from './doctor-store';

@Injectable({ providedIn: 'root' })
export class DoctorQuery extends Query<DoctorState> {

	
	doctor$: Observable<Doctor> = this.select(state => state.doctor);
	get doctor(): Doctor { return this.getValue().doctor };
	isLicenseEditable$: Observable<boolean> = this.select(state => state.isLicenseEditable);

	constructor(store: DoctorStore) {
		super(store);
	}
}
