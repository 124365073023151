import { DefaultCrownData, DefaultCrownState } from '@modules/tooth-editor/models/default-crown-data';
import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export const createInitialState = (): DefaultCrownState => ({
	crownDataMap: {}
});

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'default-crown-data', resettable: true })
export class DefaultCrownStore extends Store<DefaultCrownState> {
	constructor() {
		super(createInitialState());
	}

	setDefaultData(propertyValue: number, property: keyof DefaultCrownData, bridgeIndex: number) {
		let crownDataMap = this.getValue().crownDataMap;
		let bridgeData = crownDataMap[bridgeIndex];

		if (propertyValue == null || property == null || (!!bridgeData && !!bridgeData[property])) {
			return;
		}
		bridgeData = { ...bridgeData, [property]: propertyValue };
		crownDataMap = { ...crownDataMap, [bridgeIndex]: bridgeData };
		this.update({ crownDataMap });
	}

	setDefaultBodyData(value: string, bridgeIndex: number, shadeSystemId: number, toothId: number) {
		let crownDataMap = this.getValue().crownDataMap;
		let bridgeData = crownDataMap[bridgeIndex];
		let bodyData = bridgeData?.Body;

		if (
			value == null ||
			(!!bodyData && shadeSystemId >= 0 && !!bodyData[shadeSystemId]) ||
			(shadeSystemId === -1 && !!bridgeData.otherShadeSystemToothId && bridgeData.otherShadeSystemToothId !== toothId)
		) {
			return;
		}

		bodyData = { ...bodyData, [shadeSystemId]: value };
		bridgeData = { ...bridgeData, Body: bodyData };

		if (shadeSystemId === -1) {
			bridgeData = { ...bridgeData, otherShadeSystemToothId: toothId };
		}
		crownDataMap = { ...crownDataMap, [bridgeIndex]: bridgeData };
		this.update({ crownDataMap });
	}

	clearDefaultData(bridgeIndex: number) {
		const crownDataMap = { ...this.getValue().crownDataMap };

		if (!!crownDataMap && !!crownDataMap[bridgeIndex]) {
			delete crownDataMap[bridgeIndex];
			this.update({ crownDataMap });
		}
	}
}
