import { AfterViewInit, Directive, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { CaseTypeEnum } from '@modules/order/models/case-type.enum';
import { preDefinedNotesId, teethRowHeight } from '@modules/teeth-diagram/models/consts';
import { getJawByToothNumber } from '@modules/teeth-diagram/models/teeth-numbering';
import { getNewTooth, newTooth, Tooth } from '@modules/teeth-diagram/models/tooth';
import { ToothEditorModalActions } from '@modules/teeth-diagram/models/tooth-editor-actions.enum';
import { UnitTypesInBridge } from '@modules/teeth-diagram/models/unit-type-in-bridge.enum';
import { UnitTypes } from '@modules/teeth-diagram/models/unit-type.enum';
import { PreDefinedNotesService } from '@modules/teeth-diagram/services/pre-defined-notes.service';
import { TeethDiagramFacade } from '@modules/teeth-diagram/teeth-diagram.facade';
import { TranslateService } from '@ngx-translate/core';
import { BaseDestroyableDirective } from '@shared/base-classes/base-destroyable';
import { IdName } from '@shared/models/id-name';
import { PositionObject } from '@shared/models/position-object';
import { RxModel } from '@shared/models/rx-models/interfaces/rx-model';
import { BridgeService } from '@shared/services/bridge.service';
import { extractPositionObject } from '@shared/services/position-object-extractor';
import { ToothEditorService } from '@shared/services/tooth-editor.service';
import { combineLatest, Observable, of } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';
import { unitTypesWithoutDetails } from '@shared/models/consts';
import { AligntechNotesService } from '@modules/aligntech-notes/services/aligntech-notes.service';
import { UnitTypeInBridgeEvent } from '@shared/models/unit-type-in-bridge-event';
import { TeethManagerService } from '@modules/tooth-editor/state/teeth-manager-service';

@Directive()
export abstract class TeethDiagramBaseDirective extends BaseDestroyableDirective implements OnInit, AfterViewInit {
	@Input() rxForPrint: RxModel;
	@Input() isRxForModeling: boolean;

	@Output() imagesLoaded = new EventEmitter<void>();

	upperJaw$ = this.teethDiagramFacade.upperJaw$;
	lowerJaw$ = this.teethDiagramFacade.lowerJaw$;
	teethNumberingSystem$ = this.teethDiagramFacade.teethNumberingSystem$;
	unitTypes$: Observable<IdName[]>;
	unitTypeMenuInput$: Observable<[IdName[], { tooth: Tooth; positionObj: PositionObject }]>;
	isReadOnly$: Observable<boolean> = this.teethDiagramFacade.isReadOnly$;
	caseType: CaseTypeEnum;
	rowHeight: string = teethRowHeight;
	toothBridge: number[];
	upperJaw: Tooth[];
	lowerJaw: Tooth[];
	preDefinedNoteOption: IdName;

	get isPrintContext() {
		return !!this.rxForPrint;
	}

	get isGlidewellOrder(): boolean {
		return this.teethDiagramFacade.isGlidewellOrder;
	}

	bridgeEdges: {
		[bridgeIndex: number]: {
			firstToothOnLeftToothId: number;
			lastToothOnRightToothId: number;
		};
	} = this.bridgeService.bridgeEdges;

	constructor(
		protected teethDiagramFacade: TeethDiagramFacade,
		protected toothEditorService: ToothEditorService,
		protected bridgeService: BridgeService,
		protected translateService: TranslateService,
		protected preDefinedNotesService: PreDefinedNotesService,
		protected aligntechNotesService: AligntechNotesService,
		protected teethManagerService: TeethManagerService
	) {
		super();
	}

	private teethLoadedCounter = 0;

	abstract isImmediatelyChangeUnitType(unitTypeInBridge: number): boolean;

	ngOnInit(): void {
		this.fetchRxAndUpdate();
		this.teethDiagramFacade.updateTeethNumberingSystem(this.rxForPrint);
		combineLatest([this.upperJaw$, this.lowerJaw$])
			.pipe(takeUntil(this.componentAlive$))
			.subscribe(([upperJaw, lowerJaw]) => {
				this.upperJaw = upperJaw;
				this.lowerJaw = lowerJaw;
			});
		if (this.isRxForModeling) {
			this.preDefinedNoteOption = {
				Id: preDefinedNotesId,
				Name: this.translateService.instant('PreDefinedNotes.PreDefinedNotes')
			};
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes?.rxForPrint && changes.rxForPrint.previousValue !== changes.rxForPrint.currentValue) {
			this.toothBridge = this.getBridgesAll || [];
		}
	}

	ngAfterViewInit(): void {
		if (this.isPrintContext) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-call
			const { upperJaw, lowerJaw } = this.teethDiagramFacade.getUpdatedTeethSync({
				teethToUpdate: this.rxForPrint.Teeth,
				fromNewState: true
			});

			this.upperJaw$ = of(upperJaw);
			this.lowerJaw$ = of(lowerJaw);
		}
	}

	handleToothClick({
		tooth,
		event,
		isReadOnly,
		overriddenUnitType
	}: {
		tooth: Tooth;
		event: any;
		isReadOnly?: boolean;
		overriddenUnitType?: UnitTypes;
	}): void {
		if (isReadOnly) {
			if (!tooth.BridgeIndex) {
				if (this.toothEditorService.isGlidewellOrder || tooth.UnitTypeID === UnitTypes.SupportingTooth) {
					return;
				}
				this.toothEditorService
					.openToothEditor({ unitType: tooth.UnitTypeID, teeth: [tooth], toothClickedOn: tooth, autoFocus: false })
					.subscribe((result: { teeth: Tooth[]; action: ToothEditorModalActions }) => {
						const teethToUpdate = this.toothEditorService.handleModalResult({
							result,
							toothId: tooth.ToothID,
							upperJaw: this.upperJaw,
							lowerJaw: this.lowerJaw
						});

						this.teethDiagramFacade.updateTeeth({ teethToUpdate });
					});
			} else {
				this.openToothEditorInBridgeMode({ tooth });
			}
		} else {
			this.unitTypes$ = tooth.BridgeIndex
				? this.teethDiagramFacade.getUnitTypeNamesForBridge(tooth)
				: this.teethDiagramFacade.getUnitTypeNames(tooth);

			const positionObj: PositionObject = extractPositionObject({ event });
			const toothClickObj$ = of({ tooth, positionObj, overriddenUnitType });

			this.unitTypeMenuInput$ = combineLatest([this.unitTypes$, toothClickObj$]);
		}
	}

	handleToothLoaded() {
		this.teethLoadedCounter++;

		if (this.teethLoadedCounter === 32) {
			this.imagesLoaded.emit();
		}
	}

	handleUnitTypeInBridgeSelection(event: UnitTypeInBridgeEvent): void {
		const immediatelyChangeUnitType = this.isImmediatelyChangeUnitType(event.unitTypeInBridge);

		const toothToUpdate: Tooth = immediatelyChangeUnitType
			? {
					...newTooth,
					ToothID: event.tooth.ToothID,
					UnitTypeID: event.tooth.UnitTypeID,
					ToothInBridgeTypeID: event.tooth.ToothInBridgeTypeID,
					BridgeIndex: event.tooth.BridgeIndex
			  }
			: event.tooth;
		const updatedTooth: Tooth = {
			...toothToUpdate,
			ToothInBridgeTypeID: event.unitTypeInBridge === null ? event.tooth.ToothInBridgeTypeID : event.unitTypeInBridge
		};

		if (immediatelyChangeUnitType && !event.clickOnBridge) {
			this.teethDiagramFacade.updateTeeth({ teethToUpdate: [updatedTooth] });
		} else {
			this.openToothEditorInBridgeMode({ tooth: updatedTooth });
		}

		if (this.isRxForModeling && event.unitTypeInBridge !== event.tooth.ToothInBridgeTypeID) {
			this.aligntechNotesService.createChangeUnitTypeNote(event.tooth, updatedTooth);
		}
	}

	handleUnitTypeSelection({ unitType, tooth }: { unitType: number; tooth: Tooth }) {
		const toothToUpdate: Tooth =
			unitType !== tooth.UnitTypeID
				? getNewTooth({
						ToothID: tooth.ToothID,
						UnitTypeID: tooth.UnitTypeID,
						ToothInBridgeTypeID: tooth.ToothInBridgeTypeID
				  })
				: getNewTooth(tooth);

		const isBridgeSelected = unitType === null;

		toothToUpdate.UnitTypeID = isBridgeSelected ? getNewTooth().UnitTypeID : unitType;
		toothToUpdate.ToothInBridgeTypeID = isBridgeSelected
			? this.teethDiagramFacade.convertToBridgeToothUnitType({ unitType: tooth.UnitTypeID })
			: null;

		const noNeedToOpenEditor =
			unitTypesWithoutDetails.includes(unitType) || (this.toothEditorService.isGlidewellOrder && !isBridgeSelected);

		if (noNeedToOpenEditor) {
			this.teethDiagramFacade.updateTeeth({ teethToUpdate: [toothToUpdate] });
		} else if (!isBridgeSelected) {
			this.updateToothInTeethEditor(toothToUpdate, unitType);
		} else {
			this.openToothEditorInBridgeMode({ tooth: toothToUpdate });
		}

		if (this.isRxForModeling && tooth.UnitTypeID !== UnitTypes.Regular && tooth.UnitTypeID !== toothToUpdate.UnitTypeID) {
			this.aligntechNotesService.createChangeUnitTypeNote(tooth, toothToUpdate);
		}
	}

	handlePreDefinedNoteSelected(preDefinedNote: string) {
		this.teethDiagramFacade.updatePreDefinedNote({ preDefinedNote });
	}

	handleUnitTypeMenuClosed() {
		this.unitTypeMenuInput$ = null;
	}

	protected openToothEditorInBridgeMode({ tooth }: { tooth: Tooth }) {
		combineLatest([this.upperJaw$, this.lowerJaw$])
			.pipe(
				take(1),
				tap(([upperJaw, lowerJaw]) => {
					const jaw = getJawByToothNumber({ toothId: tooth.ToothID });
					const allTeethInJaw = jaw === 'upper' ? upperJaw : lowerJaw;

					this.toothEditorService
						.openToothEditorInBridgeMode({ tooth, upperJaw, lowerJaw })
						.subscribe((result: { teeth: Tooth[]; action: ToothEditorModalActions }) => {
							if (result.action === ToothEditorModalActions.Delete) {
								this.teethManagerService.removeMany(result.teeth);
							}

							const teethToUpdate = this.toothEditorService.handleModalResult({
								result,
								toothId: tooth.ToothID,
								upperJaw: this.upperJaw,
								lowerJaw: this.lowerJaw,
								allTeethInJaw
							});

							this.teethDiagramFacade.updateTeeth({ teethToUpdate });
						});
				})
			)
			.subscribe();
	}

	protected updateBridgeEdgesFromExistingTeethArray({ teeth }: { teeth: Tooth[] }): void {
		const bridges: { [bridgeindex: number]: Tooth[] } = {};
		let maxBridgeIndex = 0;

		teeth?.forEach(tooth => {
			if (tooth.BridgeIndex > 0) {
				if (!!bridges[tooth.BridgeIndex]) {
					bridges[tooth.BridgeIndex].push(tooth);
				} else {
					bridges[tooth.BridgeIndex] = [tooth];
				}
			}
		});
		for (const bridgeKey of Object.keys(bridges)) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-call
			bridges[bridgeKey] = this.teethDiagramFacade.sortBridgeTeethByToothId({ teeth: bridges[bridgeKey] });
			this.bridgeEdges[bridgeKey] = {
				firstToothOnLeftToothId: bridges[bridgeKey][0].ToothID,
				lastToothOnRightToothId: bridges[bridgeKey][bridges[bridgeKey].length - 1].ToothID
			};

			const parsedBridgeKey = parseInt(bridgeKey, 10);

			if (maxBridgeIndex < parsedBridgeKey) {
				maxBridgeIndex = parsedBridgeKey;
			}
		}
		this.teethDiagramFacade.bridgeIndex = maxBridgeIndex;
	}

	protected fetchRxAndUpdate() {
		this.teethDiagramFacade.rxTeeth$
			.pipe(
				tap((teeth: Tooth[]) => {
					if (!!teeth) {
						this.teethDiagramFacade.resetTeeth();
						this.updateBridgeEdgesFromExistingTeethArray({ teeth });

						const teethToUpdate = teeth.map(tooth => {
							const isBridge = tooth.BridgeIndex > 0;

							if (isBridge) {
								const { ...toothClone } = tooth;

								if (tooth.ToothInBridgeTypeID !== UnitTypesInBridge.Missing) {
									toothClone.ToothInBridgeTypeID = this.teethDiagramFacade.convertToBridgeToothUnitType({
										unitType: tooth.UnitTypeID
									});
								}
								toothClone.UnitTypeID = newTooth.UnitTypeID;

								return toothClone;
							} else {
								return { ...tooth, BridgeIndex: 0 };
							}
						});

						this.teethManagerService.setManyAsTakenFromRxModel(teethToUpdate);
						this.teethDiagramFacade.updateTeeth({ teethToUpdate });
					}
				}),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}

	get getBridgesAll(): number[] {
		return !!this.rxForPrint ? this.rxForPrint.Teeth?.map(teeth => teeth.ToothID) : [];
	}

	protected updateToothInTeethEditor(updatedTooth: Tooth, unitType: number) {
		this.toothEditorService
			.openToothEditor({
				unitType,
				teeth: [updatedTooth],
				toothClickedOn: updatedTooth
			})
			.subscribe((result: { teeth: Tooth[]; action: ToothEditorModalActions }) => {
				if (result.action === ToothEditorModalActions.Delete) {
					this.teethManagerService.removeMany(result.teeth);
				}

				const teethToUpdate = this.toothEditorService.handleModalResult({
					result,
					toothId: updatedTooth.ToothID,
					upperJaw: this.upperJaw,
					lowerJaw: this.lowerJaw
				});

				this.teethDiagramFacade.updateTeeth({ teethToUpdate });
			});
	}
}
