import { Injectable } from '@angular/core';
import { combineLatest, from, Observable, of, switchMap } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { ApplicationModes, FeatureToggleSettings } from '@shared/models/enums/enums';
import { pendo } from '@itero/itero-pendo';
import { RxAppId } from '@shared/models/consts';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { LoggerService } from '@core/services/logger/logger.service';
import { HostPlatformService } from '@shared/services/host-platform.service';
import { FeatureToggle } from '@shared/models/feature-toggle';

@Injectable({
	providedIn: 'root'
})
export class PendoService {
	constructor(
		private logger: LoggerService,
		private shellQuery: ShellQuery
	) {
	}

	initPendo(): Observable<boolean> {
		const pendo = (window as any).pendo;
		if (pendo !== undefined && pendo.isReady()) {
			return of(true);
		}

		return this.getApplicationModeIfSuitable().pipe(
			switchMap(appMode => this.initPendoWithConditions(appMode)
			)
		);
	}

	private getLDFeatureFlagsIfScanner(appMode: ApplicationModes): Observable<FeatureToggle[]> {
		if (HostPlatformService.isScanner(appMode)) {
			return this.getLDFFs();
		}
		return this.shellQuery.featureToggles$;
	}

	private getLDFFs(): Observable<FeatureToggle[]> {
		const ffUrl = `${this.shellQuery.apiEndpoint.replace(/\/RxEditor$/i, '')}/Web/GetRawFeatureToggle`;
		return from(fetch(ffUrl, { method: 'GET' })).pipe(
			switchMap(response => {
				if (response.ok && response.url === ffUrl) {
					return response.json();
				} else {
					return this.shellQuery.featureToggles$;
				}
			}),
			catchError(() => {
				return this.shellQuery.featureToggles$;
			})
		);
	}

	private getApplicationModeIfSuitable(): Observable<ApplicationModes> {
		return this.shellQuery.applicationMode$.pipe(
			filter(appMode => !!appMode && !HostPlatformService.isOrthoCad(appMode as ApplicationModes)),
			map(appMode => appMode as ApplicationModes)
		);
	}

	private initPendoWithConditions(applicationMode: ApplicationModes): Observable<boolean> {
		const ff$ = this.getLDFeatureFlagsIfScanner(applicationMode);
		return combineLatest([this.shellQuery.companySoftwareOptions$, ff$, this.shellQuery.appSettings$]).pipe(
			map(([companySoftwareOptions, featureToggles, appSettings]) => {
				if (!appSettings.pendoApiKey) {
					return false;
				}

				const isPendoEnabled = featureToggles?.some(
					x => (x.id as FeatureToggleSettings) === FeatureToggleSettings.PendoToggle && x.isActive
				);

				if (!isPendoEnabled) {
					return false;
				}

				const isPendoEnabledForAllCompanies = featureToggles.some(
					x => (x.id as FeatureToggleSettings) === FeatureToggleSettings.PendoInitOnAppStart && x.isActive
				);

				const pendoAudienceSwos =
					appSettings.pendoAudienceSwos
						?.map(x => (companySoftwareOptions?.some(c => c === x.id) ? x.key : ''))
						?.filter(x => x);

				if (!isPendoEnabledForAllCompanies && !(pendoAudienceSwos?.length > 0)) {
					return false;
				}

				try {
					this.initPendoScript(isPendoEnabled, isPendoEnabledForAllCompanies, pendoAudienceSwos, appSettings);

					this.logger.info('Pendo service is enabled', {
						module: 'pendo-service',
						extendedParameters: {
							isPendoEnabled: isPendoEnabled.toString(),
							isPendoEnabledForAllCompanies: isPendoEnabledForAllCompanies.toString(),
							pendoAudienceSwos: pendoAudienceSwos?.join(','),
							userId: String(this.shellQuery.contactId),
							companyId: String(this.shellQuery.companyId),
							appId: RxAppId
						}
					});

					return true;
				} catch (error) {
					this.logger.error(`Pendo service error: ${JSON.stringify(error)}`, { module: 'pendo-service' });
				}

				return false;
			})
		);
	}

	private initPendoScript(isPendoEnabled: boolean, isPendoEnabledForAllCompanies: boolean, pendoAudienceSwos: string[], appSettings) {
		pendo(
			isPendoEnabled,
			isPendoEnabledForAllCompanies,
			pendoAudienceSwos,
			appSettings.pendoApiKey,
			String(this.shellQuery.contactId),
			String(this.shellQuery.companyId),
			RxAppId,
			(message: string) => this.logger.info(message, { module: 'pendo-service' })
		);
	}
}
