import { Injectable } from '@angular/core';
import { getJawByToothNumber } from '@modules/teeth-diagram/models/teeth-numbering';
import { newTooth, Tooth } from '@modules/teeth-diagram/models/tooth';
import { UnitTypesInBridge } from '@modules/teeth-diagram/models/unit-type-in-bridge.enum';
import { UnitTypes } from '@modules/teeth-diagram/models/unit-type.enum';
import { BridgeMappingService } from '@shared/services/bridge-mapping.service';

@Injectable({
	providedIn: 'root'
})
export class BridgeService {
	private static bridgeIndex = 0;

	constructor(private bridgeMappingService: BridgeMappingService) {}

	bridgeEdges: {
		[bridgeIndex: number]: {
			firstToothOnLeftToothId: number;
			lastToothOnRightToothId: number;
		};
	} = {};

	set bridgeIndex(bridgeIndex: number) {
		BridgeService.bridgeIndex = bridgeIndex;
	}

	isOuterTooth(tooth: Tooth, teethInBridge: Tooth[]): boolean {
		const first = teethInBridge[0];
		const last = teethInBridge[teethInBridge.length - 1];

		return first.ToothID === tooth.ToothID || last.ToothID === tooth.ToothID;
	}

	getBridgeIndex({ toothClickedOn, teeth }: { toothClickedOn: Tooth; teeth: Tooth[] }): number {
		if (toothClickedOn.BridgeIndex) {
			return toothClickedOn.BridgeIndex;
		}

		const maxIndex = Math.max(...teeth.map(tooth => tooth.BridgeIndex || 0));

		if (BridgeService.bridgeIndex <= maxIndex) {
			BridgeService.bridgeIndex += 1;
		}

		return BridgeService.bridgeIndex;
	}

	getAvailableBridgeSpan({ toothClickedOn, allTeethInJaw }: { toothClickedOn: Tooth; allTeethInJaw: Tooth[] }): {
		fromIndex: number;
		toIndex: number;
	} {
		const toothClickedOnIndexInJaw = allTeethInJaw.findIndex(tooth => tooth.ToothID === toothClickedOn.ToothID);
		let leftIndex = 0;
		let rightIndex = allTeethInJaw.length - 1;

		for (let i = toothClickedOnIndexInJaw; i < allTeethInJaw.length; i++) {
			if (allTeethInJaw[i].BridgeIndex && allTeethInJaw[i].BridgeIndex !== toothClickedOn.BridgeIndex) {
				rightIndex = i;
				break;
			}
		}
		for (let i = toothClickedOnIndexInJaw; i > 0; i--) {
			if (allTeethInJaw[i].BridgeIndex && allTeethInJaw[i].BridgeIndex !== toothClickedOn.BridgeIndex) {
				leftIndex = i;
				break;
			}
		}

		return {
			fromIndex: leftIndex !== 0 ? leftIndex + 1 : 0,
			toIndex: rightIndex !== allTeethInJaw.length - 1 ? rightIndex : rightIndex + 1
		};
	}

	getTeethInBridgeByBridgeIndex({ bridgeIndex, allTeethInJaw }: { bridgeIndex: number; allTeethInJaw: Tooth[] }) {
		return allTeethInJaw.filter(tooth => tooth.BridgeIndex === bridgeIndex);
	}

	convertToBridgeToothUnitType({ unitType }: { unitType: UnitTypes }): UnitTypesInBridge {
		return this.bridgeMappingService.unitTypeToBridge(unitType) ?? null;
	}

	convertFromBridgeToothUnitType({ unitTypeInBridge }: { unitTypeInBridge: UnitTypesInBridge }): UnitTypes {
		return this.bridgeMappingService.bridgeToUnitType(unitTypeInBridge);
	}

	sortBridgeTeethByToothId({ teeth }: { teeth: Tooth[] }) {
		const jaw = getJawByToothNumber({ toothId: teeth[0].ToothID });

		if (jaw === 'lower') {
			return teeth.sort((toothA, toothB) => toothB.ToothID - toothA.ToothID);
		}

		return teeth.sort((toothA, toothB) => toothA.ToothID - toothB.ToothID);
	}

	getBridgeByTooth({ tooth, upperJaw, lowerJaw }: { tooth: Tooth; upperJaw: Tooth[]; lowerJaw: Tooth[] }): Tooth[] {
		const bridgeIndex = tooth.BridgeIndex;
		const jaw = getJawByToothNumber({ toothId: tooth.ToothID });
		const allTeethInJaw = jaw === 'upper' ? upperJaw : lowerJaw;

		return allTeethInJaw.filter(toothInJaw => toothInJaw.BridgeIndex === bridgeIndex);
	}

	getAllBridges({ upperJaw, lowerJaw }: { upperJaw: Tooth[]; lowerJaw: Tooth[] }): { [bridgeindex: number]: Tooth[] } {
		const bridges: { [bridgeindex: number]: Tooth[] } = {};
		const teeth = [...upperJaw, ...lowerJaw];

		teeth.forEach(tooth => {
			if (!!tooth.BridgeIndex && tooth.BridgeIndex > 0) {
				if (!!bridges[tooth.BridgeIndex]) {
					bridges[tooth.BridgeIndex].push(tooth);
				} else {
					bridges[tooth.BridgeIndex] = [tooth];
				}
			}
		});

		return bridges;
	}

	getBridgeTeethToUpdate({
		teethToUpdate,
		teethInJaw,
		shouldConvertDeletingToregular
	}: {
		teethToUpdate: Tooth[];
		teethInJaw: Tooth[];
		shouldConvertDeletingToregular;
	}): Tooth[] {
		const bridgeIndex = teethToUpdate[0].BridgeIndex;
		const teethRemovedFromBridge = teethInJaw
			.filter(
				toothInJaw =>
					toothInJaw.BridgeIndex === bridgeIndex &&
					!teethToUpdate.some(toothToUpdate => toothToUpdate.ToothID === toothInJaw.ToothID)
			)
			.map(tooth => {
				if (shouldConvertDeletingToregular) {
					const toothId = tooth.ToothID;
					const unitTypeID = UnitTypes.Regular;

					return {
						...newTooth,
						ToothID: toothId,
						UnitTypeID: unitTypeID
					};
				}

				return {
					...tooth,
					BridgeIndex: null,
					ToothInBridgeTypeID: null,
					UnitTypeID: this.convertFromBridgeToothUnitType({ unitTypeInBridge: tooth.ToothInBridgeTypeID })
				};
			});

		return [...teethRemovedFromBridge, ...teethToUpdate];
	}

	isPonticMDAbutmentOrMissing(unitType: UnitTypesInBridge): boolean {
		return [
			UnitTypesInBridge.Pontic,
			UnitTypesInBridge.MarylandAbutment,
			UnitTypesInBridge.Missing,
			UnitTypesInBridge.Skipped
		].includes(unitType);
	}
}
