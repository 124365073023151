import { Injectable } from '@angular/core';
import { RxNote } from '@modules/notes/models/rx-note';
import { Tooth } from '@modules/teeth-diagram/models/tooth';
import { createdByNameIteroTeam } from '@shared/models/consts';
import { RoleTypeEnum } from '@shared/models/role-type';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { RxRulesService } from '@shared/services/rx-rules-helper/rx-rules.service';
import { UnitTypes } from '@modules/teeth-diagram/models/unit-type.enum';
import { TranslateService } from '@ngx-translate/core';
import { TeethNumberingSystem } from '@modules/teeth-diagram/models/teeth-numbering-system.enum';
import { UnitTypesInBridge } from '@modules/teeth-diagram/models/unit-type-in-bridge.enum';
import { getToothNumberById } from '@modules/teeth-diagram/models/teeth-numbering';
import { DateService } from '@shared/services/date.service';
import { TeethDiagramQuery } from '@modules/teeth-diagram/state/teeth-diagram-query';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class ModelingNotesService {
	constructor(
		private shellQuery: ShellQuery,
		private rxRulesService: RxRulesService,
		private translateService: TranslateService,
		private dateservice: DateService,
		private teethDiagramQuery: TeethDiagramQuery
	) {}

	public getToothUnitTypeChangeNote$(oldTooth: Tooth, newTooth: Tooth): Observable<RxNote> {
		const toothNumbering = this.getToothNumberingNotePart(oldTooth);

		return combineLatest([this.getTranslatedUnitType(oldTooth), this.getTranslatedUnitType(newTooth)]).pipe(
			map(([oldTranslatedUnitType, newTranslatedUnitType]) => {
				const customAbutment = this.getCustomAbutmentNotePart(oldTooth);

				const materialName = this.getMaterialNotePart(oldTooth);

				const prepDesign = this.getPrepDesignNotePart(oldTooth);

				const marginDesign = this.getMarginDesignNotePart(oldTooth);

				const shadeSystem = this.getShadeSystemNotePart(oldTooth);

				const stumpfShade = this.getStumpfShadeNotePart(oldTooth, newTooth);

				const noteTemplate = `Changed tooth ${toothNumbering} to ${newTranslatedUnitType}\nFrom: ${oldTranslatedUnitType}${customAbutment} ${materialName}${prepDesign}${marginDesign}${shadeSystem}${stumpfShade}`;

				return {
					dateCreated: this.dateservice.currentDate.toISOString(),
					content: noteTemplate,
					role: RoleTypeEnum.Technician,
					createdByName: createdByNameIteroTeam,
					createdById: this.shellQuery.rx.ContactID,
					isPreDefinedNote: true
				};
			})
		);
	}

	private getStumpfShadeNotePart(oldTooth: Tooth, newTooth: Tooth): string {
		return oldTooth.StumpfShade ? `, Stumpf Shade = ${newTooth.StumpfShade ?? ''}` : '';
	}

	private getShadeSystemNotePart(oldTooth: Tooth): string {
		return oldTooth.ShadeIncisal || oldTooth.ShadeGingival || oldTooth.ShadeBody
			? `, Shade = ${oldTooth.ShadeIncisal ?? ''} / ${oldTooth.ShadeBody ?? ''} / ${oldTooth.ShadeGingival ?? ''}`
			: '';
	}

	private getMarginDesignNotePart(oldTooth: Tooth): string {
		return oldTooth.MarginDesignBuccalId
			? `, MarginD = ${oldTooth.MarginDesignBuccal ?? ''} / ${oldTooth.MarginDesignLingual ?? ''}`
			: '';
	}

	private getPrepDesignNotePart(oldTooth: Tooth): string {
		return oldTooth.PreparationDesignBuccalId || oldTooth.PreparationDesignLingualId
			? `, PrepD = ${oldTooth.PreparationDesignBuccal ?? ''} / ${oldTooth.PreparationDesignLingual ?? ''}`
			: '';
	}

	private getMaterialNotePart(oldTooth: Tooth): string {
		const material = this.rxRulesService.getMaterial(oldTooth.MaterialID);
		return oldTooth.MaterialID ? `, Material = ${material.Name ?? ''}` : '';
	}

	private getCustomAbutmentNotePart(oldTooth: Tooth): string {
		return oldTooth.AbutmentType ? `, CustomAbutment = ${oldTooth.CAMaterialID ?? ''} / ${oldTooth.CAMarginStyle ?? ''}` : '';
	}

	private getToothNumberingNotePart(tooth: Tooth): string {
		const teethNumberingSystem = this.teethDiagramQuery.teethNumberingSystem;
		return `${TeethNumberingSystem[teethNumberingSystem] ?? ''} ${getToothNumberById({
			toothId: tooth.ToothID,
			teethNumberingSystem
		})}`;
	}

	private getTranslatedUnitType(tooth: Tooth): Observable<string | any> {
		return tooth.UnitTypeID
			? this.translateService.get(`UnitTypes.${UnitTypes[tooth.UnitTypeID]}`)
			: this.translateService.get(`BridgeUnitTypes.${UnitTypesInBridge[tooth.ToothInBridgeTypeID]}`);
	}
}
