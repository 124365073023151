import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { AuthInfoStore } from '@shared/store/authInfo/auth-info-store';
import { AuthInfoQuery } from '@shared/store/authInfo/auth-info-query';
import { catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import apiMap from '@core/maps/apiMap';
import { HostDispatcher } from './host-dispatcher';
import { SKIP_JWT_ADDING } from '@core/interceptors/auth.interceptor';

@Injectable({
	providedIn: 'root'
})
export class AuthInfoService {
	private appId = 'rx-app';
	constructor(
		private http: HttpClient,
		private authInfoStore: AuthInfoStore,
		private authInfoQuery: AuthInfoQuery,
		private hostDispatcher: HostDispatcher
	) {}

	get isJWTSupported(): boolean {
		return !!this.authInfoQuery.jwt;
	}

	refreshAuthInfo() {
		const authInfo = this.authInfoQuery.authInfo;
		const headers = { Authorization: `Bearer ${authInfo.accessToken}` };
		const baseUrl = new URL(authInfo.authUrl);
		const apiURL = `${baseUrl.origin}/${apiMap.refreshToken.path}?sessionId=${authInfo.sessionId}&appId=${this.appId}`;
		const context = new HttpContext().set(SKIP_JWT_ADDING, true);

		return this.http
			.get<{
				sessionId: string;
				accessToken: string;
			}>(apiURL, { headers, context })
			.pipe(
				tap(({ sessionId, accessToken }) => {
					this.authInfoStore.update({ sessionId, accessToken });
				}),
				catchError((error: HttpErrorResponse) => {
					if (error.status === HttpStatusCode.Unauthorized) {
						this.backToLogin();
					}

					return throwError(() => error);
				})
			);
	}

	backToLogin() {
		this.hostDispatcher.dispatchExpiredTokenEvent(this.appId);
	}
}
