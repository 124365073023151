import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class TranslationLoaderService implements TranslateLoader {
	private readonly path = './assets/translations';
	private readonly postfix = '.json';

	constructor(private httpClient: HttpClient, private shellQuery: ShellQuery) {}

	getTranslation(lang: string): Observable<any> {
		return this.shellQuery.staticFilesEndpoint$.pipe(
			switchMap(endpoint => {
				return this.httpClient.get(`${endpoint}/${this.path}/${lang}${this.postfix}`);
			})
		)
	}
}
