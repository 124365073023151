<mat-form-field class="moulds-dropdown" [class.read-only]="isReadOnly">
    <mat-label [class.read-only-label]="isReadOnly && !control.value">{{ label }}:</mat-label>
    <mat-select
        disableOptionCentering
        [id]="'rx-moulds-select-' + id"
        [formControl]="control"
        name="option"
        class="moulds-dropdown__wrapper"
        panelClass="moulds-dropdown__panel"
        [compareWith]="compareWith"
    >
        <div class="moulds-dropdown__flex-container">
            <mat-option
                    *ngFor="let mould of options; let index = index;"
                    [class]="'rx-auto-simple-select-option-' + index"
                    class="moulds-dropdown__option"
                    [value]="mould"
            >
                <div class="moulds-dropdown__image-wrapper">
                    <rx-mould-element [mould]="mould" [isSelected]="mould.Id === (selectedMouldId$ | async)"></rx-mould-element>
                </div>
            </mat-option>
        </div>

        <footer *ngIf="isCancellable">
            <button
                    [id]="id + '-cancel-selection'"
                    [disabled]="isCancelButtonDisabled"
                    mat-raised-button
                    class="gradient"
                    (click)="handleCancelSelection($event)"
            >{{ 'Selector.ClearSelection' | translate }}</button>
        </footer>
    </mat-select>
</mat-form-field>
