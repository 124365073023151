import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface RxForDoctorState {
	isReferralWorkflowPractice: boolean;
}

export const createInitialState = (): RxForDoctorState => ({
	isReferralWorkflowPractice: false
});

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'rx-for-doctor' })
export class RxForDoctorStore extends Store<RxForDoctorState> {
	constructor() {
		super(createInitialState());
	}
}
