import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import * as flex from '@shared/utils/flex-utils';
import { PatientModelDto } from '@shared/models/rx-models/interfaces/patient-model-dto';

@Component({
	selector: 'rx-patient-info-v1',
	templateUrl: './patient-info-v1.component.html',
	styleUrls: ['./patient-info-v1.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PatientInfoV1Component {
	@Input() patient: PatientModelDto;
	@Input() isResponsive = true;

    getPatientName = (p: PatientModelDto) => (p ? `${p?.LastName}, ${p?.FirstName}` : '');

	getResponsiveDirection = flex.getResponsiveDirection;
	getResponsiveWidth = flex.getResponsiveWidth;
}
