import { Inject, Injectable, InjectionToken } from '@angular/core';
import { CrownData } from '@modules/tooth-editor/models/tooth-editor';
import {
	notIndexesOfTooth,
	ToothManagedItem,
	ToothPropertiesState,
	ToothPropertyStateEnum
} from '@modules/tooth-editor/state/teeth-manager-store';
import { getNewTooth, Tooth } from '@modules/teeth-diagram/models/tooth';
import { ToothAndSharedProps } from '@modules/tooth-editor/services/tooth-editor-sharing.service';

export type CrownDataToToothMapType = Record<keyof CrownData, (keyof Partial<ToothPropertiesState>)[]>;
export const FROM_CROWN_DATA_TO_TOOTH_MAP = new InjectionToken<CrownDataToToothMapType>('map_for_crown_data_controls_and_tooth', {
	providedIn: 'root',
	factory: () => ({
		material: ['MaterialID'],
		preparationDesignBuccal: ['PreparationDesignBuccalId', 'PreparationDesignBuccal'],
		preparationDesignLingual: ['PreparationDesignLingualId', 'PreparationDesignLingual'],
		marginDesignBuccal: ['MarginDesignBuccalId', 'MarginDesignBuccal'],
		marginDesignLingual: ['MarginDesignLingualId', 'MarginDesignLingual'],
		shadeSystem: ['ShadeSystemId'],
		incisal: ['ShadeIncisal'],
		body: ['ShadeBody'],
		gingival: ['ShadeGingival'],
		stumpShade: ['StumpfShade'],
		specification: ['SpecificationId'],
		ponticDesign: ['PonticDesignID']
	})
});

export const allProperties = Object.keys(getNewTooth()).filter(k => notIndexesOfTooth(k as keyof Tooth)) as ReadonlyArray<
	keyof ToothPropertiesState
>;

@Injectable({ providedIn: 'root' })
export class TeethManagerAdapter {
	constructor(@Inject(FROM_CROWN_DATA_TO_TOOTH_MAP) private fromCrownDataToTooth: CrownDataToToothMapType) {}
	adaptCrownData(controls: (keyof CrownData)[], targetPropertyState: ToothPropertyStateEnum) {
		const toothPropertiesState: ToothPropertiesState = {};

		controls.forEach(control => {
			const props = this.fromCrownDataToTooth[control] ?? [];

			props.forEach(prop => (toothPropertiesState[prop] = targetPropertyState));
		});

		return toothPropertiesState;
	}

	adaptTeethAndSharedProps(teethAndSharedProps: ToothAndSharedProps[], targetPropertyState: ToothPropertyStateEnum) {
		return teethAndSharedProps.map(({ tooth, sharedProps }) => {
			const toothManagedItem: ToothManagedItem = {
				ToothID: tooth.ToothID,
				BridgeIndex: tooth.BridgeIndex
			};

			sharedProps.forEach(property => {
				if (notIndexesOfTooth(property)) {
					toothManagedItem[property] = targetPropertyState;
				}
			});

			return toothManagedItem;
		});
	}

	adaptTeeth(teeth: Tooth[], targetPropertyState: ToothPropertyStateEnum) {
		return teeth.map(tooth => {
			const toothManagedItem: ToothManagedItem = {
				ToothID: tooth.ToothID,
				BridgeIndex: tooth.BridgeIndex
			};

			allProperties.forEach(property => {
				// TODO: add smarter checking
				if (tooth[property] !== null && tooth[property] !== undefined) {
					toothManagedItem[property] = targetPropertyState;
				}
			});

			return toothManagedItem;
		});
	}
}
