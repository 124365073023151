/* eslint-disable @typescript-eslint/no-unsafe-call */
import { Injectable } from '@angular/core';
import { BaseDestroyableComponent } from '@shared/base-classes/base-destroyable';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { takeUntil, tap } from 'rxjs/operators';
import { HostPlatformService } from '@shared/services/host-platform.service';

@Injectable({
	providedIn: 'root'
})
export class CacheAssetsService extends BaseDestroyableComponent {
	staticFilesEndpoint = '';
	isScannerMode: boolean;
	cacheForTeethAssetsLoaded = false;

	constructor(private shellQuery: ShellQuery, private hostPlatformService: HostPlatformService) {
		super();
		this.subscribeToIsScannerMode();
		this.subscribeToStaticFilesEndpointUrlChanges();
	}

	loadAssetsForCache() {
		if (this.isScannerMode) {
			return this.fetchNgswJson().then(assetsToCache => {
				assetsToCache.forEach(assetUrl => {
					fetch(assetUrl);
				});
			});
		}
		return Promise.resolve();
	}

	loadTeethAssetsForCache() {
		if (!this.isScannerMode && !this.cacheForTeethAssetsLoaded && !this.shellQuery.isHeadlessPrint) {
			return this.fetchNgswJson().then(assetsToCache => {
				this.cacheForTeethAssetsLoaded = true;
				assetsToCache.forEach(assetUrl => {
					if (assetUrl.includes('teeth')) {
						fetch(assetUrl, { mode: 'no-cors' });
					}
				});
			});
		}
		return Promise.resolve();
	}

	private getAdditionalAssetsToCache() {
		return ['https://fonts.gstatic.com/s/materialicons/v88/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2'];
	}

	private subscribeToIsScannerMode() {
		this.hostPlatformService.isScanner$
			.pipe(
				tap(isScanner => {
					this.isScannerMode = isScanner;
				}),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}

	private subscribeToStaticFilesEndpointUrlChanges(): void {
		this.shellQuery.staticFilesEndpoint$
			.pipe(
				tap(staticFilesEndpoint => {
					this.staticFilesEndpoint = staticFilesEndpoint;
				}),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}

	private fetchNgswJson(): Promise<any> {
		return fetch(`${this.staticFilesEndpoint}/ngsw.json`)
			.then(res => {
				return res.json();
			})
			.then(assetListJson => {
				const { urls } = assetListJson.assetGroups[1];

				const assetsFromServiceWorkerConfig = urls.map(url => {
					const assetPath: string = url.substring(1);
					return `${this.staticFilesEndpoint}${assetPath}`;
				});

				return [...assetsFromServiceWorkerConfig, ...this.getAdditionalAssetsToCache()];
			});
	}
}
