<link
  href="https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap"
  rel="stylesheet"
/>

<div class="app-hidden-version">RX-App - {{ currentApplicationVersion }}</div>
<div *ngIf="!isHeadlessPrint" class="main-layout" [class.scanner-mode] = "isHostPlatformScanner$ | async">
  <rx-spinner></rx-spinner>
	<ng-container [ngSwitch]="userRole$ | async">
		<rx-for-lab *ngSwitchCase="RoleTypeEnum.Lab" />
		<app-rx-for-modeling *ngSwitchCase="RoleTypeEnum.Technician" />
		<rx-for-doctor *ngSwitchDefault />
	</ng-container>
</div>
<div class="print-rx-hidden">
    <ng-container #printRxOutlet></ng-container>
</div>