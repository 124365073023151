import { SpecificationEnum } from '@core/procedure-helpers/models/specification.enum';
import { UnitTypes } from './unit-type.enum';
import { PonticDesignEnum } from '@shared/models/pontic-design.enum';

export interface Tooth {
	ToothID: number; // follows ADA numbering always. displayed tooth ids differ according to rx settings
	// not nullable on backend.
	UnitTypeID?: UnitTypes;
	// not nullable on backend.
	MaterialID?: number;
	ImplantTypeID?: number;
	ShadeSystemId?: number;
	ShadeIncisal?: string;
	ShadeBody?: string;
	ShadeGingival?: string;
	PreparationDesignBuccal?: string;
	PreparationDesignBuccalId?: string;
	PreparationDesignLingual?: string;
	PreparationDesignLingualId?: string;
	MarginDesignBuccal?: string;
	MarginDesignBuccalId?: string;
	MarginDesignLingual?: string;
	MarginDesignLingualId?: string;
	StumpfShade?: string;
	Analog?: number;
	CAMarginStyle?: string;
	CAMarginStyleId?: string;
	CAMaterialID?: number;
	AbutmentType?: number; // can we use Enumb here?
	AbutmentMarginStyle?: string;
	AbutmentMarginStyleId?: string;
	AbutmentMaterialId?: number;
	// not nullable on backend.
	SpecificationId?: number;
	// not nullable on backend.
	PonticDesignID?: PonticDesignEnum;
	// not nullable on backend.
	ImplantBasedRestorationTypeId?: number;

	// //For bridges
	BridgeIndex?: number;
	InternalToothInBridgeTypeID?: number;
	ToothInBridgeTypeID?: number;
	IsValidForSend?: boolean;
}

/**
 * @deprecated Use getNewTooth to get new instance.
 */
export const newTooth: Tooth = {
	UnitTypeID: null,
	ToothID: null,
	MaterialID: null,
	SpecificationId: SpecificationEnum.None,
	ImplantBasedRestorationTypeId: null,
	PonticDesignID: null,
	ImplantTypeID: null,
	ShadeSystemId: null,
	ShadeIncisal: null,
	ShadeBody: null,
	ShadeGingival: null,
	PreparationDesignBuccal: null,
	PreparationDesignBuccalId: null,
	PreparationDesignLingual: null,
	PreparationDesignLingualId: null,
	MarginDesignBuccal: null,
	MarginDesignBuccalId: null,
	MarginDesignLingual: null,
	MarginDesignLingualId: null,
	StumpfShade: null,
	Analog: null,
	CAMarginStyle: null,
	CAMarginStyleId: null,
	CAMaterialID: null,
	AbutmentType: null,
	AbutmentMarginStyle: null,
	AbutmentMarginStyleId: null,
	AbutmentMaterialId: null,
	BridgeIndex: null,
	InternalToothInBridgeTypeID: null,
	ToothInBridgeTypeID: null,
	IsValidForSend: false
};

export const getNewTooth = (toothValues?: Partial<Tooth>): Tooth => {
	return { ...newTooth, ...toothValues };
};
