<img
  (click)="handleToothClick($event)"
  class="tooth-image"
  [class.no-action]="isNotActionTooth"
  [id]="'tooth_' + tooth?.ToothID"
  [src]="toothImage"
  (load)="imageLoaded({ toothId: tooth?.ToothID })"
  (error)="imageLoaded({ toothId: tooth?.ToothID })"
  [alt]="tooth?.ToothID"
/>
