import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { IdName } from '@shared/models/id-name';

export interface OrderInformationState {
	localIdeCadCamSystemId: number;
	ideCadCamSystemId: number;
	ideProductionCenterId?: number;
	idxProductionCenterId?: number;
	dieDitch: boolean;
	additionalDies: number;
	numOfModels: number;
	integrationCompany?: number;
	cadCamSystemOptions?: IdName[];
	canOrderPhysicalModel?: boolean;
	invalidForSend?: boolean;
}

export const createInitialState = (): OrderInformationState => ({
	localIdeCadCamSystemId: 0,
	ideCadCamSystemId: 0,
	ideProductionCenterId: 0,
	idxProductionCenterId: 0,
	dieDitch: false,
	additionalDies: 0,
	numOfModels: 1,
	integrationCompany: 0,
	cadCamSystemOptions: null,
	canOrderPhysicalModel: false
});

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'order-information' })
export class OrderInformationStore extends Store<OrderInformationState> {
	constructor() {
		super(createInitialState());
	}

	updateOrderInformation({ orderInformation }: { orderInformation: OrderInformationState }) {
		this.update({ ...orderInformation });
	}

	updateInvalidForSend(invalidForSend: boolean) {
		this.update({ invalidForSend });
	}
}
