<mat-grid-list cols="16" [rowHeight]="rowHeight" *ngIf="!isPrintContext">
    <mat-grid-tile class="tooth-number" *ngFor="let toothNumber of teethNumbers[teethNumberingSystem]?.upperJaw; index as i"
        ><span [class.selected-bridge]="toothNumber | includes: toothBridges">{{ toothNumber }}</span></mat-grid-tile
    >
    <hr class="tooth-numbers-separator" />
    <mat-grid-tile class="tooth-number" *ngFor="let toothNumber of teethNumbers[teethNumberingSystem]?.lowerJaw; index as i"
        ><span [class.selected-bridge]="toothNumber | includes: toothBridges">{{ toothNumber }}</span></mat-grid-tile
    >
</mat-grid-list>
<!-- for print -->
<div *ngIf="isPrintContext">
    <div class="teeth-numbers-row">
        <div class="tooth-number-container" *ngFor="let toothNumber of teethNumbers[teethNumberingSystem]?.upperJaw; index as i">
            <div
                [class.selected-bridge]="
                    convertToothNumberToDefaultSystemById({ toothId: toothNumber, teethNumberingSystem: teethNumberingSystem })
                        | includes: toothBridges
                "
            >
                {{ toothNumber }}
            </div>
        </div>
    </div>
    <hr class="tooth-numbers-separator" />
    <div class="teeth-numbers-row">
        <div class="tooth-number-container" *ngFor="let toothNumber of teethNumbers[teethNumberingSystem]?.lowerJaw; index as i">
            <div
                [class.selected-bridge]="
                    convertToothNumberToDefaultSystemById({ toothId: toothNumber, teethNumberingSystem: teethNumberingSystem })
                        | includes: toothBridges
                "
            >
                {{ toothNumber }}
            </div>
        </div>
    </div>
</div>
