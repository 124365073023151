export interface ScannerTypes {
	element: string;
	evx: string;
	ng?: string;
	ngPro?: string;
}

export enum scannerVersionPrefixByType {
	element = '1',
	evx = '2',
	ng = '3',
	ngPro = '4'
}

export interface ScannerTypesByVersioningType {
	clientVersion: ScannerTypes;
	packageVersion: ScannerTypes;
	anyVersionFrom?: string;
}
