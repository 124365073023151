import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { isObservable, Observable } from 'rxjs';
import { ObjectUtils } from '@shared/utils/object-utils/object-utils';

export const TRANSLATE_ON_DEMAND_PREFIX = '@translate@';
/*
 *
 * The pipe was created basing on this source code
 * https://github.com/ngx-translate/core/blob/v13.0.0/projects/ngx-translate/core/src/lib/translate.pipe.ts
 *
 * In case of updating ngx-translate/core package make sure the public api of TranslatePipe doesn't have breaking changes.
 * */

/*
 *
 * If some issue occurs with the pipe, please check TR-34427
 * There were some issues with the pipe and with original translate pipe in the past which caused by translate service behaviour
 *
 * */
@Pipe({
    name: 'translateOnDemand',
    pure: false // required to update the value when the promise is resolved
    ,
    standalone: true
})
export class TranslateOnDemandPipe extends TranslatePipe implements PipeTransform {
	translateService: TranslateService;
	changeDetectorRef: ChangeDetectorRef;
	transparentValue = '';

	constructor(translate: TranslateService, ref: ChangeDetectorRef) {
		super(translate, ref);
		this.translateService = translate;
		this.changeDetectorRef = ref;
	}

	transparentMode(query: string): boolean {
		return !query.startsWith(TRANSLATE_ON_DEMAND_PREFIX);
	}

	removePrefix(query: string): string {
		return query.replace(TRANSLATE_ON_DEMAND_PREFIX, '');
	}

	transform(query: string, ...args: any[]): any {
		if (!query || !query.length) {
			return query;
		}

		if (ObjectUtils.equals(query, this.lastKey) && ObjectUtils.equals(args, this.lastParams)) {
			return this.transparentValue?.length ? this.transparentValue : this.value;
		}

		if (this.transparentMode(query)) {
			this.transparentValue = query;
			this.value = '';
			this.lastKey = query;
			this.lastParams = args;

			// we need to unsubscribe when language was changed on previous tick
			this.dispose();

			return this.transparentValue;
		}

		this.transparentValue = '';

		return super.transform(query, ...args);
	}

	updateValue(key: string, interpolateParams?: object, translations?: any) {
		const keyWithoutPrefix = this.removePrefix(key);
		const onTranslation = (res: string) => {
			this.value = res !== undefined ? res : key;
			this.lastKey = key;
			this.changeDetectorRef.markForCheck();
		};

		if (translations) {
			const res: Observable<string> | string = this.translateService.getParsedResult(
				translations,
				keyWithoutPrefix,
				interpolateParams
			);

			if (isObservable(res)) {
				res.subscribe(onTranslation);
			} else {
				onTranslation(res);
			}
		}
		this.translateService.get(keyWithoutPrefix, interpolateParams).subscribe(onTranslation);
	}

	dispose() {
		if (typeof this.onTranslationChange !== 'undefined') {
			this.onTranslationChange.unsubscribe();
			this.onTranslationChange = undefined;
		}
		if (typeof this.onLangChange !== 'undefined') {
			this.onLangChange.unsubscribe();
			this.onLangChange = undefined;
		}
		if (typeof this.onDefaultLangChange !== 'undefined') {
			this.onDefaultLangChange.unsubscribe();
			this.onDefaultLangChange = undefined;
		}
	}
}
