import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { AlignTechNotesComponent } from './containers/aligntech-notes/aligntech-notes.component';

@NgModule({
	declarations: [AlignTechNotesComponent],
	imports: [CommonModule, SharedModule],
	exports: [AlignTechNotesComponent]
})
export class AlignTechNotesModule {}
