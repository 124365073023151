export enum SendToTypeEnum {
	empty = 0,
	orthoLab = 1,
	restoLab = 2,
	chairsideGlidewell = 3,
	chairsideExocad = 4,
	midcOnly = 5,
	realGUIDE = 6,
	sprintRay = 7
}

// PAY ATTENTION!!! THESE IDS ARE NOT SENT TO BACKEND (THEY ARE USED ONLY FOR RECOGNIZING "SENDTO" UI OPTIONS)
export enum SendToIdForNotLabsEnum {
	glidewell = -999,
	exocad = -1000,
	midcOnly = -1001,
	realGUIDE = -1002,
	sprintRay = -1003
}
