import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PatientComponent } from './containers/patient/patient.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { PatientAppInlineComponent } from './components/patient-app-inline/patient-app-inline.component';
import { GenderPipe } from './pipes/gender.pipe';
import { PatientReadOnlyComponent } from './components/patient-read-only/patient-read-only.component';
import { PatientAppPopUpComponent } from './components/patient-app-popup/patient-app-popup.component';
import { DateOfBirthPipe } from '@shared/pipes/date-of-birth.pipe';

@NgModule({
    declarations: [
        PatientComponent,
        PatientAppInlineComponent,
        GenderPipe,
        PatientReadOnlyComponent,
        PatientAppPopUpComponent,
        DateOfBirthPipe
    ],
    imports: [
        CommonModule,
        SharedModule
    ],
    exports: [
        PatientComponent
    ]
})
export class PatientModule { }
