import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseDestroyableComponent } from '@shared/base-classes/base-destroyable';
import { RxModel } from '@shared/models/rx-models/interfaces/rx-model';

@Component({
	selector: 'rx-teeth-diagram',
	template: `
		<ng-container [ngSwitch]="isProcedureFlow">
			<rx-teeth-diagram-v0
				(imagesLoaded)="imagesLoaded.emit($event)"
				*ngSwitchCase="false"
				[rxForPrint]="rxForPrint"
				[isRxForModeling]="isRxForModeling"
			></rx-teeth-diagram-v0>
			<rx-teeth-diagram-v1
				(imagesLoaded)="imagesLoaded.emit($event)"
				*ngSwitchCase="true"
				[rxForPrint]="rxForPrint"
				[isRxForModeling]="isRxForModeling"
			></rx-teeth-diagram-v1>
		</ng-container>
	`
})
export class TeethDiagramComponent extends BaseDestroyableComponent {
	@Input() rxForPrint: RxModel;
	@Input() isRxForModeling: boolean;
	@Input() isProcedureFlow: boolean;

	@Output() imagesLoaded = new EventEmitter<void>();

	constructor() {
		super();
	}
}
