import { ErrorStateMatcher } from '@angular/material/core';
import { FormGroupDirective, NgForm, UntypedFormControl } from '@angular/forms';

export class ErrorStateMatcherForInstantDisplay implements ErrorStateMatcher {
	isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
		if (form.form.controls.body === control) {
			return !!(control && control.invalid && control.touched);
		}

		return !!(control && control.invalid);
	}
}
