<div fxLayout="column">
    <div fxLayout="row" [fxLayout.lt-sm]="isResponsive | mapper : getResponsiveDirection">
        <rx-info-block
            fxFlex="40"
            [fxFlex.lt-md]="isResponsive | mapper : getResponsiveWidth"
            class="info-box"
            [fields]="[
            {
                name: 'GeneralRxData.Procedure',
                id: 'general-procedure',
                value: rx?.Order?.ProcedureId | mapper: getProcedureDescription: rxConfig?.Procedures,
                isInline: true
            }
        ]"
        ></rx-info-block>
        <div [ngClass.lt-sm]="{'horizontal visible' : isResponsive}" class="rx-separator vertical transparent"></div>
        <rx-info-block
            fxFlex
            class="info-box"
            [fields]="[
            {
                name: 'GeneralRxData.ProcedureType',
                id: 'general-procedure-type',
                value: rx?.Order?.ProcedureTypeId | mapper: getTypeDescription: rxConfig?.Types,
                isInline: true
            }
        ]"
        ></rx-info-block>
    </div>
    <div class="rx-separator horizontal"></div>
    <div fxLayout="row" [fxLayout.lt-md]="isResponsive | mapper : getResponsiveDirection">
        <rx-info-block
            fxLayout="column"
            class="info-box"
            fxFlex="40"
            [fields]="isRxForModelingContext | mapper : getFields : rx"
        ></rx-info-block>
        <div [ngClass.lt-md]="{'horizontal' : isResponsive}" class="rx-separator vertical"></div>
        <div fxLayout="row" [fxLayout.lt-sm]="isResponsive | mapper : getResponsiveDirection" fxFlex>
            <rx-info-block
                fxLayout="column"
                class="info-box"
                fxFlex
                [fields]="[
            { name: 'GeneralRxData.ScanDate', id: 'general-scan-date', value: rx?.Order?.ScanDate | date: dateFormat },
            { name: 'GeneralRxData.DueDate', id: 'general-due-date', value: rx?.Order?.DueDate | date: dateFormat :'UTC' },
            { name: 'GeneralRxData.Status', id: 'general-status', value: rx?.Order?.State }
        ]"
            ></rx-info-block>
            <div [ngClass.lt-sm]="{'horizontal' : isResponsive}" class="rx-separator vertical"></div>
            <rx-info-block
                fxLayout="column"
                class="info-box"
                fxFlex
                [fields]="[
            { name: 'GeneralRxData.Signature', id: 'general-signature', value: rx?.Signature, isSignature: true },
            { name: 'GeneralRxData.License', id: 'general-license', value: rx?.Doctor?.Licence }
        ]"
            ></rx-info-block>
        </div>
    </div>
</div>
