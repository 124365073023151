import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export interface DialogButton {
	matDialogClose: any;
	term: string;
	confirm?: boolean;
}

@Component({
	selector: 'rx-awareness-popup-layout',
	templateUrl: './awareness-popup-layout.component.html',
	styleUrls: ['./awareness-popup-layout.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AwarenessPopupLayoutComponent {
	@Input()
	buttons: DialogButton[];
}
