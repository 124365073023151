export enum AwarenessPopUpActions {
	RemindLater = 0,
	Confirm = 1
}

export enum Awareness {
	BeforeLMR = 1,
	AfterLMR = 2,
	BeforeGA = 3,
	AfterGA = 4
}
