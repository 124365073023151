import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';
import { RxForDoctorState, RxForDoctorStore } from './rx-for-doctor-store';

@Injectable({ providedIn: 'root' })
export class RxForDoctorQuery extends Query<RxForDoctorState> {
	constructor(store: RxForDoctorStore) {
		super(store);
	}
	isReferralWorkflowPractice$: Observable<boolean> = this.select(state => state.isReferralWorkflowPractice);
	get isReferralWorkflowPractice(): boolean { return this.getValue().isReferralWorkflowPractice; };
}
