
<div class="teeth-section">
  <div *ngFor="let tooth of teeth" class="tooth-container" [class.selected]="tooth.ToothID === toothClickedOn.ToothID">
      <rx-tooth [isReadOnly]="isReadOnly" 
                [isMiniDiagramBridgeSelectionEnabled]="enableToothSelectionInReadOnlyBridge" 
                [usedFromMiniDiagram]="true" class="rx-tooth" 
                [tooth]="tooth"
                (toothClicked)="handleToothClick($event)"
                [isGlidewellOrder]="isGlidewellOrder"
      ></rx-tooth>
      <div class="tooth-number">
        {{
      getToothNumberById({
        toothId: tooth.ToothID,
        teethNumberingSystem: teethNumberingSystem
      })
    }}
      </div>
    </div>
  </div>