import { IdName } from '@shared/models/id-name';
import { CompanyRxConfig } from '@shared/models/company-rx-config';
import { RxRules } from '@shared/models/rx-rules';
import { FeatureToggle } from '@shared/models/feature-toggle';
import { RxVersion } from '@shared/models/enums/enums';
import { ApplicationConfiguration } from '@shared/models/application-configuration';
import { LoggerService } from '@core/services/logger/logger.service';
import { LinkedCompanies } from '@shared/models/linked-companies';
import { RxConfiguration } from '@shared/models/rx-configuration';
import { DoctorsRxConfiguration } from '@shared/models/doctors-rx-configuration';

export interface RxConfigurationProblems {
	EmptyProperties: string[];
	EmptyNameProperties: EmptyNamesProperty[];
	InvalidDataProcedureMaps: number[];
}

export interface EmptyNamesProperty {
	Name: ConfigurationProperty;
	Ids: any[];
}

type ConfigurationProperty = keyof RxConfiguration | keyof LinkedCompanies | keyof CompanyRxConfig | 'FeatureToggles';

export class ConfigurationAnalyzer {
	private static readonly companyConfigurationName = 'CompanyConfiguration';
	private static readonly rxRulesName = 'RxRules';
	private static readonly doctors = 'Doctors';
	private static readonly module = 'ConfigurationAnalyzer';

	public static analyzeAppConfiguration(
		appConfiguration: ApplicationConfiguration,
		logger: LoggerService,
		currentUserId: number,
		isLab: boolean,
		isOrthocad: boolean = false,
		traceIds: string = null,
		isPrintRx: boolean = false
	): RxConfigurationProblems {
		const configurationProblems = {
			EmptyNameProperties: [],
			EmptyProperties: [],
			InvalidDataProcedureMaps: []
		} as RxConfigurationProblems;

		try {
			if (isLab || isOrthocad || isPrintRx) {
				this.analyzeNotDoctor(appConfiguration, configurationProblems);
			} else {
				this.analyzeDoctor(appConfiguration, configurationProblems, currentUserId);
			}

			this.excludeProperties(configurationProblems, 'DirectToLabs', 'DentalLabs', 'OrthoLabs');

			let extendedParameters: { [key: string]: string } = null;

			if (configurationProblems.EmptyProperties.length > 0) {
				extendedParameters = { EmptyProperties: configurationProblems.EmptyProperties.join(', ') };
			}

			if (configurationProblems.InvalidDataProcedureMaps.length > 0) {
				extendedParameters = {
					...extendedParameters,
					InvalidDataProcedureMaps: configurationProblems.InvalidDataProcedureMaps.join(', ')
				};
			}

			if (configurationProblems.EmptyNameProperties.length > 0) {
				const emptyNames = configurationProblems.EmptyNameProperties.map(x => `${x.Name}: ${x.Ids.join(', ')}`).join('; ');

				extendedParameters = {
					...extendedParameters,
					EmptyNameProperties: emptyNames
				};
			}

			if (extendedParameters != null) {
				extendedParameters = { ...extendedParameters, configurationTraceIds: traceIds };
				logger.error('Invalid configuration', {
					module: this.module,
					extendedParameters
				});
			}
		} catch (e) {
			logger.error('Error while analyze configuration', { module: this.module, error: e });
		}

		return configurationProblems;
	}

	private static analyzeNotDoctor(appConfiguration: ApplicationConfiguration, configurationProblems: RxConfigurationProblems) {
		this.analyzeRxConfiguration(appConfiguration.rxConfiguration, configurationProblems);
		this.excludeProperties(configurationProblems, 'Doctors', 'Scanners');
	}

	private static analyzeDoctor(
		appConfiguration: ApplicationConfiguration,
		configurationProblems: RxConfigurationProblems,
		currentUserId?: number
	) {
		this.analyzeRxConfiguration(appConfiguration.rxConfiguration, configurationProblems);
		this.analyzeFeatureToggles(appConfiguration.featureToggles, configurationProblems);
		this.analyzeAvailableProcedureMapsAndCaseTypes(appConfiguration, configurationProblems);
		this.analyzeDoctors(appConfiguration.rxConfiguration.Doctors, configurationProblems, currentUserId);
	}

	// eslint-disable-next-line @typescript-eslint/ban-types
	private static analyzeRxConfiguration(configuration: object, configurationProblems: RxConfigurationProblems): RxConfigurationProblems {
		Object.keys(configuration).forEach((property: ConfigurationProperty) => {
			if (!configuration.hasOwnProperty(property)) {
				return;
			}

			const propertyValue = configuration[property];

			this.analyzeConfigurationProperty(propertyValue, property, configurationProblems);
		});

		return configurationProblems;
	}

	// eslint-disable-next-line @typescript-eslint/ban-types
	private static analyzeConfigurationProperty(
		propertyValue: object,
		property: ConfigurationProperty,
		configurationProblems: RxConfigurationProblems
	) {
		if (property === this.companyConfigurationName) {
			this.analyzeCompanyConfiguration(propertyValue, property, configurationProblems);

			return;
		}
		if (property === this.rxRulesName) {
			this.analyzeRxRules(propertyValue, property, configurationProblems);

			return;
		}
		if (property === this.doctors) {
			return;
		}

		if (Array.isArray(propertyValue)) {
			if (this.checkIfArrayIsEmpty(propertyValue, configurationProblems, property)) {
				return;
			}

			const emptyNames = propertyValue.filter((x: IdName) => x.Id > 0 && !x.Name);

			if (emptyNames.length > 0) {
				configurationProblems.EmptyNameProperties.push({ Name: property, Ids: emptyNames.map(x => x.Id) });
			}

			return;
		}
		if (typeof propertyValue === 'object') {
			if (this.checkIfPropertyNull(propertyValue, configurationProblems, property)) {
				return;
			}
			this.analyzeRxConfiguration(propertyValue, configurationProblems);
		}
	}

	private static analyzeFeatureToggles(
		featureToggles: FeatureToggle[],
		configurationProblems: RxConfigurationProblems
	): RxConfigurationProblems {
		if (this.checkIfArrayIsEmpty(featureToggles, configurationProblems, 'HighestPackageVersions')) {
			return configurationProblems;
		}

		const emptyDescriptionFFs = featureToggles.filter(x => !x.description);

		if (emptyDescriptionFFs.length > 0) {
			configurationProblems.EmptyNameProperties.push({
				Name: 'FeatureToggles',
				Ids: emptyDescriptionFFs.map(x => x.id)
			});
		}
	}

	private static analyzeAvailableProcedureMapsAndCaseTypes(
		appConfiguration: ApplicationConfiguration,
		configurationProblems: RxConfigurationProblems
	) {
		switch (appConfiguration.rxVersion) {
			case RxVersion.CaseTypeFlow:
				this.checkIfArrayIsEmpty(appConfiguration.availableCaseTypeIds, configurationProblems, 'AvailableCaseTypes');

				return;
			case RxVersion.ProcedureFlow:
				if (appConfiguration.rxConfiguration.RxRules.ProceduresMap?.length < 1) {
					return;
				}
				this.checkIfArrayIsEmpty(appConfiguration.availableProcedureMaps, configurationProblems, 'AvailableProcedureMaps');
		}
	}

	private static analyzeCompanyConfiguration(
		// eslint-disable-next-line @typescript-eslint/ban-types
		propertyValue: object,
		property: string,
		configurationProblems: RxConfigurationProblems
	): void {
		if (this.checkIfPropertyNull(propertyValue, configurationProblems, property)) {
			return;
		}

		const companyConfiguration = propertyValue as CompanyRxConfig;

		this.checkIfArrayIsEmpty(companyConfiguration.SoftwareOptionsForCompany, configurationProblems, 'SoftwareOptionsForCompany');

		const scannersIsEmpty = this.checkIfArrayIsEmpty(companyConfiguration.Scanners, configurationProblems, 'Scanners');

		if (!scannersIsEmpty) {
			const someScannerContainsSWOs = companyConfiguration.Scanners.some(x => x.RelatedSoftwareOptions?.length > 0);

			if (!someScannerContainsSWOs) {
				configurationProblems.EmptyProperties.push('Scanners.RelatedSoftwareOptions');
			}
		}
	}

	// eslint-disable-next-line @typescript-eslint/ban-types
	private static analyzeRxRules(propertyValue: object, property: string, configurationProblems: RxConfigurationProblems): void {
		if (this.checkIfPropertyNull(propertyValue, configurationProblems, property)) {
			return;
		}

		const rxRules = propertyValue as RxRules;

		if (this.checkIfArrayIsEmpty(rxRules.ProceduresMap, configurationProblems, 'ProceduresMap')) {
			return;
		}

		const invalidProcedureMaps = rxRules.ProceduresMap.filter(x => x.ProcedureId < 1 || x.CaseTypeId < 1);

		if (invalidProcedureMaps.length > 0) {
			configurationProblems.InvalidDataProcedureMaps.push(...invalidProcedureMaps.map(x => x.Id));
		}
	}

	// eslint-disable-next-line @typescript-eslint/ban-types
	private static checkIfPropertyNull(propertyValue: object, configurationProblems: RxConfigurationProblems, property: string) {
		if (propertyValue === null) {
			configurationProblems.EmptyProperties.push(property);

			return true;
		}

		return false;
	}

	private static checkIfArrayIsEmpty(array: any[], configurationProblems: RxConfigurationProblems, property: string): boolean {
		if (!array || array.length < 1) {
			configurationProblems.EmptyProperties.push(property);

			return true;
		}

		return false;
	}

	private static excludeProperties(configurationProblems: RxConfigurationProblems, ...properties: ConfigurationProperty[]) {
		configurationProblems.EmptyProperties = configurationProblems.EmptyProperties.filter(
			(el: ConfigurationProperty) => !properties.includes(el)
		);
		configurationProblems.EmptyNameProperties = configurationProblems.EmptyNameProperties.filter(
			(el: EmptyNamesProperty) => !properties.includes(el.Name)
		);
	}

	private static analyzeDoctors(
		doctors: DoctorsRxConfiguration[],
		configurationProblems: RxConfigurationProblems,
		currentUserId: number
	): void {
		if (!currentUserId) {
			configurationProblems.EmptyProperties.push('UserId');
		}

		if (this.checkIfArrayIsEmpty(doctors, configurationProblems, this.doctors)) {
			return;
		}

		const emptyNames = doctors.filter((x: IdName) => x.Id === currentUserId && !x.Name);

		if (emptyNames.length > 0) {
			configurationProblems.EmptyNameProperties.push({
				Name: this.doctors,
				Ids: emptyNames.map(x => x.Id)
			});
		}
	}
}
