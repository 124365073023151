import { ChangeDetectorRef, Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { ControlValueAccessorDirective } from '@shared/base-classes/control-value-accessor.directive';
import { TlkSelectComponent, ConnectedPosition } from '@itero/ui-toolkit-angular/select';
import { DropDownItem } from '@shared/models/drop-down-item';
import { getOptionClassName, getOptionId, idNameCompareWith } from '@shared/utils/selectors-utils';
import { TranslateModule } from '@ngx-translate/core';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';

@Component({
	selector: 'rx-select',
	template: `
		<tlk-select
			[id]="id ? 'rx-auto-select-' + id : undefined"
			[label]="label"
			[placeholder]="placeholder"
			[formControl]="control"
			[options]="options"
			[required]="isRequired"
			[isDisabled]="isDisabled"
			(disableChangeEvent)="disableChangeEvent.emit($event)"
			[cancelSelectionText]="isCancellable ? ('Selector.ClearSelection' | translate) : ''"
			[hintText]="hintText"
			[requiredText]="'Validation.RequiredField' | translate"
			[autoClose]="autoClose"
			[dropdownPositions]="dropdownPositions"
			[panelWidth]="panelWidth"
			[panelClass]="panelClass"
			[panelStyle]="panelStyle"
			[tabIndex]="tabIndex"
			(openEvent)="openEvent.emit($event)"
			(closeEvent)="closeEvent.emit($event)"
			(cancelSelectionEvent)="cancelSelectionEvent.emit($event)"
			[compareWith]="compareWith"
			[getOptionId]="getOptionId"
			[getOptionName]="getOptionName"
			[getOptionClassName]="getOptionClassName"
			[getOptionStyle]="getOptionStyle"
		></tlk-select>
	`,
	standalone: true,
	imports: [TlkSelectComponent, TranslateModule, ReactiveFormsModule],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: SelectComponent,
			multi: true
		}
	]
})
export class SelectComponent<T extends DropDownItem> extends ControlValueAccessorDirective<T> {
	constructor(
		injector: Injector,
		public cdr: ChangeDetectorRef
	) {
		super(injector);
	}

	@Input()
	id?: string;

	@Input()
	label?: string;

	@Input()
	placeholder?: string;

	@Input()
	options?: T[];

	@Input()
	isRequired = false;

	@Input()
	isDisabled = false;

	@Input()
	isCancellable = false;

	@Input()
	hintText?: string;

	@Input()
	autoClose = true;

	@Input()
	dropdownPositions?: ConnectedPosition[] | 'left' | 'right';

	@Input()
	panelWidth?: string | number | null = 'auto';

	@Input()
	panelClass?: string | string[] | Set<string> | { [key: string]: any };

	@Input()
	panelStyle?: { [p: string]: any };

	@Input()
	tabIndex?: number | string;

	@Output()
	openEvent = new EventEmitter<void>();

	@Output()
	closeEvent = new EventEmitter<void>();

	@Output()
	cancelSelectionEvent = new EventEmitter<MouseEvent>();

	@Output()
	disableChangeEvent = new EventEmitter<boolean>();

	@Input()
	compareWith = idNameCompareWith;

	@Input()
	getOptionId = (index: number, option: T) => {
		return getOptionId(option.Name, option.Id, this.label);
	};

	@Input()
	getOptionName: (currentOption: T) => string = (currentOption: T) => currentOption.Name;

	@Input()
	getOptionClassName?: (index: number, option: T) => string | string[] | Set<string> | { [key: string]: any } = (
		index: number,
		option: T
	) => {
		return getOptionClassName(index);
	};

	@Input()
	getOptionStyle?: (index: number, option: T) => { [p: string]: any } | null | undefined = (i: number, option: T) => {
		return option.Hidden ? { display: 'none' } : null;
	};
}
