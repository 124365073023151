import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { LocalSettings } from '@shared/models/local-settings';
import { FavoritesEnum } from '@shared/models/enums/favorites.enum';
import { FavoritesSettings } from '@shared/models/favorites-settings';
import { RegisterBannerSetting } from '@shared/models/rx-models/interfaces/rx-model';

export const localSettingsStoreName = 'localSettings';

export interface LocalSettingsState {
	userSettings?: Record<number, LocalSettings>;
	favoritesSettings?: Record<number, FavoritesSettings>;
	registerBannerSetting?: Record<number, RegisterBannerSetting>;
}

export const getInitialState = (): LocalSettingsState => {
	return {
		userSettings: {},
		favoritesSettings: {},
		registerBannerSetting: {}
	};
};

@Injectable({
	providedIn: 'root'
})
@StoreConfig({ name: localSettingsStoreName })
export class LocalSettingsStore extends Store<LocalSettingsState> {
	constructor() {
		super(getInitialState());
	}
	updateLocalSettings(id: number, settings: LocalSettings): void {
		let userSettings = this.getValue().userSettings;
		if (userSettings[id]) {
			settings = { ...userSettings[id], ...settings };
		}
		userSettings = { ...userSettings, [id]: settings };
		this.update({ userSettings });
	}

	updateFavoritesSettings(id: number, settingName: FavoritesEnum, items: string[]): void {
		let favoritesSettings = this.getValue().favoritesSettings;
		const favoritesForUser = favoritesSettings[id] ?? {};
		const settings = this.enhanceUsersFavorites(settingName, items, favoritesForUser);
		favoritesSettings = { ...favoritesSettings, [id]: settings };
		this.update({ favoritesSettings });
	}

	private enhanceUsersFavorites(settingName: FavoritesEnum, items: string[], prevSettings: FavoritesSettings): FavoritesSettings {
		const newItem = { ...prevSettings };
		newItem[settingName] = items;
		return newItem;
	}
	updateRegisterForDesignSuite(id: number, settings: RegisterBannerSetting): void {
		let registerBannerSetting = this.getValue().registerBannerSetting;
		if (registerBannerSetting[id]) {
			settings = { ...registerBannerSetting[id], ...settings };
		}
		registerBannerSetting = { ...registerBannerSetting, [id]: settings };
		this.update({ registerBannerSetting });
	}
}
