import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { RxNote } from '../models/rx-note';
import { RxUILabNote } from '../models/rx-ui-lab-note';

export interface NotesState {
	notes: string;
	notesArray: RxNote[];
	labNotes: RxUILabNote[];
}

export const createInitialState = (): NotesState => ({
	notes: null,
	notesArray: null,
	labNotes: null
});

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'notes' })
export class NotesStore extends Store<NotesState> {
	constructor() {
		super(createInitialState());
	}
}
