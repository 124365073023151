/**
 * returns a date pipe value based on date format from 'YYYY/MM/DD' | 'MM/DD/YYYY' | 'DD/MM/YYYY'
 * @param dateFormat 'YYYY/MM/DD' | 'MM/DD/YYYY' | 'DD/MM/YYYY' string
 */
export const getDateFormatOption = (dateFormat: string): string => {
	try {
		let dateFormatPipeOption = dateFormat.replace(/Y/g, 'y');
		dateFormatPipeOption = dateFormatPipeOption.replace(/D/g, 'd');
		return dateFormatPipeOption ? dateFormatPipeOption : 'YYYY/MM/DD';
	} catch {
		return 'YYYY/MM/DD';
	}
};
