import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { IdName } from '@shared/models/id-name';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { filter, map, shareReplay, tap } from 'rxjs/operators';
import { RxRulesService } from '@shared/services/rx-rules-helper/rx-rules.service';
import { ShadeSystem } from '@shared/models/shade-system';
import { DentureDetailsState, DentureDetailsStore } from '@modules/denture-details/state/denture-details.store';
import { DentureDetailsQuery } from '@modules/denture-details/state/denture-details.query';
import { combineQueries } from '@datorama/akita';
import { OrderQuery } from '@modules/order/state/order-query';
import { TeethDiagramQuery } from '@modules/teeth-diagram/state/teeth-diagram-query';

@Injectable()
export class DentureDetailsFacade {
	stages$: Observable<IdName[]> = this.shellQuery.rxConfiguration$.pipe(map(c => c.Stages));
	moulds$: Observable<IdName[]> = this.shellQuery.rxConfiguration$.pipe(map(c => c.Moulds));

	shadeSystems: ShadeSystem[];

	teethShades$: Observable<string[]> = this.query.shadeSystem$.pipe(
		map(s => s?.Options),
		shareReplay({ bufferSize: 1, refCount: true })
	);

	gingivalShades$: Observable<IdName[]> = this.shellQuery.rxConfiguration$.pipe(map(c => c.DentureGingivalShades));

	shouldTeethShadeBeSelected$: Observable<boolean> = this.teethShades$.pipe(
		map(x => DentureDetailsFacade.shouldTeethShadeBeSelected(x)),
		shareReplay({ bufferSize: 1, refCount: true })
	);

	shouldValidateForSend$: Observable<boolean> = this.shellQuery.shouldValidateForSend$.pipe(
		shareReplay({ bufferSize: 1, refCount: true })
	);
	isReadonly$ = this.shellQuery.getReadOnlyState('dentureDetails').pipe(shareReplay({ bufferSize: 1, refCount: true }));

	dentureUpperSectionIsReadonly$ = this.orderQuery.dentureUpperSectionIsReadonly$;
	dentureLowerSectionIsReadonly$ = this.orderQuery.dentureLowerSectionIsReadonly$;
	checkIsToothDiagramEnabled$ = this.orderQuery.procedureMap$.pipe(map(procedureMap => procedureMap.ToothDiagramIsEnabled));

	getUpperSectionToggleValueWhenToothSelected$ = combineLatest([this.teethDiagramQuery.upperJaw$, this.orderQuery.procedureMap$]).pipe(
		map(([teeth, procedureMap]) => this.rxRulesService.checkDentureDetailsRule(teeth, procedureMap.TypeId))
	);

	getLowerSectionToggleValueWhenToothSelected$ = combineLatest([this.teethDiagramQuery.lowerJaw$, this.orderQuery.procedureMap$]).pipe(
		map(([teeth, procedureMap]) => this.rxRulesService.checkDentureDetailsRule(teeth, procedureMap.TypeId))
	);

	rxConfiguration$ = this.shellQuery.rxConfiguration$;

	dentureCopyScanValue$ = this.query.isDentureCopyScan$;

	constructor(
		private store: DentureDetailsStore,
		private query: DentureDetailsQuery,
		private shellQuery: ShellQuery,
		private rxRulesService: RxRulesService,
		private orderQuery: OrderQuery,
		private teethDiagramQuery: TeethDiagramQuery
	) {
		this.shadeSystems = this.rxRulesService.getDefaultShadeSystemOptions();
	}

	updateStore(formValues: DentureDetailsState): { formValues: DentureDetailsState; isFormValuesModified: boolean } {
		let isFormValuesModified = false;

		if (
			this.query.shadeSystem !== formValues.shadeSystem &&
			DentureDetailsFacade.shouldTeethShadeBeSelected(formValues.shadeSystem.Options) &&
			!formValues.shadeSystem.Options.includes(formValues.teethShade)
		) {
			formValues.teethShade = null;
			isFormValuesModified = true;
		}

		this.store.update(formValues);

		return { formValues, isFormValuesModified };
	}

	getLoadedRx(): Observable<DentureDetailsState> {
		return combineQueries([
			this.shellQuery.dentureDetails$,
			this.shellQuery.rxConfiguration$,
			this.shellQuery.isProcedureChanged$
		]).pipe(
			filter(([dentureDetails, rxConfiguration, isProcedureChanged]) => !!(dentureDetails && rxConfiguration && !isProcedureChanged)),
			map(([dentureDetails, rxConfiguration]) => {
				return {
					isUpperJawChecked: dentureDetails.UpperJaw,
					isLowerJawChecked: dentureDetails.LowerJaw,
					stage: rxConfiguration.Stages.find(x => x.Id === dentureDetails.Stage),
					mould: rxConfiguration.Moulds.find(x => x.Id === dentureDetails.Mould),
					shadeSystem: this.shadeSystems.find(x => x.Id === dentureDetails.ShadeSystemId),
					teethShade: dentureDetails.TeethShade,
					gingivalShade: rxConfiguration.DentureGingivalShades.find(x => x.Id === dentureDetails.GingivalShadeId),
					isDentureCopyScan: dentureDetails.IsDentureCopyScan
				};
			}),
			tap(dentureDetails => {
				this.store.update(dentureDetails);
			})
		);
	}
	getSrcForRadioButtonImg(checked: boolean): string {
		return checked
			? `${this.shellQuery.staticFilesEndpoint}/assets/icons/check.svg`
			: `${this.shellQuery.staticFilesEndpoint}/assets/icons/uncheck.svg`;
	}

	getDefaultShadeSystem(): Observable<ShadeSystem> {
		return combineQueries([this.shellQuery.defaultShade$, this.shellQuery.isReadOnly$]).pipe(
			filter(([, isReadOnly]) => !isReadOnly),
			map(([shadeSystem]) => {
				return this.shadeSystems.find(x => x.Id === shadeSystem);
			})
		);
	}

	private static shouldTeethShadeBeSelected(teethShades: string[]): boolean {
		return !!teethShades?.length;
	}
}
