import { CaseTypeEnum } from "@modules/order/models/case-type.enum"

export const isResorative = ({ caseTypeId }: { caseTypeId: number }) =>
    [
        CaseTypeEnum.Restorative,
        CaseTypeEnum.ChairSideMilling,
        CaseTypeEnum.ChairSideMillingGlidewellIo,
        CaseTypeEnum.ChairSideMillingCerec
    ]
        .includes(caseTypeId);

export const isLegacyRestorative = ({ caseTypeId }: { caseTypeId: number }) =>
    [
        CaseTypeEnum.Quadrant,
        CaseTypeEnum.Expanded,
        CaseTypeEnum.FullArch,
        CaseTypeEnum.ReferenceModel
    ]
        .includes(caseTypeId);