<div class="fields-container" fxLayout="column" fxLayoutAlign="space-between">
    <div class="field" *ngFor="let field of fields" [id]="field?.id" >
        <div class="field-name">
            {{ field?.name | translate }}:
            <span *ngIf="field?.isInline && !field?.isSignature">{{ field?.value | doubleHyphen }}</span>
        </div>
        <div class="field-info" *ngIf="!field?.isInline && !field?.isSignature">{{ field?.value | doubleHyphen }}</div>
        <img *ngIf="field?.isSignature && !!field?.value" class="signature" [src]="field?.value" />
    </div>
</div>
