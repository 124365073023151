import { EventEmitter, Injectable } from '@angular/core';
import { combineLatest, forkJoin, Observable, of, pipe, zip } from 'rxjs';
import { catchError, filter, find, first, map, mergeMap, switchMap, switchMapTo, take, tap, withLatestFrom } from 'rxjs/operators';
import { IBaseFacade } from '@shared/base-classes/base.facade';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { PatientQuery } from '@modules/patient/state/patient-query';
import { ShellStore } from '@shared/store/shell/shell-store';
import { NotesQuery } from '@modules/notes/state/notes-query';
import { SdkInputs } from '@shared/models/sdk-inputs';
import { RxConfiguration } from '@shared/models/rx-configuration';
import { UserSettings } from '@shared/models/user-settings';
import { DoctorStore } from '@modules/doctor/state/doctor-store';
import { FeatureToggle } from '@shared/models/feature-toggle';
import { OrderQuery } from '@modules/order/state/order-query';
import { ValidateRxService } from '@shared/services/validate-rx';
import { PopUpActions } from '@shared/models/enums/popup-modal-actions.enum';
import { ShellApiService } from '@shared/services/shell-api.service';
import { LoggerService } from '@core/services/logger/logger.service';
import { RxModel } from '@shared/models/rx-models/interfaces/rx-model';
import { RoleTypeEnum } from '@shared/models/role-type';
import { OrderApiService } from '@modules/order/api/order-api.service';
import { OrderStore } from '@modules/order/state/order-store';
import { OrderModel } from '@shared/models/rx-models/interfaces/order-model';
import { CaseTypeEnum } from '@modules/order/models/case-type.enum';
import { VersionsService } from '@shared/services/versions.service';
import { PatientAppIframeCommunicationService } from '@modules/patient/services/patient-app-iframe-communication.service';
import { getToothWithReplacedForRxAppDefault } from '@shared/models/Mappers/default-values-mapper';
import { ScanOptionsQuery } from '@modules/scan-options/state/scan-options-query';
import { isLegacyRestorative } from '@shared/utils/restorative-check-util';
import { SoftwareOptionsService } from '@shared/services/software-options.service';
import { FeatureToggleSettings, PrintOrientation, RxVersion, SoftwareOptions } from '@shared/models/enums/enums';
import { combineQueries, withTransaction } from '@datorama/akita';
import { PatientStore } from '@modules/patient/state/patient-store';
import { OrderInformationModel } from '@shared/models/rx-models/interfaces/order-information-model';
import { CacheAssetsService } from '@shared/services/cache-assets.service';
import { LimitedFeatures } from '@shared/models/limited-features';
import { getNewTooth, Tooth } from '@modules/teeth-diagram/models/tooth';
import { ApplicationConfiguration } from '@shared/models/application-configuration';
import { ChangeUnitTypeInput } from '@shared/models/change-unit-type-input';
import { TeethDiagramStore } from '@modules/teeth-diagram/state/teeth-diagram-store';
import { TeethDiagramQuery } from '@modules/teeth-diagram/state/teeth-diagram-query';
import { ScanOptionsStore } from '@modules/scan-options/state/scan-options-store';
import { allTeeth } from '@core/procedure-helpers/teeth-diagram/teeth-sets';
import { ToothNumber } from '@core/procedure-helpers/models/procedure-unit-type-material-rules-config';
import { TeethNumberingSystem } from '@modules/teeth-diagram/models/teeth-numbering-system.enum';
import { isTeethNumberingSystemValid } from '@shared/utils/teeth-numbering-system-util';
import { ProcedureMap } from '@shared/models/procedure-map';
import { BridgeService } from '@shared/services/bridge.service';
import { OrderInformationState, OrderInformationStore } from '@modules/order-information/state/order-information.store';
import { AligntechNotesService } from '@modules/aligntech-notes/services/aligntech-notes.service';
import { UnitTypes } from '@modules/teeth-diagram/models/unit-type.enum';
import { ToothEditorQuery } from '@modules/tooth-editor/state/tooth-editor-query';
import { BridgeValidatorService } from '@shared/services/bridge-validator.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { CompanyScanner, ScannerVersion } from '@shared/models/company-scanner';
import { ArrayOfObservables } from '@shared/utils/type-util';
import { IdName } from '@shared/models/id-name';
import { OrderInformationQuery } from '@modules/order-information/state/order-information.query';
import { RxValidation } from '@shared/models/rx-validation';
import { TranslateService } from '@ngx-translate/core';
import { ConfigurationAnalyzer } from '@shared/services/configuration.analyzer';
import { HostPlatformService } from '@shared/services/host-platform.service';
import { v4 as uuid } from 'uuid';
import { AuthInfo } from '@shared/models/auth-info';
import { AuthInfoStore } from '@shared/store/authInfo/auth-info-store';
import { TracesQuery } from '@shared/store/traces/traces-query';
import { stayInRx } from '@shared/models/consts';
import { SaveRxService } from '@modules/rx-for-doctor/services/save-rx.service';
import { ForceSaveToothEditorService } from '@shared/services/force-save-tooth-editor.service';
import { RxAttachmentsApiService } from '@shared/rx-attachments/rx-attachments-api.service';
import { PdfCreationService } from '@shared/services/pdf-creation.service';
import { RxForDoctorStore } from '@modules/rx-for-doctor/state/rx-for-doctor-store';
import { JWTStateEnum } from '@shared/models/jwt-state.enum';
import { AddRxService } from '@shared/services/add-rx.service';
import { PendoService } from '@shared/services/pendo.service';

export type RxSavedPayload = typeof stayInRx | undefined | RxModel; // LOL
@Injectable()
export class AppFacade implements IBaseFacade {
	constructor(
		private shellQuery: ShellQuery,
		private shellStore: ShellStore,
		private authInfoStore: AuthInfoStore,
		private shellApiService: ShellApiService,
		private patientQuery: PatientQuery,
		private notesQuery: NotesQuery,
		private scanOptionsQuery: ScanOptionsQuery,
		private doctorStore: DoctorStore,
		private orderQuery: OrderQuery,
		private validateRxService: ValidateRxService,
		private logger: LoggerService,
		private orderApiService: OrderApiService,
		private orderStore: OrderStore,
		private patientAppIframeCommunicationService: PatientAppIframeCommunicationService,
		private versionsService: VersionsService,
		private patientStore: PatientStore,
		private cacheAssetsService: CacheAssetsService,
		private teethDiagramQuery: TeethDiagramQuery,
		private teethDiagramStore: TeethDiagramStore,
		private scanOptionsStore: ScanOptionsStore,
		private bridgeService: BridgeService,
		private orderInformationStore: OrderInformationStore,
		private orderInformationQuery: OrderInformationQuery,
		private aligntechNotesService: AligntechNotesService,
		private toothEditorQuery: ToothEditorQuery,
		private bridgeValidatorService: BridgeValidatorService,
		private translateService: TranslateService,
		private snackBar: MatSnackBar,
		private hostPlatformService: HostPlatformService,
		private tracesQuery: TracesQuery,
		private saveRxService: SaveRxService,
		private forceSaveToothEditorService: ForceSaveToothEditorService,
		private rxAttachmentsApiService: RxAttachmentsApiService,
		private pdfCreationService: PdfCreationService,
		private rxForDoctorStore: RxForDoctorStore,
		private addRxService: AddRxService,
		private pendoService: PendoService
	) {}
	private readonly facadeName = 'AppFacade';

	getState$: Observable<any> = zip(this.shellQuery.shellState$, this.patientQuery.patientState$, this.notesQuery.notesArray$).pipe(
		map(res => res)
	);

	rxConfiguration$: Observable<RxConfiguration> = this.shellQuery.rxConfiguration$;
	userSettings$: Observable<UserSettings> = this.shellQuery.userSettings$;
	dateFormat$: Observable<string> = this.shellQuery.dateFormat$;
	languageCode$: Observable<string> = this.shellQuery.languageCode$;
	featureToggles$: Observable<FeatureToggle[]> = this.shellQuery.featureToggles$;
	rxId$: Observable<string> = this.shellQuery.rxId$;
	orderId$: Observable<number> = this.shellQuery.orderId$;
	clonedFromRxId$: Observable<string> = this.shellQuery.clonedFromRxId$;
	sleeveCheckedChangedByUser$: Observable<boolean> = this.scanOptionsQuery.sleeveCheckedChangedByUser$;
	shouldValidateForSend$: Observable<boolean> = this.shellQuery.shouldValidateForSend$;
	userRole$: Observable<RoleTypeEnum> = this.shellQuery.userRole$;
	shouldAnonymizeRx$: Observable<boolean> = this.shellQuery.shouldAnonymizeRx$;
	pdfRequested$: Observable<any> = this.pdfCreationService.pdfRequested.pipe(
		switchMap(isCalledByScanner => {
			return this.tryToSaveRx(isCalledByScanner).pipe(
				tap(rxModel => {
					if (!rxModel) {
						return;
					}
					rxModel = rxModel.Result ?? rxModel;

					const rx = { ...rxModel };

					this.shellStore.update({ rx });
					this.pdfRequested.emit(rxModel);
				})
			);
		})
	);
	rxSaved: EventEmitter<RxSavedPayload> = new EventEmitter<RxSavedPayload>();
	rxSaveFailed: EventEmitter<any> = new EventEmitter<any>();
	pdfRequested: EventEmitter<any> = new EventEmitter<any>();

	getStateSync() {
		return {
			...this.shellQuery.getValue(),
			...this.patientQuery.getValue()
		};
	}

	mockGetRx() {
		const mockRxId = '4ad595a3-3526-4e09-824e-ffc1d39645e7';

		this.shellStore.update({
			rxId: mockRxId
		});

		return this.getRxById(mockRxId);
	}

	updateSdkInputs(sdkInputs: SdkInputs) {
		this.shellStore.update(sdkInputs);
	}

	updateRxConfiguration(rxConfiguration: RxConfiguration) {
		this.shellStore.update({ rxConfiguration });
	}

	updateFeatureToggles(featureToggles: FeatureToggle[]) {
		this.shellStore.update({ featureToggles });
	}

	updateUserSettings(userSettings: UserSettings) {
		this.shellStore.update({ userSettings });

		const isLicenseEditable = !userSettings.LicenseNumber;

		this.doctorStore.update({ isLicenseEditable });
	}

	validateRxForSave(): Observable<boolean> {
		return combineQueries([
			this.patientQuery.patient$,
			this.orderQuery.caseType$,
			this.orderQuery.currentAlignerId$,
			this.orderQuery.procedureMap$,
			this.shellQuery.doctor$,
			this.patientQuery.isPatientInConflict$,
			this.shellQuery.rx$
		]).pipe(
			map(([patient, caseType, currentAlignerId, procedureMap, doctor, isPatientInConflict, rx]) => {
				const isRxValidForSave =
					this.validateRxService.validateRxForSave({
						patient,
						caseType,
						currentAlignerId,
						procedureMap,
						doctor,
						rx
					}) && !isPatientInConflict;

				this.shellStore.update({ isRxValidForSave });

				return isRxValidForSave;
			})
		);
	}

	validateBeforeSend(): Observable<RxValidation> {
		const isValidForSend = !this.isInvalidForSend;

		return (isValidForSend ? this.validateRxForSave() : of(false)).pipe(
			map(isValid => {
				return {
					isValid,
					message: isValid ? '' : this.getMessageForInvalidValidation()
				};
			})
		);
	}

	get isInvalidForSend(): boolean {
		const { upperJaw, lowerJaw } = this.teethDiagramQuery.teeth;
		const invalidTooth = this.teethDiagramQuery.getTreatedTeeth(upperJaw, lowerJaw).find((tooth: Tooth) => {
			return this.validateRxService.isTreatmentInvalidForSend(tooth);
		});

		if (this.hostPlatformService.isIteroModeling && this.toothEditorQuery.isToothEditorOpen) {
			const teethInToothEditor: Tooth[] = this.toothEditorQuery.teeth;

			if (teethInToothEditor[0].UnitTypeID == null) {
				const isBridgeInvalid = this.isBridgeInvalidForSend(teethInToothEditor);

				if (isBridgeInvalid) {
					this.logger.info('Bridge invalid for send', {
						module: this.facadeName
					});
				}

				return isBridgeInvalid;
			}

			return this.validateRxService.isTreatmentInvalidForSend(teethInToothEditor[0]);
		}

		if (this.hostPlatformService.isIteroLab) {
			const orderInfo = this.orderInformationQuery.getOrderInformationForSave();
			const isOrderInfoInvalid: boolean = this.validateRxService.isOrderInfoInvalidForSend(orderInfo);

			this.orderInformationStore.updateInvalidForSend(isOrderInfoInvalid);

			return isOrderInfoInvalid || !!invalidTooth;
		}

		return !!invalidTooth;
	}

	validateRxAndPatientAppForSave(): Observable<boolean> {
		if (this.shellQuery.isReadOnly) {
			return of(true);
		}

		return this.validateRxForSave().pipe(
			take(1),
			switchMap(isRxValid => {
				if (isRxValid && this.patientQuery.isPatientAppConfigured && !this.patientQuery.patientGuid) {
					return this.savePatient().pipe(take(1));
				}

				return of(isRxValid);
			})
		);
	}

	openPatientConflictPopUp(): Observable<PopUpActions> {
		this.patientAppIframeCommunicationService.postMessageForCreatePatient();

		return this.patientAppIframeCommunicationService.patientConflictPopup$;
	}

	openValidationPopUp(): Observable<PopUpActions> {
		return this.validateRxService.openValidationPopup();
	}

	getRxById(rxId: string, shouldAnonymize?: boolean): Observable<RxModel> {
		return this.shellApiService
			.getRxById({ rxId, shouldAnonymize })
			.pipe(tap((rx: RxModel) => this.shellStore.update({ rx: this.getConvertedRx({ rx }) })));
	}

	updateStaticFilesEndpoint({ staticFilesEndpoint }: { staticFilesEndpoint: string }) {
		this.shellStore.update({ staticFilesEndpoint });
	}

	updateRxSessionId(rxSessionId: string) {
		this.shellStore.update({ rxSessionId });
	}

	updateApiEndpoint({ apiEndpoint }: { apiEndpoint: string }) {
		this.shellStore.update({ apiEndpoint });
	}

	updateContactId({ contactId }: { contactId: number }) {
		this.shellStore.update({ contactId });
	}

	updateCompanyId({ companyId }: { companyId: number }) {
		this.shellStore.update({ companyId });
	}

	updateScannerId({ scannerId }: { scannerId: string }) {
		this.shellStore.update({ scannerId });
	}

	updateClientVersion({ clientVersion }: { clientVersion: string }) {
		this.shellStore.update({ clientVersion });
	}

	updatePackageVersion({ packageVersion }: { packageVersion: string }) {
		this.shellStore.update({ packageVersion });
	}

	updateApplicationMode({ applicationMode }: { applicationMode: string }) {
		this.shellStore.update({ applicationMode });
	}

	updateProductType({ productType }: { productType: string }) {
		this.shellStore.update({ productType: productType?.toUpperCase() });
	}

	updateRxId({ rxId }: { rxId: string }) {
		this.shellStore.update({ rxId });
	}

	updateOrderId({ orderId }: { orderId: any }) {
		this.shellStore.update({ orderId });
	}

	updateIsReadOnly({ isReadOnly }: { isReadOnly: boolean }) {
		this.shellStore.update({ isReadOnly });
	}

	updateIsRxTakenForScan({ isRxTakenForScan }: { isRxTakenForScan: boolean }) {
		this.shellStore.update({ isRxTakenForScan });
	}

	updateAuthToken(authToken: string): void {
		this.shellStore.update({ authToken });
	}

	updateLanguageCode(languageCode: string): void {
		this.shellStore.update({ languageCode });
	}

	updateEnableAllCaseTypesForAddRx(enableAllCaseTypesForAddRx: boolean) {
		this.shellStore.update({ enableAllCaseTypesForAddRx });
	}

	changeUnitType(input: ChangeUnitTypeInput) {
		if (!allTeeth.includes(input.ToothId as ToothNumber)) {
			return;
		}

		const { upperJaw, lowerJaw } = this.teethDiagramQuery.teeth;
		const teeth = [...upperJaw, ...lowerJaw];

		const existingTooth = teeth.find(x => x.ToothID === input.ToothId) ?? getNewTooth({ ToothID: input.ToothId });
		const newTooth = getNewTooth({ ToothID: input.ToothId });

		if (!!existingTooth.BridgeIndex) {
			newTooth.BridgeIndex = existingTooth.BridgeIndex;
			newTooth.ToothInBridgeTypeID = this.bridgeService.convertToBridgeToothUnitType({ unitType: input.UnitType });

			if (this.hostPlatformService.isIteroModeling && existingTooth.ToothInBridgeTypeID !== newTooth.ToothInBridgeTypeID) {
				this.aligntechNotesService.createChangeUnitTypeNote(existingTooth, newTooth);
			}
		} else {
			newTooth.UnitTypeID = input.UnitType;
			if (
				this.hostPlatformService.isIteroModeling &&
				existingTooth.UnitTypeID !== UnitTypes.Regular &&
				existingTooth.UnitTypeID !== newTooth.UnitTypeID
			) {
				this.aligntechNotesService.createChangeUnitTypeNote(existingTooth, newTooth);
			}
		}

		this.teethDiagramStore.updateTeeth({ teethToUpdate: [newTooth] });
	}

	loadPatient(patientGuid: string): Observable<any> {
		const isHostPlatformScanner = this.hostPlatformService.isScanner;

		return combineQueries([this.shellQuery.companyId$, this.shellQuery.contactId$]).pipe(
			filter(([companyId, doctorId]) => !!companyId && !!doctorId),
			tap(() => this.patientStore.setLoading(true)),
			switchMap(([companyId, doctorId]) =>
				combineQueries([
					this.shellApiService.getPatientByUid({ patientGuid, doctorId, companyId, isHostPlatformScanner }),
					this.shellQuery.regulatoryDOBMask$
				])
			),
			filter(([patient]) => !!patient),
			withTransaction(([patient, regulatoryDOBMask]) => {
				this.patientStore.updatePatient(patient, null, regulatoryDOBMask);
				this.patientStore.setLoading(false);
			})
		);
	}

	loadRxIfNeeded(): Observable<Partial<ApplicationConfiguration>> {
		return combineQueries([this.rxId$, this.orderId$, this.userRole$, this.clonedFromRxId$, this.shouldAnonymizeRx$]).pipe(
			tap(() => this.shellStore.setLoading(true)),
			withLatestFrom(this.languageCode$),
			// try to prevent request canceling
			// https://stackoverflow.com/questions/45844426/angular-4-a-series-of-http-requests-get-cancelled.
			mergeMap(([[rxId, orderId, roleType, clonedFromRxId, shouldAnonymize], languageCode]) => {
				const shouldLoadRxWithPatient = !!rxId || !!orderId || !!clonedFromRxId;

				if (shouldLoadRxWithPatient) {
					this.patientStore.setLoading(true);
				}

				if (!shouldLoadRxWithPatient) {
					this.shellStore.update({ isRxPending: false });

					return of({});
				}

				return !!rxId
					? this.shellApiService.getRxById({ rxId, shouldAnonymize }).pipe(map(rx => ({ rx: this.getConvertedRx({ rx }) })))
					: !!orderId
						? this.shellApiService
								.getRxByOrderId({ orderId, langCode: languageCode, roleType, shouldAnonymize })
								.pipe(map(rx => ({ rx: this.getConvertedRx({ rx }) })))
						: this.shellApiService
								.getRxById({ rxId: clonedFromRxId })
								.pipe(map((rx: RxModel) => ({ clonedRx: this.getConvertedRx({ rx }) })));
			})
		);
	}

	loadContactNameIfNeeded(): Observable<string> {
		return this.hostPlatformService.isIteroLab$.pipe(
			switchMap(isIteroLab => {
				return isIteroLab && !this.shellQuery.isHeadlessPrint ? this.shellApiService.getLabContact() : of(null);
			}),
			catchError(() => of(null)),
			map(contact => contact?.contactName)
		);
	}

	loadRxConfigurationData(): Observable<{
		configuration: Partial<ApplicationConfiguration>;
		isV1ConfigurationSupported: boolean;
	}> {
		return combineLatest([
			this.shellQuery.contactId$,
			this.shellQuery.companyId$,
			this.hostPlatformService.isScanner$,
			this.shellQuery.clientVersion$,
			this.shellQuery.packageVersion$
		]).pipe(
			tap(() => this.shellStore.setLoading(true)),
			switchMap(([contactId, companyId, isHostPlatformScanner, clientVersion, packageVersion]) => {
				const isReadOnlyProcedureFlow = this.versionsService.isFeatureAvailableForScanner(
					LimitedFeatures.ProcedureFlowReadOnly,
					clientVersion,
					packageVersion
				);

				// less 21b scanner does not support procedure flow at all.
				// 21b scanner supports procedure flow end points but should works as case type flow.
				if (isHostPlatformScanner && !isReadOnlyProcedureFlow) {
					return forkJoin(this.legacyScannerEndpoints(companyId, contactId)).pipe(
						map(([rxConfiguration, featureToggles, userSettings, availableCaseTypeIds]) => {
							return {
								configuration: {
									rxConfiguration,
									featureToggles,
									availableCaseTypeIds,
									userSettings,
									rxVersion: RxVersion.CaseTypeFlow
								},
								isV1ConfigurationSupported: false
							};
						})
					);
				}

				let endpoints: Observable<[ScannerVersion[], string[], RxConfiguration, FeatureToggle[], UserSettings, number[], number[]]>;

				if (
					!isHostPlatformScanner &&
					this.shellQuery.userRole === RoleTypeEnum.Doctor &&
					!this.shellQuery.isReadOnly &&
					!this.shellQuery.isHeadlessPrint
				) {
					endpoints = forkJoin(this.relevantWebEndpointsByDoctor(companyId, contactId)).pipe(
						map(([companiesScanners, rxConfig, ...args]) => {
							const scannersVersions = companiesScanners.map(s => s.ScannerVersion);

							return [
								scannersVersions,
								this.getHighestPackageVersions(companiesScanners, rxConfig.HighestScannerVersion),
								rxConfig,
								...args
							];
						})
					);
				} else if (isHostPlatformScanner) {
					endpoints = forkJoin(this.relevantCommonEndpoints(companyId, contactId)).pipe(map(args => [[], [], ...args]));
				} else {
					endpoints = forkJoin(this.readonlyEndpointsOrNotForDoctorEndpoints(companyId, contactId)).pipe(
						map(args => [[], [], ...args, [], []])
					);
				}

				return endpoints.pipe(
					map(
						([
							scannersVersions,
							highestPackageVersions,
							rxConfiguration,
							featureToggles,
							userSettings,
							availableCaseTypeIds,
							availableProcedureMapIds
						]) => {
							const isReferralWorkflowPractice = this.isReferralWorkflowPractice(
								rxConfiguration.CompanyConfiguration?.SoftwareOptionsForCompany
							);

							const rxVersion = this.mapConfigurationToRxVersionFlow(
								rxConfiguration.CompanyConfiguration?.SoftwareOptionsForCompany,
								featureToggles,
								isHostPlatformScanner,
								availableProcedureMapIds?.length > 0,
								clientVersion,
								packageVersion,
								highestPackageVersions,
								isReferralWorkflowPractice
							);

							const availableProcedureMaps = this.filterProcedureMapsByIds(
								rxConfiguration?.RxRules?.ProceduresMap,
								availableProcedureMapIds
							);

							return {
								configuration: {
									rxConfiguration,
									featureToggles,
									availableProcedureMaps,
									availableCaseTypeIds,
									userSettings,
									rxVersion,
									isReferralWorkflowPractice,
									scannersVersions
								},
								isV1ConfigurationSupported: true
							};
						}
					)
				);
			})
		);
	}

	isReferralWorkflowPractice(companySWOs?: number[]) {
		return SoftwareOptionsService.checkSoftwareOptions(companySWOs ?? [], [SoftwareOptions.ReferralWorkflowPractice]);
	}

	getHighestPackageVersions(companiesScanners: CompanyScanner[], highestScannerVersion: string): string[] {
		let packageVersions = companiesScanners?.map(scanner => scanner?.ScannerVersion?.VersionNumber)?.filter(v => !!v);

		packageVersions = packageVersions ? Object.values(this.versionsService.findMaxVersions(packageVersions)) : [];

		if (!packageVersions.length && highestScannerVersion) {
			packageVersions.push(highestScannerVersion); // fallback
		}

		return packageVersions;
	}

	mapConfigurationToRxVersionFlow(
		companySWOs: number[],
		featureToggles: FeatureToggle[],
		isHostPlatformScanner: boolean,
		hasAvailableProcedure: boolean,
		clientVersion?: string,
		packageVersion?: string,
		highestPackageVersions?: string[],
		isReferralWorkflowPractice?: boolean
	) {
		if (!hasAvailableProcedure) {
			return RxVersion.CaseTypeFlow;
		}
		if (isHostPlatformScanner) {
			if (!this.versionsService.isFeatureAvailableForScanner(LimitedFeatures.ProcedureFlow, clientVersion, packageVersion)) {
				return RxVersion.CaseTypeFlow;
			}
		} else {
			if (
				!isReferralWorkflowPractice &&
				!this.versionsService.isFeatureAvailableForWeb(LimitedFeatures.ProcedureFlow, highestPackageVersions)
			) {
				return RxVersion.CaseTypeFlow;
			}
		}

		const hasV1Swo = SoftwareOptionsService.checkSoftwareOptions(companySWOs, [SoftwareOptions.ProcedureBasedRx]);
		const hasV1Ft = featureToggles.some(x => (x.id as FeatureToggleSettings) === FeatureToggleSettings.ProcedureBasedRx && x.isActive);

		return hasV1Swo || hasV1Ft ? RxVersion.ProcedureFlow : RxVersion.CaseTypeFlow;
	}

	updateValidationMode(validationMode: string) {
		this.shellStore.update({ validationMode });
	}

	updateUserRole(userRole: RoleTypeEnum) {
		this.shellStore.update({ userRole });
	}

	updateClonedFromRxId(clonedFromRxId: string) {
		this.shellStore.update({ clonedFromRxId });
	}

	updateForceV0(forceV0: boolean) {
		this.shellStore.update({ forceV0 });
	}

	updateIsReturn(isReturn: boolean) {
		this.shellStore.update({ isReturn });
	}

	updatePrintOrientation({ printOrientation }: { printOrientation: PrintOrientation }) {
		this.shellStore.update({ printOrientation });
	}

	updateIsHeadlessPrint({ isHeadlessPrint }: { isHeadlessPrint: boolean }) {
		this.shellStore.update({ isHeadlessPrint: !!isHeadlessPrint });
	}

	updateShouldAnonymizeRx(shouldAnonymizeRx: boolean) {
		this.shellStore.update({ shouldAnonymizeRx });
	}
	updateAuthInfo(authInfo: AuthInfo) {
		this.authInfoStore.update({
			accessToken: authInfo.accessToken,
			sessionId: authInfo.sessionId,
			authUrl: authInfo.authUrl,
			sessionType: authInfo.sessionType,
			JWTState: JWTStateEnum.Ready
		});
		this.logger.setSessionParameters(authInfo.sessionId);
	}

	getRxsBulk({ rxIds }: { rxIds: number[] }) {
		return this.shellApiService.getRxsForPrint({ rxIds }).pipe(
			tap((rxsForPrint: RxModel[]) => {
				this.shellStore.update({ rxsForPrint });
			})
		);
	}

	loadAssetsForCache() {
		void this.cacheAssetsService.loadAssetsForCache();
	}

	updateOrderInformation(orderInformation: OrderInformationModel): void {
		if (!orderInformation) {
			return;
		}

		const orderInformationState: OrderInformationState = {
			numOfModels: orderInformation.NumOfModels,
			additionalDies: orderInformation.AdditionalDies,
			dieDitch: orderInformation.DieDitch,
			ideCadCamSystemId: orderInformation.IdeCadCamSystemId ? orderInformation.IdeCadCamSystemId : -1,
			localIdeCadCamSystemId: orderInformation.LocalIdeCadCamSystemId ? orderInformation.LocalIdeCadCamSystemId : -1
		};

		this.orderInformationStore.updateOrderInformation({ orderInformation: orderInformationState });
	}

	resetPreparedNewRxId() {
		this.shellStore.update({ preparedNewRxId: null });
	}

	loadConfigurationAndRxIfNeeded(): Observable<ApplicationConfiguration> {
		return combineLatest([
			this.loadRxConfigurationData(),
			this.loadRxIfNeeded(),
			this.shellApiService.getAppSettingsConfig(),
			this.loadContactNameIfNeeded(),
			this.shellQuery.isReadOnly$,
			this.shellQuery.enableAllCaseTypesForAddRx$
		]).pipe(
			filter(([configurationData, rx, appSettings]) => !!configurationData && !!rx && !!appSettings),
			map(([configurationData, rx, appSettings, contactName, isReadOnly, enableAllCaseTypesForAddRx]) => {
				const appConfiguration = configurationData.configuration;

				appConfiguration.contactName = contactName;

				const findVersionFlow = (rxModel, configurationVersion: RxVersion) => {
					return rx.rx?.Version ?? rx.clonedRx?.Version ?? configurationVersion;
				};

				if (!configurationData.isV1ConfigurationSupported) {
					appConfiguration.rxVersion = RxVersion.CaseTypeFlow;
				} else if (isReadOnly || this.hostPlatformService.isIteroModeling || RoleTypeEnum.Lab === this.shellQuery.userRole) {
					appConfiguration.rxVersion = findVersionFlow(rx, appConfiguration.rxVersion);
				} else {
					appConfiguration.rxVersion = Math.min(findVersionFlow(rx, appConfiguration.rxVersion), appConfiguration.rxVersion);
				}

				return { ...appConfiguration, ...rx, appSettings, enableAllCaseTypesForAddRx } as ApplicationConfiguration;
			}),
			tap(appConfiguration => {
				if (appConfiguration.rx == null) {
					this.shellStore.update({ preparedNewRxId: uuid() });
				}

				this.logApplicationConfigurationLoaded(appConfiguration);
				this.updateStoresWithConfiguration(appConfiguration);
				this.shellStore.updateProcedureChangedFlag(false);
				this.patientStore.setLoading(false);
				this.shellStore.setLoading(false);
				this.logBISessionStart(appConfiguration.rx?.ID ?? this.shellQuery.preparedNewRxId);
			})
		);
	}

	logBISessionStart(rxId: string) {
		this.logger.sessionStartEvent(this.shellQuery.contactId, this.shellQuery.companyId, this.shellQuery.rxSessionId);
	}

	updateIsSleeveCheckedSentByScanner(isSleeveCheckedSentByScanner: boolean) {
		this.scanOptionsStore.update({ isSleeveCheckedSentByScanner });
	}

	updateForceDisplayTeethNumberingSystem(teethNumberingSystem: TeethNumberingSystem) {
		if (!isTeethNumberingSystemValid(teethNumberingSystem)) {
			return;
		}

		this.teethDiagramStore.update({ forceDisplayTeethNumberingSystem: teethNumberingSystem });
	}

	filterProcedureMapsByIds(procedureMaps: ProcedureMap[], ids: number[]): ProcedureMap[] {
		return ids?.length && procedureMaps?.length ? procedureMaps.filter(({ Id }) => ids.includes(Id)) : [];
	}

	updatePrintStyleName(printStyleName: string) {
		this.shellStore.update({ printStyleName });
	}

	tryToSaveRx(isCalledByScanner: boolean): Observable<any> {
		this.logger.info('RXLog - Attempting to save Rx.', {
			module: this.facadeName,
			extendedParameters: {
				rxId: this.shellQuery.rx?.ID ?? this.shellQuery.preparedNewRxId,
				isFirstSave: String(this.shellQuery.isNewAndNotClonedRx)
			}
		});

		if (document.activeElement instanceof HTMLElement) {
			document.activeElement.blur();
		}

		if (this.toothEditorQuery.isToothEditorOpen) {
			this.forceSaveToothEditorService.forceSaveToothEditor$.next(true);
		}

		if (this.shellQuery.isPatientAppDialogOpen) {
			this.logger.info('Confirmation dialog from PatientApp blocks Rx to be saved', {
				module: this.facadeName,
				extendedParameters: { rxId: this.shellQuery?.rx?.ID }
			});

			if (isCalledByScanner) {
				this.rxSaved.next(stayInRx);
			}

			return of(false);
		}

		return this.validateRxAndPatientAppForSave().pipe(
			take(1),
			switchMap(isValid => {
				if (isValid) {
					return this.saveRxService.saveRx().pipe(this.afterSaveRx());
				}

				if (isCalledByScanner) {
					return this.handleInvalidRxOnScanner();
				}

				return this.handleInvalidRx();
			})
		);
	}

	afterSaveRx() {
		return pipe<Observable<RxModel>, Observable<any>, Observable<RxModel | any>>(
			tap(rxModel => {
				let model;

				if (this.hostPlatformService.isScanner && typeof rxModel !== 'object') {
					// save from scanner
					model = JSON.parse(rxModel);
				} else if (!rxModel.Result) {
					// save from lab
					model = rxModel;
				} else {
					// save from doctor
					model = rxModel.Result;
				}
				this.logger.logRXData(model, 'RXLog - Rx Saved Successfully.', this.facadeName, {
					traces: this.tracesQuery.savingGettingRxTraceIds
				});
				this.rxSaved.next(rxModel);
			}),
			catchError(error => {
				this.logger.error('RXLog - Error Saving Rx.', { module: this.facadeName, extendedParameters: error });
				this.rxSaveFailed.next(error);

				return of(error);
			})
		);
	}

    initPendo(): Observable<boolean> {
		return this.pendoService.initPendo();
	}

	private handleInvalidRxOnScanner(): Observable<void> {
		if (this.patientQuery.isPatientInConflict) {
			this.openPatientConflictPopUp();
			this.rxSaved.next(stayInRx);
		} else {
			this.rxSaved.next(undefined);
		}

		return of();
	}

	private handleInvalidRx(): Observable<boolean> {
		if (this.patientQuery.isPatientInConflict) {
			return this.openPatientConflictPopUp().pipe(this.afterConflictPopUpClosed());
		}

		return this.openValidationPopUp().pipe(this.afterPopUpClosed());
	}

	private afterConflictPopUpClosed() {
		return pipe<Observable<PopUpActions>, Observable<boolean>, Observable<boolean>>(
			switchMap(popUpResult => {
				if (popUpResult === PopUpActions.Ok) {
					return this.patientQuery.patientGuid$.pipe(
						find(guid => !!guid),
						switchMapTo(this.saveOnlyValidRx())
					);
				}

				return of(false);
			}),
			first()
		);
	}

	private afterPopUpClosed() {
		return pipe<Observable<PopUpActions>, Observable<boolean>>(
			map(popUpResult => {
				if (popUpResult === PopUpActions.Ok) {
					this.rxAttachmentsApiService
						.deleteRxAttachments({
							rxId: this.shellQuery.preparedNewRxId,
							companyId: this.shellQuery.companyId
						})
						.catch(() => {});

					this.rxSaved.next(undefined);
				}

				return false;
			})
		);
	}
	private saveOnlyValidRx(): Observable<any> {
		return this.validateRxForSave().pipe(
			take(1),
			switchMap(isValid => {
				if (isValid) {
					return this.saveRxService.saveRx().pipe(this.afterSaveRx());
				}

				this.logger.info('Attemt to save ivalid rx', {
					module: this.facadeName,
					extendedParameters: {
						traces: this.tracesQuery.configurationTraceIdsAsString + this.tracesQuery.savingGettingRxTraceIds
					}
				});

				return of(false);
			})
		);
	}

	private getConvertedRx({ rx }: { rx: RxModel }): RxModel {
		const convertLegacyRestorativeCaseTypeId = ({ caseTypeId }: { caseTypeId: CaseTypeEnum }): CaseTypeEnum =>
			isLegacyRestorative({ caseTypeId }) ? CaseTypeEnum.Restorative : caseTypeId;
		// eslint-disable-next-line @typescript-eslint/naming-convention
		const { Order } = rx;
		const order: OrderModel = { ...Order, CaseTypeId: convertLegacyRestorativeCaseTypeId({ caseTypeId: Order.CaseTypeId }) };
		const rxTeeth = rx.Teeth?.map(tooth => getToothWithReplacedForRxAppDefault(tooth));

		rx.Version = rx.Version ?? RxVersion.CaseTypeFlow;

		return { ...rx, Order: order, Teeth: rxTeeth };
	}

	private savePatient(): Observable<boolean> {
		return this.patientAppIframeCommunicationService.savePatient();
	}

	private relevantCommonEndpoints(
		companyId: number,
		contactId: number
	): ArrayOfObservables<[RxConfiguration, FeatureToggle[], UserSettings, number[], number[]]> {
		return [
			this.shellApiService.getProcedureFlowConfiguration({ companyId }),
			this.shellApiService.getFeatureToggles(),
			this.shellApiService.getUserSettings({ contactId }),
			this.orderApiService.getAvailableCaseTypeIds({ contactId, companyId }),
			this.orderApiService.getAvailableProcedureMapIds(contactId, companyId)
		];
	}

	private relevantWebEndpointsByDoctor(
		companyId: number,
		contactId: number
	): ArrayOfObservables<[CompanyScanner[], RxConfiguration, FeatureToggle[], UserSettings, number[], number[]]> {
		return [
			this.shellApiService.getCompaniesScanners({ companyId }).pipe(catchError(() => of([]))),
			...this.relevantCommonEndpoints(companyId, contactId)
		];
	}

	private legacyScannerEndpoints(companyId, contactId): ArrayOfObservables<[RxConfiguration, FeatureToggle[], UserSettings, number[]]> {
		return [
			this.shellApiService.getRxConfiguration({ companyId }),
			this.shellApiService.getFeatureToggles(),
			this.shellApiService.getUserSettings({ contactId }),
			this.orderApiService.getAvailableCaseTypeIds({ contactId, companyId })
		];
	}

	private readonlyEndpointsOrNotForDoctorEndpoints(
		companyId: number,
		contactId: number
	): ArrayOfObservables<[RxConfiguration, FeatureToggle[], UserSettings]> {
		return [
			this.shellApiService.getProcedureFlowConfiguration({ companyId }),
			this.shellApiService.getFeatureToggles(),
			this.shellApiService.getUserSettings({ contactId })
		];
	}

	private updateStoresWithConfiguration(appConfiguration: ApplicationConfiguration) {
		const {
			rxConfiguration,
			featureToggles,
			userSettings,
			rxVersion,
			availableCaseTypeIds,
			availableProcedureMaps,
			appSettings,
			clonedRx,
			isReferralWorkflowPractice,
			scannersVersions
		} = appConfiguration;
		const isLicenseEditable = !userSettings?.LicenseNumber;

		this.shellStore.update({
			rxConfiguration,
			rxVersionFlow: rxVersion,
			featureToggles,
			userSettings,
			appSettings,
			clonedRx,
			scannersVersions
		});
		this.doctorStore.update({ isLicenseEditable });
		this.orderStore.update({ availableCaseTypeIds, availableProcedureMaps, isDraftSelected: appConfiguration.rx?.IsDraft });
		this.rxForDoctorStore.update({ isReferralWorkflowPractice: isReferralWorkflowPractice ?? false });

		this.updateStoreWithUser(appConfiguration);
		this.updateStoreWithRx(appConfiguration);
	}

	private logApplicationConfigurationLoaded(appConfiguration: ApplicationConfiguration): void {
		const isLab = this.shellQuery.userRole === RoleTypeEnum.Lab && !this.hostPlatformService.isIteroLab;
		const traceIds = this.tracesQuery.configurationTraceIdsAsString;

		ConfigurationAnalyzer.analyzeAppConfiguration(
			appConfiguration,
			this.logger,
			this.shellQuery.contactId,
			isLab,
			this.hostPlatformService.isOrthoCad,
			this.tracesQuery.configurationTraceIdsAsString,
			this.shellQuery.isHeadlessPrint
		);

		this.logger.info('Rx-App configuration loaded', {
			module: this.facadeName,
			extendedParameters: {
				version: RxVersion[appConfiguration.rxVersion],
				configurationTraceIds: traceIds
			}
		});
	}

	private isBridgeInvalidForSend(teethInToothEditor: Tooth[]) {
		const bridgeValidation = this.shellQuery.isProcedureFlow
			? this.bridgeValidatorService.validateBridgeForProcedureFlow(teethInToothEditor)
			: this.bridgeValidatorService.validateBridgeForCaseTypeFlow(teethInToothEditor);

		if (!bridgeValidation.isValid) {
			this.snackBar.open(bridgeValidation.validationMessage, null, {
				duration: 6000,
				horizontalPosition: 'center',
				verticalPosition: 'top',
				panelClass: 'bridge-validation-message'
			});

			return true;
		} else {
			const bridgeTeeth: Tooth[] = teethInToothEditor.map((tooth: Tooth) => ({
				...tooth,
				IsValidForSend: !this.validateRxService.isTreatmentInvalidForSend(tooth)
			}));

			return bridgeTeeth.some(tooth => tooth.IsValidForSend === false);
		}
	}

	private updateStoreWithUser(appConfiguration: ApplicationConfiguration): void {
		let user: IdName;

		if (this.shellQuery.userRole === RoleTypeEnum.Doctor) {
			user = { Id: this.shellQuery.doctor.Id || this.shellQuery.contactId, Name: this.shellQuery.doctor.Name };
		} else {
			user = { Id: this.shellQuery.contactId, Name: appConfiguration.contactName || appConfiguration?.rx?.Order?.ShipToName };
		}

		this.shellStore.update({ user });
	}

	private updateStoreWithRx(appConfiguration: ApplicationConfiguration): void {
		let rx: RxModel = appConfiguration?.rx;

		const isRestorativeAddRxAvailable = this.addRxService.isRestorativeAddRxAvailable(
			appConfiguration.featureToggles,
			appConfiguration.enableAllCaseTypesForAddRx
		);
		const allowToClone = this.addRxService.isAllowToCloneOrder(
			appConfiguration?.clonedRx?.Order?.ProcedureId,
			appConfiguration.availableProcedureMaps
		);

		if (isRestorativeAddRxAvailable && !!allowToClone && !rx) {
			rx = this.addRxService.cloneRx(appConfiguration.clonedRx);
		} else if (isRestorativeAddRxAvailable && !rx) {
			// this is the case when client want to clone order that couldn't be cloned because of selected procedure
			this.logger.info('Attemt to clone rx with ivalid for clone procedure', {
				module: this.facadeName,
				extendedParameters: {
					procedure: appConfiguration.clonedRx?.Order?.ProcedureId.toString()
				}
			});
			rx = undefined;
			this.shellStore.update({ clonedFromRxId: '', clonedRx: undefined });
		}

		// ToDo: move into order-information.facade and made method like loadOrderInfoFromRx
		// update orderInformation prior to Rx, because RxUpdated event takes Rx from its store and OrderInformation from its store
		this.updateOrderInformation(rx?.OrderInformation);

		this.shellStore.update({ rx });
	}

	private getMessageForInvalidValidation(): string {
		if (this.hostPlatformService.isIteroLab) {
			return this.translateService.instant('OrderInformation.ValidationError');
		}

		return this.translateService.instant('Validation.RequiredFields');
	}
}
