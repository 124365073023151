import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { ControlValueAccessorDirective } from '@shared/base-classes/control-value-accessor.directive';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { TlkErrorStateMatcher } from '@itero/ui-toolkit-angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { TlkTextInputComponent } from '@itero/ui-toolkit-angular/text-input';
import { NgIf } from '@angular/common';

@Component({
	selector: 'rx-text-input',
	template: `
		<tlk-text-input
			[id]="id"
			[label]="label"
			[placeholder]="placeholder"
			[required]="isRequired"
			[isDisabled]="isDisabled"
			[cancelable]="isCancellable"
			[name]="name"
			[autocomplete]="autocomplete"
			(disableChangeEvent)="disableChangeEvent.emit($event)"
			[formControl]="control"
			[errorStateMatcher]="errorStateMatcher"
		>
			<ng-container tlk-error *ngIf="control?.hasError('required')">
				{{ 'Validation.RequiredField' | translate }}
			</ng-container>
			<ng-container tlk-hint *ngIf="hintText">
				{{ hintText }}
			</ng-container>
		</tlk-text-input>
	`,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: TextInputComponent,
			multi: true
		}
	],
	imports: [TlkTextInputComponent, TranslateModule, ReactiveFormsModule, NgIf],
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextInputComponent extends ControlValueAccessorDirective<string | null> {
	@Input()
	id?: string;

	@Input()
	label?: string;

	@Input()
	placeholder = '';

	@Input()
	isRequired = false;

	@Input()
	isDisabled = false;

	@Input()
	isCancellable: boolean;

	@Input()
	name?: string;

	@Input()
	autocomplete: string | null = 'off';

	@Input()
	hintText?: string;

	@Input()
	errorStateMatcher?: TlkErrorStateMatcher;

	@Output()
	disableChangeEvent = new EventEmitter<boolean>();

	constructor(
		public cdr: ChangeDetectorRef,
		injector: Injector
	) {
		super(injector);
	}
}
