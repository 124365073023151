import { AfterViewInit, Component, Injector, Input, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessorDirective } from '@shared/base-classes/control-value-accessor.directive';
import { TemplatePortal } from '@angular/cdk/portal';
import { RxDatepicker } from '@shared/components/rx-datepicker/rx-datepicker.component';
import { ErrorStateMatcherForInstantDisplay } from '@shared/utils/is-error.state';

let dueDatePickerUid = 0;

@Component({
	selector: 'rx-due-date-picker',
	templateUrl: './due-date-picker.component.html',
	styleUrls: ['./due-date-picker.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: DueDatePickerComponent,
			multi: true
		}
	]
})
export class DueDatePickerComponent<T extends Date> extends ControlValueAccessorDirective<T> implements AfterViewInit {
	@Input() id = `due-date-picker-${dueDatePickerUid++}`;
	@Input() minimalAvailableDueDate: Date;
	@Input() maxDueDate: Date;
	@Input() isRequired: boolean;
	@Input() isReadOnly: boolean;
	@Input() isCancellable: boolean;
	@Input() matcher = new ErrorStateMatcherForInstantDisplay();

	@ViewChild('footer') footer: TemplateRef<unknown>;
	@ViewChild('dueDateDatepicker') datepicker: RxDatepicker<any>;

	constructor(injector: Injector) {
		super(injector);
	}

	ngAfterViewInit() {
		const vcr = this.injector.get(ViewContainerRef);

		this.datepicker.contentPortal = new TemplatePortal(this.footer, vcr);
	}

	handleCancelSelection(event: Event) {
		event.stopPropagation();
		this.control.patchValue(null);
		this.datepicker.close();
	}

	get isCancelButtonDisabled(): boolean {
		return this.isCancellable && !this.control?.value;
	}
}
