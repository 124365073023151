import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { Bites } from '@modules/teeth-diagram/models/bites.enum';

export interface ScanOptionsState {
	isSleeveChecked: NewSleeveAttachedState;
	isSleeveCheckedSentByScanner: boolean;
	isNiriCaptureChecked: boolean;
	isPreTreatmentChecked: boolean;
	isEmergenceProfileChecked: boolean;
	additionalBites: Record<string, boolean>;
	isRestoMultiBiteSelected: boolean;
}

export interface NewSleeveAttachedState {
	value: boolean;
	isValueChangedByUser: boolean;
}

export const initializeAdditionalBites = (): Record<Bites, boolean> => {
	const bites: Record<Bites, boolean> = {} as Record<Bites, boolean>;

	Object.values(Bites).forEach(key => {
		bites[key as Bites] = false;
	});

	return bites;
};

export const createInitialState = (): ScanOptionsState => ({
	isSleeveChecked: { value: false, isValueChangedByUser: false },
	isSleeveCheckedSentByScanner: false,
	isNiriCaptureChecked: false,
	isPreTreatmentChecked: false,
	isEmergenceProfileChecked: false,
	additionalBites: initializeAdditionalBites(),
	isRestoMultiBiteSelected: false
});

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'scan-options', resettable: true })
export class ScanOptionsStore extends Store<ScanOptionsState> {
	constructor() {
		super(createInitialState());
	}

	reset() {
		const initialState = createInitialState();
		const newState = {
			...this.getValue(),
			isPreTreatmentChecked: initialState.isPreTreatmentChecked,
			isEmergenceProfileChecked: initialState.isEmergenceProfileChecked,
			isRestoMultiBiteSelected: initialState.isRestoMultiBiteSelected,
			additionalBites: initialState.additionalBites
		};

		this.update(newState);
	}

	resetEmergenceProfile() {
		this.update({ isEmergenceProfileChecked: createInitialState().isEmergenceProfileChecked });
	}
}
