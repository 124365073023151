import { Component } from '@angular/core';
import { DentureDetailsUtils } from '@modules/denture-details/utils/denture-details-utils';
import { RxForModelingFacade } from '@modules/rx-for-modeling/rx-for-modeling.facade';
import { RoleTypeEnum } from '@shared/models/role-type';
import { Observable } from 'rxjs';

@Component({
	selector: 'app-rx-for-modeling',
	templateUrl: './rx-for-modeling.component.html',
	styleUrls: ['./rx-for-modeling.component.scss'],
	providers: [RxForModelingFacade]
})
export class RxForModelingComponent {
	isRestoOrder$: Observable<boolean> = this.facade.isRestoOrder$;
	isProcedureFlow$: Observable<boolean> = this.facade.isProcedureFlow$;
	isDentureDetailsVisible$: Observable<boolean> = this.facade.isDentureDetailsVisible$;
	role: RoleTypeEnum = RoleTypeEnum.Technician;
	rx$ = this.facade.rx$;
	procedureId$: Observable<number> = this.facade.procedureId$;

	isDentureDetailsVisible(procedureId: number): boolean {
		return DentureDetailsUtils.isDentureDetailsEnabled(procedureId);
	}

	constructor(private facade: RxForModelingFacade) {}
}
