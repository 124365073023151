<div
	class="rx-card"
	[class.read-only]="isReadOnly$ | async"
    *ngIf="formState$ | async as formState"
>
	<div class="rx-card-title">
		<div
			class="rx-title-row"
			fxLayout="row"
			fxLayoutAlign="start center"
		>
			<div class="rx-card-title-text">{{ 'OrderSection.Order' | translate }}:</div>
		</div>
	</div>
    <rx-banner [type]="'info'" *ngIf="isSendToIDSMessageVisible$ | async"
               [message]="'OrderSection.StudyModelsAreAvailableForInvisalignMessage' | translate"
               class="banner-inside"
    ></rx-banner>
	<form
		class="rx-card-body"
		[formGroup]="secondaryForm"
		fxLayout="row"
		fxLayoutAlign="space-between"
	>
		<div fxLayout="column" fxLayoutAlign="start stretch" fxFlex="0 1 calc(50% - 15px)">
            <rx-object-selector
                [id]="'procedure'"
                [label]="'OrderSection.Procedure' | translate"
                [formControl]="procedureControl"
                [options]="procedures$ | async"
                [isRequired]="true"
                [isReadOnly]="isProcedureAndTypeReadOnly$ | async"
                name="procedureControl"
                panelClass="fit-content">
            </rx-object-selector>

            <rx-object-selector
                *ngIf="formState?.treatmentStage?.isVisible"
                [id]="'treatmentStage'"
                [label]="'OrderSection.TreatmentStage' | translate"
                [formControl]="treatmentStageControl"
                [options]="treatmentStages$ | async"
                name="treatmentStage"
                [isCancellable] = "true"
                [isDisabled]="!formState.treatmentStage.isEnabled"
                (isDisabledEvent)="clearControl(treatmentStageControl)"
                [isReadOnly]="isReadOnly$ | async">
            </rx-object-selector>

            <rx-due-date-picker
				*ngIf="formState?.dueDate?.isVisible"
                [isRequired]="formState?.dueDate?.isRequired"
				[formControl]="dueDateControl"
				[isCancellable]="!formState.dueDate?.isRequired"
                [isReadOnly]="isReadOnly$ | async"
				[minimalAvailableDueDate]="minimalAvailableDueDate$ | async"
				name="dueDateControl"
                (rxIsDisabledEvent)="clearControl(dueDateControl)"
                [rxIsDisabled]="!formState?.dueDate.isEnabled"
			></rx-due-date-picker>
		</div>

		<div fxLayout="column" fxLayoutAlign="start stretch" fxFlex="0 1 calc(50% - 15px)">
            <rx-icast-ortho-model-toggle
                *ngIf="formState?.iCastOrthoModel?.isVisible"
                class="icast-ortho-model"
                [formControl]="typeControl"
                [turnedOnValue]="iCastOrthoModelOnValue$ | async"
                name="isIcastOrthoModelSelectedControl"
                [rxIsDisabled]="isiCastToggleDisabled$ | async"
            >
            </rx-icast-ortho-model-toggle>

            <rx-object-selector
                *ngIf="formState?.type?.isVisible"
                [id]="'type'"
                [label]="'OrderSection.Type' | translate"
                [formControl]="typeControl"
                [options]="types$ | async"
                [isRequired]="true"
                [isDisabled]="!formState.type.isEnabled"
                [isReadOnly]="isProcedureAndTypeReadOnly$ | async"
                name="typeControl"
				panelClass="rx-object-options-panel-8">
            </rx-object-selector>
			<rx-favorite-selector
					*ngIf="formState?.sendTo?.isVisible"
					[id]="'sendTo'"
					[label]="'OrderSection.SendTo' | translate"
					[formControl]="sendToControl"
					[options]="availableLabs$ | async"
					name="sendToControl"
					[isCancellable] = "isCancellable$ | async"
					[isDisabled]="!formState.sendTo.isEnabled"
					[isRequired]="formState.sendTo.isRequired"
					[isReadOnly]="isReadOnly$ | async"
					[favoriteName]="favoriteSendTo"
					[enableSimpleMode]="isNotDoctor$ | async"
			></rx-favorite-selector>
			<rx-current-aligner-input
				*ngIf="formState?.currentAlignerId?.isVisible"
				[formControl]="currentAlignerIdControl"
                [isReadOnly]="isReadOnly$ | async"
				name="currentAlignerIdControl"
                [rxIsDisabled]="!formState.currentAlignerId.isEnabled"
			></rx-current-aligner-input>
		</div>
	</form>
</div>
