import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Awareness, AwarenessPopUpActions } from '@shared/models/enums/awareness.enum';
import { AwarenessFacade } from '@modules/awareness/awareness.facade';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DialogButton } from '@modules/awareness/components/awareness-popup-layout/awareness-popup-layout.component';
import { customerSupportLink } from '@shared/models/consts';

@Component({
	selector: 'rx-awareness-popup',
	templateUrl: './awareness-popup.component.html',
	styleUrls: ['./awareness-popup.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AwarenessPopupComponent {
	learningCenterLink$ = this.awarenessFacade.linkToTheLearningCenter$;
	showLearningCenterPart$ = this.awarenessFacade.showLearningCenterPart$;
	shouldNotifyCompanyByEmail$ = this.awarenessFacade.shouldNotifyCompanyByEmail$;
	customerSupportLink = customerSupportLink;

	awarenessEnum = Awareness;

	buttonsBeforeLmr: DialogButton[] = [
		{ matDialogClose: AwarenessPopUpActions.RemindLater, term: 'Awareness.PopupRemindMeBeforeLmr' },
		{ matDialogClose: AwarenessPopUpActions.Confirm, term: 'Awareness.PopupConfirmBeforeLmr', confirm: true }
	];

	buttonsBeforeGA: DialogButton[] = [
		{ matDialogClose: AwarenessPopUpActions.Confirm, term: 'Awareness.PopupConfirmBeforeGA', confirm: true }
	];

	buttonsAfterLmrOrGA: DialogButton[] = [
		{ matDialogClose: AwarenessPopUpActions.Confirm, term: 'Awareness.PopupConfirmAfterLmr', confirm: true }
	];

	constructor(private awarenessFacade: AwarenessFacade, @Inject(MAT_DIALOG_DATA) public awareness: Awareness) {}
}
