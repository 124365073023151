import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { GeneralRxDataV1Facade } from '@modules/general-rx-data/general-rx-data-v1.facade';

@Component({
	selector: 'rx-general-data-v1',
	templateUrl: './general-rx-data-v1.component.html',
	styleUrls: ['./general-rx-data-v1.component.scss'],
	providers: [GeneralRxDataV1Facade],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class GeneralRxDataV1Component {
	dateFormat$: Observable<string> = this.facade.dateFormat$;
	rx$ = this.facade.rx$;
	rxConfiguration$ = this.facade.rxConfiguration$;
	isRxForModelingContext$ = this.facade.isRxForModelingContext$;
	isResponsive = true;

	constructor(private facade: GeneralRxDataV1Facade) {}
}
