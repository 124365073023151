import { Component } from '@angular/core';
import { SpinnerService } from '@core/services/spinner.service';
import { NgIf } from '@angular/common';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'rx-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss'],
    standalone: true,
    imports: [NgIf]
})
export class SpinnerComponent {
	active = false;

	constructor(private spinner: SpinnerService) {
		this.spinner.onActiveChanged.subscribe((isActive: boolean) => {
			this.active = isActive;
		});
	}
}
