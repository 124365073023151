<ng-template #aside>
	<ng-container [ngSwitch]="shouldNotifyCompanyByEmail$ | async">
		<ng-container *ngSwitchCase="true">
			<span class="base-text use-email-text">{{ 'Awareness.PopupUseEmail' | translate }}</span>
			<div class="auto-show-customer-support-link" fxLayout="column" *ngIf="showLearningCenterPart$ | async">
				<span class="or-text">{{ 'Awareness.PopupLearningCenterOrPart' | translate | lowercase }}</span>
				<span class="base-text">
				<a target="_blank" rel="noopener noreferrer" [href]="customerSupportLink">
					{{ 'Awareness.PopupCustomerSupport' | translate }}
				</a>
				</span>
			</div>
		</ng-container>
		<ng-container *ngSwitchCase="false">
			<span class="base-text use-qr-text">{{ 'Awareness.PopupUseQr' | translate }}</span>
			<rx-awareness-qr-code></rx-awareness-qr-code>
			<div class="auto-show-learning-center-part" fxLayout="column" *ngIf="showLearningCenterPart$ | async">
				<span class="or-text">{{ 'Awareness.PopupLearningCenterOrPart' | translate | lowercase }}</span>
				<span class="base-text mb-0">
					{{ 'Awareness.PopupLearningCenterVisitPart' | translate }}<br />
					<a target="_blank" rel="noopener noreferrer" [href]="learningCenterLink$ | async">
					{{ 'Awareness.PopupLearningCenter' | translate }}
					</a>
				</span>
			</div>
		</ng-container>
	</ng-container>
</ng-template>

<rx-awareness-popup-layout
	class="rx-awareness-popup-before-lmr"
	*ngIf="awareness === awarenessEnum.BeforeLMR"
	[buttons]="buttonsBeforeLmr"
>
	<ng-container ngProjectAs="[main-title]">{{ 'Awareness.PopupTitleBeforeLmr' | translate }}</ng-container>
	<ng-container ngProjectAs="[sub-title]">{{ 'Awareness.PopupSubTitleBeforeLmr' | translate }}</ng-container>
	<ng-container ngProjectAs="[aside]" *ngTemplateOutlet="aside"></ng-container>
	<ng-container ngProjectAs="[article]">{{ 'Awareness.PopupMainTextBeforeLmr' | translate }}</ng-container>
</rx-awareness-popup-layout>

<rx-awareness-popup-layout
	class="rx-awareness-popup-before-ga"
	*ngIf="awareness === awarenessEnum.BeforeGA"
	[buttons]="buttonsBeforeGA"
>
	<ng-container ngProjectAs="[main-title]">{{ 'Awareness.PopupTitleBeforeGA' | translate }}</ng-container>
	<ng-container ngProjectAs="[sub-title]">{{ 'Awareness.PopupSubTitleBeforeGA' | translate }}</ng-container>
	<ng-container ngProjectAs="[aside]" *ngTemplateOutlet="aside"></ng-container>
	<ng-container ngProjectAs="[article]">{{ 'Awareness.PopupMainTextBeforeGA' | translate }}</ng-container>
</rx-awareness-popup-layout>

<rx-awareness-popup-layout
	[ngClass]="{
	'rx-awareness-popup-after-lmr': awareness === awarenessEnum.AfterLMR,
	 'rx-awareness-popup-after-ga': awareness === awarenessEnum.AfterGA
	 }"
	*ngIf="awareness === awarenessEnum.AfterLMR || awareness === awarenessEnum.AfterGA"
	[buttons]="buttonsAfterLmrOrGA"
>
	<ng-container ngProjectAs="[main-title]">{{ 'Awareness.PopupTitleAfterLmr' | translate }}</ng-container>
	<ng-container ngProjectAs="[sub-title]">{{ 'Awareness.PopupSubTitleAfterLmr' | translate }}</ng-container>
	<ng-container ngProjectAs="[aside]" *ngTemplateOutlet="aside"></ng-container>
	<ng-container ngProjectAs="[article]">{{ 'Awareness.PopupMainTextAfterLmr' | translate }}</ng-container>
</rx-awareness-popup-layout>
