import { Inject, Injectable } from '@angular/core';
import { AuthInfoQuery } from '@shared/store/authInfo/auth-info-query';
import { WINDOW } from '@shared/tokens';

@Injectable({
	providedIn: 'root'
})
export class HostDispatcher {
	constructor(private authInfoQuery: AuthInfoQuery, @Inject(WINDOW) private window: Window) {}

	public dispatchExpiredTokenEvent(appId: string) {
		const returnUrl = this.window.location.pathname + this.window.location.search;
		const sessionExpired = new CustomEvent('midc_event_onTokenExpired', {
			detail: {
				appId,
				sessionId: this.authInfoQuery.authInfo.sessionId,
				payload: { returnUrl }
			}
		});

		this.window.dispatchEvent(sessionExpired);
	}
}
