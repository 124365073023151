import { PositionObject } from "@shared/models/position-object";

export const extractPositionObject = ({ event }: { event: any }): PositionObject => {
    const rect: DOMRect = event.target.getBoundingClientRect();
    const rectRight: number = rect.right;
    const rectLeft: number = rect.left;
    const clientXposition: number = event.clientX;
    const clientYposition: number = event.clientY;
    return {
        rectRight,
        rectLeft,
        clientXposition,
        clientYposition
    };
}