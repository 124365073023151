import { Injectable } from '@angular/core';
import { ApiService, IApiCall } from '@core/services/api.service';
import { RxModel } from '@shared/models/rx-models/interfaces/rx-model';
import { TracesStore } from '@shared/store/traces/traces-store';
import { HttpUtils } from '@shared/utils/http-utils';
import { map, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RxForDoctorApiService {
	constructor(
		private apiService: ApiService,
		private tracesStore: TracesStore
	) {}

	saveRx(rx: RxModel) {
		const config: IApiCall = {
			selector: 'saveRx',
			options: { observe: 'response', body: rx }
		};

		return this.apiService.request(config).pipe(
			tap(response => this.tracesStore.update({ savingTraceId: HttpUtils.getTraceId(response) })),
			map(response => response?.body)
		);
	}
}
