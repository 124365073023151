<div *ngIf="!isHidden" class="rx-card">
    <div class="rx-card-title">
        <div class="rx-title-row" fxLayout="row" fxLayoutAlign="start center">
            <div class="treatment-information-title">{{ 'TreatmentInformation.TreatmentInformation' | translate }}</div>
        </div>
    </div>
    <div
        class="rx-card-body"
        *ngIf="
            isTreatmentsTableShownEmpty ||
            (treatments$ | async)?.length
        "
    >
        <table mat-table [dataSource]="treatments$" multiTemplateDataRows>
            <ng-container [matColumnDef]="'Tooth No'">
                <th mat-header-cell *matHeaderCellDef scope="col">
                    {{ 'TreatmentInformation.ToothNo' | translate }}
                </th>
                <td
                    mat-cell
                    *matCellDef="let tooth"
                    [class.invalid-for-send]="tooth.IsInvalidForSend"
                    [id]="'treatment-info-tooth-no-' + tooth.ToothID"
                >
                    <div>
                        {{ tooth.ToothID | mapper : getToothNumberById.bind(this) | async }}
                    </div>
                    <div class="invalid-for-send" *ngIf="tooth.IsInvalidForSend">
                        <p class="error-message">{{ 'TreatmentInformation.TreatmentErrorMessage' | translate }}</p>
                    </div>
                </td>
            </ng-container>
            <ng-container [matColumnDef]="'Treatment'">
                <th mat-header-cell *matHeaderCellDef scope="col">
                    {{ 'TreatmentInformation.Treatment' | translate }}
                </th>
                <td
                    [class.error-tr-field]="tooth.IsInvalidForSend"
                    mat-cell
                    *matCellDef="let tooth"
                    [id]="'treatment-info-treatment-' + tooth.ToothID"
                >
                    {{ tooth | mapper : getUnitType.bind(this) }}
                    <span *ngIf="isPrintContext">{{ tooth | mapper : getAdditionalInfo }}</span>
                </td>
            </ng-container>
            <ng-container [matColumnDef]="'Material'">
                <th mat-header-cell *matHeaderCellDef scope="col">
                    {{ 'TreatmentInformation.Material' | translate }}
                </th>
                <td
                    [class.error-tr-field]="tooth.IsInvalidForSend"
                    mat-cell
                    *matCellDef="let tooth"
                    [id]="'treatment-info-material-' + tooth.ToothID"
                >
                    {{  tooth.MaterialID | mapper : getMaterialName.bind(this) | async | emptyStringAsHyphen }}
                </td>
            </ng-container>
            <ng-container [matColumnDef]="'Preparation Design'">
                <th mat-header-cell *matHeaderCellDef scope="col">
                    {{ 'TreatmentInformation.PreparationDesign' | translate }}
                    <div>{{ 'Buccal/Lingual' | translate }}</div>
                </th>
                <td
                    [class.error-tr-field]="tooth.IsInvalidForSend"
                    mat-cell
                    *matCellDef="let tooth"
                    [id]="'treatment-info-preparation-design-' + tooth.ToothID"
                >
                    {{ tooth.PreparationDesignBuccal | emptyStringAsHyphen | translate }}/{{
                        tooth.PreparationDesignLingual | emptyStringAsHyphen | translate
                    }}
                </td>
            </ng-container>
            <ng-container [matColumnDef]="'Shade'">
                <th mat-header-cell *matHeaderCellDef scope="col">
                    {{ 'TreatmentInformation.Shade' | translate }}
                    <div>
                        {{ 'TreatmentInformation.Incisal' | translate }}/{{ 'TreatmentInformation.Body' | translate }}/{{
                            'TreatmentInformation.Gingival' | translate
                        }}
                    </div>
                </th>
                <td
                    [class.error-tr-field]="tooth.IsInvalidForSend"
                    mat-cell
                    *matCellDef="let tooth"
                    [id]="'treatment-info-shade-' + tooth.ToothID"
                >
                    {{ tooth.ShadeIncisal | emptyStringAsHyphen }}/{{ tooth.ShadeBody | emptyStringAsHyphen }}/{{
                        tooth.ShadeGingival | emptyStringAsHyphen
                    }}
                </td>
            </ng-container>
            <ng-container [matColumnDef]="'Show details'">
                <th mat-header-cell *matHeaderCellDef></th>
                <td
                    [class.error-tr-field]="tooth.IsInvalidForSend"
                    mat-cell
                    *matCellDef="let tooth"
                    [id]="'treatment-info-show-details-' + tooth.ToothID"
                >
                    <a [ngClass]="{ link: true, disabled: !isTreatmentClickable(tooth) }" (click)="openToothEditor($event, tooth)">
                        {{ 'TreatmentInformation.ShowDetails' | translate }}</a
                    >
                </td>
            </ng-container>
            <ng-container [matColumnDef]="'Stump Shade'">
                <th mat-header-cell *matHeaderCellDef scope="col">
                    {{ 'TreatmentInformation.StumpShade' | translate }}
                </th>
                <td [class.error-tr-field]="tooth.IsInvalidForSend" mat-cell *matCellDef="let tooth">
                    {{ tooth.StumpfShade | emptyStringAsHyphen }}
                </td>
            </ng-container>
            <ng-container matColumnDef="expandedDetailStart">
                <td [class.error-tr-field]="tooth.IsInvalidForSend" mat-cell *matCellDef="let tooth" colspan="1"></td>
            </ng-container>
            <ng-container matColumnDef="expandedDetailEnd">
                <td
                    [class.error-tr-field]="tooth.IsInvalidForSend"
                    mat-cell
                    *matCellDef="let tooth"
                    [attr.colspan]="displayedColumns.length - 1"
                >
                    <div class="abutment-info">
                        <span>{{ 'TreatmentInformation.AbutmentType' | translate }}: </span>
                        <span class="text-muted">{{
                tooth | mapper : getAbutmentTypeById.bind(this) | translate
                        }}</span
                        >{{ ' ; ' }}
                        <span>{{ 'TreatmentInformation.Material' | translate }}: </span>
                        <span class="text-muted"
                            >{{
                (tooth.CAMaterialID || tooth.AbutmentMaterialId) | mapper : getMaterialName.bind(this) | async | emptyStringAsHyphen

                            }}{{ ' ; ' }}</span
                        >
                        <span>{{ 'TreatmentInformation.MarginStyle' | translate }}: </span>
                        <span class="text-muted">{{ tooth.AbutmentMarginStyle || tooth.CAMarginStyle | emptyStringAsHyphen }}</span>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr
                mat-row
                *matRowDef="let tooth; columns: displayedColumns"
                [id]="'treatment-info-' + tooth.ToothID"
                [class.clickable-row]="tooth | mapper : isTreatmentClickable.bind(this)"
                (click)="openToothEditor($event, tooth)"
            ></tr>
            <tr
                mat-row
                *matRowDef="let tooth; columns: ['expandedDetailStart', 'expandedDetailEnd']"
                [id]="'treatment-info-details-' + tooth.ToothID"
                class="detail-row"
                [class.show-scan-body-info]="tooth | mapper : isAbutmentInfoAvailable.bind(this)"
                [class.clickable-row]="tooth | mapper : isTreatmentClickable.bind(this)"
                (click)="openToothEditor($event, tooth)"
            ></tr>
			<tr id="treatment-info-no-data" class="mat-row detail-row" *matNoDataRow
				[class.show-nodata]="(isPrintContext && hasNoBridge)">
				<td class="mat-cell" [attr.colspan]="displayedColumns.length">
			  		{{ 'TreatmentInformation.NoData' | translate }}
				</td>
			</tr>
        </table>
    </div>
    <div class="bridges-container">
        <div class="rx-card bridge-container" *ngFor="let bridge of bridges$ | async">
            <div class="rx-card-title">
                <div class="rx-title-row" fxLayout="row" fxLayoutAlign="start center">
                    <div class="bridge-information-title" [id]="'treatment-info-bridge' + (bridge | mapper: getBridgeSpanId)">
                        {{ 'TreatmentInformation.Bridge' | translate }}
                        {{ bridge | mapper: getBridgeSpanTitle }}
                    </div>
                </div>
            </div>
            <div class="rx-card-body" [class.print-context]="isPrintContext">
                <table mat-table [dataSource]="bridge" multiTemplateDataRows>
                    <ng-container [matColumnDef]="'Tooth No'">
                        <th mat-header-cell *matHeaderCellDef scope="col">
                            {{ 'TreatmentInformation.ToothNo' | translate }}
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let tooth"
                            [class.invalid-for-send]="tooth.IsInvalidForSend"
                            [id]="'treatment-info-bridge-tooth-no' + (bridge | mapper: getBridgeSpanId) + '-' + tooth.ToothID"
                        >
                            <div>
                                {{ tooth.ToothID | mapper : getToothNumberById.bind(this) | async }}
                            </div>
                            <div class="invalid-for-send" *ngIf="tooth.IsInvalidForSend">
                                <p class="error-message">{{ 'TreatmentInformation.TreatmentErrorMessage' | translate }}</p>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container [matColumnDef]="'Treatment'">
                        <th mat-header-cell *matHeaderCellDef scope="col">
                            {{ 'TreatmentInformation.Treatment' | translate }}
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let tooth"
                            [id]="'treatment-info-bridge-treatment' + (bridge | mapper: getBridgeSpanId) + '-' + tooth.ToothID"
                        >
                            {{ tooth | mapper : getBridgeTreatment.bind(this) }}
                            <span *ngIf="isPrintContext">{{ tooth | mapper : getAdditionalInfo }}</span>
                        </td>
                    </ng-container>
                    <ng-container [matColumnDef]="'Material'">
                        <th mat-header-cell *matHeaderCellDef scope="col">
                            {{ 'TreatmentInformation.Material' | translate }}
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let tooth"
                            [id]="'treatment-info-bridge-material' + (bridge | mapper: getBridgeSpanId) + '-' + tooth.ToothID"
                        >
                            {{ tooth.MaterialID | mapper : getMaterialName.bind(this) | async | emptyStringAsHyphen }}
                        </td>
                    </ng-container>
                    <ng-container [matColumnDef]="'Preparation Design'">
                        <th mat-header-cell *matHeaderCellDef scope="col">
                            {{ 'TreatmentInformation.PreparationDesign' | translate }}
                            <div>{{ 'Buccal/Lingual' | translate }}</div>
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let tooth"
                            [id]="'treatment-info-bridge-preparation-design' + (bridge | mapper: getBridgeSpanId) + '-' + tooth.ToothID"
                        >
                            {{ tooth.PreparationDesignBuccal | emptyStringAsHyphen | translate }}/{{
                                tooth.PreparationDesignLingual | emptyStringAsHyphen | translate
                            }}
                        </td>
                    </ng-container>
                    <ng-container [matColumnDef]="'Shade'">
                        <th mat-header-cell *matHeaderCellDef scope="col">
                            {{ 'TreatmentInformation.Shade' | translate }}
                            <div>
                                {{ 'TreatmentInformation.Incisal' | translate }}/{{ 'TreatmentInformation.Body' | translate }}/{{
                                    'TreatmentInformation.Gingival' | translate
                                }}
                            </div>
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let tooth"
                            [id]="'treatment-info-bridge-shade' + (bridge | mapper: getBridgeSpanId) + '-' + tooth.ToothID"
                        >
                            {{ tooth.ShadeIncisal | emptyStringAsHyphen }}/{{ tooth.ShadeBody | emptyStringAsHyphen }}/{{
                                tooth.ShadeGingival | emptyStringAsHyphen
                            }}
                        </td>
                    </ng-container>
                    <ng-container [matColumnDef]="'Show details'">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td
                            mat-cell
                            *matCellDef="let tooth"
                            [id]="'treatment-info-bridge-show-details' + (bridge | mapper: getBridgeSpanId) + '-' + tooth.ToothID"
                        >
                            <a [ngClass]="{ link: true, disabled: !isTreatmentClickable(tooth) }" (click)="openToothEditor($event, tooth)">
                                {{ 'TreatmentInformation.ShowDetails' | translate }}</a
                            >
                        </td>
                    </ng-container>
                    <ng-container [matColumnDef]="'Stump Shade'">
                        <th mat-header-cell *matHeaderCellDef scope="col">
                            {{ 'TreatmentInformation.StumpShade' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let tooth">
                            {{ tooth.StumpfShade | emptyStringAsHyphen }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="expandedDetailStart">
                        <td mat-cell *matCellDef="let tooth" colspan="1"></td>
                    </ng-container>
                    <ng-container matColumnDef="expandedDetailEnd">
                        <td mat-cell *matCellDef="let tooth" [attr.colspan]="displayedColumns.length - 1">
                            <div class="abutment-info">
                                <span>{{ 'TreatmentInformation.AbutmentType' | translate }}: </span>
                                <span class="text-muted">{{
                  tooth | mapper : getAbutmentTypeById.bind(this) | translate
                                }}</span
                                >{{ ' ; ' }}
                                <span>{{ 'TreatmentInformation.Material' | translate }}: </span>
                                <span class="text-muted"
                                    >{{(tooth.CAMaterialID || tooth.AbutmentMaterialId) | mapper : getMaterialName.bind(this)
                      | async
                      | emptyStringAsHyphen

                                    }}{{ ' ; ' }}</span
                                >
                                <span>{{ 'TreatmentInformation.MarginStyle' | translate }}: </span>
                                <span class="text-muted">{{ tooth.AbutmentMarginStyle || tooth.CAMarginStyle | emptyStringAsHyphen }}</span>
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr
                        mat-row
                        *matRowDef="let tooth; columns: displayedColumns"
                        [id]="'treatment-info-bridge-' + tooth.ToothID"
                        [class.clickable-row]="tooth | mapper : isTreatmentClickable.bind(this)"
                        (click)="openToothEditor($event, tooth)"
                    ></tr>
                    <tr
                        mat-row
                        *matRowDef="let tooth; columns: ['expandedDetailStart', 'expandedDetailEnd']"
                        [id]="'treatment-info-bridge-details-' + tooth.ToothID"
                        class="detail-row"
                        [class.show-scan-body-info]="tooth | mapper : isAbutmentInfoAvailable.bind(this)"
                        [class.clickable-row]="tooth | mapper : isTreatmentClickable.bind(this)"
                        (click)="openToothEditor($event, tooth)"
                    ></tr>
                </table>
            </div>
        </div>
    </div>
</div>
