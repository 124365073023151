import { Injectable } from '@angular/core';
import { Tooth } from '@modules/teeth-diagram/models/tooth';
import { UnitTypesInBridge } from '@modules/teeth-diagram/models/unit-type-in-bridge.enum';
import { TranslateService } from '@ngx-translate/core';

export interface BridgeValidationState {
	isValid: boolean;
	validationMessage?: string;
}

type BridgeValidator = (...args: any[]) => BridgeValidationState;

@Injectable({
	providedIn: 'root'
})
export class BridgeValidatorService {
	constructor(private translateService: TranslateService) {}

	validateBridgeForProcedureFlow(teeth: Tooth[]): BridgeValidationState {
		return this.concatValidators(
			() => this.validateLength(teeth),
			() => this.validateForUnitTypesExists(teeth),
			() => this.validateInnerTeeth(teeth, UnitTypesInBridge.Maryland, 'ToothEditor.BridgeValidationMarylandWarning'));
	}

	validateBridgeForCaseTypeFlow(teeth: Tooth[]): BridgeValidationState {
		return this.concatValidators(
			() => this.validateLength(teeth),
			() => this.validateInnerTeeth(teeth, UnitTypesInBridge.MarylandAbutment, 'ToothEditor.BridgeValidationMarylandAbutmentWarning'));
	}

	private concatValidators(...validators: BridgeValidator[]): BridgeValidationState {
		for (const validator of validators) {
			const state = validator.call(this);

			if (!state.isValid) {
				return state;
			}
		}

		return { isValid: true};
	}

	private validateLength(teeth: Tooth[]): BridgeValidationState {
		const isValid = teeth.length !== 1;

		return {
			isValid,
			validationMessage: isValid ? undefined : this.translateService.instant('ToothEditor.BridgeValidationInvalidSpanWarning')
		};
	}

	private validateForUnitTypesExists(teeth: Tooth[]): BridgeValidationState {
		const isValid = !teeth.find(tooth => tooth?.ToothInBridgeTypeID === null);

		return {
			isValid,
			validationMessage: isValid ? undefined : this.translateService.instant('ToothEditor.BridgeValidationRegularWarning')
		};
	}

	private validateInnerTeeth(teeth: Tooth[], unitType: UnitTypesInBridge, messageKey: string): BridgeValidationState {
		if (teeth.length <= 2) {
			return { isValid: true };
		}

		const innerTeeth: Tooth[] = teeth.slice(1, teeth.length - 1);
		const isValid = !innerTeeth.find(tooth => tooth?.ToothInBridgeTypeID === unitType);

		return {
			isValid,
			validationMessage: isValid ? undefined : this.translateService.instant(messageKey)
		};
	}
}
