import { ToothEditorService } from '@shared/services/tooth-editor.service';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { ToothEditorComponent as ToothEditorComponentV0 } from '../containers/tooth-editor/v0/tooth-editor.component';
import { ToothEditorComponent as ToothEditorComponentV1 } from '../containers/tooth-editor/v1/tooth-editor.component';
import { TOOTH_EDITOR_COMPONENT } from './tokens';

export const TOOTH_EDITOR_PROVIDERS = [
	{
		provide: TOOTH_EDITOR_COMPONENT,
		deps: [ShellQuery],
		useFactory: toothEditorComponentFactory
	},
	ToothEditorService
];
function toothEditorComponentFactory(shellQuery: ShellQuery) {
	return shellQuery.isProcedureFlow ? ToothEditorComponentV1 : ToothEditorComponentV0;
}
