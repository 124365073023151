<div class="rx-card print-context read-only scan-options">
    <div class="rx-card-title-print additional-scans">
		<strong>{{ 'ScanOptions.AdditionalScansTitle' | translate }}</strong>
    </div>
	<div class="rx-card-body print-context">
		<mat-label [ngClass]="!!rx?.EmergenceProfile ? 'three-columns' : 'two-columns'"
			name="preTreatmentScan">
			<mat-radio-button class="checkbox-container">
				<img class="general-info-checkbox-print"
					 [src]="rx?.PrePrepScan | mapper : getSrcForRadioButtonImg.bind(this)"
					 alt="preTreatmentScan"
				>
				<a class="field-name">{{ 'GeneralRxData.PreTreatmentScan' | translate }}</a>
			</mat-radio-button>
		</mat-label>
		<mat-label class="three-columns" *ngIf="rx?.EmergenceProfile"
			name="emergenceProfile">
			<mat-radio-button class="checkbox-container">
				<img class="general-info-checkbox-print"
					 [src]="rx?.EmergenceProfile | mapper : getSrcForRadioButtonImg.bind(this)"
					 alt="emergencyProfile"
				>
				<a class="field-name">{{ 'ScanOptions.EmergenceProfile' | translate }}</a>
			</mat-radio-button>
		</mat-label>
		<mat-label [ngClass]="!!rx?.EmergenceProfile ? 'three-columns' : 'two-columns'"
			name="dentureCopyScan">
			<mat-radio-button class="checkbox-container">
				<img class="general-info-checkbox-print"
					 [src]="rx?.DentureDetails?.IsDentureCopyScan | mapper : getSrcForRadioButtonImg.bind(this)"
					 alt="dentureCopyScan"
				>
				<a class="field-name">{{ 'ScanOptions.DentureCopyScan' | translate }}</a>
			</mat-radio-button>
		</mat-label>
	</div>
	<div class="additional-bites print-container" *ngIf="(isRestoMultiBiteSelected())">
		<div class="rx-card-title-print">
			<strong class="resto-multi-bite-title"> {{ 'ScanOptions.MultiBite' | translate }} </strong>
		</div>
		<div class = "rx-card-body print-context">
			<div class="additional-bites-row" fxLayout="row" fxFlex>
				<div class="additional-bite"  *ngFor="let bite of additionalBites" [id]= "bite.textKey.toLowerCase()">
					<mat-radio-button class="additional-bite-checkbox-print">
						<img class="general-info-checkbox-print"
							[src]="isBiteSelected(bite.id) | mapper : getSrcForRadioButtonImg.bind(this)"
							alt="additionalBite">
						<a class="field-name">{{ 'RestoMultiBite.' + bite.textKey | translate }}</a>
					</mat-radio-button>
				</div>
			</div>
		</div>
	</div>
</div>
