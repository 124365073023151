import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { EmptyStringAsHyphenPipe } from './pipes/empty-string-as-hyphen.pipe';
import { TreatmentInformationV0Component } from './containers/treatment-information/v0/treatment-information-v0.component';
import { TreatmentInformationV1Component } from './containers/treatment-information/v1/treatment-information-v1.component';
import { TreatmentInformationComponent } from './containers/treatment-information/treatment-information-component';
import {ToothEditorSharedModule} from '@modules/tooth-editor/tooth-editor-shared.module';
import {
	TreatmentInformationDetailsComponent
} from '@modules/treatment-information/components/treatment-information-details.component';

@NgModule({
	declarations: [
		TreatmentInformationV0Component,
		TreatmentInformationV1Component,
		TreatmentInformationComponent,
		EmptyStringAsHyphenPipe,
		TreatmentInformationDetailsComponent
	],
	imports: [CommonModule, SharedModule, ToothEditorSharedModule],
	exports: [TreatmentInformationComponent, TreatmentInformationV1Component, TreatmentInformationV0Component]
})
export class TreatmentInformationModule {}
