import { Injectable } from "@angular/core";
import { IdName } from "@shared/models/id-name";
import { TreatmentStageEnum } from "../models/treatment-stage.enum";
import { OrderStore } from "../state/order-store";

@Injectable()
export class TreatmentStageChangedService {

	constructor(
		private store: OrderStore
	) { }

	updateCurrentAlignerId({ treatmentStage }: { treatmentStage: IdName }): void {
		if ([TreatmentStageEnum.InitialRecord].includes(treatmentStage?.Id)) {
			this.store.update({ currentAlignerId: '0' });
			return;
		}
	}

}
