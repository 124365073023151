<div fxLayout="row" [fxLayout.lt-sm]="isResponsive | mapper : getResponsiveDirection"> 
    <rx-info-block
        fxFlex="40"
        [fxFlex.lt-md]="isResponsive | mapper : getResponsiveWidth"
        class="info-box"
        [fields]="[{ name: 'PatientSection.Patient', id: 'general-patient-name', value: patient | mapper: getPatientName, isInline: true }]"
    ></rx-info-block>
    <div [ngClass.lt-sm]="{'horizontal visible' : isResponsive}" class="rx-separator vertical transparent"></div>
    <rx-info-block
        fxFlex
        class="info-box"
        [fields]="[{ name: 'GeneralRxData.ChartNumber', id: 'general-chart-number', value: patient?.ChartNumber, isInline: true }]"
    ></rx-info-block>
  </div>