import { Injectable } from '@angular/core';
import { Tooth } from '@modules/teeth-diagram/models/tooth';
import { ValueOf } from '@shared/utils/type-util';
import { RxRulesService } from '@shared/services/rx-rules-helper/rx-rules.service';
import { OrderQuery } from '@modules/order/state/order-query';
import { memoize } from '@shared/utils/memoize-decorator';
import { UnitTypesInBridge } from '@modules/teeth-diagram/models/unit-type-in-bridge.enum';
import { MaterialOption } from '@shared/models/material-option';

@Injectable({
	providedIn: 'root'
})
export class ValidateToothPropertyService {
	constructor(private rxRulesService: RxRulesService, private orderQuery: OrderQuery) {}

	validatePropsCompatibilityInTooth(tooth: Tooth, propName: keyof Tooth, value: ValueOf<Tooth>): boolean {
		if (value == null) {
			return false;
		}
		switch (propName) {
			case 'SpecificationId':
				return this.validateSpecification(tooth, value);
			case 'MaterialID':
				return this.validateMaterial(tooth, value);
			case 'ShadeBody':
				return this.validateBody(tooth);
			default:
				return true;
		}
	}

	private validateSpecification(tooth: Tooth, value: ValueOf<Tooth>): boolean {
		const procedureMapId = this.orderQuery.procedureMap?.Id;
		const specWithMaterial = this.getSpecIdsWithMaterialIdsForBridge(procedureMapId, tooth.ToothID, tooth.ToothInBridgeTypeID);

		return specWithMaterial.some(spec => spec.Id === value);
	}
	private validateMaterial(tooth: Tooth, value: ValueOf<Tooth>): boolean {
		const procedureMapId = this.orderQuery.procedureMap?.Id;
		const specWithMaterial = this.getSpecIdsWithMaterialIdsForBridge(procedureMapId, tooth.ToothID, tooth.ToothInBridgeTypeID);
		// validation requires the tooth to have specificationId. Make sure it has been copied or defined
		const currentSpecWithMat = specWithMaterial?.find(spec => spec.Id === tooth.SpecificationId);

		return currentSpecWithMat ? currentSpecWithMat.Materials.some(mat => mat === value) : false;
	}

	private validateBody(tooth: Tooth): boolean {
		// validation requires the tooth to have specificationId and MaterialID. Make sure they have been copied or defined
		const matOption = this.getMaterialSpecOption(tooth.MaterialID, tooth.SpecificationId);

		return matOption?.ShadeVisible ?? false;
	}

	@memoize({ extractUniqueId: (materialId: number, specId: number) => `${specId},${materialId}}` })
	private getMaterialSpecOption(materialId: number, specId: number): MaterialOption {
		return this.rxRulesService.getMaterialSpecOption(materialId, specId);
	}

	@memoize({
		extractUniqueId: (procedureMapId: number, toothId: number, toothInBridgeUnitType: UnitTypesInBridge) =>
			`${procedureMapId},${toothId},${toothInBridgeUnitType}`
	})
	private getSpecIdsWithMaterialIdsForBridge(procedureMapId: number, toothId: number, toothInBridgeUnitType: UnitTypesInBridge) {
		return this.rxRulesService.getSpecIdsWithMaterialIdsForBridge(procedureMapId, toothId, toothInBridgeUnitType);
	}
}
