export enum CaseTypeEnum {
	Quadrant = 1,
	FullArch = 2,
	StudyModel = 3,
	Expanded = 4,
	iCast = 5,
	ReferenceModel = 6,
	VS = 11,
	iQ = 12,
	iRecord = 13,
	RTMHalfArch = 20,
	RTMFullArch = 21,
	RTMReferenceModel = 22,
	RTMQuadrant = 23,
	RTMExpanded = 24,
	Invisalign = 30,
	Realine = 31,
	Vivera = 32,
	ViveraPreDebond = 33,
	InvisalignAndiRecord = 34,
	SmileDirectClub = 36,
	ChairSideMilling = 40,
	DentaliRecord = 41,
	ChairSideMillingGlidewellIo = 42,
	InvisalignFirst = 43,
	ChairSideMillingCerec = 44,
	Restorative = 50,
	Diagnostics = 99
}
