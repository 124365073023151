<mat-form-field fxFlex [class.read-only]="isReadOnly">
    <mat-label [class.current-aligner-read-only-label-no-value]="isReadOnly && !control.value"
        >{{ 'OrderSection.CurrentAligner' | translate }} #:</mat-label
    >
    <input [readonly]="isReadOnly" [class.read-only]="isReadOnly" matInput type="text" [formControl]="control" autocomplete="off" name="currentAlignerId" ngDefaultControl />
    <mat-error
        translate="OrderSection.AlignerIdLengthErrorMessage"
        [translateParams]="{ minValue: '0', maxValue: maxValue.toString() | translate }"
    >
    </mat-error>
</mat-form-field>
