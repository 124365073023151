import { Injectable } from '@angular/core';
import { ApiService, IApiCall } from '@core/services/api.service';
import { Response } from '@shared/models/response';
import { IdName } from '@shared/models/id-name';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
 
@Injectable({
	providedIn: 'root'
})
export class OrderInformationApiService {
	constructor(private apiService: ApiService) {}

	getCadCamSystemTypes(): Observable<IdName[]> {
		const apiCallOptions: IApiCall = {
			selector: 'getCadCamSystemTypes'
		};

		return this.apiService.request(apiCallOptions).pipe(map(response => (response as Response<IdName[]>).Result));
	}

	getCanOrderPhysicalModel(companyId: number): Observable<boolean> {
		const apiCallOptions: IApiCall = {
			selector: 'getCanOrderPhysicalModel',
			queryParams: { companyId }
		};

		return this.apiService.request(apiCallOptions).pipe(map(response => (response as Response<boolean>).Result));
	}
}
