<form [formGroup]="form" [class.read-only]="isReadOnly$ | async" class="restoration-type-section">
	<rx-select
		[id]="'restoration-type'"
		[label]="'ToothEditor.RestorationType' | translate"
		[isCancellable]="true"
		[formControl]="restorationTypeControl"
		[isDisabled]="isReadOnly$ | async"
		[options]="restorationTypes$ | async"
		name="restorationTypeControl"
	></rx-select>
	<rx-select
		[id]="'abutment-material'"
		[label]="'ToothEditor.AbutmentMaterial' | translate"
		[isCancellable]="true"
		[formControl]="abutmentMaterialControl"
		[options]="materials$ | async"
		[isDisabled]="(isScrewRetainedOrEmpty$ | async) || !(materials$ | async)?.length || (isReadOnly$ | async)"
		(disableChangeEvent)="cleanControlWhenDisabled($event, abutmentMaterialControl)"
		name="abutmentMaterialControl"
	></rx-select>
	<rx-select
		[id]="'abutment-type'"
		[label]="'ToothEditor.AbutmentType' | translate"
		[isCancellable]="true"
		[formControl]="abutmentTypeControl"
		[options]="abutmentTypes$ | async"
		[isDisabled]="(isScrewRetainedOrEmpty$ | async) || (isReadOnly$ | async)"
		(disableChangeEvent)="cleanControlWhenDisabled($event, abutmentTypeControl)"
		name="abutmentTypeControl"
	></rx-select>
	<tlk-slide-toggle
		[formControl]="isTiBasedControl"
		[id]="'ti-based'"
		name="isTiBasedControl"
		labelPosition="left"
		[isDisabled]="isTiBasedDisabled$ | async"
		(disableChangeEvent)="switchOffIsTiBasedControl($event)"
	>
		{{ 'ToothEditor.TiBaseTitle' | translate }}
	</tlk-slide-toggle>
</form>
