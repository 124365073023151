import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { OrderInformationComponent } from './containers/order-information/order-information.component';
import { IncrementalSelectorComponent } from './components/incremental-selector/incremental-selector.component';

@NgModule({
	declarations: [OrderInformationComponent, IncrementalSelectorComponent],
	imports: [CommonModule, SharedModule],
	exports: [OrderInformationComponent]
})
export class OrderInformationModule {}
