import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { TracesState, TracesStore } from '@shared/store/traces/traces-store';

@Injectable({ providedIn: 'root' })
export class TracesQuery extends Query<TracesState> {
	get configurationTraceIdsAsString(): string {
		const {
			configurationTraceId,
			availableCaseTypesTraceId,
			availableProcedureMapIdsTraceId,
			featureTogglesTraceId,
			userSettingsTraceId,
			getRxTraceId
		} = this.getValue();

		return JSON.stringify({
			configurationTraceId,
			availableCaseTypesTraceId,
			availableProcedureMapIdsTraceId,
			featureTogglesTraceId,
			userSettingsTraceId,
			getRxTraceId
		});
	}

	get savingGettingRxTraceIds(): string {
		const { getRxTraceId, savingTraceId } = this.getValue();

		return getRxTraceId || savingTraceId
			? JSON.stringify({
					getRxTraceId,
					savingTraceId
				})
			: null;
	}

	constructor(tracesStore: TracesStore) {
		super(tracesStore);
	}
}
