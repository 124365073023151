<rx-awareness-banner>
	<ng-container [ngSwitch]="isProcedureFlow">
		<span class="rx-awareness-banner-title" *ngSwitchCase="true">{{ 'Awareness.BannerTitleAfterLmr' | translate }}</span>
		<ng-container *ngSwitchCase="false">
			<div fxLayout="column">
				<span class="rx-awareness-banner-title">{{ 'Awareness.BannerTitleBeforeLmr' | translate }}</span>
				<span class="rx-awareness-banner-subtitle">{{ 'Awareness.BannerSubtitleBeforeLmr' | translate }}</span>
			</div>
		</ng-container>
	</ng-container>
</rx-awareness-banner>
