import { Component, Input } from '@angular/core';
import { MouldSelectorService } from '@modules/denture-details/components/mould-element/mould-selector.service';
import { IdName } from '@shared/models/id-name';
import { MapperPipe } from '@shared/pipes/mapper.pipe';
import { NgIf } from '@angular/common';

@Component({
	selector: 'rx-mould-element',
	templateUrl: './mould-element.component.html',
	styleUrls: ['./mould-element.component.scss'],
	standalone: true,
	imports: [NgIf, MapperPipe]
})
export class MouldElementComponent {
	@Input()
	mould: IdName;

	@Input()
	isSelected: boolean;

	@Input()
	showName = true;
	constructor(private mouldSelectorService: MouldSelectorService) {}

	getMouldImage(mould: IdName): string {
		return mould ? this.mouldSelectorService.getSrcForMouldImage(mould) : '';
	}
}
