import { Injectable } from '@angular/core';
import { RxStatusEnum } from '@shared/models/enums/rx-status.enum';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class RxStatusService {

	constructor(
		private shellQuery: ShellQuery
	) { }

	status$: Observable<RxStatusEnum> = combineLatest([
		this.shellQuery.isRxScanned$,
		this.shellQuery.isRxSent$
	]).pipe(
		map(([isRxScanned, isRxSent]) => this.getRxStatus(isRxScanned, isRxSent))
	);

	private getRxStatus(isRxScanned: boolean, isRxSent: boolean): RxStatusEnum {		
		if (isRxSent) { return RxStatusEnum.Sent }				
		if (isRxScanned && !isRxSent) { return RxStatusEnum.Scanned }		
		if (!isRxScanned) { return RxStatusEnum.Unscanned }					
	}

}
