    <h1 mat-dialog-title tabindex="0" onfocus="this.blur()">
        {{ this.dialogInput.titleTranslationKey | translate }}
    </h1>
    <div mat-dialog-content>
        <div class="dialog-content">
            <div class="info-icon" [ngStyle]="{ 'background-image': 'url(' + iconSrc + ')' }"></div>
            <div class="dialog-message" [innerHTML]="this.dialogInput.contentTranslationKey | translate">
            </div>
        </div>
    </div>
    <div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center">
        <div>
            <mat-checkbox *ngIf="isDontShowAgainVisible" [(ngModel)]="dontShowAgain">
                {{ 'Popup.DontShowAgainTitle' | translate }}
            </mat-checkbox>
        </div>
        <div>
            <button mat-raised-button class="xl" [mat-dialog-close]="popUpActions.Cancel">
                {{ 'Popup.Cancel' | translate }}
            </button>
            <button mat-raised-button color="primary" class="ok-button xl" [mat-dialog-close]="popUpActions.Ok">
                {{ 'Popup.OK' | translate }}
            </button>
        </div>
    </div>
