import { Pipe, PipeTransform } from '@angular/core';

type mapperFunc<V = any, T extends any[] = any[], U = any> = (value: V, ...args: T) => U;

@Pipe({
    name: 'mapper',
    standalone: true
})
export class MapperPipe implements PipeTransform {
	transform<V = any, T extends any[] = any[], U = any>(
		value: V,
		callBack: mapperFunc<V, T, U>,
		...args: T
	): ReturnType<mapperFunc<V, T, U>> | null {
		return callBack && typeof callBack === 'function' ? callBack(value, ...args) : null;
	}
}
