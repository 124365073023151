import { ProcedureRule, Specification } from '../../models/procedure-unit-type-material-rules-config';
import { ProcedureMapEnum } from '../../models/procedure-map.enum';
import { allTeeth, molarTeeth } from '../teeth-sets';
import { SpecificationEnum } from '../../models/specification.enum';
import { MaterialEnum } from '../../models/material.enum';
import { UnitTypesInBridge } from '@modules/teeth-diagram/models/unit-type-in-bridge.enum';
import { LimitedFeatures } from '@shared/models/limited-features';

const specificatons: Specification[] = [
	{
		Id: SpecificationEnum.FullContour,
		Materials: [
			MaterialEnum.CeramicZirconia,
			MaterialEnum.CeramicTranslucentZirconia,
			MaterialEnum.CeramicMultilayerZirconia,
			MaterialEnum.CeramicLithiumDisilicate,
			MaterialEnum.CeramicLabPreference,
			MaterialEnum.MetalHighNoble,
			MaterialEnum.MetalSemiPrecious,
			MaterialEnum.MetalTitanium,
			MaterialEnum.MetalCobaltChrome,
			MaterialEnum.ResinAcrylicPMMA,
			MaterialEnum.PolymerPEEK,
			MaterialEnum.OtherCheckNote
		]
	},
	{
		Id: SpecificationEnum.PFM_PFZ,
		Materials: [
			MaterialEnum.CeramicZirconia,
			MaterialEnum.CeramicTranslucentZirconia,
			MaterialEnum.CeramicMultilayerZirconia,
			MaterialEnum.CeramicLithiumDisilicate,
			MaterialEnum.CeramicLabPreference,
			MaterialEnum.MetalHighNoble,
			MaterialEnum.MetalSemiPrecious,
			MaterialEnum.MetalTitanium,
			MaterialEnum.MetalCobaltChrome,
			MaterialEnum.OtherCheckNote
		]
	},
	{
		Id: SpecificationEnum.PFM_PFZ_CopingOnly,
		Materials: [
			MaterialEnum.CeramicZirconia,
			MaterialEnum.CeramicTranslucentZirconia,
			MaterialEnum.CeramicLithiumDisilicate,
			MaterialEnum.CeramicLabPreference,
			MaterialEnum.PolymerPEEK,
			MaterialEnum.OtherCheckNote
		]
	},
	{
		Id: SpecificationEnum.Temporary,
		Materials: [MaterialEnum.ResinAcrylicPMMA, MaterialEnum.PolymerPEEK, MaterialEnum.Composite, MaterialEnum.OtherCheckNote]
	}
];

export const rulesForBridgeFixedRestorative: ProcedureRule = {
	Ids: [
		ProcedureMapEnum.FixedRestorativeLabResto,
		ProcedureMapEnum.FixedRestorativeChairsideExocad,
		ProcedureMapEnum.FixedRestorativeWithoutSendTo,
		ProcedureMapEnum.FixedRestorativeMidcOnly,
		ProcedureMapEnum.FixedRestorativeSprintRay
	],
	TeethRules: [
		{
			Ids: allTeeth,
			UnitAndMaterialRules: {
				UnitTypeRules: [
					{
						Id: UnitTypesInBridge.Abutment,
						Specifications: [
							{
								Id: SpecificationEnum.FullContour,
								Materials: [
									MaterialEnum.CeramicZirconia,
									MaterialEnum.CeramicTranslucentZirconia,
									MaterialEnum.CeramicMultilayerZirconia,
									MaterialEnum.CeramicLithiumDisilicate,
									MaterialEnum.CeramicLabPreference,
									MaterialEnum.MetalHighNoble,
									MaterialEnum.MetalSemiPrecious,
									MaterialEnum.MetalTitanium,
									MaterialEnum.MetalCobaltChrome,
									MaterialEnum.ResinAcrylicPMMA,
									MaterialEnum.PolymerPEEK,
									MaterialEnum.OtherCheckNote
								]
							},
							{
								Id: SpecificationEnum.PFM_PFZ,
								Materials: [
									MaterialEnum.CeramicZirconia,
									MaterialEnum.CeramicLithiumDisilicate,
									MaterialEnum.CeramicLabPreference,
									MaterialEnum.MetalHighNoble,
									MaterialEnum.MetalSemiPrecious,
									MaterialEnum.MetalTitanium,
									MaterialEnum.MetalCobaltChrome,
									MaterialEnum.OtherCheckNote
								]
							},
							{
								Id: SpecificationEnum.PFM_PFZ_CopingOnly,
								Materials: [
									MaterialEnum.CeramicZirconia,
									MaterialEnum.CeramicTranslucentZirconia,
									MaterialEnum.CeramicLithiumDisilicate,
									MaterialEnum.CeramicLabPreference,
									MaterialEnum.PolymerPEEK,
									MaterialEnum.OtherCheckNote
								]
							},
							{
								Id: SpecificationEnum.Temporary,
								Materials: [
									MaterialEnum.ResinAcrylicPMMA,
									MaterialEnum.PolymerPEEK,
									MaterialEnum.Composite,
									MaterialEnum.OtherCheckNote
								]
							}
						]
					},
					{
						Id: UnitTypesInBridge.Pontic,
						Specifications: specificatons
					},
					{
						Id: UnitTypesInBridge.Maryland,
						Specifications: specificatons
					},
					{
						Id: UnitTypesInBridge.ImplantBased,
						Specifications: specificatons
					},
					{
						Id: UnitTypesInBridge.Missing
					},
					{
						Id: UnitTypesInBridge.EggshellBridge,
						Specifications: [
							{
								Id: SpecificationEnum.Temporary,
								Materials: [
									MaterialEnum.ResinAcrylicPMMA,
									MaterialEnum.PolymerPEEK,
									MaterialEnum.Composite,
									MaterialEnum.OtherCheckNote
								]
							}
						],
						RestrictionUnitTypes: {
							LimitedFeatures: LimitedFeatures.EggshellCrown
						}
					}
				]
			}
		},
		{
			Ids: molarTeeth,
			UnitAndMaterialRules: {
				UnitTypeRules: [
					{
						Id: UnitTypesInBridge.Inlay,
						Specifications: specificatons
					},
					{
						Id: UnitTypesInBridge.Onlay,
						Specifications: specificatons
					}
				]
			}
		}
	]
};
