import { Injectable } from '@angular/core';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { Observable } from 'rxjs';
import { HostPlatformService } from '@shared/services/host-platform.service';

@Injectable()
export class GeneralRxDataV1Facade {
	rx$ = this.shellQuery.rx$;
	rxConfiguration$ = this.shellQuery.rxConfiguration$;
	dateFormat$: Observable<string> = this.shellQuery.dateFormat$;
	isRxForModelingContext$: Observable<boolean> = this.hostPlatformService.isIteroModeling$;

	constructor(private shellQuery: ShellQuery, private hostPlatformService: HostPlatformService) {}
}
