<label [attr.for]="inputId" #label (mouseup)="onReleased(true)" (mousedown)="onReleased(false)">
	<div class="star-icon-container" [ngClass]="{'disabled': disabled}">
		<input #input
			   class="star-icon-input cdk-visually-hidden" type="checkbox"
			   [id]="inputId"
			   [checked]="checked"
			   [disabled]="disabled"
			   [attr.value]="checked"
			   (change)="onInteractionEvent($event)"
			   (click)="onInputClick($event)">
		<span matRipple class="star-icon-ripple mat-focus-indicator"
			  [matRippleTrigger]="label"
			  [matRippleDisabled]="disabled"
			  [matRippleColor]="'#00ABED'"
			  [matRippleRadius]="20"
			  [matRippleCentered]="true"
			  [matRippleAnimation]="{enterDuration: 150}">
		</span>

		<svg #svg class="star-icon" [class.released]="released" width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M11 1.30198L13.4479 7.18738L13.5652 7.46936L13.8696 7.49377L20.2234 8.00315L15.3825 12.1499L15.1505 12.3486L15.2214 12.6456L16.7004 18.8459L11.2606 15.5233L11 15.3641L10.7394 15.5233L5.29965 18.8459L6.77863 12.6456L6.84949 12.3486L6.61755 12.1499L1.77663 8.00315L8.13042 7.49377L8.43484 7.46936L8.55212 7.18738L11 1.30198Z"
				  stroke-linejoin="round"
			/>
		</svg>
	</div>
	<span class="star-label">
		<ng-content></ng-content>
	</span>
</label>

