import { Inject, Injectable } from '@angular/core';
import { SpecIdWithMaterialIds, TeethUnitType } from '@shared/models/rx-rules-json-interface';
import { UnitTypes } from '@modules/teeth-diagram/models/unit-type.enum';
import { IdName } from '@shared/models/id-name';
import { Material } from '@shared/models/material';
import { MaterialOption } from '@shared/models/material-option';
import { ShadeSystem } from '@shared/models/shade-system';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { AbutmentType } from '@shared/models/abutment-type';
import { unitTypeSortOrder, bridgeUnitType } from '@shared/models/consts';
import { Specification } from '@shared/models/specification';
import { Tooth } from '@modules/teeth-diagram/models/tooth';
import { map, take } from 'rxjs/operators';
import { OrderQuery } from '@modules/order/state/order-query';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { TranslateService } from '@ngx-translate/core';
import { MaterialShadeSystem } from '@shared/models/material-shade-system';
import { MaterialToothEditorRule } from '@shared/models/material-tooth-editor-rule';
import { RxGeneralRules } from '@shared/models/rx-general-rules';
import {
	DENTURE_DETAILS_RULES,
	MATERIAL_SHADE_SYSTEM_RULES,
	PROCEDURE_BRIDGE_AVAILABILITY_RULES,
	RESTORATION_CONFIGURATION,
	RX_GENERAL_RULES,
	UNIT_TYPE_MATERIAL_TOOTH_EDITOR_RULES
} from '@shared/rules/rules-tokens';
import { RxModel } from '@shared/models/rx-models/interfaces/rx-model';
import { ProcedureRulesParser } from '@shared/services/rx-rules-helper/procedure-rules.parser';
import { UnitTypesInBridge } from '@modules/teeth-diagram/models/unit-type-in-bridge.enum';
import { CaseTypeRulesParser } from '@shared/services/rx-rules-helper/case-type-rules.parser';
import { ProcedureToothEditorParser } from '@shared/services/rx-rules-helper/procedure-tooth-editor-parser';
import {
	CASETYPE_RULES_PARSER,
	PROCEDURE_IN_BRIDGE_RULES_PARSER,
	PROCEDURE_RULES_PARSER,
	PROCEDURE_TOOTH_EDITOR_PARSER
} from '@shared/services/rx-rules-helper/parser-tokens';
import { ProcedureMapEnum } from '@core/procedure-helpers/models/procedure-map.enum';
import { RxDentureDetailsRules } from '@shared/models/denture-details-rules';
import { TypeEnum } from '@modules/order/models/type.enum';
import { ProcedureEnum } from '@core/procedure-helpers/models/procedure.enum';
import { RestorationConfiguration } from '@shared/models/restoration-configuration-interface';
import { HostPlatformService } from '@shared/services/host-platform.service';
import { SpecificationEnum } from '@core/procedure-helpers/models/specification.enum';
import { ToothEditorQuery } from '@modules/tooth-editor/state/tooth-editor-query';

@Injectable({ providedIn: 'root' })
export class RxRulesService {
	private toothEditorRulesMarginDesignTranslated: IdName[] = this.rxGeneralRules.ToothEditorRules.Crown.MarginDesigns;
	private materialShadeSystemRulesTranslated: MaterialShadeSystem[] = this.materialShadeSystemRules;
	private preparationDesignsTranslated: IdName[] = this.rxGeneralRules.IdNameMapping.PreparationDesign;
	private toothEditorRulesAbutmentTypesTranslated: AbutmentType[] = this.rxGeneralRules.ToothEditorRules.Abutment.AbutmentTypes;
	private readonly defaultMaterialId = -1;

	abutmentTypes$ = new BehaviorSubject<AbutmentType[]>(this.rxGeneralRules.ToothEditorRules.Abutment.AbutmentTypes);

	constructor(
		@Inject(UNIT_TYPE_MATERIAL_TOOTH_EDITOR_RULES) private unitTypeMaterialToothEditorRules: MaterialToothEditorRule[],
		@Inject(PROCEDURE_TOOTH_EDITOR_PARSER) private procedureToothEditorParser: ProcedureToothEditorParser,
		@Inject(RX_GENERAL_RULES) private rxGeneralRules: RxGeneralRules,
		@Inject(MATERIAL_SHADE_SYSTEM_RULES) private materialShadeSystemRules: MaterialShadeSystem[],
		@Inject(PROCEDURE_RULES_PARSER) private procedureRulesParser: ProcedureRulesParser<UnitTypes>,
		@Inject(PROCEDURE_IN_BRIDGE_RULES_PARSER) private procedureInBridgeRulesParser: ProcedureRulesParser<UnitTypesInBridge>,
		@Inject(CASETYPE_RULES_PARSER) private caseTypeRulesParser: CaseTypeRulesParser,
		@Inject(PROCEDURE_BRIDGE_AVAILABILITY_RULES) private prcoedureBridgeAvailabilityRules: ReadonlyArray<ProcedureMapEnum>,
		@Inject(DENTURE_DETAILS_RULES) private dentureDetailsRules: RxDentureDetailsRules,
		@Inject(RESTORATION_CONFIGURATION) private restorationConfiguration: RestorationConfiguration,
		private orderQuery: OrderQuery,
		private shellQuery: ShellQuery,
		private translateService: TranslateService,
		private hostPlatformService: HostPlatformService,
		private toothEditorQuery: ToothEditorQuery
	) {}

	translateSelectOptions() {
		this.toothEditorRulesMarginDesignTranslated = this.rxGeneralRules.ToothEditorRules.Crown.MarginDesigns.map(marginDesign =>
			this.getTranslatedObject<IdName>(marginDesign, 'MarginDesign')
		);
		this.preparationDesignsTranslated = this.rxGeneralRules.IdNameMapping.PreparationDesign.map(preparationDesign =>
			this.getTranslatedObject<IdName>(preparationDesign, 'PreparationDesign')
		);
		this.materialShadeSystemRulesTranslated = this.materialShadeSystemRules.map(materialShadeSystem => {
			const shadeSystems = materialShadeSystem.ShadeSystems.map(shadeSystem =>
				this.getTranslatedObject<ShadeSystem>(shadeSystem, 'ShadeSystem')
			);

			return { ...materialShadeSystem, ShadeSystems: shadeSystems };
		});
		this.toothEditorRulesAbutmentTypesTranslated = this.rxGeneralRules.ToothEditorRules.Abutment.AbutmentTypes.map(abutmentType =>
			this.getTranslatedObject<AbutmentType>(abutmentType, 'AbutmentTypes')
		);

		this.abutmentTypes$.next(this.toothEditorRulesAbutmentTypesTranslated);
	}

	getSpecIdsWithMaterialIds(procedureMapId: number, toothId: number, currentUnitTypeOnTooth: UnitTypes): SpecIdWithMaterialIds[] {
		return this.procedureRulesParser.getSpecIdsWithMaterialIds(procedureMapId, toothId, currentUnitTypeOnTooth);
	}

	getSpecIdsWithMaterialIdsForBridge(
		procedureMapId: number,
		toothId: number,
		bridgeUnitTypeOnTooth: UnitTypesInBridge
	): SpecIdWithMaterialIds[] {
		return this.procedureInBridgeRulesParser.getSpecIdsWithMaterialIds(procedureMapId, toothId, bridgeUnitTypeOnTooth);
	}

	getMaterialIdsBySpecification(specificationId: number, specIdsWithMaterialIds: SpecIdWithMaterialIds[]): number[] {
		if (specIdsWithMaterialIds[0]?.Id === SpecificationEnum.None) {
			return specIdsWithMaterialIds[0].Materials;
		}

		return specIdsWithMaterialIds?.find(({ Id }) => Id === specificationId)?.Materials;
	}

	getFilteredSpecifications(specIds: number[], specifications?: Specification[]): Specification[] {
		if (!specifications) {
			specifications = this.shellQuery.specifications;
		}

		return specifications.filter(({ Id }) => specIds.includes(Id));
	}

	getSpecification(specId: number, specifications?: Specification[]): Specification {
		return this.getFilteredSpecifications([specId], specifications)?.[0];
	}

	getMaterial(matId: number, materials?: Material[]): Material {
		return this.getFilteredMaterials([matId], materials)?.[0];
	}

	getProcedureFlowUnitTypeNamesForBridge(procedureMapId: number, tooth: Tooth, isOuterTooth: boolean): IdName[] {
		const procedureUnitTypesInBridge = this.procedureUnitTypesInBridgeByToothPosition(isOuterTooth);
		const unitTypeIds = this.procedureInBridgeRulesParser.getUnitTypeIdsByProcedureMapAndToothId(
			procedureMapId,
			tooth.ToothID,
			tooth.ToothInBridgeTypeID
		);

		return procedureUnitTypesInBridge.filter(({ Id }) => unitTypeIds.includes(Id));
	}

	getCaseTypeFlowUnitTypeNamesForBridge(isOuterTooth: boolean): IdName[] {
		return this.unitTypesInBridgeByToothPosition(isOuterTooth);
	}

	getCaseTypeFlowUnitTypeNamesForGlidewellBridge(isOuterTooth: boolean): IdName[] {
		return this.glidewellCaseUnitTypesInBridgeByToothPosition(isOuterTooth);
	}

	getProcedureFlowUnitTypeNames(tooth: Tooth): Observable<IdName[]> {
		return combineLatest([this.orderQuery.procedureMap$, this.shellQuery.rxConfiguration$]).pipe(
			take(1),
			map(([procedureMap, rxConfiguration]) => {
				let unitTypeIds = this.procedureRulesParser.getUnitTypeIdsByProcedureMapAndToothId(
					procedureMap.Id,
					tooth.ToothID,
					tooth.UnitTypeID,
					rxConfiguration
				);

				if (!this.hostPlatformService.isIteroModeling) {
					unitTypeIds = this.removeModelingSpecificUnitTypes(unitTypeIds);
				}
				if (!this.toothEditorQuery.isToothEditorOpen && this.isBridgeAvailableFor(procedureMap.Id)) {
					unitTypeIds.push(bridgeUnitType);
				}

				const shouldSortByDefaultOrder = procedureMap.ProcedureId !== ProcedureEnum.Denture_Removable;

				return this.getFilteredAndCustomSortedUnitTypeMenuNames({ unitTypeIds, shouldSortByDefaultOrder });
			})
		);
	}

	getCaseTypeFlowUnitTypeNames(tooth: Tooth): Observable<IdName[]> {
		return combineLatest([this.orderQuery.caseType$, this.shellQuery.rx$]).pipe(
			take(1),
			map(([caseTypeItem, existingRx]: [IdName, RxModel]) => {
				const caseTypeId = caseTypeItem?.Id ?? existingRx?.Order?.CaseTypeId;
				let unitTypeIds = this.caseTypeRulesParser.getUnitTypeIdsByCaseTypeIdAndToothId(
					caseTypeId,
					tooth.ToothID,
					tooth?.UnitTypeID
				);

				if (!this.hostPlatformService.isIteroModeling) {
					unitTypeIds = unitTypeIds.filter(unitTypeId => unitTypeId !== UnitTypes.Crown_ThreeQuarters);
				}

				return this.getFilteredAndCustomSortedUnitTypeMenuNames({ unitTypeIds });
			})
		);
	}

	getFilteredAndCustomSortedUnitTypeMenuNames({
		unitTypeIds,
		shouldSortByDefaultOrder = true
	}: {
		unitTypeIds: number[];
		shouldSortByDefaultOrder?: boolean;
	}): IdName[] {
		const sortedOrder = shouldSortByDefaultOrder
			? unitTypeSortOrder.filter(sortedItem => unitTypeIds.includes(sortedItem))
			: unitTypeIds;

		return sortedOrder
			.map(id => {
				return id
					? { Id: id, Name: this.getUnitTypeTranslatedName(id) }
					: { Id: bridgeUnitType, Name: this.translateService.instant('UnitTypes.Bridge') };
			})
			.filter(Boolean);
	}

	getUnitTypeTranslatedName(unitTypeId: number): string {
		return this.translateService.instant(`UnitTypes.${UnitTypes[unitTypeId]}`);
	}

	getBridgeUnitTypeTranslatedName(bridgeUnitTypeId: number): string {
		return this.translateService.instant(`BridgeUnitTypes.${UnitTypesInBridge[bridgeUnitTypeId]}`);
	}

	getMaterialIds(caseTypeId: number, toothId: number, currentUnitTypeOnTooth: UnitTypes): number[] {
		return this.caseTypeRulesParser.getMaterialIds(caseTypeId, toothId, currentUnitTypeOnTooth);
	}

	getFilteredMaterials(materialIds: number[], materialNames?: Material[]): Material[] {
		if (!materialIds || materialIds.length === 0) {
			return [];
		}

		if (!materialNames) {
			materialNames = this.shellQuery.materials;
		}

		return materialNames.filter((materialNameItem: Material) => materialIds.includes(materialNameItem.Id) && materialNameItem.IsActive);
	}

	getMarginDesignOptions(): IdName[] {
		return this.toothEditorRulesMarginDesignTranslated;
	}

	getAbutmentOptionsMapping(): IdName[] {
		return this.rxGeneralRules.IdNameMapping.Abutment;
	}

	getPreparationDesignOptionsMapping(): IdName[] {
		return this.preparationDesignsTranslated;
	}

	getMaterialSpecOption(materialId: number, specId: number): MaterialOption {
		return this.procedureToothEditorParser.getMaterialSpecOption(materialId, specId);
	}

	getMaterialUnitTypeOption({ materialId, unitTypeId }: { materialId: number; unitTypeId: number }): MaterialOption {
		const allMaterialOptions = this.unitTypeMaterialToothEditorRules.find(materialObj => materialObj.Id === materialId)?.Options;

		return allMaterialOptions?.find(materialOption => materialOption.UnitType === unitTypeId);
	}

	getMaterialShadeSystemRuleByMaterialId({ materialId }: { materialId: number }) {
		return this.materialShadeSystemRulesTranslated.find(rule => rule.Id === materialId);
	}

	getShadeSystemOptionsByMaterialId({ materialId }: { materialId: number }): ShadeSystem[] {
		return this.getMaterialShadeSystemRuleByMaterialId({ materialId })?.ShadeSystems;
	}

	getDefaultShadeSystemSelectionId({ materialId }: { materialId: number }): number {
		return this.getMaterialShadeSystemRuleByMaterialId({ materialId })?.DefaultShadeSystem;
	}

	getDefaultShadeSystemOptions(): ShadeSystem[] {
		return this.getMaterialShadeSystemRuleByMaterialId({ materialId: this.defaultMaterialId })?.ShadeSystems;
	}

	getIncisalBodyGingivalOptions({ materialId, shadeSystemId }: { materialId: number; shadeSystemId: number }): IdName[] {
		let shadeSystemOptions = this.getShadeSystemOptionsByMaterialId({ materialId });

		if (!shadeSystemOptions) {
			shadeSystemOptions = this.getDefaultShadeSystemOptions();
		}

		const shadeSystem: ShadeSystem = shadeSystemOptions?.find(shadeOption => shadeOption.Id === shadeSystemId);

		return shadeSystem?.Options?.map((shadeSystemOption: string) => ({
			Id: shadeSystemOption,
			Name: shadeSystemOption
		}));
	}

	getStumpShadeOptions(): IdName[] {
		const stumpShades: string[] = this.getMaterialShadeSystemRuleByMaterialId({ materialId: this.defaultMaterialId })?.StumpShade;

		return stumpShades?.map((stumpShade: string) => ({
			Id: stumpShade,
			Name: stumpShade
		}));
	}

	isBridgeAvailableFor(procedureMapId: ProcedureMapEnum): boolean {
		return this.prcoedureBridgeAvailabilityRules.includes(procedureMapId);
	}

	checkDentureDetailsRule(teeth: Tooth[], orderType: TypeEnum): boolean {
		const rule = this.dentureDetailsRules.ToothRules.find(r => r.OrderType === orderType)?.TeethTypes;

		return !!rule ? teeth.some(t => rule.includes(t.UnitTypeID)) : false;
	}

	getTiBase(abutmentTypeId: number): boolean {
		return this.restorationConfiguration.AbutmentTypeMappings.find(x => x.TargetId === abutmentTypeId)?.VisibleIsTiBase;
	}

	removeModelingSpecificUnitTypes(modelingSpecificUnitTypeIds: number[]) {
		return modelingSpecificUnitTypeIds.filter(
			modelingSpecificUnitTypeId =>
				modelingSpecificUnitTypeId !== UnitTypes.Crown_ThreeQuarters && modelingSpecificUnitTypeId !== UnitTypes.Detachable
		);
	}

	getUnitTypeConfiguration(rx: RxModel): TeethUnitType[] {
		let unitTypes = null;

		if (this.shellQuery?.isProcedureFlow) {
			unitTypes = this.procedureRulesParser.getTeethUnitTypeRule(rx?.Order?.ProcedureMapId);
		} else {
			unitTypes = this.caseTypeRulesParser.getTeethUnitTypeRule(rx?.Order?.CaseTypeId);
		}

		return unitTypes;
	}

	private glidewellCaseUnitTypesInBridgeByToothPosition(isOuterTooth): IdName[] {
		const innerBridgeTypes = [UnitTypesInBridge.Abutment, UnitTypesInBridge.Pontic, UnitTypesInBridge.Skipped];
		const outerBridgeTypes = [UnitTypesInBridge.Abutment, UnitTypesInBridge.Pontic];
		const bridgeTypesToShow = isOuterTooth ? outerBridgeTypes : innerBridgeTypes;

		return bridgeTypesToShow.map(id => ({
			Id: id,
			Name: this.getBridgeUnitTypeTranslatedName(id)
		}));
	}

	private unitTypesInBridgeByToothPosition(isOuterTooth): IdName[] {
		const innerBridgeTypes = [
			UnitTypesInBridge.Abutment,
			UnitTypesInBridge.ImplantAbutment,
			UnitTypesInBridge.ScanBody,
			UnitTypesInBridge.Pontic,
			UnitTypesInBridge.Skipped
		];

		const outerBridgeTypes = [
			UnitTypesInBridge.Abutment,
			UnitTypesInBridge.ImplantAbutment,
			UnitTypesInBridge.ScanBody,
			UnitTypesInBridge.Pontic,
			UnitTypesInBridge.MarylandAbutment
		];
		const bridgeTypesToShow = isOuterTooth ? outerBridgeTypes : innerBridgeTypes;

		return bridgeTypesToShow.map(id => ({
			Id: id,
			Name: this.getBridgeUnitTypeTranslatedName(id)
		}));
	}

	private procedureUnitTypesInBridgeByToothPosition(isOuterTooth: boolean): IdName[] {
		const innerBridgeTypes: UnitTypesInBridge[] = [
			UnitTypesInBridge.Abutment,
			UnitTypesInBridge.Pontic,
			UnitTypesInBridge.Inlay,
			UnitTypesInBridge.Onlay,
			UnitTypesInBridge.ImplantBased,
			UnitTypesInBridge.EggshellBridge,
			UnitTypesInBridge.Missing
		];
		const outerBridgeTypes: UnitTypesInBridge[] = [
			UnitTypesInBridge.Abutment,
			UnitTypesInBridge.Pontic,
			UnitTypesInBridge.Maryland,
			UnitTypesInBridge.Inlay,
			UnitTypesInBridge.Onlay,
			UnitTypesInBridge.ImplantBased,
			UnitTypesInBridge.EggshellBridge
		];

		const bridgeTypesToShow = isOuterTooth ? outerBridgeTypes : innerBridgeTypes;

		return bridgeTypesToShow.map(id => {
			const name = this.getBridgeUnitTypeTranslatedName(id);

			return { Id: id, Name: name };
		});
	}

	private getTranslatedObject<T extends { Name: string }>(object: T, sectionName: string): T {
		return {
			...object,
			Name: this.translateService.instant(`${sectionName}.${object.Name}`)
		};
	}
}
