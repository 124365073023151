import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';
import { NewSleeveAttachedState, ScanOptionsState, ScanOptionsStore } from './scan-options-store';
import { filter, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ScanOptionsQuery extends Query<ScanOptionsState> {
	isSleeveChecked$: Observable<NewSleeveAttachedState> = this.select(state => state.isSleeveChecked);
	isSleeveCheckedSentByScanner$: Observable<boolean> = this.select(state => state.isSleeveCheckedSentByScanner);
	isNiriCaptureChecked$: Observable<boolean> = this.select(state => state.isNiriCaptureChecked);
	isPreTreatmentChecked$: Observable<boolean> = this.select(state => state.isPreTreatmentChecked);
	isEmergenceProfileChecked$: Observable<boolean> = this.select(state => state.isEmergenceProfileChecked);
	additionalBites$: Observable<Record<string, boolean>> = this.select(state => state.additionalBites);
	isRestoMultiBiteSelected$: Observable<boolean> = this.select(state => state.isRestoMultiBiteSelected);
	get isNiriCaptureChecked(): boolean {
		return this.getValue().isNiriCaptureChecked;
	}
	get isSleeveChecked(): NewSleeveAttachedState {
		return this.getValue().isSleeveChecked;
	}
	get isPreTreatmentChecked(): boolean {
		return this.getValue().isPreTreatmentChecked;
	}
	get isEmergenceProfileChecked(): boolean {
		return this.getValue().isEmergenceProfileChecked;
	}
	get additionalBites(): Record<string, boolean> {
		return this.getValue().additionalBites;
	}

	get isRestoMultiBiteSelected(): boolean {
		return this.getValue().isRestoMultiBiteSelected;
	}

	sleeveCheckedChangedByUser$: Observable<boolean> = this.select(state => state.isSleeveChecked).pipe(
		filter(isSleeveChecked => !!isSleeveChecked.isValueChangedByUser),
		map(isSleeveChecked => isSleeveChecked.value)
	);

	constructor(store: ScanOptionsStore) {
		super(store);
	}
}
