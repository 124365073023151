import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { RestorationTypesEnum } from '@modules/tooth-editor/models/restoration-types.enum';
import { ToothEditorFacade } from '@modules/tooth-editor/tooth-editor.facade';
import { BaseDestroyableComponent } from '@shared/base-classes/base-destroyable';
import { combineLatest, merge, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, takeUntil, tap } from 'rxjs/operators';
import { RestorationTypeFacade } from '@modules/tooth-editor/restoration-type.facade';
import { IdName } from '@shared/models/id-name';
import { RestorationTypeForm } from '@modules/tooth-editor/models/restoration-type-from';

@Component({
	selector: 'rx-restoration-type-section',
	styleUrls: ['./restoration-type-section.component.scss'],
	templateUrl: 'restoration-type-section.component.html',
	providers: [RestorationTypeFacade],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class RestorationTypeSectionComponent extends BaseDestroyableComponent implements OnInit {
	form = this.formBuilder.group<RestorationTypeForm>({
		restorationType: null,
		abutmentType: null,
		abutmentMaterial: null,
		isTiBased: false
	});

	get restorationTypeControl() {
		return this.form.controls.restorationType;
	}

	get abutmentTypeControl() {
		return this.form.controls.abutmentType;
	}

	get abutmentMaterialControl() {
		return this.form.controls.abutmentMaterial;
	}

	get isTiBasedControl() {
		return this.form.controls.isTiBased;
	}

	private isReadOnly: boolean;

	isReadOnly$: Observable<boolean> = this.toothEditorFacade.isReadOnly$.pipe(
		tap(isReadOnly => (this.isReadOnly = isReadOnly)),
		shareReplay({ refCount: true, bufferSize: 1 })
	);
	restorationTypes$: Observable<IdName[]> = this.facade.restorationTypes$;
	abutmentTypes$: Observable<IdName[]> = this.facade.abutmentTypes$;
	materials$: Observable<IdName[]> = this.facade.materials$.pipe(shareReplay({ refCount: true, bufferSize: 1 }));

	isScrewRetainedOrEmpty$: Observable<boolean> = this.facade.restorationTypeId$.pipe(
		map(restorationTypeId => restorationTypeId === RestorationTypesEnum.ScrewRetained || !restorationTypeId),
		shareReplay({ refCount: true, bufferSize: 1 })
	);

	isTiBasedDisabled$: Observable<boolean> = combineLatest([
		this.isScrewRetainedOrEmpty$,
		this.facade.abutmentTypeId$,
		this.isReadOnly$
	]).pipe(
		map(([isScrewRetainedOrEmpty, abutmentTypeId, isReadOnly]) => isScrewRetainedOrEmpty || !abutmentTypeId || isReadOnly),
		shareReplay({ refCount: true, bufferSize: 1 })
	);

	constructor(
		private formBuilder: FormBuilder,
		private toothEditorFacade: ToothEditorFacade,
		private facade: RestorationTypeFacade
	) {
		super();
	}

	ngOnInit(): void {
		merge(this.subscribeToUserChanges(), this.subscribeToSelectedToothChanges()).pipe(takeUntil(this.componentAlive$)).subscribe();
	}

	cleanControlWhenDisabled(isDisabled: boolean, formControl: FormControl): void {
		if (isDisabled && !this.isReadOnly) {
			formControl.patchValue(null);
		}
	}

	switchOffIsTiBasedControl(isDisabled: boolean): void {
		if (isDisabled && !this.isReadOnly) {
			this.isTiBasedControl.patchValue(false);
		}
	}

	private subscribeToUserChanges() {
		return this.form.valueChanges.pipe(
			distinctUntilChanged(),
			tap((formValue: RestorationTypeForm) => {
				this.facade.updateTooth(formValue);
			})
		);
	}

	private subscribeToSelectedToothChanges(): Observable<any> {
		return this.facade.restorationTypeForm$.pipe(
			filter(formValues => !!formValues),
			tap(formValues => this.form.patchValue(formValues, { emitEvent: false }))
		);
	}
}
