<mat-form-field [attr.id]="'rx-mat-form-field-' + id" fxFlex [class.read-only]="isReadOnly" hideRequiredMarker>
    <mat-label [class.read-only-label]="isReadOnly && !control.value"
    >{{ label }}<span *ngIf="secondaryLabel">- {{secondaryLabel}}</span
    ><span *ngIf="labelSeparator">{{ labelSeparator }}</span
    ><span class="mat-form-field-required-marker" *ngIf="isRequired && control.enabled">&#32;*</span>
    </mat-label>
    <mat-select [id]="'rx-auto-select-' + id"
                disableOptionCentering
                [value]="control.value"
                [rxIsRequired]="isRequired"
                [formControl]="control"
                [rxIsDisabled]="isDisabled"
                (rxIsDisabledEvent)="handleDisableEvent($event)"
                [compareWith]="compareWith"
                [panelClass]="'rx-object-options-inner-container'"
                [class.read-only]="isReadOnly"
                name="option"
    >
        <div class="options-flex-container">
            <div [ngClass]="panelClass" class="rx-object-options-panel rx-object-options-panel-5">
                <mat-option
                        *ngFor="let option of options; let index = index"
                        [value]="option"
                        [id]="option | mapper : generateOptionId.bind(this)"
                        [class]="{index, option} | mapper : getOptionClassName.bind(this)"
                >
                    {{ option | mapper : getOptionValue }}
                </mat-option>
            </div>
            <footer *ngIf="isCancellable">
                <button
                    [id]="id + '-cancel-selection'"
                    [disabled]="isCancelButtonDisabled"
                    mat-raised-button
                    class="gradient"
                    (click)="handleCancelSelection($event)"
                >{{ 'Selector.ClearSelection' | translate }}</button>
            </footer>
        </div>
    </mat-select>
    <mat-error *ngIf="isRequired && control.enabled">{{ 'Validation.RequiredField' | translate }}</mat-error>
</mat-form-field>
