import { TeethNumberingSystem } from '@modules/teeth-diagram/models/teeth-numbering-system.enum';
import { LoggerService } from '@core/services/logger/logger.service';

export const isTeethNumberingSystemValid = (teethNumberingSystem): boolean => {
	return [TeethNumberingSystem.FDI, TeethNumberingSystem.ADA, TeethNumberingSystem.Quadrant].includes(teethNumberingSystem);
};

export const getTeethNumberingSystem = ({
	forceTeethNumberingSystem,
	teethNumberingSystemFromRx,
	teethNumberingSystemFromUserSettings,
	isDoctor,
	logger
}: {
	forceTeethNumberingSystem?: TeethNumberingSystem;
	teethNumberingSystemFromRx?: TeethNumberingSystem;
	teethNumberingSystemFromUserSettings: TeethNumberingSystem;
	isDoctor: boolean;
	logger: LoggerService;
}): TeethNumberingSystem => {
	let result: TeethNumberingSystem;

	if (forceTeethNumberingSystem != null) {
		result = forceTeethNumberingSystem;
	} else if (!isDoctor && teethNumberingSystemFromRx != null) {
		result = teethNumberingSystemFromRx;
	} else {
		result = teethNumberingSystemFromUserSettings;
	}

	if (!isTeethNumberingSystemValid(result)) {
		logger.error(`Invalid teeth numbering system ${teethNumberingSystemFromUserSettings}`, {
			module: new Error().stack
		});

		result = TeethNumberingSystem.ADA;
	}

	return result;
};
