import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BaseDestroyableComponent } from '@shared/base-classes/base-destroyable';
import { DentureDetailsFacade } from '@modules/denture-details/denture-details.facade';
import { takeUntil, tap } from 'rxjs/operators';
import { RxModel } from '@shared/models/rx-models/interfaces/rx-model';
import { RxConfiguration } from '@shared/models/rx-configuration';
import { Observable } from 'rxjs';
import * as flex from '@shared/utils/flex-utils';
import { IdName } from '@shared/models/id-name';
import { MapperPipe } from '../../../../shared/pipes/mapper.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { GeneralRxDataModule } from '../../../general-rx-data/general-rx-data.module';
import { MouldElementComponent } from '../../components/mould-element/mould-element.component';
import { NgIf } from '@angular/common';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { FlexModule } from '@angular/flex-layout/flex';
import { DoubleHyphenPipe } from '@modules/general-rx-data/pipes/double-hyphen.pipe';
import { InfoBlockComponent } from '@modules/general-rx-data/components/info-block/info-block.component';

@Component({
    selector: 'rx-denture-details-info',
    templateUrl: './denture-details-info.component.html',
    styleUrls: ['denture-details-info.component.scss'],
    providers: [DentureDetailsFacade],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
	imports: [FlexModule, ExtendedModule, NgIf, MouldElementComponent, TranslateModule, MapperPipe, DoubleHyphenPipe, InfoBlockComponent]
})
export class DentureDetailsInfoComponent extends BaseDestroyableComponent implements OnInit {
	getResponsiveDirection = flex.getResponsiveDirection;

	@Input() rx: RxModel;
	@Input() isResponsive = true;

	stage: string;
	isUpperJawChecked: boolean;
	isLowerJawChecked: boolean;
	gingivalShade: string;
	teethShade: string;
	mould: IdName;
	shadeSystem: string;

	constructor(private facade: DentureDetailsFacade) {
		super();
	}

	ngOnInit() {
		this.subscribeToGetRxConfiguration();
	}

	subscribeToGetRxConfiguration() {
		this.getDentureDetailsConfig().pipe(takeUntil(this.componentAlive$)).subscribe();
	}

	getDentureDetailsConfig(): Observable<RxConfiguration> {
		return this.facade.rxConfiguration$.pipe(
			tap(rxConfiguration => {
				this.stage = rxConfiguration?.Stages?.find(x => x.Id === this.rx?.DentureDetails?.Stage)?.Name;
				this.isUpperJawChecked = this.rx?.DentureDetails?.UpperJaw;
				this.isLowerJawChecked = this.rx?.DentureDetails?.LowerJaw;
				this.gingivalShade = rxConfiguration?.DentureGingivalShades?.find(
					x => x.Id === this.rx?.DentureDetails?.GingivalShadeId
				)?.Name;
				this.teethShade = this.rx?.DentureDetails?.TeethShade;
				this.mould = rxConfiguration?.Moulds?.find(x => x.Id === this.rx?.DentureDetails?.Mould);

				this.shadeSystem = this.facade.shadeSystems?.find(
					shadeSystem => shadeSystem.Id === this.rx?.DentureDetails?.ShadeSystemId
				)?.Name;
			})
		);
	}

	getSrcForRadioButtonImg = (checked: boolean): string => this.facade.getSrcForRadioButtonImg(checked);
}
