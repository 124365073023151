import { Component, Directive, EventEmitter, Injector, Input, Output, ViewChild } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacySelect as MatSelect, MatLegacySelectModule } from '@angular/material/legacy-select';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { DropDownItem } from '@shared/models/drop-down-item';
import { ALIGNED_LEFT_POSITIONS } from '@shared/components/object-selector/positions';
import { ControlValueAccessorDirective } from '@shared/base-classes/control-value-accessor.directive';
import { ErrorStateMatcherForInstantDisplay } from '@shared/utils/is-error.state';
import { getOptionClassName, idNameCompareWith, simpleCompareWith } from '@shared/utils/selectors-utils';
import { MapperPipe } from '../../pipes/mapper.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyOptionModule } from '@angular/material/legacy-core';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { IsDisabledDirective } from '../../directives/is-disabled/is-disabled.directive';
import { IsRequiredDirective } from '../../directives/is-required.directive';
import { NgIf, NgClass, NgFor } from '@angular/common';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';

export interface CancelEvent<T> {
	event: Event;
	selector: ICancelableControl<T>;
}

@Directive()
export abstract class BaseSelectorDirective<T> extends ControlValueAccessorDirective<T> implements ICancelableControl<T> {
	@Input() id: string;
	@Input() label: string;
	@Input() secondaryLabel: string;
	@Input() isRequired: boolean;
	@Input() isDisabled: boolean;
	@Input() isReadOnly: boolean;
	@Input() isCancellable: boolean;
	@Input() labelSeparator: string;

	@Input()
	options: T[] = [];

	@Output() cancelSelection: EventEmitter<CancelEvent<T>> = new EventEmitter<CancelEvent<T>>();

	private shouldCleanOnDisable = false;

	@Input()
	set cleanOnDisable(value: any) {
		this.shouldCleanOnDisable = value !== undefined;
	}

	@Input()
	panelClass: string | string[] | Set<string> | { [key: string]: any } = null;

	protected position: ConnectedPosition[] = ALIGNED_LEFT_POSITIONS;
	@Input()
	set alignToPosition(position: ConnectedPosition[]) {
		if (this.matSelectElem && position) {
			// eslint-disable-next-line no-underscore-dangle
			this.matSelectElem._positions = position;
		}
		this.position = position;
	}

	@Output()
	isDisabledEvent: EventEmitter<void> = new EventEmitter<void>();
	private matSelectElem: MatSelect;

	@ViewChild(MatSelect) set setup(matSelect: MatSelect) {
		this.matSelectElem = matSelect;
		if (matSelect && this.position) {
			// eslint-disable-next-line no-underscore-dangle
			matSelect._positions = this.position;
		}
	}

	constructor(injector: Injector) {
		super(injector);
	}

	handleCancelSelection(event: Event) {
		if (this.cancelSelection.observers.length > 0) {
			this.cancelSelection.emit({ event, selector: this });

			return;
		}

		this.handleCancelDefault(event);
	}

	handleCancelDefault(event: Event) {
		event.stopPropagation();
		this.control.patchValue(null);
		this.close();
	}

	close() {
		this.matSelectElem.close();
	}

	handleDisableEvent($event: void) {
		if (this.shouldCleanOnDisable) {
			this.control.patchValue(null);
		}

		this.isDisabledEvent.emit($event);
	}

	getOptionClassName({ index }: { index: number; option: T }): Record<string, boolean> {
		return getOptionClassName(index);
	}

	get isCancelButtonDisabled(): boolean {
		const value = this.control?.value;

		return this.isCancellable && (value === undefined || value === null);
	}

	abstract generateOptionId(option: T, label: string, secondaryLabel: string): any;

	abstract getOptionValue(option: T): any;

	protected getOptionId(value: string, id: string, label: string, secondaryLabel: string): string {
		const labelPart = label?.toLowerCase().replace(/[ ]/g, '-');
		const secondaryLabelPart = secondaryLabel ? '-' + secondaryLabel?.toLowerCase().replace(/[ ]/g, '-') : '';
		const idPart = id ? '-' + id : '';
		const valuePart = value ? '-' + value?.replace(/[\W_]+/g, '')?.toLowerCase() : '';

		return 'simple-select-option-' + labelPart + secondaryLabelPart + idPart + valuePart;
	}
}

@Component({
    selector: 'rx-object-selector',
    templateUrl: './object-selector.component.html',
    styleUrls: ['./object-selector.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: ObjectSelectorComponent,
            multi: true
        }
    ],
    standalone: true,
    imports: [MatLegacyFormFieldModule, FlexModule, NgIf, MatLegacySelectModule, IsRequiredDirective, FormsModule, ReactiveFormsModule, IsDisabledDirective, NgClass, ExtendedModule, NgFor, MatLegacyOptionModule, MatLegacyButtonModule, TranslateModule, MapperPipe]
})
export class ObjectSelectorComponent<T extends DropDownItem> extends BaseSelectorDirective<T> {
	@Input()
	compareWith: (o1: T, o2: T) => boolean = idNameCompareWith;
	generateOptionId(option: T): string {
		return this.getOptionId(option.Name, option.Id, this.label, this.secondaryLabel);
	}

	getOptionValue(option: T): any {
		return option.Name;
	}

	getOptionClassName({ index, option }: { index: number; option: T }): Record<string, boolean> {
		return {
			...getOptionClassName(index),
			hidden: option.Hidden
		};
	}

	constructor(injector: Injector) {
		super(injector);
	}
}

@Component({
    selector: 'rx-string-selector',
    templateUrl: './object-selector.component.html',
    styleUrls: ['./object-selector.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: StringSelectorComponent,
            multi: true
        }
    ],
    standalone: true,
    imports: [MatLegacyFormFieldModule, FlexModule, NgIf, MatLegacySelectModule, IsRequiredDirective, FormsModule, ReactiveFormsModule, IsDisabledDirective, NgClass, ExtendedModule, NgFor, MatLegacyOptionModule, MatLegacyButtonModule, TranslateModule, MapperPipe]
})
export class StringSelectorComponent extends BaseSelectorDirective<string> {
	@Input()
	compareWith: (o1: string, o2: string) => boolean = simpleCompareWith;

	generateOptionId(option: string): string {
		return this.getOptionId(null, option, this.label, this.secondaryLabel);
	}

	getOptionValue(option: string): string {
		return option;
	}

	constructor(injector: Injector) {
		super(injector);
	}
}

export interface ICancelableControl<T> {
	handleCancelDefault(event: Event): void;
	control: FormControl<T>;
}
