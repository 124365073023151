import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { RxNote } from '@modules/notes/models/rx-note';

export interface AlignTechNotesState {
	alignTechNotesArray: RxNote[];
	alignTechNotes: string;
}

export const createInitialState = (): AlignTechNotesState => ({
	alignTechNotesArray: null,
	alignTechNotes: null
});

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'aligntech-notes' })
export class AlignTechNotesStore extends Store<AlignTechNotesState> {
	constructor() {
		super(createInitialState());
	}
}
