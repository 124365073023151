import { Injectable } from '@angular/core';
import { ProcedureEnum } from '@core/procedure-helpers/models/procedure.enum';
import { ProcedureMap } from '@shared/models/procedure-map';
import { ProcedureFormState } from '@modules/order/models/procedure-form-state';
import { TypeEnum } from '@modules/order/models/type.enum';
import { IdName } from '@shared/models/id-name';
import { TreatmentStage } from '@shared/models/treatment-stage';

@Injectable({
	providedIn: 'root'
})
export class ProcedureFormStateService {

	getFormState(
		procedureMap: ProcedureMap,
		isReadOnly: boolean,
		treatmentStage: IdName | TreatmentStage,
		shouldValidateForSend: boolean,
		procedureMaps: ProcedureMap[]
	): ProcedureFormState {
		return {
			sendTo: {
				isVisible: this.isSendToVisible(procedureMap),
				isEnabled: !!procedureMap?.SendToIsEnabled,
				isRequired: this.isSendToRequired(procedureMap, shouldValidateForSend)
			},
			iCastOrthoModel: {
				isVisible: this.isIcastOrthoModelVisible(procedureMap),
				isEnabled: true
			},
			treatmentStage: {
				isVisible: this.isTreatmentStageVisible(procedureMap),
				isEnabled: this.isTreatmentStageEnabled(procedureMap)
			},
			dueDate: {
				isVisible: procedureMap?.ProcedureId !== ProcedureEnum.Invisalign || !!procedureMap?.DueDateIsEnabled,
				isEnabled: !!procedureMap?.DueDateIsEnabled,
				isRequired: !isReadOnly ? this.isDueDateRequired(procedureMap) : false
			},
			currentAlignerId: {
				isVisible: this.isCurrentAlignerIdVisible(procedureMap),
				isEnabled: this.isCurrentAlignerIdEnabled(treatmentStage)
			},
			type: {
				isVisible: !this.isIcastOrthoModelVisible(procedureMap),
				isEnabled: this.isTypeEnabled(procedureMap, procedureMaps)
			}
		};
	}

	private isSendToVisible(procedureMap: ProcedureMap): boolean {
		return ![ProcedureEnum.Invisalign].includes(procedureMap?.ProcedureId);
	}

	private isSendToRequired(procedureMap: ProcedureMap, shouldValidate: boolean): boolean {
		return shouldValidate && !!procedureMap?.SendToIsMandatory;
	}

	private isIcastOrthoModelVisible(procedureMap: ProcedureMap): boolean {
		return [ProcedureEnum.StudyModel_iRecord].includes(procedureMap?.ProcedureId);
	}

	private isTreatmentStageVisible(procedureMap: ProcedureMap): boolean {
		return [ProcedureEnum.Invisalign].includes(procedureMap?.ProcedureId);
	}

	private isTreatmentStageEnabled(procedureMap: ProcedureMap): boolean {
		const byType = [
			TypeEnum.Invisalign_Aligners,
			TypeEnum.Invisalign_First_Aligners,
			TypeEnum.Invisalign_First_Palatal_Expander
		].includes(procedureMap?.TypeId);

		return byType;
	}

	private isDueDateRequired(procedureMap: ProcedureMap): boolean {
		return [].includes(procedureMap);
	}

	private isCurrentAlignerIdVisible(procedureMap: ProcedureMap): boolean {
		return [ProcedureEnum.Invisalign].includes(procedureMap?.ProcedureId);
	}

	private isCurrentAlignerIdEnabled(treatmentStage: IdName | TreatmentStage): boolean {
		return !!(treatmentStage as TreatmentStage)?.ClearAlignerIsEnabled;
	}

	private isTypeEnabled(procedureMap: ProcedureMap, procedureMaps: ProcedureMap[]): boolean {
		if (!procedureMap || procedureMaps?.length === 0) {
			return false;
		}
		const filteredProcedureMaps = procedureMaps?.filter(x => x.ProcedureId === procedureMap.ProcedureId);

		return filteredProcedureMaps.some(x => x.TypeId > TypeEnum.Empty);
	}
}
