import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';
import { AuthInfoState, AuthInfoStore } from './auth-info-store';
import { AuthInfo } from '@shared/models/auth-info';

@Injectable({ providedIn: 'root' })
export class AuthInfoQuery extends Query<AuthInfoState> {
	authInfo$: Observable<AuthInfo> = this.select(state => this.getAuthInfoFromState(state));
	jwtState$ = this.select(state => state.JWTState);

	get authInfo(): AuthInfo {
		return this.getAuthInfoFromState(this.getValue());
	}

	get jwt(): AuthInfo['accessToken'] {
		return this.authInfo.accessToken;
	}

	get jwtState() {
		return this.getValue().JWTState;
	}

	private getAuthInfoFromState(state: AuthInfoState): AuthInfo {
		const { accessToken, authUrl, sessionType, sessionId } = state;

		return { accessToken, authUrl, sessionType, sessionId };
	}

	constructor(authInfoStore: AuthInfoStore) {
		super(authInfoStore);
	}
}
