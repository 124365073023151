import { Injectable } from '@angular/core';
import { DoctorModel } from '@shared/models/rx-models/interfaces/doctor-model';
import { OrderModel } from '@shared/models/rx-models/interfaces/order-model';
import { PatientModelDto } from '@shared/models/rx-models/interfaces/patient-model-dto';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class GeneralRxDataV0Facade {

	doctor$: Observable<DoctorModel> = this.shellQuery.rx$.pipe(map(rx => rx?.Doctor));
	patient$: Observable<PatientModelDto> = this.shellQuery.rx$.pipe(map(rx => rx?.Patient));
	order$: Observable<OrderModel> = this.shellQuery.rx$.pipe(map(rx => rx?.Order));

	dateFormat$: Observable<string> = this.shellQuery.dateFormat$;

	doctorName$: Observable<string> = this.doctor$.pipe(map(doctor => doctor?.Name));
	patientName$: Observable<string> = this.patient$.pipe(map(patient => patient && `${patient?.LastName}, ${patient?.FirstName}`));

	caseTypeId$: Observable<number> = this.order$.pipe(map(order => order?.CaseTypeId));
	scanDate$: Observable<string> = this.order$.pipe(map(order => order?.ScanDate as string));
	dueDate$: Observable<string> = this.order$.pipe(map(order => order?.DueDate as string));
	signature$: Observable<string> = this.shellQuery.rx$.pipe(map(rx => rx?.Signature));
	license$: Observable<string> = this.doctor$.pipe(map(doctor => doctor?.Licence));
	chartNumber$: Observable<string> = this.patient$.pipe(map(patient => patient?.ChartNumber));
	state$: Observable<string> = this.order$.pipe(map(order => order?.State));
	sendToLab$: Observable<string> = this.order$.pipe(map(order => order?.ShipToName));
	preTreatmentScan$: Observable<boolean> = this.shellQuery.rx$.pipe(map(rx => rx?.PrePrepScan));


	constructor(private shellQuery: ShellQuery) {}

	getCaseTypeTranslationById(caseTypeId: number): Observable<string> {
		return this.shellQuery.rxConfiguration$.pipe(
			map(rxConfiguration => rxConfiguration?.CaseTypes?.find(x => x.Id === caseTypeId)?.Name || '')
		);
	}
}