import { ChangeDetectionStrategy, Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { CaseTypeOrderForm } from '@modules/order/models/order-form';
import { CaseTypeFormState } from '@modules/order/models/case-type-form-state';
import { BaseDestroyableComponent } from '@shared/base-classes/base-destroyable';
import { IdName } from '@shared/models/id-name';
import { Observable } from 'rxjs';
import { distinctUntilChanged, switchMap, takeUntil, tap, take, shareReplay, delay } from 'rxjs/operators';
import { CaseTypeFormStateService } from '@modules/order/services/case-type-form-state.service';
import { OrderV0Facade } from '@modules/order/order-v0.facade';

@Component({
	selector: 'rx-case-type-flow-order',
	templateUrl: './case-type-flow-order.component.html',
	styleUrls: ['./case-type-flow-order.component.scss'],
	providers: [OrderV0Facade],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CaseTypeFlowOrderComponent extends BaseDestroyableComponent implements OnInit {
	get caseTypeControl() {
		return this.orderForm.controls.caseType;
	}

	get sendToControl() {
		return this.orderForm.controls.sendTo;
	}
	get isMultiBiteSelectedControl() {
		return this.orderForm.controls.isMultiBiteSelected;
	}
	get treatmentStageControl() {
		return this.orderForm.controls.treatmentStage;
	}
	get dueDateControl() {
		return this.orderForm.controls.dueDate;
	}
	get currentAlignerIdControl() {
		return this.orderForm.controls.currentAlignerId;
	}

	constructor(
		private facade: OrderV0Facade,
		private formBuilder: FormBuilder,
		private orderFormControlsStatusService: CaseTypeFormStateService,
		private changeDetectorRef: ChangeDetectorRef
	) {
		super();
	}
	isReadOnly$: Observable<boolean> = this.facade.isReadOnly$;
	isReturned$: Observable<boolean> = this.facade.isReturned$;
	orderForm$: Observable<CaseTypeOrderForm> = this.facade.caseTypeOrderForm$;
	formState$: Observable<CaseTypeFormState> = this.facade.caseTypeFormState$;
	caseTypes$: Observable<IdName[]> = this.facade.caseTypes$;
	treatmentStages$: Observable<IdName[]> = this.facade.treatmentStages$;
	availableLabs$: Observable<IdName[]> = this.facade.availableLabs$;
	minimalAvailableDueDate$: Observable<Date> = this.facade.minimalAvailableDueDate$;
	maxDueDate$: Observable<Date> = this.facade.maxDueDate$;
	dueDate$: Observable<Date> = this.facade.dueDate$;
	isViveraCasetypeSelected$: Observable<boolean> = this.facade.isViveraCasetypeSelected$;
	isCaseTypeReadOnly$: Observable<boolean> = this.facade.isCaseTypeReadOnly$.pipe(shareReplay({ bufferSize: 1, refCount: true }));

	orderForm = this.formBuilder.group<CaseTypeOrderForm>(OrderV0Facade.getOrderFormInitialValue());

	ngOnInit(): void {
		this.subscribeToSetDefaultCaseType();
		this.subscribeToStoreChanges();
		this.subscribeToUserChanges();
		this.subscribeToDisableFormControls();
		this.subscribeToRxLoaded();
		this.subscribeToGetDirectToLab();
		this.subscribeToSetDirectToLab();
		this.subscribeToSetMaxDueDate();
		this.subscribeToSetIsEditableTreatmentStage();
	}

	handleCancelSendToLabSelection() {
		this.facade.cancelShipTo();
		this.sendToControl.patchValue(null);
	}

	private subscribeToSetIsEditableTreatmentStage(): void {
		this.facade.setIsFinalRecordsSoftWareOption().pipe(takeUntil(this.componentAlive$)).subscribe();
	}

	private subscribeToSetDefaultCaseType(): void {
		this.facade.setDefaultCaseType().pipe(takeUntil(this.componentAlive$)).subscribe();
	}

	private subscribeToGetDirectToLab(): void {
		this.facade.getDirectToLab().pipe(takeUntil(this.componentAlive$)).subscribe();
	}

	private subscribeToSetDirectToLab(): void {
		this.facade.setDirectToLab().pipe(takeUntil(this.componentAlive$)).subscribe();
	}

	private subscribeToStoreChanges(): void {
		this.orderForm$
			.pipe(
				tap(formValue => this.orderForm.patchValue(formValue, { emitEvent: false })),
				tap(() => this.changeDetectorRef.detectChanges()),
				takeUntil(this.componentAlive$)
			)
			.subscribe();

		this.facade
			.handleCaseTypeChanged()
			.pipe(
				tap(caseType => this.markDueDateWhenCaseTypeChanged(caseType[0] as IdName)),
				takeUntil(this.componentAlive$)
			)
			.subscribe();

		this.facade.handleTreatmentStageChanged().pipe(takeUntil(this.componentAlive$)).subscribe();
	}

	private markDueDateWhenCaseTypeChanged(caseType): void {
		if (this.orderFormControlsStatusService.isDueDateEnabled({ caseType })) {
			this.dueDate$
				.pipe(
					take(1),
					tap(dueDate => this.orderForm.controls.dueDate.patchValue(dueDate, { emitEvent: false }))
				)
				.subscribe();
		}
	}

	private subscribeToUserChanges(): void {
		this.orderForm.valueChanges
			.pipe(
				distinctUntilChanged(),
				switchMap((formValue: CaseTypeOrderForm) => this.facade.updateCaseTypeBasedForm({ formValue })),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}

	private subscribeToDisableFormControls(): void {
		this.formState$
			.pipe(
				// workaround, otherwise sometimes the changes don't reflect the controls
				delay(0),
				tap(({ sendTo, treatmentStage, dueDate, currentAlignerId, multiBite }) => {
					const dontEmitEvent = { emitEvent: false };

					if (sendTo.isEnabled) {
						this.sendToControl.enable(dontEmitEvent);
					} else {
						this.sendToControl.disable(dontEmitEvent);
					}

					if (treatmentStage.isEnabled) {
						this.treatmentStageControl.enable(dontEmitEvent);
					} else {
						this.treatmentStageControl.disable(dontEmitEvent);
					}

					if (dueDate.isEnabled) {
						this.dueDateControl.enable(dontEmitEvent);
					} else {
						this.dueDateControl.disable(dontEmitEvent);
					}

					if (currentAlignerId.isEnabled) {
						this.currentAlignerIdControl.enable(dontEmitEvent);
					} else {
						this.currentAlignerIdControl.disable(dontEmitEvent);
					}

					if (multiBite.isEnabled) {
						this.isMultiBiteSelectedControl.enable(dontEmitEvent);
					} else {
						this.isMultiBiteSelectedControl.disable(dontEmitEvent);
					}
				}),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}

	private subscribeToRxLoaded(): void {
		this.facade.loadOrder().pipe(takeUntil(this.componentAlive$)).subscribe();
	}

	private subscribeToSetMaxDueDate() {
		this.facade.setMaxDueDate().pipe(takeUntil(this.componentAlive$)).subscribe();
	}
}
