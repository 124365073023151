export const memoize = ({ extractUniqueId, clearCacheTimeout, doUseWeakMap }: MemoizePayload): MemoizeDecorator => {
	return (target: unknown, propertyKey: string, descriptor: PropertyDescriptor): void => {
		let cacheTeardownTimer: ReturnType<typeof setTimeout>;

		let cache = initCache(doUseWeakMap);

		const startTeardownTimeout = !clearCacheTimeout
			? null
			: () => {
					if (cacheTeardownTimer) {
						clearTimeout(cacheTeardownTimer);
					}
					cacheTeardownTimer = setTimeout(() => {
						cache = initCache(doUseWeakMap);
					}, clearCacheTimeout);
			  };

		const originalMethod = descriptor.value;

		descriptor.value = function (...args: unknown[]) {
			startTeardownTimeout?.();

			const uniqueId: any = extractUniqueId(...args);

			if (cache.has(uniqueId)) {
				const cachedResult = cache.get(uniqueId);

				return cachedResult;
			}

			// eslint-disable-next-line @typescript-eslint/no-unsafe-call
			const result = originalMethod.apply(this, args);

			cache.set(uniqueId, result);

			return result;
		};
	};
};

const initCache = (doUseWeakMap?: boolean) => {
	return doUseWeakMap ? new WeakMap<object, unknown>() : new Map<any, unknown>();
};

export interface MemoizePayload {
	extractUniqueId: (...args: any[]) => any;
	doUseWeakMap?: boolean;
	clearCacheTimeout?: number;
}

export type MemoizeDecorator = (target: unknown, propertyKey: string, descriptor: PropertyDescriptor) => void;
