import { Component, Input, OnInit } from '@angular/core';
import { ScanOptionsFacade } from '../../scan-options.facade';
import { RxModel } from '@shared/models/rx-models/interfaces/rx-model';
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { additionalBites } from '../../../../shared/data/bites';
import { BaseDestroyableComponent } from '@shared/base-classes/base-destroyable';

@Component({
	selector: 'rx-scan-options-lab',
	templateUrl: './scan-options-lab.component.html',
	styleUrls: ['./scan-options-lab.component.scss'],
	providers: [ScanOptionsFacade]
})
export class ScanOptionsLabComponent extends BaseDestroyableComponent implements OnInit {
	isRxForModelingContext$ = this.facade.isRxForModelingContext$;
	isRestoMultiBiteSelected$: Observable<boolean> = this.facade.isRestoMultiBiteSelected$;
	additionalBites$: Observable<Record<string, boolean>> = this.facade.additionalBites$;

	additionalBites = additionalBites;
	@Input() rx: RxModel;

	constructor(private facade: ScanOptionsFacade) {
		super();
	}

	ngOnInit(): void {
		this.facade.loadMultiBiteRestoScan().pipe(takeUntil(this.componentAlive$)).subscribe();
		this.facade.loadAdditionalBites().pipe(takeUntil(this.componentAlive$)).subscribe();
	}

	isBiteSelected(biteId): Observable<boolean> {
		return this.additionalBites$.pipe(map(bite => bite[biteId] || false));
	}
}
