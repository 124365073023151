export interface IApiMap {
	[selector: string]: {
		method: 'GET' | 'POST' | 'PUT' | 'DELETE';
		path: string;
		pathScanner: string;
		mock?: string;
	};
}

const apiMap: IApiMap = {
	getRxConfig: {
		method: 'GET',
		path: 'mvc/rx/Configuration/<%=companyId%>',
		pathScanner: 'Configuration/<%=companyId%>',
		mock: 'mocks_rx_config'
	},
	getProcedureFlowConfiguration: {
		method: 'GET',
		path: 'mvc/rx/ProcedureConfiguration/<%=companyId%>',
		pathScanner: 'ProcedureConfiguration/<%=companyId%>',
		mock: 'mocks_rx_config'
	},
	getPatient: {
		method: 'GET',
		path: '',
		pathScanner: '',
		mock: 'mock_patient'
	},
	getAvailableCaseTypes: {
		method: 'GET',
		path: 'mvc/rx/GetAvailableCaseTypes/<%=contactId%>',
		pathScanner: 'GetAvailableCaseTypes/<%=contactId%>',
		mock: 'mock_available_case_types'
	},
	getAvailableProcedureMaps: {
		method: 'GET',
		path: 'mvc/rx/GetAvailableProceduresMapId/<%=contactId%>',
		pathScanner: 'GetAvailableProceduresMapId/<%=contactId%>',
		mock: 'mock_get_available_procedures_map_id'
	},
	getUserSettings: {
		method: 'GET',
		path: 'mvc/rx/GetUserSettings/<%=contactId%>',
		pathScanner: 'GetUserSettings/<%=contactId%>',
		mock: 'mock_user_settings'
	},
	getRxByOrderId: {
		method: 'POST',
		path: 'mvc/rx/GetRxByOrderId',
		pathScanner: 'GetRxByOrderId',
		mock: 'mock_get_rx_by_order_id'
	},
	getFeatureToggles: {
		method: 'GET',
		path: 'mvc/featuresToggleSettings/getFeaturesToggleSettings',
		pathScanner: 'GetFeatureToggle',
		mock: 'mock_get_feature_toggles'
	},
	saveRx: {
		method: 'POST',
		path: 'mvc/rx/Save',
		pathScanner: 'Save',
		mock: 'mock_save_rx'
	},
	saveRxForLab: {
		method: 'POST',
		path: 'mvc/rxForm/SaveOrderRxForm',
		pathScanner: '',
		mock: 'mock_save_rx'
	},
	getRxById: {
		method: 'GET',
		path: 'mvc/rx/GetRxById/<%=rxId%>',
		pathScanner: 'GetRxById/<%=rxId%>',
		mock: 'mock_get_rx_by_id'
	},
	getPatientByUid: {
		method: 'GET',
		path: 'mvc/rx/GetPatientByUID',
		pathScanner: 'GetPatientByUID/<%=patientGuid%>',
		mock: 'mock_get_patient_by_id'
	},
	getRxsBulk: {
		method: 'POST',
		path: 'mvc/RX/GetRxsBulk',
		pathScanner: 'GetRxsBulk',
		mock: 'mock_get_rxs_bulk'
	},
	getCadCamSystemTypes: {
		method: 'GET',
		path: 'mvc/OrderInformationApi/GetCadCamSystemTypes',
		pathScanner: '',
		mock: 'mock_get_cad_cam_system_types'
	},
	getCanOrderPhysicalModel: {
		method: 'GET',
		path: 'mvc/OrderInformationApi/CanOrderPhysicalModel',
		pathScanner: '',
		mock: 'mock_get_cad_cam_system_types'
	},
	getCompaniesScanners: {
		method: 'GET',
		path: 'mvc/BusinessPartner/GetCompaniesScanners',
		pathScanner: '',
		mock: 'mock_get_companies_scanners'
	},
	getLabContact: {
		method: 'GET',
		path: 'mvc/lab/GetContactInfo',
		pathScanner: '',
		mock: 'mock_get_lab_contact'
	},
	refreshToken: {
		method: 'GET',
		path: 'api/v1/auth/session/refresh',
		pathScanner: '',
		mock: 'mock_refresh_token'
	}
};

export default apiMap;
