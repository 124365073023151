import { Injectable } from '@angular/core';
import { IdName } from '@shared/models/id-name';
import { CaseTypeEnum } from '../models/case-type.enum';
import { CaseTypeFormState } from '../models/case-type-form-state';
import { TreatmentStageEnum } from '../models/treatment-stage.enum';
import { RxStatusEnum } from '@shared/models/enums/rx-status.enum';
@Injectable({
	providedIn: 'root'
})
export class CaseTypeFormStateService {

	isSendToEnabled({ caseType }: { caseType: IdName }): boolean {
		return [CaseTypeEnum.iRecord, CaseTypeEnum.Restorative].includes(caseType?.Id);
	}

	isSendToRequired({ caseType }: { caseType: IdName }): boolean {
		return [CaseTypeEnum.Restorative].includes(caseType?.Id);
	}

	isMultiBiteVisible({ caseType }: { caseType: IdName }): boolean {
		return [CaseTypeEnum.iRecord].includes(caseType?.Id);
	}

	isTreatmentStageVisible({ caseType }: { caseType: IdName }): boolean {
		return [CaseTypeEnum.iRecord, CaseTypeEnum.Invisalign, CaseTypeEnum.Vivera,
			 CaseTypeEnum.ViveraPreDebond, CaseTypeEnum.InvisalignAndiRecord, CaseTypeEnum.InvisalignFirst].includes(caseType?.Id);
	}

	isTreatmentStageEnabled({ caseType }: { caseType: IdName }): boolean {
		return ![CaseTypeEnum.Vivera, CaseTypeEnum.ViveraPreDebond].includes(caseType?.Id);
	}

	isDueDateEnabled({ caseType }: { caseType: IdName }): boolean {
		const allowedCaseTypes = [CaseTypeEnum.Restorative, CaseTypeEnum.ChairSideMilling, CaseTypeEnum.ChairSideMillingGlidewellIo];
		return allowedCaseTypes.includes(caseType?.Id)
	}

	isDueDateEnabledForReferralWorkflow({ caseType, isReferralWorkflowPractice }: 
		{ caseType: IdName, isReferralWorkflowPractice: boolean }): boolean {
		if (!isReferralWorkflowPractice) return false;
		return [CaseTypeEnum.Invisalign, CaseTypeEnum.InvisalignFirst, CaseTypeEnum.Vivera].includes(caseType?.Id);
	}

	isDueDateRequired({ caseType }: { caseType: IdName }): boolean {
		return [CaseTypeEnum.Restorative, CaseTypeEnum.ChairSideMilling].includes(caseType?.Id)
	}

	isCurrentAlignerIdVisible({ caseType }: { caseType: IdName }): boolean {
		return [CaseTypeEnum.iRecord, CaseTypeEnum.Invisalign, CaseTypeEnum.Vivera, CaseTypeEnum.ViveraPreDebond,
			 CaseTypeEnum.InvisalignAndiRecord, CaseTypeEnum.InvisalignFirst].includes(caseType?.Id);
	}

	isCurrentAlignerIdEnabled({ treatmentStage }: { treatmentStage: IdName }): boolean {
		return [TreatmentStageEnum.ProgressRecord, TreatmentStageEnum.FinalRecord, CaseTypeEnum.InvisalignFirst].includes(treatmentStage?.Id);
	}

	isCurrentAlignerRequired({ caseType }: { caseType: IdName }): boolean {
		return this.isCurrentAlignerIdVisible({ caseType });
	}

	isTreatmentVisibleBySWO({isFinalRecord, isReferralWorkflowPractice} :
		 {isFinalRecord : boolean, isReferralWorkflowPractice : boolean } ): boolean {
		  return isFinalRecord || isReferralWorkflowPractice;
	}

	getFormState({ caseType, treatmentStage, shouldValidateForSend, isReadOnly, isReferralWorkflowPractice, isFinalRecord, rxScanStatus }: 
		{ caseType: IdName, treatmentStage: IdName, shouldValidateForSend: boolean,
			 isReadOnly: boolean, isReferralWorkflowPractice: boolean, isFinalRecord: boolean,
			 rxScanStatus: RxStatusEnum }): CaseTypeFormState {
		return {
			sendTo: {
				isVisible: true,
				isEnabled: this.isSendToEnabled({ caseType }),
				isRequired: (!isReadOnly && shouldValidateForSend) ? this.isSendToRequired({ caseType}) : false
			},
			multiBite: {
				isVisible: this.isMultiBiteVisible({ caseType }),
				isEnabled: rxScanStatus === RxStatusEnum.Unscanned
			},
			treatmentStage: {
				isVisible: this.isTreatmentStageVisible({ caseType }) && this.isTreatmentVisibleBySWO({ isFinalRecord, isReferralWorkflowPractice }),
				isEnabled: this.isTreatmentStageEnabled({ caseType })
			},
			dueDate: {
				isVisible: true,
				isEnabled: this.isDueDateEnabled({ caseType }) || this.isDueDateEnabledForReferralWorkflow({caseType, isReferralWorkflowPractice}),
				isRequired: (!isReadOnly && shouldValidateForSend) ? this.isDueDateRequired({ caseType }) : false	
			},
			currentAlignerId: {
				isVisible: this.isCurrentAlignerIdVisible({ caseType }) && this.isTreatmentVisibleBySWO({ isFinalRecord, isReferralWorkflowPractice }),
				isEnabled: this.isCurrentAlignerIdEnabled({ treatmentStage }),
				isRequired: this.isCurrentAlignerRequired({ caseType })
			},
		};
	}
}
