import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { AuthInfo } from '@shared/models/auth-info';
import { JWTStateEnum } from '@shared/models/jwt-state.enum';

export interface AuthInfoState extends AuthInfo {
	JWTState: JWTStateEnum;
}

export const getInitialState = (): AuthInfoState => {
	return {
		sessionId: null,
		sessionType: null,
		accessToken: null,
		authUrl: null,
		JWTState: JWTStateEnum.Ready
	};
};

@Injectable({
	providedIn: 'root'
})
@StoreConfig({ name: 'AuthInfo' })
export class AuthInfoStore extends Store<AuthInfoState> {
	constructor() {
		super(getInitialState());
	}
}
