import { ProcedureMapEnum } from '@core/procedure-helpers/models/procedure-map.enum';

export const ProcedureBridgeAvailabilityRules = [
	ProcedureMapEnum.FixedRestorativeLabResto,
	ProcedureMapEnum.FixedRestorativeChairsideExocad,
	ProcedureMapEnum.FixedRestorativeGlidewellIo,
	ProcedureMapEnum.FixedRestorativeWithoutSendTo,
	ProcedureMapEnum.FixedRestorativeMidcOnly,
	ProcedureMapEnum.FixedRestorativeSprintRay
];
