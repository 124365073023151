import { Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';

@Component({
	selector: 'rx-notice',
	templateUrl: './notice.component.html',
	styleUrls: ['./notice.component.scss']
})
export class NoticeComponent implements OnChanges {

	@Input() config: { companyCountryCode: string, noticeTranslationPath: string }[];
	@Input() companyCountryCode: string;

	translatedNoteRows: string[];

	constructor(private translateService: TranslateService) { }

	ngOnChanges(changes: SimpleChanges): void {
		const configForCurrentCountry = this.config?.find(config => config.companyCountryCode === this.companyCountryCode);
		if (configForCurrentCountry) {
			const noticeTranslationPath = configForCurrentCountry.noticeTranslationPath;
			this.getTranslation({ noticeTranslationPath }).subscribe();
		}
	}

	private getTranslation({ noticeTranslationPath }: { noticeTranslationPath: string }): Observable<string[]> {
		return this.translateService.get(noticeTranslationPath)
			.pipe(
				first(),
				tap((translatedNoteRows: string[]) => this.translatedNoteRows = translatedNoteRows)
			);
	}

}
