<div class="layout-container" fxLayout="row" fxLayoutAlign="center">
    <div class="modeling-container">
        <div class="modeling-components-container" fxLayout="column" fxLayoutAlign="start">
            <div class="hide-disable-ordersection">
                <ng-container [ngSwitch]="isProcedureFlow$ | async" #orderContainer>
                    <div *ngSwitchCase="true">
                        <rx-procedure-flow-order></rx-procedure-flow-order>
                        <rx-denture-details *ngIf="isDentureDetailsVisible$ | async"></rx-denture-details>
                    </div>
                    <rx-case-type-flow-order *ngSwitchCase="false"></rx-case-type-flow-order>
                </ng-container>
            </div>
            <ng-container [ngSwitch]="isProcedureFlow$ | async">
                <rx-general-data-v1 *ngSwitchCase="true"></rx-general-data-v1>
                <rx-general-data-v0 *ngSwitchCase="false"></rx-general-data-v0>
            </ng-container>
            <rx-denture-details-info [rx]="rx$ | async" [isResponsive]="true" *ngIf="procedureId$ | async | mapper : isDentureDetailsVisible">
            </rx-denture-details-info>
			<rx-scan-options-lab [rx]="rx$ | async"></rx-scan-options-lab>
            <div *ngIf="isRestoOrder$ | async">
                <rx-teeth-diagram [isProcedureFlow]="isProcedureFlow$ | async" [isRxForModeling]="true"></rx-teeth-diagram>
                <rx-treatment-information [isProcedureFlow]="isProcedureFlow$ | async"></rx-treatment-information>
                <rx-order-information></rx-order-information>
            </div>
            <div fxLayoutAlign="space-between stretch" class="notes-section-container">
                <rx-notes fxFlex="1 1 49%" [role]="role"></rx-notes>
                <rx-aligntech-notes fxFlex="1 1 49%"></rx-aligntech-notes>
            </div>
        </div>
    </div>
</div>
