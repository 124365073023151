import { Component, Input } from '@angular/core';

@Component({
    selector: 'rx-banner',
    templateUrl: './rx-banner.component.html',
    styleUrls: ['./rx-banner.component.scss'],
    standalone: true
})
export class RxBannerComponent {

  constructor() { }
  @Input() type: 'notification' | 'info';
  @Input() message: string;

}
