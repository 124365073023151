import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Tooth } from '@modules/teeth-diagram/models/tooth';
import { ToothEditorFacade } from '@modules/tooth-editor/tooth-editor.facade';
import { BaseDestroyableComponent } from '@shared/base-classes/base-destroyable';
import { PopupIconNames } from '@shared/models/enums/popup-icon.enum';
import { IdName } from '@shared/models/id-name';
import { MaterialOption } from '@shared/models/material-option';
import { PopUpActions } from '@shared/models/enums/popup-modal-actions.enum';
import { PopupService } from '@shared/services/popup.service';
import { merge, Observable, scan, Subject } from 'rxjs';
import { debounceTime, map, pairwise, takeUntil, tap, shareReplay, filter, startWith } from 'rxjs/operators';
import { ShadeSystem } from '@shared/models/shade-system';
import { Specification } from '@shared/models/specification';
import { SpecIdWithMaterialIds } from '@shared/models/rx-rules-json-interface';
import { UnitTypesInBridge } from '@modules/teeth-diagram/models/unit-type-in-bridge.enum';
import { LoggerService } from '@core/services/logger/logger.service';
import { unitTypesWithoutDetails } from '@shared/models/consts';
import { FavoritesEnum } from '@shared/models/enums/favorites.enum';
import { RoleTypeEnum } from '@shared/models/role-type';
import { DefaultCrownStore } from '@modules/tooth-editor/state/default-crown-store';
import { DefaultCrownData, CrownDefaultProperties } from '@modules/tooth-editor/models/default-crown-data';
import { CrownData } from '@modules/tooth-editor/models/tooth-editor';
import { Form, getMeaningfulValue, isIdName } from '@shared/utils/type-util';
import { ToothEditorSharingService } from '@modules/tooth-editor/services/tooth-editor-sharing.service';
import { TeethManagerService } from '@modules/tooth-editor/state/teeth-manager-service';
import { CancelEvent } from '@shared/components/object-selector/object-selector.component';
import { CrownSectionForm } from '@modules/tooth-editor/containers/crown-section/v1/crown-section-form';
import { DropDownItem } from '@shared/models/drop-down-item';

@Component({
	selector: 'rx-crown-section',
	styleUrls: ['./crown-section.component.scss'],
	templateUrl: 'crown-section.component.html',
	providers: [ToothEditorFacade],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CrownSectionComponent extends BaseDestroyableComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
	readonly maxInputLengthForOtherShadeSystem = 10;
	isOtherShadeSystemSelected: boolean;
	isMarginDesignEnabled = false;
	private allowToothReset = false;
	private toothResetTimeout;
	private readonly componentName = 'CrownSectionComponent';

	@Input() toothClickedOn: Tooth;
	@Input() isReadOnly: boolean;

	stumpShadeOptions: IdName[] = this.toothEditorFacade.getStumpShadeOptions();
	preparationDesignOptions: IdName[];
	shadeSystemOptions: ShadeSystem[];
	incisalBodyGingivalOptions: IdName[];

	materialOptions: IdName[];
	specifications: Specification[];

	shouldValidateForSend$: Observable<boolean> = this.toothEditorFacade.shouldValidateForSend$;
	favoriteMaterial = FavoritesEnum.Materials;

	private isPopUpEnabled = true;

	marginDesignOptions: IdName[] = this.toothEditorFacade.getMarginDesignOptions();

	get isToothClickedOnAPrep() {
		return this.toothEditorFacade.isPrep({ tooth: this.toothClickedOn });
	}

	isCrownSectionDisabled$ = this.toothEditorFacade.toothClickedOn$.pipe(
		filter(tooth => !!tooth),
		map(tooth => this.checkIsCrownSectionDisabled(tooth)),
		shareReplay({ refCount: true, bufferSize: 1 })
	);

	isPonticIndicationSelected$: Observable<boolean> = this.toothEditorFacade.toothClickedOn$.pipe(
		filter(tooth => !!tooth),
		map(tooth => this.checkPonticIndication(tooth))
	);

	materialOption: MaterialOption;

	ponticDesigns: IdName[];

	formChangingDestroy$ = new Subject<void>();

	isNotDoctor$: Observable<boolean> = this.toothEditorFacade.userRole$.pipe(
		map(userRole => userRole !== RoleTypeEnum.Doctor),
		shareReplay({ bufferSize: 1, refCount: true })
	);

	expandAdditionalInfo = false;

	crownSectionForm = new FormGroup<Form<CrownSectionForm>>({
		specification: new FormControl({ value: null, disabled: false }),
		material: new FormControl({ value: null, disabled: false }),
		preparationDesignBuccal: new FormControl({ value: null, disabled: false }),
		preparationDesignLingual: new FormControl({ value: null, disabled: false }),
		marginDesignBuccal: new FormControl({ value: null, disabled: false }),
		marginDesignLingual: new FormControl({ value: null, disabled: false }),
		shadeSystem: new FormControl({ value: null, disabled: false }),
		incisal: new FormControl({ value: null, disabled: false }),
		body: new FormControl({ value: null, disabled: false }),
		gingival: new FormControl({ value: null, disabled: false }),
		stumpShade: new FormControl({ value: null, disabled: false }),
		ponticDesign: new FormControl()
	});

	controlNames = Object.keys(this.crownSectionForm.controls) as (keyof CrownData)[];

	get specificationControl() {
		return this.crownSectionForm.controls.specification;
	}
	get materialControl() {
		return this.crownSectionForm.controls.material;
	}
	get preparationDesignBuccalControl() {
		return this.crownSectionForm.controls.preparationDesignBuccal;
	}
	get preparationDesignLingualControl() {
		return this.crownSectionForm.controls.preparationDesignLingual;
	}
	get marginDesignBuccalControl() {
		return this.crownSectionForm.controls.marginDesignBuccal;
	}
	get marginDesignLingualControl() {
		return this.crownSectionForm.controls.marginDesignLingual;
	}
	get shadeSystemControl() {
		return this.crownSectionForm.controls.shadeSystem;
	}

	get incisalControlAsIdName() {
		return this.incisalControl as FormControl<IdName>;
	}

	get incisalControl() {
		return this.crownSectionForm.controls.incisal;
	}

	get bodyControlAsIdName() {
		return this.bodyControl as FormControl<IdName>;
	}
	get bodyControlAsAString() {
		return this.bodyControl as FormControl<string>;
	}

	get bodyControl() {
		return this.crownSectionForm.controls.body;
	}

	get gingivalControlAsIdName() {
		return this.gingivalControl as FormControl<IdName>;
	}

	get gingivalControl() {
		return this.crownSectionForm.controls.gingival;
	}
	get stumpShadeControl() {
		return this.crownSectionForm.controls.stumpShade;
	}

	get ponticDesignControl() {
		return this.crownSectionForm.controls.ponticDesign;
	}

	get isShadeDisabled() {
		return !this.materialOption?.ShadeVisible;
	}

	constructor(
		private toothEditorFacade: ToothEditorFacade,
		private popupService: PopupService,
		private logger: LoggerService,
		private defaultCrownStore: DefaultCrownStore,
		private toothEditorSharingService: ToothEditorSharingService,
		private teethManagerService: TeethManagerService
	) {
		super();
	}

	ngOnChanges(changes: SimpleChanges): void {
		const isDifferentTooth = changes.toothClickedOn.currentValue.ToothID !== changes.toothClickedOn.previousValue?.ToothID;
		const isDifferentUnitType = changes.toothClickedOn.currentValue.UnitTypeID !== changes.toothClickedOn.previousValue?.UnitTypeID;
		const isDifferentUnitTypeInBridge =
			changes.toothClickedOn.currentValue.ToothInBridgeTypeID !== changes.toothClickedOn.previousValue?.ToothInBridgeTypeID;

		if (isDifferentTooth || isDifferentUnitType || isDifferentUnitTypeInBridge || this.allowToothReset) {
			this.logger.info(`Crown section Inputs changed, see here: ${JSON.stringify(changes, null, 2)}`, { module: this.componentName });
			this.formChangingDestroy$.next();
			if (isDifferentTooth) {
				this.crownSectionForm.markAsPristine();
			}
			this.setForm();
			this.subscribeToFormChanges();
			this.allowToothReset = false;
			this.isOtherShadeSystemSelected = (changes.toothClickedOn.currentValue as Tooth)?.ShadeSystemId === -1;
		}

		if (changes.isReadOnly?.currentValue) {
			this.toothEditorFacade.setFormGroupReadOnlyMode(this.crownSectionForm);
		}
	}

	ngOnInit() {
		this.subscribeToShadeSystemChanges();
		this.subscribeToSpecificationChanges();
		this.subscribeToMaterialChanges();
		this.subscribeToBodyChanges();
		this.checkAdditionalInfo();
	}

	ngAfterViewInit() {
		this.formChangingDestroy$.next();
		this.setForm();
		this.subscribeToFormChanges();
	}

	ngOnDestroy() {
		super.ngOnDestroy();
		clearTimeout(this.toothResetTimeout);
		this.formChangingDestroy$.complete();
	}

	checkIsCrownSectionDisabled(tooth: Tooth): boolean {
		if (tooth.BridgeIndex) {
			return this.toothEditorFacade.isGlidewellOrder || tooth.ToothInBridgeTypeID === UnitTypesInBridge.Missing;
		}

		return this.toothEditorFacade.isGlidewellOrder || unitTypesWithoutDetails.includes(tooth.UnitTypeID);
	}

	handleCancelSelection(cancelEvent: CancelEvent<DropDownItem>) {
		cancelEvent.selector.handleCancelDefault(cancelEvent.event);

		const targetControl = this.controlNames.find(key => this.crownSectionForm.controls[key] === cancelEvent.selector.control);

		if (targetControl) {
			this.teethManagerService.markCrownControlsAsRemovedByUser(this.toothClickedOn, [targetControl]);
		}
	}

	handleCancelSpecification(cancelEvent: CancelEvent<DropDownItem>) {
		this.clearControlsInAdditionalInfoPanel();

		const removedControls: (keyof CrownData)[] = [];

		if (this.bodyControl.value !== null) {
			this.bodyControl.patchValue(null);
			removedControls.push('body');
		}

		if (this.materialControl.value !== null) {
			this.materialControl.patchValue(null);
			removedControls.push('material');
		}
		// clear specification
		cancelEvent.selector.handleCancelDefault(cancelEvent.event);
		removedControls.push('specification');

		this.teethManagerService.markCrownControlsAsRemovedByUser(this.toothClickedOn, removedControls);
	}

	handleCancelMaterial(cancelEvent: CancelEvent<DropDownItem>) {
		this.clearControlsInAdditionalInfoPanel();

		const removedControls: (keyof CrownData)[] = [];

		if (this.bodyControl.value !== null) {
			this.bodyControl.patchValue(null);
			removedControls.push('body');
		}
		// clear material
		cancelEvent.selector.handleCancelDefault(cancelEvent.event);
		removedControls.push('material');

		this.teethManagerService.markCrownControlsAsRemovedByUser(this.toothClickedOn, removedControls);
	}

	private clearControlsInAdditionalInfoPanel() {
		[
			this.preparationDesignLingualControl,
			this.preparationDesignBuccalControl,
			this.marginDesignLingualControl,
			this.marginDesignBuccalControl,
			this.gingivalControl,
			this.incisalControl,
			this.stumpShadeControl
		].forEach(control => {
			if (control.value !== null) {
				control.patchValue(null);
			}
		});
	}

	private clearShadeValuesAndSetOptions(): void {
		this.incisalControl.patchValue(null);
		this.gingivalControl.patchValue(null);
		this.setIncisalBodyGingivalOptions();
		this.updateBodyOnShadeSystemChanges();
	}

	private updateBodyOnShadeSystemChanges() {
		const shadeId = this.shadeSystemControl?.value?.Id;

		this.isOtherShadeSystemSelected = shadeId === -1;

		if (!shadeId) {
			return this.bodyControl.patchValue(null);
		}

		const defaultBody = this.toothEditorSharingService.getDefaultBodyForShadeId(
			shadeId,
			this.toothClickedOn.BridgeIndex,
			this.toothClickedOn.ToothID,
			this.toothEditorFacade.teeth
		);

		if (!defaultBody) {
			return this.bodyControl.patchValue(null);
		}

		if (this.isOtherShadeSystemSelected) {
			return this.bodyControl.patchValue(defaultBody);
		}

		const defaultIncisalBodyGingivalOption = this.incisalBodyGingivalOptions.find(option => option.Id === defaultBody);

		this.bodyControl.patchValue(defaultIncisalBodyGingivalOption ?? null);
	}

	private subscribeToShadeSystemChanges() {
		this.shadeSystemControl.valueChanges
			.pipe(
				startWith(this.shadeSystemControl.value),
				pairwise(),
				tap(([prevShadeControlValue, nextShadeControlValue]: [IdName, IdName]) => {
					if (nextShadeControlValue) {
						if (this.incisalControl.value || this.bodyControl.value || this.gingivalControl.value) {
							if (!this.isPopUpEnabled) {
								return;
							}

							const popUpInput = {
								titleTranslationKey: 'Popup.Confirmation',
								contentTranslationKey: 'ToothEditor.ShadeSystemWarning',
								iconName: PopupIconNames.Question
							};

							this.popupService
								.openConfirmationPopUp({ popUpInput })
								.pipe(takeUntil(this.componentAlive$))
								.subscribe(result => {
									switch (result) {
										case PopUpActions.Cancel:
											this.isPopUpEnabled = false;
											this.shadeSystemControl.patchValue(prevShadeControlValue);
											this.isPopUpEnabled = true;
											break;
										case PopUpActions.Ok:
											this.clearShadeValuesAndSetOptions();
											break;
									}
								});
						} else {
							this.clearShadeValuesAndSetOptions();
						}
					}
				}),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}

	private subscribeToFormChanges() {
		this.crownSectionForm.valueChanges
			.pipe(
				scan(previous => {
					const current = this.crownSectionForm.getRawValue();

					this.updateUserAssignments(previous, current);

					return current;
				}, {}),
				takeUntil(merge(this.componentAlive$, this.formChangingDestroy$))
			)
			.subscribe();

		this.crownSectionForm.valueChanges
			.pipe(
				debounceTime(200),
				tap(() => this.updateToothFromForm()),
				takeUntil(merge(this.componentAlive$, this.formChangingDestroy$))
			)
			.subscribe();
	}

	private subscribeToSpecificationChanges() {
		this.specificationControl.valueChanges
			.pipe(
				tap(() => {
					this.updateToothFromForm();
					this.updateMaterial();
					this.setDefaultValue(this.specificationControl.value?.Id, CrownDefaultProperties.SpecificationId);
					this.updateMaterialControl();
				}),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}

	private subscribeToMaterialChanges() {
		this.materialControl.valueChanges
			.pipe(
				tap(() => {
					this.updateMaterial();
					this.setDefaultValue(this.materialControl.value?.Id, CrownDefaultProperties.MaterialId);
				}),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}

	private subscribeToBodyChanges() {
		this.bodyControl.valueChanges
			.pipe(
				filter(value => value !== undefined),
				tap(value => {
					this.setDefaultBodyValue(!this.isOtherShadeSystemSelected && isIdName(value) ? value.Id : value);
				}),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}

	private updateMaterial() {
		this.setMaterialOption();
		this.clearShadeSettingsControls();
		this.setPreparationDesignOptions();
		this.clearPrepAndMarginDesignControlsWhenNoAvailableOptions();
	}

	private updateMaterialControl() {
		this.materialOptions = this.toothEditorFacade.getMaterialsBySpecification(
			this.specificationControl.value?.Id,
			this.getSpecIdsWithMaterialIds()
		);

		const selectedMaterial = this.toothEditorFacade.toothClickedOn.MaterialID;

		if (this.materialControl.value && !this.materialOptions.some(({ Id }) => this.materialControl.value.Id === Id)) {
			this.materialControl.patchValue(null);
		} else if (!this.materialControl.value && selectedMaterial) {
			const materialOption = this.materialOptions.find(({ Id }) => selectedMaterial === Id);

			if (materialOption) {
				this.materialControl.patchValue(materialOption);
			}
		}
	}

	private clearPrepAndMarginDesignControlsWhenNoAvailableOptions() {
		const clearControlWhenNoAvailableOptions = <T extends IdName>(control: FormControl<T>, options?: T[]) => {
			if (control.value && !options?.some(({ Id }) => Id === control.value.Id)) {
				control.patchValue(null);
			}
		};

		clearControlWhenNoAvailableOptions(this.preparationDesignBuccalControl, this.preparationDesignOptions);
		clearControlWhenNoAvailableOptions(this.preparationDesignLingualControl, this.preparationDesignOptions);
		clearControlWhenNoAvailableOptions(this.marginDesignBuccalControl, this.marginDesignOptions);
		clearControlWhenNoAvailableOptions(this.marginDesignLingualControl, this.marginDesignOptions);
	}

	private setForm() {
		this.setSpecificationSelection();
	}

	private getSpecIdsWithMaterialIds(): SpecIdWithMaterialIds[] {
		return !!this.toothClickedOn?.BridgeIndex
			? this.toothEditorFacade.getSpecIdsWithMaterialIdsForBridge(
					this.toothEditorFacade.procedureMap.Id,
					this.toothClickedOn.ToothID,
					this.toothClickedOn.ToothInBridgeTypeID
			  )
			: this.toothEditorFacade.getSpecIdsWithMaterialIds(
					this.toothEditorFacade.procedureMap.Id,
					this.toothClickedOn.ToothID,
					this.toothClickedOn.UnitTypeID
			  );
	}

	private updateUserAssignments(previous: CrownSectionForm | object, current: CrownSectionForm) {
		const dirtyControls = this.controlNames.filter(key => {
			return previous[key] !== current[key] && this.crownSectionForm.controls[key]?.dirty;
		});

		this.teethManagerService.markCrownControlsAsAssignedByUser(this.toothClickedOn, dirtyControls);
	}

	private setSpecificationSelection() {
		const specIdsWithMaterialIds = this.getSpecIdsWithMaterialIds();

		const availableSpecifications = this.toothEditorFacade.getFilteredSpecifications(specIdsWithMaterialIds.map(({ Id }) => Id));
		const { currentSpecification, specifications } = this.getSpecificationFromOptions(availableSpecifications);

		this.specifications = specifications;
		this.specificationControl.patchValue(currentSpecification, { emitEvent: false });

		const availableMaterials = this.toothEditorFacade.getMaterialsBySpecification(
			this.specificationControl.value?.Id,
			specIdsWithMaterialIds
		);
		const { currentMaterial, materials } = this.getMaterialFromOptions(availableMaterials);

		this.materialOptions = materials;
		this.materialControl.patchValue(currentMaterial, { emitEvent: false });

		this.ponticDesigns = this.toothEditorFacade.ponticDesigns;
		this.ponticDesignControl.patchValue(this.getPonticFromOptions());

		this.setMaterialOption();
		this.setPreparationDesignOptions();
		this.setPrepAndMarginDesignSelection();
		this.setShadeSystemOptions();
		this.setIncisalBodyGingivalOptions();
		this.setShadeSystemSelections();
		this.setShadeBodySelection();
		this.setStumpShadeSelection();

		this.updateToothFromForm();
	}

	private getSpecificationFromOptions(specifications: Specification[]): {
		currentSpecification: Specification;
		specifications: Specification[];
	} {
		if (![-1, null, undefined].includes(this.toothClickedOn?.SpecificationId)) {
			let currentSpecification = specifications.find(({ Id }) => Id === this.toothClickedOn.SpecificationId);

			if (!currentSpecification) {
				currentSpecification = this.toothEditorFacade.addHiddenOptionToTop(
					this.toothClickedOn.SpecificationId,
					this.toothEditorFacade.getSpecificationName(this.toothClickedOn.SpecificationId),
					specifications
				) as Specification;
			}

			return { currentSpecification, specifications };
		}
		this.logger.info(`Specification of current tooth (#${this.toothClickedOn?.ToothID}) is ${this.toothClickedOn?.SpecificationId}`, {
			module: this.componentName
		});

		return { currentSpecification: null, specifications };
	}

	private getMaterialFromOptions(materials: IdName[]): { currentMaterial: DropDownItem; materials: IdName[] } {
		if (this.toothClickedOn?.MaterialID) {
			let currentMaterial = materials?.find(({ Id }) => Id === this.toothClickedOn.MaterialID);

			if (!currentMaterial) {
				currentMaterial = this.toothEditorFacade.addHiddenOptionToTop(
					this.toothClickedOn.MaterialID,
					this.toothEditorFacade.getMaterialName(this.toothClickedOn.MaterialID),
					materials
				);
			}

			return { currentMaterial, materials };
		}
		this.logger.info(`Material of current tooth (#${this.toothClickedOn?.ToothID}) is ${this.toothClickedOn?.MaterialID}`, {
			module: this.componentName
		});

		return { currentMaterial: null, materials };
	}

	private getPonticFromOptions(): IdName {
		if (this.checkPonticIndication(this.toothClickedOn)) {
			const ponticDesignID = this.toothClickedOn?.PonticDesignID;

			if (ponticDesignID) {
				return this.ponticDesigns.find(({ Id }) => Id === ponticDesignID) ?? null;
			}

			return null;
		}
		this.logger.info(`current tooth (#${this.toothClickedOn?.ToothID}) is not pontic or it is not procedure flow`, {
			module: this.componentName
		});

		return null;
	}

	private setShadeSystemSelections() {
		let incisalSelection;
		let gingivalSelection;

		if (this.isOtherShadeSystemSelected) {
			incisalSelection = this.toothClickedOn.ShadeIncisal;
			gingivalSelection = this.toothClickedOn.ShadeGingival;
		} else {
			incisalSelection = this.incisalBodyGingivalOptions?.find(
				incisalBodyGingivalOption => incisalBodyGingivalOption.Id === this.toothClickedOn.ShadeIncisal
			);
			gingivalSelection = this.incisalBodyGingivalOptions?.find(
				incisalBodyGingivalOption => incisalBodyGingivalOption.Id === this.toothClickedOn.ShadeGingival
			);
		}
		this.incisalControl.patchValue(incisalSelection || null);
		this.gingivalControl.patchValue(gingivalSelection || null);
	}

	private setStumpShadeSelection() {
		const stumpShadeOption = this.stumpShadeOptions.find(stumpShade => stumpShade?.Id === this.toothClickedOn.StumpfShade);

		this.stumpShadeControl.patchValue(stumpShadeOption || null);
	}

	private updateToothFromForm() {
		const crownSectionFormChanges = this.crownSectionForm.getRawValue();

		const toothChanges: CrownData = {
			material: crownSectionFormChanges.material?.Id ?? null,
			preparationDesignBuccal: crownSectionFormChanges.preparationDesignBuccal?.Id ?? null,
			preparationDesignLingual: crownSectionFormChanges.preparationDesignLingual?.Id ?? null,
			marginDesignBuccal: crownSectionFormChanges.marginDesignBuccal?.Id ?? null,
			marginDesignLingual: crownSectionFormChanges.marginDesignLingual?.Id ?? null,
			shadeSystem: crownSectionFormChanges.shadeSystem?.Id ?? null,
			incisal: getMeaningfulValue<string>(crownSectionFormChanges?.incisal),
			body: getMeaningfulValue<string>(crownSectionFormChanges?.body),
			gingival: getMeaningfulValue<string>(crownSectionFormChanges?.gingival),
			stumpShade: crownSectionFormChanges.stumpShade?.Id ?? null,
			specification: crownSectionFormChanges.specification?.Id ?? null,
			ponticDesign: crownSectionFormChanges.ponticDesign?.Id ?? null
		};

		if (!!this.toothClickedOn.BridgeIndex) {
			this.toothEditorFacade.updateToothAndSharedFieldsInBridge({
				tooth: this.toothClickedOn,
				crownData: { ...toothChanges }
			});
		} else {
			this.toothEditorFacade.updateTooth({ tooth: this.toothClickedOn, crownData: { ...toothChanges } });
		}
	}

	private setMaterialOption() {
		this.materialOption = this.toothEditorFacade.getMaterialOption(this.materialControl.value?.Id);
	}

	private clearShadeSettingsControls() {
		if (!this.materialOption) {
			return;
		}

		if (this.isShadeDisabled) {
			this.bodyControl.patchValue(null);
			this.incisalControl.patchValue(null);
			this.gingivalControl.patchValue(null);
		}

		if (!this.materialOption.StumpShadeVisible) {
			this.stumpShadeControl.patchValue(null);
		}
	}

	private setPreparationDesignOptions() {
		this.preparationDesignOptions = this.toothEditorFacade.mapPreparationDesignOptions(this.materialOption?.PrepDesignValues);
	}

	private setPrepAndMarginDesignSelection() {
		const preparationDesignBuccalSelection = this.preparationDesignOptions?.find(
			prepDesign => prepDesign.Id === this.toothClickedOn.PreparationDesignBuccalId
		);
		const preparationDesignLingualSelection = this.preparationDesignOptions?.find(
			prepDesign => prepDesign.Id === this.toothClickedOn.PreparationDesignLingualId
		);
		const marginDesignBuccalSelection = this.marginDesignOptions?.find(
			marginDesign => marginDesign.Id === this.toothClickedOn.MarginDesignBuccalId
		);
		const marginDesignLingualSelection = this.marginDesignOptions?.find(
			marginDesign => marginDesign.Id === this.toothClickedOn.MarginDesignLingualId
		);

		this.preparationDesignBuccalControl.patchValue(preparationDesignBuccalSelection || null);
		this.preparationDesignLingualControl.patchValue(preparationDesignLingualSelection || null);
		this.marginDesignBuccalControl.patchValue(marginDesignBuccalSelection || null);
		this.marginDesignLingualControl.patchValue(marginDesignLingualSelection || null);
	}

	private setShadeSystemOptions() {
		// Shade System does not depend on Material in V1
		const { shadeSystem, options } = this.toothEditorFacade.getShadeSystemOptionsAndSelectionForV1(this.toothClickedOn?.ShadeSystemId);

		this.isOtherShadeSystemSelected = shadeSystem?.Id === -1;
		this.shadeSystemOptions = options;

		this.shadeSystemControl.patchValue(shadeSystem, { emitEvent: false });
	}
	// TODO: refactor: no need to parse it when we have options in shadeSystemOptions prop
	private setIncisalBodyGingivalOptions() {
		this.incisalBodyGingivalOptions = this.toothEditorFacade.getIncisalBodyGingivalOptions({
			materialId: this.materialControl?.value?.Id,
			shadeSystemId: this.shadeSystemControl?.value?.Id
		});
	}

	private setShadeBodySelection() {
		let bodySelection;

		if (this.isOtherShadeSystemSelected) {
			bodySelection = this.toothClickedOn.ShadeBody;
		} else {
			bodySelection = this.incisalBodyGingivalOptions?.find(
				incisalBodyGingivalOption => incisalBodyGingivalOption.Id === this.toothClickedOn.ShadeBody
			);
		}

		this.bodyControl.patchValue(bodySelection ?? null);
	}

	private checkPonticIndication(tooth: Tooth): boolean {
		return tooth?.ToothInBridgeTypeID === UnitTypesInBridge.Pontic;
	}

	private checkAdditionalInfo() {
		this.expandAdditionalInfo = !!(
			this.preparationDesignBuccalControl.value ||
			this.preparationDesignLingualControl.value ||
			this.marginDesignBuccalControl.value ||
			this.marginDesignLingualControl.value ||
			this.incisalControl.value ||
			this.gingivalControl.value
		);
	}
	private setDefaultValue(value: number, property: keyof DefaultCrownData) {
		const bridgeIndex = this.toothClickedOn.BridgeIndex;

		if (bridgeIndex > 0) {
			this.defaultCrownStore.setDefaultData(value, property, bridgeIndex);
		}
	}

	private setDefaultBodyValue(value: string) {
		const bridgeIndex = this.toothClickedOn.BridgeIndex;
		const shadeSystemId = this.shadeSystemControl.value?.Id;

		if (bridgeIndex > 0 && shadeSystemId) {
			this.defaultCrownStore.setDefaultBodyData(value, bridgeIndex, shadeSystemId, this.toothClickedOn.ToothID);
		}
	}
}
