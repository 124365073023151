import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import {
	TeethManagerState,
	TeethManagerStore,
	ToothManagedID,
	ToothManagedItem,
	ToothPropertiesState,
	ToothPropertyStateEnum
} from '@modules/tooth-editor/state/teeth-manager-store';
import { Tooth } from '@modules/teeth-diagram/models/tooth';
import { CrownData } from '@modules/tooth-editor/models/tooth-editor';
import { ToothAndSharedProps } from '@modules/tooth-editor/services/tooth-editor-sharing.service';
import { TeethManagerAdapter } from '@modules/tooth-editor/state/teeth-manager-adapter';

@Injectable({ providedIn: 'root' })
export class TeethManagerQuery extends QueryEntity<TeethManagerState> {
	constructor(store: TeethManagerStore) {
		super(store);
	}
}

@Injectable({ providedIn: 'root' })
export class TeethManagerService {
	constructor(private query: TeethManagerQuery, private store: TeethManagerStore, private adapter: TeethManagerAdapter) {}

	onCreateCb = (id: number, newState: ToothPropertiesState): ToothManagedItem => ({ ToothID: id, ...newState });

	markCrownControlsAsRemovedByUser({ ToothID }: ToothManagedID, controls: (keyof CrownData)[]) {
		const toothPropertiesState = this.adapter.adaptCrownData(controls, ToothPropertyStateEnum.WasRemovedByUser);

		this.store.upsert(ToothID, toothPropertiesState, this.onCreateCb);
	}

	markCrownControlsAsAssignedByUser({ ToothID }: ToothManagedID, controls: (keyof CrownData)[]) {
		const toothPropertiesState = this.adapter.adaptCrownData(controls, ToothPropertyStateEnum.WasAssignedByUser);

		this.store.upsert(ToothID, toothPropertiesState, this.onCreateCb);
	}

	markAsShared(toothAndSharedProps: ToothAndSharedProps) {
		const [toothManagedItem] = this.adapter.adaptTeethAndSharedProps(
			[toothAndSharedProps],
			ToothPropertyStateEnum.WasSharedFromOtherTooth
		);

		this.store.upsert(toothManagedItem.ToothID, toothManagedItem, this.onCreateCb);
	}

	markManyAsShared(teethAndSharedProps: ToothAndSharedProps[]) {
		const toothManagedItems = this.adapter.adaptTeethAndSharedProps(
			teethAndSharedProps,
			ToothPropertyStateEnum.WasSharedFromOtherTooth
		);

		this.store.upsertMany(toothManagedItems);
	}

	setManyAsTakenFromRxModel(teeth: Tooth[]) {
		this.store.set(this.adapter.adaptTeeth(teeth, ToothPropertyStateEnum.WasTakenFromRxModel));
	}

	removeMany(teeth: Tooth[]) {
		const ids: number[] = teeth.map(tooth => tooth.ToothID);

		this.store.remove(ids);
	}

	isShared(entity: ToothManagedID, propertyName: keyof ToothPropertiesState): boolean {
		return this.getPropertyState(entity, propertyName) === ToothPropertyStateEnum.WasSharedFromOtherTooth;
	}

	isTakenFromRxModel(entity: ToothManagedID, propertyName: keyof ToothPropertiesState): boolean {
		return this.getPropertyState(entity, propertyName) === ToothPropertyStateEnum.WasTakenFromRxModel;
	}

	isAssignedByUser(entity: ToothManagedID, propertyName: keyof ToothPropertiesState): boolean {
		return this.getPropertyState(entity, propertyName) === ToothPropertyStateEnum.WasAssignedByUser;
	}

	isRemovedByUser(entity: ToothManagedID, propertyName: keyof ToothPropertiesState): boolean {
		return this.getPropertyState(entity, propertyName) === ToothPropertyStateEnum.WasRemovedByUser;
	}

	markAsAssignedByUser(newEntity: ToothManagedID, propertyName: keyof ToothPropertiesState) {
		this.markAs(newEntity, propertyName, ToothPropertyStateEnum.WasAssignedByUser);
	}

	private getPropertyState(entity: ToothManagedID, propertyName: keyof ToothPropertiesState): ToothPropertyStateEnum {
		return this.query.getEntity(entity.ToothID)?.[propertyName];
	}

	private markAs(newEntity: ToothManagedID, propertyName: keyof ToothPropertiesState, propertyState: ToothPropertyStateEnum) {
		this.store.upsert(newEntity.ToothID, { [propertyName]: propertyState });
	}
}
