import { Component } from '@angular/core';
import { AwarenessFacade } from '@modules/awareness/awareness.facade';

@Component({
	selector: 'rx-awareness-banner-lmr',
	templateUrl: './awareness-banner-lmr.component.html'
})
export class AwarenessBannerLmrComponent {
	constructor(private awarenessFacade: AwarenessFacade) {}

	get isProcedureFlow(): boolean {
		return this.awarenessFacade.isProcedureFlow;
	}
}
