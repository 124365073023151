import { Component, forwardRef, Injector, OnInit, ViewEncapsulation } from '@angular/core';
import { ObjectSelectorComponent } from '@shared/components/object-selector/object-selector.component';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { IdName } from '@shared/models/id-name';
import { TranslateModule } from '@ngx-translate/core';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MouldElementComponent } from '../mould-element/mould-element.component';
import { MatLegacyOptionModule } from '@angular/material/legacy-core';
import { NgFor, NgIf, AsyncPipe } from '@angular/common';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';

const MOULDS_VALUE_ACCESSOR = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => MouldsDropdownComponent),
	multi: true
};

@Component({
	selector: 'rx-moulds-dropdown',
	templateUrl: './moulds-dropdown.component.html',
	styleUrls: ['./moulds-dropdown.component.scss'],
	encapsulation: ViewEncapsulation.None,
	providers: [MOULDS_VALUE_ACCESSOR],
	standalone: true,
	imports: [
		MatLegacyFormFieldModule,
		MatLegacySelectModule,
		ReactiveFormsModule,
		NgFor,
		MatLegacyOptionModule,
		MouldElementComponent,
		NgIf,
		MatLegacyButtonModule,
		AsyncPipe,
		TranslateModule
	]
})
export class MouldsDropdownComponent extends ObjectSelectorComponent<IdName> implements OnInit {
	public selectedMouldId$: Observable<number>;

	constructor(injector: Injector) {
		super(injector);
	}

	ngOnInit(): void {
		this.selectedMouldId$ = this.control.valueChanges.pipe(map(value => value?.Id));
	}
}
