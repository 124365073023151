import { Inject, Injectable } from '@angular/core';
import { TeethDiagramState } from '@modules/teeth-diagram/models/teeth-diagram';
import { TeethNumberingSystem } from '@modules/teeth-diagram/models/teeth-numbering-system.enum';
import { Tooth } from '@modules/teeth-diagram/models/tooth';
import { UnitTypes } from '@modules/teeth-diagram/models/unit-type.enum';
import { TeethDiagramQuery } from '@modules/teeth-diagram/state/teeth-diagram-query';
import { TeethDiagramStore } from '@modules/teeth-diagram/state/teeth-diagram-store';
import { IBaseFacade } from '@shared/base-classes/base.facade';
import { Material } from '@shared/models/material';
import { RoleTypeEnum } from '@shared/models/role-type';
import { BridgeService } from '@shared/services/bridge.service';
import { ValidateRxService } from '@shared/services/validate-rx';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UnitTypesInBridge } from '@modules/teeth-diagram/models/unit-type-in-bridge.enum';
import { getToothNumberById } from '@modules/teeth-diagram/models/teeth-numbering';
import { RxRulesService } from '@shared/services/rx-rules-helper/rx-rules.service';
import { RESTORATION_CONFIGURATION } from '@shared/rules/rules-tokens';
import { RestorationConfiguration } from '@shared/models/restoration-configuration-interface';
import { OrderQuery } from '@modules/order/state/order-query';
import { IdName } from '@shared/models/id-name';

@Injectable()
export class TreatmentInformationFacade implements IBaseFacade {
	isProcedureFlow$ = this.shellQuery.isProcedureFlow$;
	getState$: Observable<TeethDiagramState> = this.teethDiagramQuery.teethState$;
	treatments$: Observable<Tooth[]> = combineLatest([
		this.teethDiagramQuery.teeth$,
		this.shellQuery.shouldValidateForSend$,
		this.orderQuery.procedureMap$ // required to re-validate teeth once the procedureMap is loaded
	]).pipe(
		map(([{ upperJaw, lowerJaw }, shouldValidateForSend]) => {
			return [...upperJaw, ...lowerJaw]
				.filter(tooth => this.checkIsToothTreatment({ tooth }))
				.map((tooth: Tooth) => ({
					...tooth,
					IsInvalidForSend: shouldValidateForSend ? this.validateRxService.isTreatmentInvalidForSend(tooth) : false
				}));
		})
	);

	bridges$: Observable<Tooth[][]> = combineLatest([this.teethDiagramQuery.teeth$, this.shellQuery.shouldValidateForSend$]).pipe(
		map(([{ upperJaw, lowerJaw }, shouldValidateForSend]) => {
			const bridges = this.bridgeService.getAllBridges({ upperJaw, lowerJaw });
			const bridgesArray: Tooth[][] = [];

			// eslint-disable-next-line guard-for-in
			for (const bridgeIndex in bridges) {
				const bridge = bridges[bridgeIndex].map((_tooth: any) => ({
					..._tooth,
					IsInvalidForSend: shouldValidateForSend ? this.validateRxService.isTreatmentInvalidForSend(_tooth) : false
				}));

				bridgesArray.push(bridge.sort((a, b) => this.sortByToothId(a, b)));
			}

			return bridgesArray.sort((a, b) => this.sortByToothId(a[0], b[0]));
		})
	);
	teethNumberingSystem$: Observable<TeethNumberingSystem> = this.teethDiagramQuery.teethNumberingSystem$;
	private materials$: Observable<Material[]> = this.shellQuery.materials$;

	upperJaw$: Observable<Tooth[]> = this.teethDiagramQuery.upperJaw$;
	lowerJaw$: Observable<Tooth[]> = this.teethDiagramQuery.lowerJaw$;
	userRole$: Observable<RoleTypeEnum> = this.shellQuery.userRole$;

	get ponticDesigns(): IdName[] {
		return this.shellQuery.ponticDesigns;
	}

	convertFromBridgeToothUnitType({ unitTypeInBridge }: { unitTypeInBridge: UnitTypesInBridge }): UnitTypes {
		return this.bridgeService.convertFromBridgeToothUnitType({ unitTypeInBridge });
	}

	getBridgeTreatment(tooth: Tooth): string {
		return this.rxRulesService.getBridgeUnitTypeTranslatedName(tooth.ToothInBridgeTypeID);
	}

	getUnitType(tooth: Tooth): string {
		return this.rxRulesService.getUnitTypeTranslatedName(tooth.UnitTypeID);
	}

	getAbutementTypeName(tooth: Tooth): string {
		const abutmentTypeMap = this.configuration.AbutmentTypeMappings.find(x => x.TargetId === tooth.AbutmentType);

		return this.shellQuery.rxConfiguration.AbutmentTypes?.find(x => x.Id === abutmentTypeMap.VisibleId)?.Name;
	}

	getRestorationTypeName(tooth: Tooth): string {
		return this.shellQuery.rxConfiguration.RestorationTypes.find(restorationType => {
			return restorationType.Id === tooth.ImplantBasedRestorationTypeId;
		})?.Name;
	}

	checkIsToothTreatment({ tooth }: { tooth: Tooth }): boolean {
		const isToothTreatment =
			!tooth.BridgeIndex &&
			tooth.UnitTypeID &&
			![UnitTypes.Missing_edentulousSpace, UnitTypes.Missing_noSpace, UnitTypes.Regular].includes(tooth.UnitTypeID);

		return isToothTreatment;
	}

	checkIsBridgeTooth({ tooth }: { tooth: Tooth }): boolean {
		return !!tooth.BridgeIndex && tooth.BridgeIndex > 0;
	}

	getTreatmentTeethSync({ teeth }: { teeth: Tooth[] }): Tooth[] {
		return teeth?.filter(tooth => this.checkIsToothTreatment({ tooth })).sort((a, b) => a.ToothID - b.ToothID);
	}

	getBridgesSync({ teeth }: { teeth: Tooth[] }): Tooth[][] {
		const bridges = teeth ? this.bridgeService.getAllBridges({ upperJaw: [...teeth], lowerJaw: [] }) : [];
		const bridgesArray: Tooth[][] = [];

		// eslint-disable-next-line guard-for-in
		for (const bridgeIndex in bridges) {
			bridgesArray.push(bridges[bridgeIndex].sort((a, b) => this.sortByToothId(a, b)));
		}

		return bridgesArray.sort((a, b) => this.sortByToothId(a[0], b[0]));
	}

	constructor(
		private teethDiagramQuery: TeethDiagramQuery,
		private teethDiagramStore: TeethDiagramStore,
		private shellQuery: ShellQuery,
		private bridgeService: BridgeService,
		private validateRxService: ValidateRxService,
		private rxRulesService: RxRulesService,
		private orderQuery: OrderQuery,
		@Inject(RESTORATION_CONFIGURATION) private configuration: RestorationConfiguration
	) {}

	getStateSync(property?: string) {
		return property ? this.teethDiagramQuery.getValue()[property] : this.teethDiagramQuery.getValue();
	}

	updateTeeth({ teethToUpdate }: { teethToUpdate: Tooth[] }) {
		this.teethDiagramStore.updateTeeth({ teethToUpdate });
	}

	getMaterialName(materialId: number): Observable<string> {
		return this.materials$.pipe(map(materials => materials?.find(material => material.Id === materialId)?.Name ?? ''));
	}

	getSpecificationName(specId: number): Observable<string> {
		return this.shellQuery.specifications$.pipe(map(specs => specs?.find(({ Id }) => Id === specId)?.Name ?? ''));
	}

	getToothNumberById(toothId: number): Observable<number | string> {
		return this.teethDiagramQuery.teethNumberingSystem$.pipe(
			map(teethNumberingSystem => getToothNumberById({ toothId, teethNumberingSystem }))
		);
	}

	getTiBase(abutmentTypeId: number) {
		return this.rxRulesService.getTiBase(abutmentTypeId);
	}

	getBridgeSpan(teeth: Tooth[]): { firstTooth: string | number; lastTooth: string | number } {
		if (teeth.length < 2) {
			return { firstTooth: '', lastTooth: '' };
		}

		const firstTooth = getToothNumberById({
			toothId: teeth[0].ToothID,
			teethNumberingSystem: this.teethDiagramQuery.teethNumberingSystem
		});
		const lastTooth = getToothNumberById({
			toothId: teeth[teeth.length - 1].ToothID,
			teethNumberingSystem: this.teethDiagramQuery.teethNumberingSystem
		});

		return { firstTooth, lastTooth };
	}

	private sortByToothId = (toothA: Tooth, toothB: Tooth) => toothA.ToothID - toothB.ToothID;
}
