import { Pipe, PipeTransform } from '@angular/core';
import { DateOfBirthUtils } from '@shared/utils/date-of-birth-utils';

@Pipe({
  name: 'dateOfBirth'
})
export class DateOfBirthPipe implements PipeTransform {
	
	transform(dateOfBirth: string | Date, dateFormat: string, regulatoryDOBMask?: string): string {
		return DateOfBirthUtils.format(dateOfBirth, dateFormat, regulatoryDOBMask);
	}
}
