import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { TeethDiagramState } from '../models/teeth-diagram';
import { defaultTeethNumbers } from '../models/teeth-numbering';
import { TeethNumberingSystem } from '../models/teeth-numbering-system.enum';
import { Tooth } from '../models/tooth';
import { UnitTypes } from '../models/unit-type.enum';

const createNewJaw = (teethNums: number[]): Tooth[] =>
	teethNums.map((toothId: number) => {
		return {
			ToothID: toothId,
			UnitTypeID: UnitTypes.Regular
		};
	});

export const createInitialState = (): TeethDiagramState => ({
	teethNumberingSystem: TeethNumberingSystem.ADA,
	teeth: {
		upperJaw: createNewJaw(defaultTeethNumbers.upperJaw),
		lowerJaw: createNewJaw(defaultTeethNumbers.lowerJaw)
	},
	copiedToothId: null
});

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'teeth-diagram', resettable: true })
export class TeethDiagramStore extends Store<TeethDiagramState> {
	constructor() {
		super(createInitialState());
	}

	updateTeeth({ teethToUpdate }: { teethToUpdate: Tooth[] }) {
		const { upperJaw, lowerJaw } = this.getUpdatedTeethSync({ teethToUpdate });
		this.update({ teeth: { upperJaw, lowerJaw } });
	}

	getUpdatedTeethSync({ teethToUpdate, fromNewState = false }: { teethToUpdate: Tooth[]; fromNewState?: boolean }): {
		upperJaw: Tooth[];
		lowerJaw: Tooth[];
	} {
		let upperJaw = fromNewState ? [...createNewJaw(defaultTeethNumbers.upperJaw)] : [...this.getValue().teeth.upperJaw];
		let lowerJaw = fromNewState ? [...createNewJaw(defaultTeethNumbers.lowerJaw)] : [...this.getValue().teeth.lowerJaw];
		teethToUpdate?.forEach(toothToUpdate => {
			const indexToUpdateUpper = upperJaw.findIndex(tooth => toothToUpdate.ToothID === tooth.ToothID);
			const indexToUpdateLower = lowerJaw.findIndex(tooth => toothToUpdate.ToothID === tooth.ToothID);
			if (indexToUpdateUpper > -1) {
				upperJaw = [...upperJaw.slice(0, indexToUpdateUpper), toothToUpdate, ...upperJaw.slice(indexToUpdateUpper + 1)];
			}
			if (indexToUpdateLower > -1) {
				lowerJaw = [...lowerJaw.slice(0, indexToUpdateLower), toothToUpdate, ...lowerJaw.slice(indexToUpdateLower + 1)];
			}
		});
		return { upperJaw, lowerJaw };
	}

	resetTeeth(): void {
		this.update({
			teeth: {
				upperJaw: createNewJaw(defaultTeethNumbers.upperJaw),
				lowerJaw: createNewJaw(defaultTeethNumbers.lowerJaw)
			},
			copiedToothId: null
		});
	}
}
