import { Observable, OperatorFunction, throwError, timer } from 'rxjs';
import { concatMap, retryWhen } from 'rxjs/operators';

export const delayedRetry = <T>(retryIntervals: number[], skipRetryProjector?: (error: Error) => boolean): OperatorFunction<T, T> => {
	return (src: Observable<T>): Observable<T> => {
		return src.pipe(
			retryWhen((errors: Observable<Error>) =>
				errors.pipe(
					concatMap((error, i) => {
						return i < retryIntervals?.length && !skipRetryProjector?.(error) ? timer(retryIntervals[i]) : throwError(error);
					})
				)
			)
		);
	};
};