import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class DateService {

	get currentDate(): Date {
		return  new Date();
	}

}
