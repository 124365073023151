<form [formGroup]="abutmentSectionForm"
[class.read-only]="isReadOnly"
>
  <div
    fxFlex
    fxLayout="row"
    fxLayoutAlign="start"
    fxLayout.sm="column stretch"
    fxLayout.xs="column stretch"
  >
    <div fxFlex="1 1 30%">
      <rx-object-selector
        [id]="'abutment-type'"
        [label]="'ToothEditor.AbutmentType' | translate"
        [isReadOnly]="isReadOnly"
        [formControl]="abutmentTypeControl"
        [options]="abutmentTypeOptions"
        name="abutmentTypeControl"
		ngDefaultControl
      ></rx-object-selector>
      <rx-object-selector
        [id]="'abutment-material'"
        [label]="'ToothEditor.AbutmentMaterial' | translate"
        [isReadOnly]="isReadOnly"
        [formControl]="abutmentMaterialControl"
        [options]="abutmentMaterialOptions"
        [isDisabled]="!abutmentTypeControl?.value"
        name="abutmentMaterialControl"
		ngDefaultControl
      ></rx-object-selector>
      <rx-object-selector
        [id]="'margin-style'"
        [label]="'ToothEditor.MarginStyle' | translate"
        [isReadOnly]="isReadOnly"
        [formControl]="marginStyleControl"
        [options]="marginStyleOptions"
        [isDisabled]="!abutmentTypeControl?.value"
        name="marginStyleControl"
		ngDefaultControl
      ></rx-object-selector>
    </div>
  </div>
</form>
