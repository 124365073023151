import { Injectable } from '@angular/core';
import { RxNote } from '@modules/notes/models/rx-note';
import { IdName } from '@shared/models/id-name';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { Observable } from 'rxjs';
import { AligntechNotesService } from './services/aligntech-notes.service';
import { AlignTechNotesQuery } from './state/aligntech-notes-query';
import { HostPlatformService } from '@shared/services/host-platform.service';

@Injectable()
export class AlignTechNotesFacade {
	isReadOnly$: Observable<boolean> = this.shellQuery.getReadOnlyState('alignNotes');
	user$: Observable<IdName> = this.shellQuery.user$;

	notesArray$: Observable<RxNote[]> = this.query.alignTechNotesArray$;
	dateFormat$: Observable<string> = this.shellQuery.dateFormat$;

	get isReadOnly() {
		return this.shellQuery.isReadOnly;
	}

	get isRxForModelingContext(): boolean {
		return this.hostPlatformService.isIteroModeling;
	}

	constructor(
		private aligntechNotesService: AligntechNotesService,
		private shellQuery: ShellQuery,
		private query: AlignTechNotesQuery,
		private hostPlatformService: HostPlatformService
	) {}

	updateNotes(notes: RxNote[]) {
		return this.aligntechNotesService.updateAlignTechNotes(notes);
	}
	setAlignTechNotesArray() {
		return this.aligntechNotesService.setAlignTechNotesArray();
	}
}
