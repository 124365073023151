import { ProcedureEnum } from '@core/procedure-helpers/models/procedure.enum';
import { AddRxProcedureMapping } from '@shared/models/rx-rules-json-interface';

export const AddRxRules: AddRxProcedureMapping[] = [
	{
		OriginalProcedure: ProcedureEnum.StudyModel_iRecord,
		AvailableProcedures: [
			ProcedureEnum.StudyModel_iRecord,
			ProcedureEnum.Invisalign,
			ProcedureEnum.Appliance,
			ProcedureEnum.FixedRestorative
		]
	},
	{
		OriginalProcedure: ProcedureEnum.Invisalign,
		AvailableProcedures: [
			ProcedureEnum.StudyModel_iRecord,
			ProcedureEnum.Invisalign,
			ProcedureEnum.Appliance,
			ProcedureEnum.FixedRestorative
		]
	},
	{
		OriginalProcedure: ProcedureEnum.Appliance,
		AvailableProcedures: [
			ProcedureEnum.StudyModel_iRecord,
			ProcedureEnum.Invisalign,
			ProcedureEnum.Appliance,
			ProcedureEnum.FixedRestorative
		]
	},
	{
		OriginalProcedure: ProcedureEnum.FixedRestorative,
		AvailableProcedures: [ProcedureEnum.FixedRestorative]
	}
];
