import { Injectable } from '@angular/core';
import { PositionObject } from '@shared/models/position-object';
import { BehaviorSubject } from 'rxjs';
import { Tooth } from '../models/tooth';
import preDefinedNotesJson from 'src/assets/pre-defined-notes/pre-defined-notes.json';
import { PreDefinedNotesElement } from '../models/pre-defined-notes-json-model';
import { IdName } from '@shared/models/id-name';
import { PreDefinedNoteSelectionMenuElement } from '../models/pre-defined-note-selection-menu-element';

@Injectable({
    providedIn: 'root'
})
export class PreDefinedNotesService {
    get preDefinedNotesJsonData(): PreDefinedNotesElement[] {
        return (preDefinedNotesJson as PreDefinedNotesElement[])
    }

    get preDefinedNotesSelectionMenuOptions(): PreDefinedNoteSelectionMenuElement[] {
        return this.preDefinedNotesJsonData.map(preDefElem => ({
            Id: preDefElem.ID,
            Name: preDefElem.Name,
            subMenuOptions: this.getPreDefinedNotesSubMenuOptionsById(preDefElem.ID)
        }));
    }

    private getPreDefinedNotesSubMenuOptionsById(id: number): IdName[] {
        const selectedPreDefElem: PreDefinedNotesElement = this.preDefinedNotesJsonData.find(preDefElem => preDefElem.ID === id);
        return selectedPreDefElem?.Children?.map(subMenuOption => ({
            Id: subMenuOption.ID,
            Name: subMenuOption.Name
        }));
    }
}