import { Pipe, PipeTransform } from '@angular/core';
import { ShellQuery } from '@shared/store/shell/shell-query';

@Pipe({
    name: 'staticFilesEndpoint',
    standalone: true
})
export class StaticFilesEndpointPipe implements PipeTransform {
	constructor(private shellQuery: ShellQuery) {}

	transform(value: string): string {
		return `${this.shellQuery.staticFilesEndpoint}/${value}`;
	}
}
