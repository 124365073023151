import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { IdName } from '@shared/models/id-name';
import { OrderInformationModel } from '@shared/models/rx-models/interfaces/order-information-model';
import { Observable } from 'rxjs';
import { OrderInformationState, OrderInformationStore } from './order-information.store';

@Injectable({ providedIn: 'root' })
export class OrderInformationQuery extends Query<OrderInformationState> {
	constructor(store: OrderInformationStore) {
		super(store);
	}

	orderInfo$: Observable<OrderInformationModel> = this.select(state => this.getOrderInformationModel(state));
	cadCamSystemOptions$: Observable<IdName[]> = this.select(state => state.cadCamSystemOptions);
	canOrderPhysicalModel$: Observable<boolean> = this.select(state => state.canOrderPhysicalModel);
	isInvalidForSend$: Observable<boolean> = this.select(state => state.invalidForSend);

	getOrderInformationForSave(): OrderInformationModel {
		const orderInformationSnapshot = this.store.getValue();
		return this.getOrderInformationModel(orderInformationSnapshot);
	}

	private getOrderInformationModel(state: OrderInformationState): OrderInformationModel {
		return state
			? {
					LocalIdeCadCamSystemId: state.localIdeCadCamSystemId,
					IdeCadCamSystemId: state.ideCadCamSystemId,
					DieDitch: state.dieDitch,
					AdditionalDies: state.additionalDies,
					NumOfModels: state.numOfModels
			  }
			: null;
	}
}
