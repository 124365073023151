import { LimitedFeatures } from '../limited-features';
import { ScannerTypesByVersioningType } from '../scanner-types';

export const scannerFeatureMapper: Record<number, ScannerTypesByVersioningType> = {
	[LimitedFeatures.ScrewRetainCrown]: {
		clientVersion: { element: '1.11.3', evx: '2.6.3' },
		packageVersion: { element: '1.11.3', evx: '2.6.3', ng: '3' }
	},
	[LimitedFeatures.ProcedureFlowReadOnly]: {
		// minimum scanner version which provided needed API endpoint is 21b(according to the PO)
		clientVersion: { element: '1.13.0', evx: '2.8.0' },
		packageVersion: { element: '1.12.8', evx: '2.7.8' },
		anyVersionFrom: '3'
	},
	[LimitedFeatures.ProcedureFlow]: {
		clientVersion: { element: '1.14.0', evx: '2.9.0' },
		packageVersion: { element: '1.12.9', evx: '2.7.9' },
		anyVersionFrom: '3'
	},
	[LimitedFeatures.SleeveConfirmation]: {
		clientVersion: { element: null, evx: '2.5.0' },
		packageVersion: { element: null, evx: '2.5.0' },
		anyVersionFrom: '3'
	},
	[LimitedFeatures.EmergenceProfile]: {
		clientVersion: { element: null, evx: null, ng: '3.2.1', ngPro: '4.2.1' },
		packageVersion: { element: null, evx: null, ng: '3.2.1', ngPro: '4.2.1' }
	},
	[LimitedFeatures.RestorativeAddRx]: {
		clientVersion: { element: null, evx: null, ng: '3.2.25', ngPro: '4.2.25' },
		packageVersion: { element: null, evx: null, ng: '3.2.25', ngPro: '4.2.25' }
	},
	[LimitedFeatures.RestorativeMultiBite]: {
		clientVersion: { element: null, evx: null, ng: '3.0.0', ngPro: '4.0.0' },
		packageVersion: { element: null, evx: null, ng: '3.0.0', ngPro: '4.0.0' },
		anyVersionFrom: '3'
	},
	[LimitedFeatures.EggshellCrown]: {
		clientVersion: { element: '1.13.15', evx: '2.8.15', ng: '3.1.5', ngPro: '4.1.15' },
		packageVersion: { element: '1.13.15', evx: '2.8.15', ng: '3.1.5', ngPro: '4.1.15' }
	}
};
