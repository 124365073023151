import { NgModule, CUSTOM_ELEMENTS_SCHEMA, Injector, APP_INITIALIZER, ErrorHandler, DoBootstrap } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from '@core/core.module';
import { SharedModule } from '@shared/shared.module';
import { createCustomElement } from '@angular/elements';
import { RxForDoctorModule } from '@modules/rx-for-doctor/rx-for-doctor.module';
import { RxForLabModule } from '@modules/rx-for-lab/rx-for-lab.module';
import { PrintRxModule } from '@modules/print-rx/print-rx.module';
import { MatIconModule } from '@angular/material/icon';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from '@angular/material/core';
import { AppSettingsService } from '@core/services/app-settings/app-settings.service';
import { RxForModelingModule } from '@modules/rx-for-modeling/rx-for-modeling.module';
import { FontsPreloadService } from '@core/services/app-settings/fonts-preload.service';
import { GlobalErrorHandlerService } from '@core/services/global-error-handler.service';
import { HttpErrorInterceptor } from '@core/interceptors/http-error.interceptor';
import { AuthInterceptor } from '@core/interceptors/auth.interceptor';

const globalRippleConfig: RippleGlobalOptions = {
	disabled: true,
	animation: {
		enterDuration: 0,
		exitDuration: 0
	}
};

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		SharedModule,
		RxForDoctorModule,
		RxForLabModule,
		RxForModelingModule,
		PrintRxModule,
		MatIconModule,
		HttpClientModule,
		CoreModule // It should be last or providers will be overridden!!!!
	],
	providers: [
		{
			provide: APP_INITIALIZER,
			multi: true,
			deps: [AppSettingsService, Injector],
			useFactory: (appSettingsService: AppSettingsService, inject: Injector) => {
				return () => {
					return appSettingsService.loadAppSettings().then(() => {
						if (!!customElements.get('rx-app')) {
							return;
						}

						const appComponent = createCustomElement(AppComponent, {
							injector: inject
						});

						customElements.define('rx-app', appComponent);
					});
				};
			}
		},
		{
			provide: APP_INITIALIZER,
			multi: true,
			deps: [FontsPreloadService],
			useFactory: (fontsPreloadService: FontsPreloadService) => {
				return () => fontsPreloadService.loadPreloadSettings();
			}
		},
		{ provide: MAT_RIPPLE_GLOBAL_OPTIONS, multi: true, useValue: globalRippleConfig },
		{ provide: ErrorHandler, useClass: GlobalErrorHandlerService },
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpErrorInterceptor,
			multi: true
		}
	],
	schemas: [
		CUSTOM_ELEMENTS_SCHEMA // Tells Angular we will have custom tags in our templates
	]
})
export class AppModule implements DoBootstrap {
	ngDoBootstrap(): void {}
}
