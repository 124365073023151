import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';
import { RxNote } from '../models/rx-note';
import { RxUILabNote } from '../models/rx-ui-lab-note';
import { NotesState, NotesStore } from './notes-store';

@Injectable({ providedIn: 'root' })
export class NotesQuery extends Query<NotesState> {
	notesArray$: Observable<RxNote[]> = this.select(state => state.notesArray);
	labNotes$: Observable<RxUILabNote[]> = this.select(state => state.labNotes);

	get notes(): string {
		return this.getValue().notes;
	}

	get notesArray(): RxNote[] {
		return this.getValue().notesArray;
	}
	
	constructor(store: NotesStore) {
		super(store);
	}
}
