import {
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import { preDefinedNotesId, unitTypeHeight, unitTypeWidth } from '@modules/teeth-diagram/models/consts';
import { PreDefinedNoteSelectionMenuElement } from '@modules/teeth-diagram/models/pre-defined-note-selection-menu-element';
import { newTooth, Tooth } from '@modules/teeth-diagram/models/tooth';
import { PreDefinedNotesService } from '@modules/teeth-diagram/services/pre-defined-notes.service';
import { IdName } from '@shared/models/id-name';
import { PositionObject } from '@shared/models/position-object';
import { UnitTypes } from '@modules/teeth-diagram/models/unit-type.enum';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { CopyToothService } from '@shared/services/copyTooth.service';
import { TeethDiagramQuery } from '@modules/teeth-diagram/state/teeth-diagram-query';
import { TeethNumberingSystem } from '@modules/teeth-diagram/models/teeth-numbering-system.enum';
import { getToothNumberById } from '@modules/teeth-diagram/models/teeth-numbering';
import { Observable } from 'rxjs';
import { HostPlatformService } from '@shared/services/host-platform.service';
import { UnitTypeInBridgeEvent } from '@shared/models/unit-type-in-bridge-event';

enum MenuOrientationEnum {
	left = 0,
	right = 1
}

const triangle = {
	left: '&#x25C0;',
	right: '&#x25B6;'
};

const triangleOffset = 14;

@Component({
	selector: 'rx-unit-type',
	templateUrl: './unit-type.component.html',
	styleUrls: ['./unit-type.component.scss']
})
export class UnitTypeMenuComponent implements OnInit, OnChanges {
	@Input() toothClicked: [IdName[], { tooth: Tooth; positionObj: PositionObject; overriddenUnitType?: UnitTypes }];
	@Input() preDefinedNoteOption: IdName;
	@Input() isProcedureFlow: boolean;

	@Output() unitTypeSelected = new EventEmitter<{ unitType: number; tooth: Tooth }>();
	@Output() unitTypeInBridgeSelected = new EventEmitter<UnitTypeInBridgeEvent>();
	@Output() preDefinedNoteSelected = new EventEmitter<string>();
	@Output() unitTypeMenuClosed = new EventEmitter<void>();

	@ViewChild('preDefinedNotesSelectionMenu') preDefinedNotesSelectionMenu: ElementRef;

	unitTypes: IdName[];
	tooth: Tooth;
	isUnitTypeMenuVisible = false;
	contextMenuPosition = { x: '0px', y: '0px' };
	arrowIndicatorPosition = { x: '0px', y: '0px' };
	triangle = triangle[MenuOrientationEnum.left];
	menuOrientation: MenuOrientationEnum = MenuOrientationEnum.right;
	MenuOrientationEnum = MenuOrientationEnum;
	unitTypeHeight = unitTypeHeight;
	unitTypeWidth = unitTypeWidth;
	isBridgeContext = false;
	overriddenUnitType?: UnitTypes;
	preDefinedNotesSelectionOptionsWithToothNum: PreDefinedNoteSelectionMenuElement[] = [];
	preDefinedNotesSelectionOptions: PreDefinedNoteSelectionMenuElement[] = [];
	copiedToothNumber$: Observable<string | number>;

	@HostListener('window:resize')
	onWindowResize() {
		this.closeUnitTypeMenu();
	}

	@HostListener('document:click', ['$event'])
	onClickOutsideListener(event: any) {
		// clicked on pre-defined notes
		if (
			event.target.id === 'unit-type-button-pre-defined-notes' ||
			(event.target as HTMLElement).parentElement?.id === 'unit-type-button-pre-defined-notes'
		) {
			return;
		}
		// clicked outside of tooth
		if (!(event.target.id as string).startsWith('tooth_')) {
			this.closeUnitTypeMenu();
		}
	}

	@HostListener('window:scroll')
	onScrollListener() {
		this.closeUnitTypeMenu();
	}

	constructor(
		private preDefinedNotesService: PreDefinedNotesService,
		private shellQuery: ShellQuery,
		private copyToothService: CopyToothService,
		private teethDiagramQuery: TeethDiagramQuery,
		private hostPlatformService: HostPlatformService
	) {}

	ngOnInit() {
		this.preDefinedNotesSelectionOptions = this.preDefinedNotesService.preDefinedNotesSelectionMenuOptions;
		this.copiedToothNumber$ = this.copyToothService.toothNumber$;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes?.toothClicked?.currentValue) {
			const unitTypes = changes.toothClicked.currentValue[0] as IdName[];

			const toothClickObj: {
				tooth: Tooth;
				positionObj: PositionObject;
				overriddenUnitType?: UnitTypes;
			} = changes.toothClicked.currentValue[1];

			this.isBridgeContext = !!toothClickObj.tooth.BridgeIndex;
			this.tooth = toothClickObj.tooth;
			this.unitTypes = [...unitTypes];
			this.overriddenUnitType = toothClickObj.overriddenUnitType;
			this.onContextMenu({ positionObj: toothClickObj.positionObj });
		}
	}

	onContextMenu({ positionObj }: { positionObj: PositionObject }) {
		this.menuOrientation = this.getMenuOrientation(positionObj.clientXposition);
		this.triangle = triangle[this.menuOrientation === MenuOrientationEnum.left ? 'right' : 'left'];

		// set x-axis position
		if (this.menuOrientation === MenuOrientationEnum.right) {
			const rightOffset = 4;

			this.arrowIndicatorPosition.x = `${positionObj.rectRight - rightOffset}px`;
			this.contextMenuPosition.x = `${positionObj.rectRight + triangleOffset}px`;
		} else {
			// menu on left
			const leftOffset = 2;

			this.arrowIndicatorPosition.x = `${positionObj.rectLeft - triangleOffset - leftOffset}px`;
			this.contextMenuPosition.x = `${positionObj.rectLeft - this.unitTypeWidth - triangleOffset}px`;
		}

		// set y-axis position
		let yMenuPosition = positionObj.clientYposition - (this.unitTypeHeight * this.unitTypes.length) / 2;

		if (this.hostPlatformService.isIteroModeling) {
			yMenuPosition = yMenuPosition < 0 ? 0 : yMenuPosition;
		}
		this.contextMenuPosition.y = `${yMenuPosition}px`;
		this.arrowIndicatorPosition.y = `${positionObj.clientYposition}px`;
		this.isUnitTypeMenuVisible = true;
	}

	onContextMenuAction(unitTypeItem: IdName, predefinedNotesTooth?: Tooth, event?: MouseEvent): void {
		if (unitTypeItem?.Id === preDefinedNotesId) {
			const target = event.target as HTMLElement;

			const buttonElemRect: DOMRect =
				target.getAttribute('name') === preDefinedNotesId
					? target.getBoundingClientRect()
					: target.parentElement.getBoundingClientRect();
			const preDefNotesSelectionElem: {
				setAttribute: (attr: string, content: string) => void;
			} = this.preDefinedNotesSelectionMenu?.nativeElement?.parentElement?.parentElement;
			const isMenuOnLeft = buttonElemRect.right + buttonElemRect.width > window.innerWidth;
			const menuOffset = isMenuOnLeft ? `-${buttonElemRect.width - 8}px` : `${buttonElemRect.width}px`;
			const styleContent = `margin-left: ${menuOffset}`;

			preDefNotesSelectionElem.setAttribute('style', styleContent);

			if (!predefinedNotesTooth) {
				return;
			}

			this.fillPredefinedNoteTemplates(predefinedNotesTooth);

			return;
		}
		if (this.isBridgeContext) {
			const unitTypeId = unitTypeItem.Id ?? this.tooth.ToothInBridgeTypeID;

			this.unitTypeInBridgeSelected.emit({
				unitTypeInBridge: unitTypeId,
				tooth: this.tooth,
				clickOnBridge: unitTypeItem.Id === null
			});
		} else if (!unitTypeItem.Id && this.isProcedureFlow && this.tooth.UnitTypeID) {
			this.tooth = { ...newTooth, ToothID: this.tooth.ToothID, UnitTypeID: null };
			this.unitTypeSelected.emit({ unitType: unitTypeItem.Id, tooth: this.tooth });
		} else {
			this.unitTypeSelected.emit({ unitType: unitTypeItem.Id, tooth: this.tooth });
		}
	}

	fillPredefinedNoteTemplates(predefinedNotestooth: Tooth) {
		const toothNumbering = this.getToothNumberingSystemName(predefinedNotestooth);

		const menuOptions = [];

		this.preDefinedNotesService.preDefinedNotesSelectionMenuOptions.forEach(menuOption => {
			const subMenuOptions = [];

			menuOption.subMenuOptions.forEach(subMenuOption => {
				subMenuOption.Name = subMenuOption.Name.replace('%s', toothNumbering);
				subMenuOptions.push(subMenuOption);
			});

			const preDefinedNotesMenuElement = { Id: menuOption.Id, Name: menuOption.Name, subMenuOptions };

			menuOptions.push(preDefinedNotesMenuElement);
		});

		this.preDefinedNotesSelectionOptionsWithToothNum = menuOptions;
	}

	handleSelectPreDefinedNote(selectedPreDefinedNote: IdName) {
		const regexp = new RegExp('%s', 'g');
		const compiledPreDefinedNote = selectedPreDefinedNote.Name.replace(regexp, `${this.tooth.ToothID}`);

		this.preDefinedNoteSelected.emit(compiledPreDefinedNote);
	}

	isOptionSelected = (unitType: IdName): boolean => {
		return (
			!!this.tooth &&
			!!unitType &&
			((this.overriddenUnitType ? this.overriddenUnitType === unitType.Id : this.tooth.UnitTypeID === unitType.Id) ||
				(this.tooth.BridgeIndex > 0 && unitType.Id === null) ||
				(this.tooth.BridgeIndex > 0 && this.tooth.ToothInBridgeTypeID === unitType.Id))
		);
	};

	onCopyTooth() {
		this.copyToothService.copyFromTooth(this.tooth);
	}

	isCopyFromToothEnabled(): boolean {
		return this.copyToothService.isCopyAvailable(this.tooth, this.unitTypes);
	}

	private getMenuOrientation = (clientX: number): MenuOrientationEnum => {
		const isMenuOnRight = clientX + this.unitTypeWidth < window.innerWidth;

		return isMenuOnRight ? MenuOrientationEnum.right : MenuOrientationEnum.left;
	};

	private closeUnitTypeMenu() {
		this.isUnitTypeMenuVisible = false;
		this.unitTypeMenuClosed.emit();
	}

	private getToothNumberingSystemName(tooth: Tooth): string {
		const teethNumberingSystem = this.teethDiagramQuery.teethNumberingSystem;

		return `${TeethNumberingSystem[teethNumberingSystem] ?? ''}${getToothNumberById({
			toothId: tooth.ToothID,
			teethNumberingSystem
		})}`;
	}
}
