import { getNewTooth, Tooth } from '@modules/teeth-diagram/models/tooth';

const defaultTooth = getNewTooth();

export const defaultValues: Record<string, { serverDefault: number; rxappDefault: number }> = {
	MaterialID: { serverDefault: -1, rxappDefault: defaultTooth.MaterialID },
	ShadeSystemId: { serverDefault: -1, rxappDefault: defaultTooth.ShadeSystemId },
	UnitTypeID: { serverDefault: -1, rxappDefault: defaultTooth.UnitTypeID },
	ImplantBasedRestorationTypeId: { serverDefault: -1, rxappDefault: defaultTooth.ImplantBasedRestorationTypeId },
	PonticDesignID: { serverDefault: -1, rxappDefault: defaultTooth.PonticDesignID }
};

// Till some moment some values were saved with 0 value on backend if null, could be get from backend.
export const defaultValuesForBackwardCompatibility: Record<string, { serverDefault: number; rxappDefault: number }> = {
	ImplantBasedRestorationTypeId: { serverDefault: 0, rxappDefault: defaultTooth.ImplantBasedRestorationTypeId },
	PonticDesignID: { serverDefault: 0, rxappDefault: defaultTooth.PonticDesignID },
	SpecificationId: { serverDefault: 0, rxappDefault: defaultTooth.SpecificationId }
};

export const defaultValuesToMapFromBackendToRxApp: Record<string, { serverDefault: number; rxappDefault: number }> = {
	MaterialID: defaultValues.MaterialID,
	UnitTypeID: defaultValues.UnitTypeID,
	ImplantBasedRestorationTypeId: defaultValues.ImplantBasedRestorationTypeId,
	PonticDesignID: defaultValues.PonticDesignID
};

export const getToothWithReplacedForRxAppDefault = (tooth: Tooth): Tooth => {
	const updatedTooth = { ...tooth };
	for (const defaultValuesKey of Object.keys(defaultValuesForBackwardCompatibility)) {
		if (updatedTooth[defaultValuesKey] === defaultValuesForBackwardCompatibility[defaultValuesKey].serverDefault) {
			updatedTooth[defaultValuesKey] = defaultValuesForBackwardCompatibility[defaultValuesKey].rxappDefault;
		}
	}

	for (const defaultValuesKey of Object.keys(defaultValuesToMapFromBackendToRxApp)) {
		if (updatedTooth[defaultValuesKey] === defaultValuesToMapFromBackendToRxApp[defaultValuesKey].serverDefault) {
			updatedTooth[defaultValuesKey] = defaultValuesToMapFromBackendToRxApp[defaultValuesKey].rxappDefault;
		}
	}
	return updatedTooth;
};
