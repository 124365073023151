import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'emptyStringAsHyphen'
})
export class EmptyStringAsHyphenPipe implements PipeTransform {

	transform(value: string, ...args: unknown[]): string {
		return (value) ? value : '-';
	}

}
