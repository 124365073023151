import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { Tooth } from '@modules/teeth-diagram/models/tooth';
import { UnitTypes } from '@modules/teeth-diagram/models/unit-type.enum';
import { TeethImagesService } from '@modules/teeth-diagram/services/teeth-images.service';
import { unitTypesWithoutDetails } from '@shared/models/consts';

@Component({
    selector: 'rx-tooth',
    templateUrl: './tooth.component.html',
    styleUrls: ['./tooth.component.scss'],
    standalone: true
})
export class ToothComponent implements OnChanges, OnDestroy {
	@Input() tooth: Tooth;
	@Input() isReadOnly: boolean;
	@Input() usedFromMiniDiagram = false;
	@Input() isMiniDiagramBridgeSelectionEnabled = false;
	@Output() toothClicked = new EventEmitter<{ tooth: Tooth; event: any; isReadOnly: boolean }>();
	@Output() toothLoaded = new EventEmitter<{ toothId: number }>();
	@Input() isGlidewellOrder: boolean;
	@Input() overriddenUnitType: UnitTypes;

	isNotActionTooth = false;
	toothImageSrc: string;
	imageLoadedTimeout;

	get toothImage() {
		return this.toothImageSrc;
	}

	constructor(private teethImagesService: TeethImagesService) {}

	ngOnChanges(changes: SimpleChanges): void {
		if (
			changes.tooth?.currentValue !== changes.tooth?.previousValue ||
			changes.overriddenUnitType?.currentValue !== changes.overriddenUnitType?.previousValue
		) {
			this.toothImageSrc = this.teethImagesService.getToothImageSrc(this.tooth, this.overriddenUnitType);
		}
		if (changes.isReadOnly?.currentValue !== changes.isReadOnly?.previousValue) {
			this.isNotActionTooth = this.checkIsNotActionTooth();

			if (this.isReadOnly && this.isMiniDiagramBridgeSelectionEnabled) {
				this.isNotActionTooth = !this.isMiniDiagramBridgeSelectionEnabled;
			}
		}
	}

	handleToothClick(event: any): void {
		this.toothClicked.emit({ tooth: this.tooth, event, isReadOnly: this.isReadOnly });
	}

	imageLoaded({ toothId }: { toothId: number }) {
		this.imageLoadedTimeout = setTimeout(() => {
			this.toothLoaded.emit({ toothId });
		}, 0);
	}

	ngOnDestroy(): void {
		clearTimeout(this.imageLoadedTimeout);
	}

	checkIsNotActionTooth(): boolean {
		const isPrep = !unitTypesWithoutDetails.includes(this.tooth?.UnitTypeID);
		const isBridgeTooth = !!this.tooth?.BridgeIndex;
		const isGlidewellWithoutBridge = this.isGlidewellOrder && !isBridgeTooth;

		return this.isReadOnly && (this.usedFromMiniDiagram || (!isPrep && !isBridgeTooth) || isGlidewellWithoutBridge);
	}
}
