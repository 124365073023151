import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { SpecificationEnum } from '@core/procedure-helpers/models/specification.enum';
import { getToothNumberById } from '@modules/teeth-diagram/models/teeth-numbering';
import { TeethNumberingSystem } from '@modules/teeth-diagram/models/teeth-numbering-system.enum';
import { getNewTooth, Tooth } from '@modules/teeth-diagram/models/tooth';
import { ToothEditorModalActions } from '@modules/teeth-diagram/models/tooth-editor-actions.enum';
import { UnitTypesInBridge } from '@modules/teeth-diagram/models/unit-type-in-bridge.enum';
import { UnitTypes } from '@modules/teeth-diagram/models/unit-type.enum';
import { ToothEditorComponentBase } from '@modules/tooth-editor/base/tooth-editor-component-base';
import { ToothEditorFacade } from '@modules/tooth-editor/tooth-editor.facade';
import { TranslateService } from '@ngx-translate/core';
import { BaseDestroyableComponent } from '@shared/base-classes/base-destroyable';
import { PopupIconNames } from '@shared/models/enums/popup-icon.enum';
import { PopUpActions } from '@shared/models/enums/popup-modal-actions.enum';
import { IdName } from '@shared/models/id-name';
import { PositionObject } from '@shared/models/position-object';
import { ForceSaveToothEditorService } from '@shared/services/force-save-tooth-editor.service';
import { PopupService } from '@shared/services/popup.service';
import { extractPositionObject } from '@shared/services/position-object-extractor';
import { combineLatest, Observable, of } from 'rxjs';
import { map, shareReplay, take, takeUntil, tap } from 'rxjs/operators';
import { TLK_MODAL_WINDOW_DATA, TlkModalWindowRef } from '@itero/ui-toolkit-angular/modal-window';

@Component({
	selector: 'rx-tooth-editor',
	styleUrls: ['./tooth-editor.component.scss'],
	templateUrl: 'tooth-editor.component.html',
	providers: [ToothEditorFacade],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToothEditorComponent extends BaseDestroyableComponent implements OnInit, ToothEditorComponentBase {
	private readonly emptyToothClickedOnValues = {
		MaterialID: null,
		PreparationDesignBuccalId: null,
		PreparationDesignBuccal: null,
		PreparationDesignLingualId: null,
		PreparationDesignLingual: null,
		MarginDesignBuccalId: null,
		MarginDesignLingualId: null,
		ShadeSystemId: null,
		ShadeIncisal: null,
		ShadeBody: null,
		ShadeGingival: null,
		StumpfShade: null,
		AbutmentType: null,
		AbutmentMaterialId: null,
		CAMaterialID: null,
		AbutmentMarginStyle: null,
		AbutmentMarginStyleId: null,
		CAMarginStyle: null,
		CAMarginStyleId: null,
		ImplantTypeID: null,
		SpecificationId: SpecificationEnum.None
	};

	getToothNumberById = getToothNumberById;
	teethNumberingSystem$: Observable<TeethNumberingSystem> = this.toothEditorFacade.teethNumberingSystem$;
	teeth$: Observable<Tooth[]> = this.toothEditorFacade.teeth$;
	isBridge$: Observable<boolean> = this.toothEditorFacade.isBridge$;
	toothClickedOn$: Observable<Tooth> = this.toothEditorFacade.toothClickedOn$;
	unitTypes$: Observable<IdName[]>;
	unitTypeMenuInput$: Observable<[IdName[], { tooth: Tooth; positionObj: PositionObject }]>;
	isScanBody$: Observable<boolean> = this.toothClickedOn$.pipe(
		map(tooth => this.toothEditorFacade.checkIsScanBody(tooth)),
		shareReplay({ bufferSize: 1, refCount: true })
	);

	isReadOnly$: Observable<boolean> = this.toothEditorFacade.isReadOnly$;
	unitTypeName$: Observable<string> = this.toothClickedOn$.pipe(
		map(toothClickedOn =>
			this.translateService.instant(`UnitTypes.${toothClickedOn.UnitTypeID ? UnitTypes[toothClickedOn.UnitTypeID] : 'Bridge'}`)
		)
	);

	get isGlidewellOrder(): boolean {
		return this.toothEditorFacade.isGlidewellOrder;
	}

	constructor(
		public dialogRef: TlkModalWindowRef<{ action: ToothEditorModalActions; teeth: Tooth[] }, ToothEditorComponent>,
		private toothEditorFacade: ToothEditorFacade,
		private translateService: TranslateService,
		private snackBar: MatSnackBar,
		private popupService: PopupService,
		private forceSaveToothEditorService: ForceSaveToothEditorService,
		@Inject(TLK_MODAL_WINDOW_DATA)
		public data: {
			unitType: number;
			teeth: Tooth[];
			toothClickedOn: Tooth;
			allTeethInJaw: Tooth[];
			isBridge: boolean;
		}
	) {
		super();
		dialogRef.disableClose = true;
	}

	ngOnInit(): void {
		this.toothEditorFacade.initTeeth({ teeth: this.data.teeth });
		this.toothEditorFacade.updateToothClickedOn({ toothClickedOn: this.data.toothClickedOn });
		this.toothEditorFacade.updateAllTeethInJaw({ allTeethInJaw: this.data.allTeethInJaw });
		this.toothEditorFacade.updateIsBridge({ isBridge: this.data.isBridge });
		this.forceSaveToothEditorService.forceSaveToothEditor$
			.pipe(
				tap(forceSave => {
					if (forceSave) {
						this.forceSave();
					}
				}),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}

	handleToothClick({ tooth, event }: { tooth: Tooth; event: any; isReadOnly?: boolean }): void {
		this.toothEditorFacade.updateToothClickedOn({ toothClickedOn: tooth });

		const positionObj: PositionObject = extractPositionObject({ event });

		this.unitTypes$ = tooth.BridgeIndex
			? this.toothEditorFacade.getUnitTypeNamesForBridge(tooth)
			: this.toothEditorFacade.getUnitTypeNames(tooth);

		const toothClickObj$ = of({ tooth, positionObj });

		this.unitTypeMenuInput$ = combineLatest([this.unitTypes$, toothClickObj$]);
	}

	handleUnitTypeInBridgeSelection({ unitTypeInBridge, tooth }: { unitTypeInBridge: number; tooth: Tooth }): void {
		const clearToothData = [UnitTypesInBridge.MarylandAbutment, UnitTypesInBridge.Skipped, UnitTypesInBridge.Pontic].includes(
			unitTypeInBridge
		);
		const toothToUpdate: Tooth = clearToothData
			? {
					...getNewTooth(),
					ToothID: tooth.ToothID,
					UnitTypeID: tooth.UnitTypeID,
					ToothInBridgeTypeID: tooth.ToothInBridgeTypeID,
					BridgeIndex: tooth.BridgeIndex
			  }
			: tooth;
		const updatedBridgeTooth = {
			...this.emptyToothClickedOnValues,
			...toothToUpdate,
			ToothInBridgeTypeID: unitTypeInBridge
		};

		this.toothEditorFacade.updateToothClickedOn({ toothClickedOn: updatedBridgeTooth });
		this.toothEditorFacade.updateTooth({ tooth: updatedBridgeTooth });
	}

	handleUnitTypeSelection({ unitType, tooth }: { unitType: number; tooth: Tooth }): void {
		const clearToothData = [
			UnitTypes.Regular,
			UnitTypes.Detachable,
			UnitTypes.Missing_edentulousSpace,
			UnitTypes.Missing_noSpace
		].includes(unitType);

		const toothToUpdate: Tooth = clearToothData
			? { ...getNewTooth(), ToothID: tooth.ToothID, UnitTypeID: tooth.UnitTypeID, ToothInBridgeTypeID: tooth.ToothInBridgeTypeID }
			: tooth;

		const updatedBridgeTooth = {
			...toothToUpdate,
			...this.emptyToothClickedOnValues,
			UnitTypeID: unitType
		};

		this.toothEditorFacade.updateToothClickedOn({ toothClickedOn: updatedBridgeTooth });
	}

	handleModalClose({ action }: { action: ToothEditorModalActions }): void {
		combineLatest([this.teeth$, this.isBridge$])
			.pipe(
				take(1),
				tap(([teeth, isBridge]: [Tooth[], boolean]) => {
					if (isBridge) {
						if (action === ToothEditorModalActions.Apply) {
							const bridgeValidation = this.toothEditorFacade.validateBridge({ teeth });

							if (bridgeValidation.isValid) {
								this.dialogRef.close({ action, teeth });
							} else {
								this.snackBar.open(bridgeValidation.validationMessage, null, {
									duration: 2000,
									horizontalPosition: 'center',
									verticalPosition: 'top',
									panelClass: 'bridge-validation-message'
								});
							}
						} else {
							const popUpInput = {
								titleTranslationKey: 'Popup.Confirmation',
								contentTranslationKey: 'ToothEditor.BridgeDeleteWarning',
								iconName: PopupIconNames.Question
							};

							this.popupService
								.openConfirmationPopUp({ popUpInput })
								.pipe(takeUntil(this.componentAlive$))
								.subscribe(result => {
									switch (result) {
										case PopUpActions.Cancel:
											break;
										case PopUpActions.Ok:
											this.dialogRef.close({ action, teeth });
											break;
									}
								});
						}
					} else {
						this.dialogRef.close({ action, teeth });
					}
				}),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}

	forceSave(): void {
		combineLatest([this.teeth$, this.isBridge$])
			.pipe(
				take(1),
				tap(([teeth, isBridge]: [Tooth[], boolean]) => {
					if (isBridge) {
						const bridgeValidation = this.toothEditorFacade.validateBridge({ teeth });

						if (bridgeValidation.isValid) {
							this.forceUpdateTeeth({ teeth });
						} else {
							this.forceUpdateTeeth({ teeth: [] });
						}
					} else {
						this.forceUpdateTeeth({ teeth });
					}
				}),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}

	isReadOnlyBannerVisible(): Observable<boolean> {
		return this.toothEditorFacade.isReadOnlyBannerVisible;
	}

	private forceUpdateTeeth({ teeth }: { teeth: Tooth[] }): void {
		const isBridge = !!teeth[0]?.BridgeIndex;
		let teethToUpdate: Tooth[] = [];

		if (isBridge) {
			teethToUpdate = this.toothEditorFacade.getBridgeTeethToUpdate({
				teethToUpdate: [...teeth],
				teethInJaw: this.data.allTeethInJaw
			});
		} else {
			teethToUpdate = [...teeth];
		}
		this.toothEditorFacade.forceUpdateTeeth({ teethToUpdate });
	}
}
