import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { TeethNumbersComponent } from './components/teeth-numbers/teeth-numbers.component';
import { TeethDiagramV0Component } from './containers/teeth-diagram/v0/teeth-diagram-v0.component';
import { TeethDiagramV1Component } from './containers/teeth-diagram/v1/teeth-diagram-v1.component';
import { BridgeIndicationComponent } from './components/bridge-indication/bridge-indication.component';
import { ArrayIncludesPipe } from '@shared/pipes/array-include.pipe';
import { UnitTypeMenuComponent } from './components/unit-type-menu/unit-type.component';
import { TeethDiagramComponent } from './containers/teeth-diagram/teeth-diagram.component';

@NgModule({
	declarations: [
		UnitTypeMenuComponent,
		TeethDiagramV0Component,
		TeethDiagramV1Component,
		TeethDiagramComponent,
		TeethNumbersComponent,
		BridgeIndicationComponent,
		ArrayIncludesPipe
	],
	imports: [CommonModule, SharedModule],
	exports: [UnitTypeMenuComponent, TeethDiagramComponent, TeethDiagramV1Component, TeethDiagramV0Component]
})
export class TeethDiagramModule {}
