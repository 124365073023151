import { Component, Input } from '@angular/core';
import { map } from 'rxjs/operators';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { Observable } from 'rxjs';
import { Region } from '@shared/models/country-region-rule';
import { AwarenessFacade } from '@modules/awareness/awareness.facade';

@Component({
	selector: 'rx-awareness-qr-code',
	templateUrl: './awareness-qr-code.component.html',
	styleUrls: ['./awareness-qr-code.component.scss']
})
export class AwarenessQrCodeComponent {
	@Input()
	height = '188';
	@Input()
	width = '188';
	@Input() 
	qrName = '';
	linkToSvg$: Observable<string> = this.awarenessFacade.region$.pipe(map(region => this.getLink(region)));
	constructor(private awarenessFacade: AwarenessFacade, private shellQuery: ShellQuery) {}

	private getLink(region: Region): string {
		const regionName = region?.Name ?? 'EMEA';

		return `${this.shellQuery.staticFilesEndpoint}/assets/qr-codes/${this.qrName == '' ? regionName : this.qrName}.svg`;
	}
}
