/* eslint max-len: 0 */
import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { AwarenessPopupLayoutComponent } from './components/awareness-popup-layout/awareness-popup-layout.component';
import { AwarenessQrCodeComponent } from '@modules/awareness/components/awareness-qr-code/awareness-qr-code.component';
import { AwarenessPopupComponent } from './containers/popup/awareness-popup/awareness-popup.component';
import { AwarenessBannerLmrComponent } from '@modules/awareness/containers/awareness-banner/awarness-banner-lmr/awareness-banner-lmr.component';
import { AwarenessBannerGaComponent } from '@modules/awareness/containers/awareness-banner/awarness-banner-ga/awareness-banner-ga.component';
import { AwarenessBannerComponent } from '@modules/awareness/containers/awareness-banner/awarness-banner-base/awareness-banner.component';
import { AwarenessBannerDesignSuiteComponent } from './containers/awareness-banner/awarness-banner-design-suite/awareness-banner-design-suite.component';
@NgModule({
	declarations: [
		AwarenessPopupLayoutComponent,
		AwarenessPopupComponent,
		AwarenessBannerComponent,
		AwarenessQrCodeComponent,
		AwarenessBannerLmrComponent,
		AwarenessBannerGaComponent,
		AwarenessBannerDesignSuiteComponent
	],
	imports: [CommonModule, SharedModule, NgOptimizedImage],
	exports: [AwarenessBannerLmrComponent, AwarenessBannerGaComponent, AwarenessBannerDesignSuiteComponent]
})
export class AwarenessModule {}
