<div [ngClass]=" {'lab-container' : !isIteroLab }">
    <ng-container [ngSwitch]="isProcedureFlow">
        <rx-for-lab-v0 *ngSwitchCase="false">
            <rx-order-information *ngIf="isIteroLab"></rx-order-information>
            <rx-notes *ngIf="isIteroLab" [role]="role"></rx-notes>
        </rx-for-lab-v0>
        <rx-for-lab-v1 *ngSwitchCase="true">
            <rx-order-information *ngIf="isIteroLab"></rx-order-information>
            <rx-notes *ngIf="isIteroLab" [role]="role"></rx-notes>
        </rx-for-lab-v1>
    </ng-container>
</div>