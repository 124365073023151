import { Inject, Injectable } from '@angular/core';
import { Tooth } from '@modules/teeth-diagram/models/tooth';
import { SHARE_TOOTH_PROPS_RULES } from '@shared/rules/rules-tokens';
import { ShareToothPropsConfig } from '@shared/models/share-tooth-props-rule';
import { UnitTypes } from '@modules/teeth-diagram/models/unit-type.enum';
import { DefaultCrownStore } from '@modules/tooth-editor/state/default-crown-store';
import { DefaultCrownQuery } from '@modules/tooth-editor/state/default-crown-query';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { ValidateToothPropertyService } from '@modules/tooth-editor/services/validate-tooth-property.service';
import { ValueOf } from '@shared/utils/type-util';
import { UnitTypesInBridge } from '@modules/teeth-diagram/models/unit-type-in-bridge.enum';
import { SpecificationEnum } from '@core/procedure-helpers/models/specification.enum';
import { TeethManagerService } from '@modules/tooth-editor/state/teeth-manager-service';
import { notIndexesOfTooth } from '@modules/tooth-editor/state/teeth-manager-store';

export interface ToothAndSharedProps {
	tooth: Tooth;
	sharedProps: (keyof Tooth)[];
}

@Injectable({
	providedIn: 'root'
})
export class ToothEditorSharingService {
	constructor(
		@Inject(SHARE_TOOTH_PROPS_RULES) private rules: ShareToothPropsConfig,
		private defaultCrownValueStore: DefaultCrownStore,
		private defaultCrownValueQuery: DefaultCrownQuery,
		private shellQuery: ShellQuery,
		private validatePropertyService: ValidateToothPropertyService,
		private teethManagerService: TeethManagerService
	) {}

	// from one tooth and defaults to all teeth ( one ====> all )
	sharePropsToTeeth(targetTeeth: Tooth[], sourceTooth: Tooth): ToothAndSharedProps[] {
		return targetTeeth?.map(targetTooth => {
			if (targetTooth.ToothID === sourceTooth.ToothID) {
				// return new tooth itself instead of copy
				return { tooth: sourceTooth, sharedProps: [] };
			}

			let toothAndSharedProps = { tooth: targetTooth, sharedProps: [] };

			toothAndSharedProps = this.mergeToothByRulesForDefaultValues(targetTeeth, toothAndSharedProps);

			return this.mergeToothByAlwaysCopyRules(sourceTooth, toothAndSharedProps);
		});
	}

	// from all Teeth and defaults to one tooth ( all =====> one )

	getToothWithSharedProps(teeth: Tooth[], tooth: Tooth): ToothAndSharedProps {
		if (!teeth) {
			return { tooth, sharedProps: [] };
		}

		let toothAndSharedProps = { tooth, sharedProps: [] };

		toothAndSharedProps = this.mergeToothByRulesForDefaultValues(teeth, toothAndSharedProps);

		teeth.forEach(sourceTooth => {
			if (sourceTooth.ToothID === tooth.ToothID) {
				// do not copy values from tooth to itself
				return;
			}
			toothAndSharedProps = this.mergeToothByAlwaysCopyRules(sourceTooth, toothAndSharedProps);
		});

		return toothAndSharedProps;
	}

	resetToothIfNeeded(tooth: Tooth, unitType: UnitTypes): Tooth {
		if (tooth.ToothInBridgeTypeID === UnitTypesInBridge.Missing) {
			return {
				SpecificationId: SpecificationEnum.None,
				ToothID: tooth.ToothID,
				BridgeIndex: tooth.BridgeIndex,
				ToothInBridgeTypeID: tooth.ToothInBridgeTypeID
			};
		} else if (unitType !== UnitTypes.ImplantBased) {
			return {
				...tooth,
				ImplantBasedRestorationTypeId: null,
				AbutmentType: null,
				AbutmentMaterialId: null
			};
		}

		return tooth;
	}

	getDefaultValue(propName: keyof Tooth, tooth: Tooth, teeth: Tooth[]) {
		if (propName === 'ShadeBody') {
			return this.getDefaultBodyForShadeId(tooth.ShadeSystemId, tooth.BridgeIndex, tooth.ToothID, teeth);
		}

		return this.getDefaultProperty(propName, tooth.BridgeIndex, teeth);
	}

	getDefaultBodyForShadeId(
		shadeId: Tooth['ShadeSystemId'],
		bridgeIndex: Tooth['BridgeIndex'],
		toothId: Tooth['ToothID'],
		teeth: Tooth[]
	): Tooth['ShadeBody'] {
		if (!this.shellQuery.isNewAndNotClonedRx) {
			return teeth.find(t => t.ToothID !== toothId && t.ShadeSystemId === shadeId && t.ShadeBody)?.ShadeBody;
		}

		return this.defaultCrownValueQuery.getDefaultShadeBody(bridgeIndex, shadeId);
	}

	private getDefaultProperty(propName: keyof Tooth, bridgeIndex: Tooth['BridgeIndex'], teeth: Tooth[]) {
		if (!this.shellQuery.isNewAndNotClonedRx) {
			return teeth.find(t => {
				const value = t[propName];

				if (typeof value === 'number') {
					return value >= 0;
				}

				return value;
			})?.[propName];
		}

		return this.defaultCrownValueQuery.getDefaultProperty(bridgeIndex, propName);
	}

	private mutateProp(targetTooth: object, propName: keyof Tooth, propValue: ValueOf<Tooth>): void {
		targetTooth[propName] = propValue;
	}

	private mergeToothByAlwaysCopyRules(sourceTooth: Tooth, toothAndSharedProps: ToothAndSharedProps): ToothAndSharedProps {
		const targetTooth = toothAndSharedProps.tooth;
		const constructedTooth: Tooth = { ...targetTooth };
		const constructedSharedProps: (keyof Tooth)[] = [...toothAndSharedProps.sharedProps];

		this.rules.alwaysCopy.forEach(rule => {
			if (!rule.unitTypeNames.includes(targetTooth.ToothInBridgeTypeID)) {
				return;
			}
			rule.propertyNames.forEach(name => {
				if (sourceTooth.ToothInBridgeTypeID === targetTooth.ToothInBridgeTypeID) {
					this.mutateProp(constructedTooth as object, name, sourceTooth[name]);
					constructedSharedProps.push(name);
				}
			});
		});

		return { tooth: constructedTooth, sharedProps: constructedSharedProps };
	}

	private mergeToothByRulesForDefaultValues(teeth: Tooth[], toothAndSharedProps: ToothAndSharedProps): ToothAndSharedProps {
		const targetTooth = toothAndSharedProps.tooth;
		const constructedTooth: Tooth = { ...targetTooth };
		const constructedSharedProps: (keyof Tooth)[] = [...toothAndSharedProps.sharedProps];

		this.rules.whenDefaultsProvided.forEach(rule => {
			if (!rule.unitTypeNames.includes(targetTooth.ToothInBridgeTypeID)) {
				return;
			}

			rule.propertyNames.forEach(name => {
				let value: ValueOf<Tooth> = targetTooth[name];

				if ((!value || value < 0) && this.checkIsNotRemoved(targetTooth, name)) {
					value = this.getDefaultValue(name, targetTooth, teeth);

					if (value && this.validatePropertyService.validatePropsCompatibilityInTooth(constructedTooth, name, value)) {
						this.mutateProp(constructedTooth as object, name, value);
						constructedSharedProps.push(name);
					}
				}
			});
		});

		return { tooth: constructedTooth, sharedProps: constructedSharedProps };
	}

	private checkIsNotRemoved(targetTooth: Tooth, name: keyof Tooth) {
		return !notIndexesOfTooth(name) || !this.teethManagerService.isRemovedByUser(targetTooth, name);
	}
}
